<template>
    <main class="px-4 pt-2 rounded-md darky h-full" style="width: 96vw">
        <back-to @back="back()" />
        <div
            id="create-cLient"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div>
                <div class="p-5">
                    <form @keydown.enter.prevent @submit.prevent="update()">
                        <div>
                            <div class="rounded-md p-4">
                                <div class="grid grid-cols-6 gap-4">
                                    <div
                                        class="col-span-3 rounded-md border-2 p-4"
                                        :class="
                                            userStore.isB2C === true
                                                ? 'col-span-3 '
                                                : 'col-span-6'
                                        "
                                    >
                                        <div class="mb-5 flex">
                                            <span class="text-lg">
                                                {{ $t('account_informations') }}
                                                <hr />
                                            </span>
                                        </div>
                                        <div class="px-3">
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('firstname') + ' *'
                                                    }}</label
                                                >
                                                <input
                                                    v-model="userForm.FirstName"
                                                    @blur="
                                                        v$.userForm.FirstName.$touch()
                                                    "
                                                    autocomplete="name"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .FirstName.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{ $t('lastname') + ' *' }}
                                                </label>
                                                <input
                                                    autocomplete="name"
                                                    v-model="userForm.LastName"
                                                    @blur="
                                                        v$.userForm.LastName.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .LastName.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('login_only') + ' *'
                                                    }}
                                                </label>
                                                <input
                                                    type="text"
                                                    v-model="userForm.Login"
                                                    @blur="
                                                        v$.userForm.LastName.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Login.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('email_address') +
                                                        ' *'
                                                    }}
                                                </label>
                                                <input
                                                    type="email"
                                                    autocomplete="email"
                                                    @blur="
                                                        v$.userForm.Email.$touch()
                                                    "
                                                    v-model="userForm.Email"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Email.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    userStore.isB2C === false &&
                                                    canUpdateUniverses()
                                                "
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    for="universeMs"
                                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                                    >{{
                                                        $t('universe', 2)
                                                    }}</label
                                                >

                                                <Multiselect
                                                    id="universeMs"
                                                    :key="refreshMS"
                                                    class="border darky rounded px-3 py-2 mt-1 w-full"
                                                    v-model="selectedUniverses"
                                                    mode="tags"
                                                    :close-on-select="false"
                                                    :searchable="true"
                                                    :create-option="true"
                                                    :options="universeOptions"
                                                    :allow-empty="false"
                                                    :can-deselect="false"
                                                    :can-clear="false"
                                                    label="name"
                                                    track-by="name"
                                                ></Multiselect>
                                            </div>
                                            <div
                                                class="text-xs italic mt-1"
                                                v-for="error of v$.userForm
                                                    .Universes.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                                    >{{ $t('phone') }}</label
                                                >
                                                <input
                                                    type="tel"
                                                    v-model="userForm.Phone"
                                                    @blur="
                                                        v$.userForm.Phone.$touch()
                                                    "
                                                    autocomplete="tel"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Phone.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2 block"
                                                    >{{ $t('mobile') }}</label
                                                >
                                                <input
                                                    type="tel"
                                                    v-model="
                                                        userForm.MobilePhone
                                                    "
                                                    @blur="
                                                        v$.userForm.MobilePhone.$touch()
                                                    "
                                                    autocomplete="tel"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .MobilePhone.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <span v-if="needPwdReset">
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('password') +
                                                            ' *'
                                                        }}</label
                                                    >
                                                    <div
                                                        class="relative w-full"
                                                    >
                                                        <div
                                                            class="absolute inset-y-0 right-0 flex items-center px-2"
                                                        >
                                                            <icon-button
                                                                class="ml-2 cursor-pointer"
                                                                @click="
                                                                    togglePasswordVisibility()
                                                                "
                                                                :icon="
                                                                    passwordVisibility
                                                                "
                                                                :noStyle="true"
                                                                :iconStyle="'text-zinc-500 pt-2'"
                                                            ></icon-button>
                                                        </div>
                                                        <input
                                                            id="passwordInput"
                                                            v-model="password"
                                                            tabindex="1"
                                                            :type="passwordType"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                </div>
                                                <password-score
                                                    :value="password"
                                                    class="mt-2 mb-6 justify-center ml-40 mr-4"
                                                    @passed="
                                                        isPasswordStrong = true
                                                    "
                                                    @failed="
                                                        isPasswordStrong = false
                                                    "
                                                />
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t(
                                                                'confirm_password',
                                                            ) + ' *'
                                                        }}</label
                                                    >
                                                    <div
                                                        class="relative w-full"
                                                    >
                                                        <div
                                                            class="absolute inset-y-0 right-0 flex items-center px-2"
                                                        >
                                                            <icon-button
                                                                @click="
                                                                    togglePasswordVisibility()
                                                                "
                                                                :icon="
                                                                    passwordVisibility
                                                                "
                                                                class="ml-2 cursor-pointer"
                                                                :noStyle="true"
                                                                :iconStyle="'text-zinc-500 pt-2'"
                                                            ></icon-button>
                                                        </div>
                                                        <input
                                                            id="confirmPasswordInput"
                                                            tabindex="2"
                                                            v-model="
                                                                confirmPassword
                                                            "
                                                            :type="passwordType"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />

                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .confirmPassword
                                                                .$errors"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'passwords_dont_match',
                                                                    )
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="flex justify-center"
                                                >
                                                    <button
                                                        type="button"
                                                        @click="
                                                            needPwdReset = false
                                                        "
                                                        class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                                    >
                                                        {{
                                                            `${$t(
                                                                'cancel',
                                                            )} ${$t(
                                                                'pw_reset',
                                                            )}`
                                                        }}
                                                    </button>
                                                </div>
                                            </span>
                                            <div
                                                class="flex justify-center"
                                                v-else
                                            >
                                                <button
                                                    type="button"
                                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    @click="
                                                        needPwdReset =
                                                            !needPwdReset
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            'reset_pw_modal_title',
                                                        )
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="userStore.isB2C === true"
                                        class="col-span-3 rounded-md border-2 p-4"
                                    >
                                        <div class="px-3">
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{ $t('personnal_infos') }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('company_name') +
                                                        ' *'
                                                    }}
                                                </label>
                                                <input
                                                    autocomplete="name"
                                                    v-model="
                                                        userForm.Client.Name
                                                    "
                                                    @blur="
                                                        v$.userForm.Client.Name.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Client.Name.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >Email
                                                </label>
                                                <input
                                                    type="email"
                                                    autocomplete="email"
                                                    @blur="
                                                        v$.userForm.Client.Email.$touch()
                                                    "
                                                    v-model="
                                                        userForm.Client.Email
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Client.Email.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>

                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{ $t('address') + ' *' }}
                                                </label>
                                                <input
                                                    id="autocomplete"
                                                    v-model="
                                                        userForm.Client.Address1
                                                    "
                                                    @blur="
                                                        v$.userForm.Client.Address1.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t('postal_code') + ' *'
                                                    }}
                                                </label>
                                                <input
                                                    autocomplete="postal_code"
                                                    v-model="
                                                        userForm.Client
                                                            .PostalCode
                                                    "
                                                    @blur="
                                                        v$.userForm.Client.PostalCode.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Client.PostalCode.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{ $t('city') + ' *' }}
                                                </label>
                                                <input
                                                    autocomplete="city"
                                                    v-model="
                                                        userForm.Client.City
                                                    "
                                                    @blur="
                                                        v$.userForm.Client.City.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.userForm
                                                    .Client.City.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{
                                                        $t(
                                                            'registration_number',
                                                        )
                                                    }}
                                                </label>
                                                <input
                                                    v-model="
                                                        userForm.Client.Siret
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >TVA
                                                </label>
                                                <input
                                                    v-model="
                                                        userForm.Client.VatIntra
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >RCS
                                                </label>
                                                <input
                                                    v-model="
                                                        userForm.Client.RCS
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    class="flex mt-1 italic text-xs items-center justify-center"
                                >
                                    {{ $t('required_fields') }}
                                </span>
                            </div>
                            <div class="p-2 mt-4">
                                <div class="flex justify-center">
                                    <button
                                        type="button"
                                        @click="back()"
                                        class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                    >
                                        {{ $t('cancel') }}
                                    </button>
                                    <!-- :disabled="
                                            updating ||
                                            (needPwdReset === true &&
                                                !isPasswordStrong)
                                        " -->
                                    <button
                                        type="submit"
                                        :disabled="
                                            updating ||
                                            (needPwdReset === true &&
                                                !isPasswordStrong)
                                        "
                                        class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                    >
                                        <span v-if="updating">
                                            <fa-icon
                                                icon="spinner"
                                                spin-pulse
                                            ></fa-icon
                                        ></span>
                                        <span v-else>
                                            {{ $t('save') }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import IconButton from '@/components/atoms/IconButton.vue'
import PasswordScore from '@/components/atoms/PasswordScore.vue'
import BackTo from '@/components/elements/BackTo.vue'
import googleScriptLoader from '@/mixins/googleScript'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import {
    email,
    helpers,
    required,
    requiredIf,
    sameAs,
} from '@vuelidate/validators'
import { mapActions, mapState, mapStores } from 'pinia'
import { ref } from 'vue'

const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
)
const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)
export default {
    name: 'MyAccount',
    components: { IconButton, BackTo, PasswordScore, Multiselect },
    mixins: [googleScriptLoader],

    setup() {
        const isPasswordStrong = ref(false)

        return { isPasswordStrong, v$: useVuelidate() }
    },
    // TODO ajouter la modif du mdp comme dans suppliers
    mounted() {
        window.scrollTo(0, 0)
        this.userStore.fetchOne(this.$route.params.id).then(() => {
            const userInterval = setInterval(() => {
                if (this.user && this.userStore.isB2C === true) {
                    clearInterval(userInterval)
                    this.userForm = {
                        Id: +this.user.id,
                        Login: this.user.login,
                        Email: this.user.email_address,
                        FirstName: this.user.firstname,
                        LastName: this.user.lastname,
                        MobilePhone: this.user.mobile,
                        Phone: this.user.phone,
                        ModificationUserId: this.userStore.current.id,
                    }
                    // this.userStore.userUniverses.forEach((universe) => {
                    //     this.userForm.Universes.push({ Id: universe.value })
                    // })
                    this.userForm.Client = {
                        Name:
                            this.user.client.Name ??
                            `${this.user.firstname} ${this.user.lastname}`,

                        Address1: this.user.client.Address1,

                        City: this.user.client.City,
                        PostalCode: this.user.client.PostalCode,
                        Country: this.user.client.Country,
                        Id: this.user.client.Id,
                        Email: this.user.client.Email,
                        Siret: this.user.client.Siret,
                        PaymentType: this.user.client.PaymentType,
                        VatIntra: this.user.client.VatIntra,
                        RCS: this.user.client.RCS,

                        ModificationUserId: this.userStore.current.id,
                    }
                } else if (this.user && this.userStore.isB2C === false) {
                    clearInterval(userInterval)
                    this.userForm = {
                        Id: +this.user.id,
                        Login: this.user.login,
                        Email: this.user.email_address,
                        FirstName: this.user.firstname,
                        LastName: this.user.lastname,
                        MobilePhone: this.user.mobile,
                        Phone: this.user.phone,
                        Type: this.user.type,
                        CreationUserId: this.user.creation_user_id,
                        Universes: [],
                    }

                    if (
                        this.user.type === 'admin' ||
                        this.user.type === 'superadmin'
                    ) {
                        const universeSearch = {
                            sortDirection: 'ASC',
                            sortField: 'Name',
                            perPage: 10000,
                            page: 1,
                            query: [],
                            filter: [],
                        }
                        this.searchUniverses(universeSearch).then((res) => {
                            res.data.Records.forEach((universe) => {
                                this.universeOptions.push({
                                    name: universe.Name,
                                    value: universe.Id,
                                    realName: universe.Name,
                                })
                            })
                            this.userStore.userUniverses.forEach((u) => {
                                this.selectedUniverses.push(u.value)
                                this.refreshMS += 1
                            })
                        })
                    } else {
                        this.userStore.userUniverses.forEach((universe) => {
                            this.universeOptions.push({
                                name: universe.name,
                                value: universe.value,
                                dbField: 'IdUniverse',
                                realName: universe.name,
                            })
                            this.selectedUniverses.push(universe.value)
                            this.refreshMS += 1
                        })
                    }
                }
            }, 1000)
        })
        this.loadGoogleScript('autocomplete')
    },
    data() {
        return {
            universeOptions: [],
            selectedUniverses: [],
            password: '',
            needPwdReset: false,
            confirmPassword: '',
            passwordType: 'password',
            passwordVisibility: 'eye',
            updating: false,
            userForm: { Client: {} },
            refreshMS: 0,
        }
    },
    validations() {
        return {
            password: {
                required: requiredIf(function (abc) {
                    return this.needPwdReset === true
                }),
            },
            confirmPassword: { sameAs: sameAs(this.password) },
            userForm: {
                Email: { email, required },
                Login: { required },
                FirstName: { required },
                LastName: { required },
                Phone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                MobilePhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                Universes: {
                    required: requiredIf(this.userStore.isB2C === false),
                },
                Client: {
                    Name: {
                        required: requiredIf(this.userStore.isB2C === true),
                    },
                    Email: { email },
                    Address1: {
                        required: requiredIf(this.userStore.isB2C === true),
                    },
                    PostalCode: {
                        required: requiredIf(this.userStore.isB2C === true),
                        isZipCode: helpers.withMessage(
                            this.$t('bad_zip_format'),
                            isZipCode,
                        ),
                    },

                    City: {
                        required: requiredIf(this.userStore.isB2C === true),
                    },
                },
            },
        }
    },
    computed: {
        ...mapState(useClientStore, {
            user: 'currentContact',
            client: 'current',
        }),
        ...mapStores(useUserStore),
    },

    methods: {
        canUpdateUniverses() {
            return checkAccess(this.userStore, actionsAccess.universes.update)
        },
        ...mapActions(useUniverseStore, { searchUniverses: 'search' }),
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.$emit('back', backPath.slice(0, 1))
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the clientForm.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.userForm.Client.Address1 = ''

            this.userForm.Client.PostalCode = ''
            this.userForm.Client.City = ''

            const userInput = this.userForm.Client.Address1
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name

                if (addressType === 'postal_code') {
                    this.userForm.Client.PostalCode = val
                } else if (addressType === 'locality') {
                    this.userForm.Client.City = val
                } else if (addressType === 'street_number') {
                    this.userForm.Client.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.userForm.Client.Address1 =
                        this.userForm.Client.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.userForm.Client.Address1} ${val}`
                            : `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.userForm.Client.Address1 === userInput
                ) {
                    this.userForm.Client.Address1 = ` ${val}`
                }
            })
        },
        togglePasswordVisibility() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text'
                this.passwordVisibility = 'eye-slash'
            } else {
                this.passwordType = 'password'
                this.passwordVisibility = 'eye'
            }
        },
        async update() {
            this.updating = true
            this.userForm.ModificationUserId = this.user.id
            this.userForm.ModificationDate = new Date()

            if (this.userStore.isB2C === true) {
                this.userForm.Client.ModificationUserId = this.user.id
                this.userForm.Client.ModificationDate = new Date()
            } else {
                this.selectedUniverses.forEach((uni) => {
                    this.userForm.Universes.push({ Id: uni })
                })
            }
            if (this.needPwdReset === true) {
                this.userForm.Password = this.password
            }
            const pwOk =
                (await this.v$.password.$validate()) &&
                (await this.v$.confirmPassword.$validate())
            if (this.needPwdReset === false || pwOk === true) {
                const isUserFormCorrect = await this.v$.userForm.$validate()
                // const isClientFormCorrect = await this.v$.clientForm.$validate()

                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (
                    // isClientFormCorrect === true &&
                    isUserFormCorrect === true
                ) {
                    const params = {
                        id: this.$route.params.id,
                        form: this.userForm,
                    }
                    try {
                        let res = null
                        if (this.userStore.isB2C === true) {
                            res =
                                await this.userStore.updateUserAndClient(params)
                        } else {
                            res = await this.userStore.update(params)
                        }
                        if (res && res.status === 200) {
                            this.needPwdReset = false
                            this.userStore.refreshConnectedUser(
                                false,
                                true,
                                this.userForm.Id,
                            )

                            this.$toast.success(this.$t('user_update_ok'))
                            this.updating = false

                            this.back()
                        } else {
                            this.updating = false
                            this.$toast.error(`${this.$t('user_update_ko')}`)
                        }
                    } catch (err) {
                        this.updating = false

                        this.$toast.error(`${this.$t('user_update_ko')}`)
                        this.$toast.error(err.data.message)
                    }
                } else {
                    this.updating = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.updating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {},
}
</script>
