<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md darky h-full"
        :class="creating === true ? 'cursor-progress' : ''"
    >
        <back-to @back="back()" />

        <div
            id="create-order"
            :key="refresh"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div v-if="isB2C !== null && isB2C === false" class="p-5">
                <form class="mb-2" @keydown.enter.prevent @submit.prevent="">
                    <div>
                        <div class="mb-2">
                            <span
                                v-if="
                                    $route.params.id &&
                                    (formData.Reference || formData.IdExternal)
                                "
                                class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                            >
                                <span
                                    v-if="$route.params.id"
                                    class="items-center flex ml-1"
                                >
                                    <span
                                        v-if="formData.Reference"
                                        class="cursor-copy custom-link"
                                        :title="copyTitle"
                                        @click="
                                            copyToClipboard(formData.Reference)
                                        "
                                    >
                                        {{ formData.Reference }}
                                        <fa-icon
                                            icon="copy"
                                            class="fa-xs ml-2"
                                        ></fa-icon>
                                    </span>
                                    <span
                                        v-if="formData.IdExternal"
                                        class="cursor-copy custom-link ml-2"
                                        :title="`${$t(
                                            'external_ref',
                                        )} - ${copyTitle}`"
                                        @click.stop="
                                            copyToClipboard(formData.IdExternal)
                                        "
                                    >
                                        {{ ` - ${formData.IdExternal}` }}

                                        <fa-icon
                                            icon="copy"
                                            class="fa-xs ml-2"
                                        ></fa-icon>
                                    </span>
                                    <span
                                        v-if="
                                            currentOrder &&
                                            currentOrder.files &&
                                            currentOrder.files.length > 0 &&
                                            $route.params.id
                                        "
                                        class="text-xs ml-6"
                                    >
                                        <dropdown-menu
                                            id="orderFiles"
                                            :noTranslate="true"
                                            :title="$t('delivery_files')"
                                            :head="$t('delivery_files')"
                                            :items="currentOrder.files"
                                            @picked="viewImportedPDF($event)"
                                        />
                                        <!-- <span
                                            v-for="(
                                                deliveryOrderFile, dofIndex
                                            ) in currentOrder.files"
                                            :key="dofIndex"
                                        >
                                            <button
                                                @click.prevent="
                                                    viewImportedPDF(
                                                        deliveryOrderFile,
                                                    )
                                                "
                                                :title="$t('see_file')"
                                                class="hover:font-semibold"
                                            >
                                                <span
                                                    class="items-center flex custom-link"
                                                >
                                                    <fa-icon
                                                        icon="eye"
                                                        class="fa-sm mr-2"
                                                    ></fa-icon>
                                                    {{ $t('imported_pdf') }}
                                                </span>
                                            </button>
                                        </span> -->
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            v-if="
                                $route.name === 'new_order' &&
                                recurrentOrder === false
                            "
                            class="rounded-md border-2 p-4 col-span-2"
                        >
                            <div
                                class="text-sm italic custom-link items-center pl-6 pt-2 my-2 flex justify-center"
                            >
                                <fa-icon
                                    icon="fa-lightbulb"
                                    class="mr-2"
                                ></fa-icon>
                                {{ $t('delivery_name_info_operator') }}
                            </div>
                            <div
                                class="items-center pl-6 mb-2 flex justify-center"
                            >
                                <label
                                    for="deliveryName"
                                    class="font-semibold custom-link text-2xl text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('delivery_name') + ' *' }}</label
                                >
                                <input
                                    id="deliveryName"
                                    type="name"
                                    v-model="newDeliveryName"
                                    class="border rounded py-2 px-2"
                                />
                                <div
                                    class="text-xs italic mt-1 ml-2 mb-2"
                                    v-for="error of v$.newDeliveryName.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 rounded-md p-2 border-2">
                            <div class="mb-2 flex">
                                <span class="text-lg">
                                    {{
                                        $t('references') +
                                        ' - ' +
                                        $t('comments')
                                    }}
                                    <hr />
                                </span>
                            </div>
                            <div class="grid grid-cols-6 gap-2">
                                <div class="col-span-1 items-center">
                                    <label
                                        for="Ref"
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('reference') }}</label
                                    >
                                    <input
                                        id="Ref"
                                        type="text"
                                        v-model="formData.Reference"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                    <!-- @blur="v$.formData.Reference.$touch" -->
                                </div>
                                <div class="col-span-1 items-center">
                                    <label
                                        for="externalRef"
                                        class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                        >{{ $t('external_ref') }}</label
                                    >
                                    <input
                                        id="externalRef"
                                        type="text"
                                        :disabled="
                                            ($route.name === 'new_order' &&
                                                !canCreateIdExternal) ||
                                            ($route.name === 'update_order' &&
                                                !canUpdateIdExternal)
                                        "
                                        v-model="formData.IdExternal"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                    <!-- @blur="v$.formData.Reference.$touch" -->
                                </div>
                                <!-- <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.formData.Reference.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div> -->
                                <div
                                    class="items-center"
                                    :class="
                                        formData.CommentClient &&
                                        formData.CommentClient !== ''
                                            ? 'col-span-2'
                                            : 'col-span-4'
                                    "
                                >
                                    <div class="flex justify-center items-end">
                                        <label
                                            for="internalComment"
                                            class="font-semibold text-gray-600 mr-2"
                                            >{{
                                                $t('internal_comments')
                                            }}</label
                                        >
                                        <span class="text-xs italic">
                                            {{
                                                $t(
                                                    'comment_not_visible_by_client',
                                                )
                                            }}
                                        </span>
                                    </div>
                                    <textarea
                                        id="internalComment"
                                        type="textarea"
                                        v-model="formData.CommentInternal"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    v-if="
                                        formData.CommentClient &&
                                        formData.CommentClient !== ''
                                    "
                                    class="items-center col-span-2"
                                >
                                    <div class="flex justify-center items-end">
                                        <label
                                            for="clientComment"
                                            class="font-semibold text-gray-600 mr-2"
                                            >{{ $t('client_comments') }}</label
                                        >
                                        <span class="text-xs italic">
                                            {{
                                                $t(
                                                    'comment_visible_by_client_info',
                                                )
                                            }}
                                        </span>
                                    </div>
                                    <textarea
                                        id="clientComment"
                                        type="textarea"
                                        disabled
                                        v-model="formData.CommentClient"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-8 gap-2 mt-2">
                            <div
                                v-if="!isB2C"
                                class="rounded-md border-2 p-4 col-span-2"
                            >
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{
                                            $t('client') +
                                            ' - ' +
                                            $t('contact') +
                                            ' - ' +
                                            $t('billing')
                                        }}
                                        <hr />
                                    </span>
                                </div>
                                <div
                                    v-if="!$route.params.id"
                                    class="mb-3 items-center px-3"
                                >
                                    <div
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                    >
                                        {{ $t('client') + ' *' }}
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-if="noClientError"
                                    >
                                        <div class="theme-color">
                                            {{
                                                $t(
                                                    'no_client_found_please_create_one',
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <Multiselect
                                        id="clientMS"
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        :class="
                                            isDuplicate === true
                                                ? 'cursor-not-allowed'
                                                : 'cursor-pointer'
                                        "
                                        v-model="formData.IdClient"
                                        :options="clientOptions"
                                        label="name"
                                        :key="refreshMS"
                                        track-by="name"
                                        :disabled="isDuplicate === true"
                                        :searchable="true"
                                        :placeholder="$t('select_clients')"
                                        :allow-empty="false"
                                        :can-clear="false"
                                        @select="
                                            fetchContactsAddressesAndContainers(
                                                $event,
                                            )
                                        "
                                        @search-change="
                                            searchClientsQuery($event)
                                        "
                                    ></Multiselect>

                                    <div
                                        v-if="
                                            !$route.params.id &&
                                            formData.IdClient &&
                                            currentClient
                                        "
                                        class="my-2"
                                    >
                                        <span class="text-sm italic">
                                            <div>
                                                {{ currentClient.company_name }}
                                            </div>
                                            <span
                                                v-if="
                                                    currentClient.default_user
                                                "
                                            >
                                                <div
                                                    class="custom-link cursor-copy"
                                                    @click.prevent="
                                                        copyToClipboard(
                                                            currentClient
                                                                .default_user
                                                                .Email,
                                                        )
                                                    "
                                                >
                                                    {{
                                                        currentClient
                                                            .default_user.Email
                                                    }}
                                                    <fa-icon
                                                        icon="copy"
                                                        class="fa-xs ml-2"
                                                    ></fa-icon>
                                                </div>

                                                <div>
                                                    {{
                                                        currentClient
                                                            .default_user.Phone
                                                    }}
                                                </div>
                                            </span>
                                            <div v-if="currentClient.vat_intra">
                                                {{
                                                    $t('vat') +
                                                    ' ' +
                                                    currentClient.vat_intra
                                                }}
                                            </div>
                                            <div v-else class="text-xs italic">
                                                {{ $t('vat_not_specified') }}
                                            </div>
                                        </span>
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.IdClient
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-if="noClientError"
                                    >
                                        <div class="theme-color">
                                            {{
                                                $t(
                                                    'no_client_found_please_create_one',
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <span
                                        class="flex items-center justify-between"
                                    >
                                        <div
                                            v-if="canCreateClient"
                                            class="mt-4 flex items-center"
                                        >
                                            <button
                                                @click.stop="toCreateClient"
                                                class="themed-button items-center my-2 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                                            >
                                                <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500" -->
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    ><fa-icon
                                                        icon="plus"
                                                        class="mr-2"
                                                    ></fa-icon
                                                    >{{
                                                        $t('new_client')
                                                    }}</span
                                                >
                                            </button>
                                        </div>

                                        <div
                                            v-if="
                                                canUpdateClient &&
                                                formData.IdClient
                                            "
                                            class="mt-4 flex justify-center"
                                        >
                                            <button
                                                @click.stop="
                                                    toUpdateClient(
                                                        formData.IdClient,
                                                    )
                                                "
                                                class="themed-button items-center my-2 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                                            >
                                                <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500" -->
                                                <span
                                                    class="inline-block ml-1 font-bold"
                                                    ><fa-icon
                                                        icon="pen"
                                                        class="mr-2"
                                                    ></fa-icon
                                                    >{{
                                                        $t('update_client')
                                                    }}</span
                                                >
                                            </button>
                                        </div>
                                    </span>
                                </div>
                                <div
                                    v-else-if="
                                        currentOrder && currentOrder.client
                                    "
                                    class="mb-3"
                                >
                                    <span class="text-sm italic">
                                        <div class="mt-2">
                                            {{ currentOrder.client.Name }}
                                        </div>
                                        <span
                                            v-if="
                                                currentOrder.client.DefaultUser
                                            "
                                        >
                                            <div
                                                class="custom-link cursor-copy"
                                                @click.prevent="
                                                    copyToClipboard(
                                                        currentOrder.client
                                                            .DefaultUser.Email,
                                                    )
                                                "
                                            >
                                                {{
                                                    currentOrder.client
                                                        .DefaultUser.Email
                                                }}
                                                <fa-icon
                                                    icon="copy"
                                                    class="fa-xs ml-2"
                                                ></fa-icon>
                                            </div>
                                            <div>
                                                {{
                                                    currentOrder.client
                                                        .DefaultUser.Phone
                                                }}
                                            </div>
                                        </span>
                                        <div
                                            v-if="currentOrder.client.VATIntra"
                                        >
                                            {{
                                                $t('vat') +
                                                ': ' +
                                                currentOrder.client.VATIntra
                                            }}
                                        </div>
                                        <div v-else class="text-xs italic">
                                            {{ $t('vat_not_specified') }}
                                        </div>
                                    </span>
                                    <div
                                        v-if="
                                            canUpdateClient &&
                                            currentOrder.client.Id
                                        "
                                        class="mt-4 flex justify-center"
                                    >
                                        <button
                                            @click.stop="
                                                toUpdateClient(
                                                    currentOrder.client.Id,
                                                )
                                            "
                                            class="themed-button items-center my-2 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                                        >
                                            <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500" -->
                                            <span
                                                class="inline-block ml-1 font-bold"
                                                ><fa-icon
                                                    icon="pen"
                                                    class="mr-2"
                                                ></fa-icon
                                                >{{ $t('update_client') }}</span
                                            >
                                        </button>
                                    </div>
                                </div>

                                <div
                                    v-if="
                                        formData.IdClient &&
                                        contactOptions.length !== null
                                    "
                                    class="mb-3 items-center px-3"
                                >
                                    <hr class="mb-2" />

                                    <div
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                    >
                                        {{ $t('contact') }}
                                    </div>
                                    <Multiselect
                                        :key="refreshMS"
                                        id="contactMS"
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        v-model="formData.IdUserContact"
                                        :options="contactOptions"
                                        :loading="contactOptions.length === 0"
                                        label="name"
                                        track-by="name"
                                        :searchable="true"
                                        :placeholder="$t('select_contact_info')"
                                        :allow-empty="false"
                                        :can-clear="false"
                                    ></Multiselect>
                                    <!-- <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData
                                            .IdUserContact.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div> -->
                                </div>
                                <div
                                    v-if="selectedContact"
                                    class="text-sm mt-2 italic"
                                >
                                    <div>
                                        {{
                                            ` ${
                                                selectedContact.FirstName
                                            } ${selectedContact.LastName}`
                                        }}
                                    </div>
                                    <div
                                        class="custom-link cursor-copy"
                                        @click.prevent="
                                            copyToClipboard(
                                                selectedContact.Email,
                                            )
                                        "
                                    >
                                        {{ `${selectedContact.Email}` }}
                                        <fa-icon
                                            icon="copy"
                                            class="fa-xs ml-2"
                                        ></fa-icon>
                                    </div>
                                    <div v-if="selectedContact.Phone">
                                        {{ `${selectedContact.Phone}` }}
                                    </div>
                                    <div v-if="selectedContact.MobilePhone">
                                        {{ `${selectedContact.MobilePhone}` }}
                                    </div>
                                </div>
                                <div
                                    v-if="noContactForClient === true"
                                    class="rounded-md border-1 p-4"
                                >
                                    {{ $t('no_contact_found_for_client') }}
                                </div>
                                <div class="mt-2" v-if="formData.IdClient">
                                    <hr />
                                    <div class="mb-3 mt-2">
                                        <div
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 blockk"
                                        >
                                            {{ $t('universe', 1) + ' *' }}
                                        </div>
                                        <div class="mb-2 mt-2">
                                            <div class="text-xs italic mt-1">
                                                {{
                                                    $t('default_universe_info')
                                                }}
                                            </div>
                                            <div class="text-xs italic mt-1">
                                                {{
                                                    $t('default_billing_info2')
                                                }}
                                            </div>
                                        </div>
                                        <Multiselect
                                            :id="'universeMS'"
                                            :key="refreshMS"
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            v-model="formData.IdUniverse"
                                            :searchable="false"
                                            :options="universeOptions"
                                            :allow-empty="false"
                                            :can-deselect="false"
                                            :can-clear="false"
                                            label="name"
                                            track-by="name"
                                            @select="
                                                loadAvailableDrivers(minDate)
                                            "
                                        ></Multiselect>
                                    </div>
                                    <span v-if="recurrentOrder === false">
                                        <div class="mb-2 mt-2">
                                            <span
                                                class="font-semibold text-gray-600"
                                            >
                                                {{ $t('billing_info') }}
                                            </span>
                                            <div class="text-xs italic mt-1">
                                                {{ $t('default_billing_info') }}
                                            </div>
                                            <div class="text-xs italic mt-1">
                                                {{
                                                    $t('default_billing_info2')
                                                }}
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <div
                                                class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                            >
                                                {{ $t('billing_type') + ' *' }}
                                            </div>
                                            <Multiselect
                                                id="billingtype"
                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                v-model="
                                                    formData.OrderClientType
                                                "
                                                :key="refreshMS"
                                                :options="orderTypeOptions"
                                                :searchable="false"
                                                :allow-empty="false"
                                                :can-deselect="false"
                                                :can-clear="false"
                                                label="name"
                                                track-by="name"
                                                @select="
                                                    selectOrderClientType()
                                                "
                                            ></Multiselect>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .OrderClientType.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <div
                                                class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                            >
                                                {{ $t('payment_type') + ' *' }}
                                            </div>
                                            <Multiselect
                                                id="payment"
                                                :key="refreshMS"
                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                v-model="formData.PaymentType"
                                                :options="paymentTypeOptions"
                                                :searchable="false"
                                                :allow-empty="false"
                                                :can-deselect="false"
                                                :can-clear="false"
                                                label="name"
                                                track-by="name"
                                                @select="selectPaymentType()"
                                            ></Multiselect>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .PaymentType.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    formData.PaymentType ===
                                                    'web'
                                                "
                                                class="text-xs italic mt-1 mb-2"
                                            >
                                                {{ $t('web_payment_info') }}
                                            </div>
                                        </div>
                                        <div
                                            class="flex my-2 items-center justify-center"
                                        >
                                            <input
                                                id="vat-checkbox"
                                                type="checkbox"
                                                v-model="formData.WithVAT"
                                                class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                            />
                                            <label
                                                for="vat-checkbox"
                                                class="ml-2 font-medium text-base"
                                                >{{ $t('with_vat') }}</label
                                            >
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <div class="rounded-md border-2 p-2 col-span-3">
                                <div
                                    v-if="canAccessRecurrent"
                                    class="rounded-md p-4 border-2"
                                >
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('recurrence') }}
                                            <hr />
                                        </span>
                                    </div>

                                    <div
                                        class="flex items-center justify-center my-4"
                                    >
                                        <input
                                            id="recurrence-checkbox"
                                            type="checkbox"
                                            :disabled="
                                                $route.params.id &&
                                                recurrentOrder === true
                                            "
                                            v-model="recurrentOrder"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="recurrence-checkbox"
                                            class="ml-2 text-sm font-medium theme-color"
                                            >{{
                                                $t('order_is_recurrent')
                                            }}</label
                                        >
                                    </div>
                                    <div
                                        v-show="recurrentOrder === true"
                                        :key="refreshMS"
                                    >
                                        <div class="flex items-center">
                                            <span
                                                class="whitespace-nowrap text-base mr-2"
                                            >
                                                {{ $t('name') + ' *' }}
                                            </span>
                                            <input
                                                :key="refreshMS"
                                                type="name"
                                                v-model="
                                                    recurrentForm.recurrentName
                                                "
                                                @blur="
                                                    v$.recurrentForm
                                                        .recurrentName.$touch;
                                                "
                                                class="border rounded py-2 px-2 w-full"
                                            />
                                            <div
                                                class="text-xs italic mt-1 ml-2 mb-2"
                                                v-for="error of v$.recurrentForm
                                                    .recurrentName.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center mt-3">
                                            <span
                                                class="whitespace-nowrap text-base mr-2"
                                            >
                                                {{ $t('repeat_every') + ' *' }}
                                            </span>
                                            <input
                                                type="number"
                                                min="1"
                                                :key="refreshMS"
                                                v-model="repetitionFrequency"
                                                class="border rounded px-3 py-2 h-10 mr-2 w-16"
                                                @change="liveRefresh()"
                                            />

                                            <Multiselect
                                                id="recurrenceMS"
                                                v-model="
                                                    recurrentForm.occurrenceFrequency
                                                "
                                                @select="
                                                    setRepetitionFrequency(
                                                        $event,
                                                    )
                                                "
                                                :key="refreshMS"
                                                class="darky"
                                                :options="frequenceOptions"
                                                :searchable="false"
                                                :can-clear="false"
                                                :can-deselect="false"
                                                :allow-empty="false"
                                                label="name"
                                                track-by="name"
                                                :object="true"
                                            ></Multiselect>
                                        </div>

                                        <span
                                            v-if="
                                                recurrentForm
                                                    .occurrenceFrequency
                                                    .value === 'weekly'
                                            "
                                        >
                                            <div
                                                class="whitespace-nowrap flex justify-start text-base mt-3 mb-2 mr-2"
                                            >
                                                {{ $t('repeat_on') + ' *' }}
                                            </div>
                                            <span
                                                :key="refreshMS"
                                                class="flex items-center ml-10"
                                            >
                                                <!-- <span
                                            > -->
                                                <span
                                                    v-for="(
                                                        recurrenceDay, index
                                                    ) in recurrenceDays"
                                                    :key="index"
                                                    style="
                                                        color: black !important;
                                                    "
                                                    class="rounded-full w-6 h-6 cursor-pointer mr-2"
                                                    @click.prevent="
                                                        addDayToPicked(
                                                            recurrenceDay.value,
                                                        )
                                                    "
                                                    :class="
                                                        recurrentForm.pickedDaysForRecurrence.includes(
                                                            recurrenceDay.value,
                                                        )
                                                            ? 'themed-bg font-semibold'
                                                            : 'bg-zinc-200'
                                                    "
                                                >
                                                    {{ $t(recurrenceDay.name) }}
                                                </span>
                                                <!-- </span> -->
                                            </span>
                                        </span>
                                        <div
                                            class="whitespace-nowrap flex justify-start text-base mt-3 mb-2 mr-2"
                                        >
                                            {{ $t('starts') + ' *' }}
                                        </div>
                                        <div
                                            class="flex items-center ml-10 mb-6"
                                        >
                                            <input
                                                type="date"
                                                :min="minDate"
                                                :key="refreshMS"
                                                v-model="
                                                    recurrentForm.recurrenceStartDate
                                                "
                                                class="border rounded px-3 py-1 w-1/3"
                                                @change="liveRefresh()"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 ml-2 mb-2"
                                            v-for="error of v$.recurrentForm
                                                .recurrenceStartDate.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>

                                        <div
                                            class="whitespace-nowrap flex justify-start text-base mt-3 mb-2 mr-2"
                                        >
                                            {{ $t('ends') + ' *' }}
                                        </div>

                                        <div
                                            class="flex items-center ml-10 mb-6"
                                        >
                                            <input
                                                id="inline-radio-never"
                                                type="radio"
                                                value="never"
                                                :key="refreshMS"
                                                v-model="
                                                    recurrentForm.recurrentEndChoice
                                                "
                                                name="inline-radio-group"
                                                class="cursor-pointer darky"
                                                @change="liveRefresh()"
                                            />
                                            <label
                                                for="inline-radio-never"
                                                class="ml-2 text-sm font-medium"
                                                >{{ $t('never') }}</label
                                            >
                                        </div>
                                        <div
                                            class="flex items-center ml-10 mb-6"
                                        >
                                            <input
                                                id="inline-2-radio-on"
                                                type="radio"
                                                :key="refreshMS"
                                                v-model="
                                                    recurrentForm.recurrentEndChoice
                                                "
                                                class="cursor-pointer darky"
                                                value="on"
                                                name="inline-radio-group"
                                                @change="liveRefresh()"
                                            />
                                            <label
                                                for="inline-2-radio-on"
                                                class="ml-2 mr-12 text-sm font-medium"
                                                >{{ $t('on') }}</label
                                            >
                                            <!-- <div
                                                    class="mb-3 items-center flex flex-row"
                                                > -->

                                            <input
                                                type="date"
                                                :min="minDate"
                                                :key="refreshMS"
                                                v-model="
                                                    recurrentForm.recurrenceEndDate
                                                "
                                                class="border rounded px-3 py-1 w-1/3"
                                                @change="liveRefresh()"
                                            />
                                            <div
                                                class="text-xs italic mt-1 ml-2 mb-2"
                                                v-for="error of v$.recurrentForm
                                                    .recurrenceEndDate.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex items-center ml-10">
                                            <input
                                                v-model="
                                                    recurrentForm.recurrentEndChoice
                                                "
                                                :key="refreshMS"
                                                id="inline-2-radio-after"
                                                type="radio"
                                                class="cursor-pointer darky"
                                                value="after"
                                                name="inline-radio-group"
                                                @change="liveRefresh()"
                                            />
                                            <label
                                                for="inline-2-radio-after"
                                                class="ml-2 mr-8 text-sm font-medium"
                                                >{{ $t('after') }}</label
                                            >
                                            <input
                                                id="end-after"
                                                type="number"
                                                :key="refreshMS"
                                                min="1"
                                                v-model="
                                                    recurrentForm.endsAfterNumber
                                                "
                                                class="border rounded px-3 py-1 w-16"
                                                @change="liveRefresh()"
                                            />
                                            <label
                                                for="end-after"
                                                :key="refreshMS"
                                                class="ml-2 text-sm font-medium"
                                                >{{
                                                    $t(
                                                        'occurrence',
                                                        recurrentForm.endsAfterNumber <=
                                                            1
                                                            ? 1
                                                            : 2,
                                                    )
                                                }}</label
                                            >
                                            <div
                                                class="text-xs italic mt-1 ml-2 mb-2"
                                                v-for="error of v$.recurrentForm
                                                    .endsAfterNumber.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 rounded-md p-4 border-2">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('delivery_mode') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <Multiselect
                                        id="deliveryModeMS"
                                        class="darky"
                                        v-model="selectedMethod"
                                        :options="methodOptions"
                                        :key="refreshMS"
                                        label="name"
                                        track-by="name"
                                        :searchable="false"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                        :object="true"
                                    >
                                        <template #singlelabel="{ value }">
                                            <div
                                                class="multiselect-single-label"
                                            >
                                                <div
                                                    class="select-label-icon-wrap"
                                                    :style="value.style"
                                                >
                                                    <fa-icon
                                                        :title="value.name"
                                                        :icon="value.icon"
                                                        class="fa-sm"
                                                    ></fa-icon>
                                                    <span class="ml-1">
                                                        {{ $t(value.name) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <template #option="{ option }">
                                            <div
                                                class="select-option-icon-wrap"
                                                :style="option.style"
                                            >
                                                <fa-icon
                                                    :title="option.name"
                                                    :icon="option.icon"
                                                    class="fa-sm"
                                                ></fa-icon
                                                ><span class="ml-1">
                                                    {{ $t(option.name) }}
                                                </span>
                                            </div>
                                        </template></Multiselect
                                    >
                                    <div
                                        v-if="
                                            selectedMethod?.value ===
                                            'orderGrouping'
                                        "
                                        class="my-5"
                                    >
                                        <div
                                            class="grid grid-cols-7 items-center mb-5"
                                        >
                                            <div
                                                :class="
                                                    orderGroupOptions.length > 0
                                                        ? 'col-span-3'
                                                        : 'col-span-7'
                                                "
                                            >
                                                <input
                                                    type="text"
                                                    :disabled="
                                                        !canAccessGroupsUpdate ||
                                                        !canAccessGroupsCreate
                                                    "
                                                    v-model="formData.GroupName"
                                                    :placeholder="
                                                        $t('add_tour_to_order')
                                                    "
                                                    class="border rounded px-3 py-2 h-10 w-full"
                                                    @input="
                                                        selectedGroup = null
                                                    "
                                                />
                                            </div>
                                            <div
                                                v-if="orderGroupOptions.length"
                                                class="col-span-1"
                                            >
                                                {{ $t('or') }}
                                            </div>
                                            <div
                                                v-if="orderGroupOptions.length"
                                                class="col-span-3"
                                                :key="refreshMS"
                                            >
                                                <Multiselect
                                                    id="tourMS"
                                                    class="darky"
                                                    :disabled="
                                                        (formData.GroupName !==
                                                            '' &&
                                                            formData.GroupName !==
                                                                null) ||
                                                        !canAccessGroupsUpdate ||
                                                        !canAccessGroupsCreate
                                                    "
                                                    v-model="selectedGroup"
                                                    :options="orderGroupOptions"
                                                    :key="refreshMS"
                                                    label="name"
                                                    track-by="name"
                                                    :searchable="true"
                                                    :can-clear="true"
                                                    :can-deselect="true"
                                                    :allow-empty="true"
                                                    :object="true"
                                                >
                                                </Multiselect>
                                            </div>
                                        </div>

                                        <Multiselect
                                            :disabled="!canAccessTourPricing"
                                            id="tourPricingMS"
                                            class="darky"
                                            v-model="selectedTourPricing"
                                            :options="tourPricingOptions"
                                            :key="refreshMS"
                                            label="name"
                                            track-by="name"
                                            :placeholder="
                                                $t('tour_pricing_scenario')
                                            "
                                            :searchable="false"
                                            :can-clear="true"
                                            :can-deselect="true"
                                            :allow-empty="true"
                                            :object="true"
                                        >
                                        </Multiselect>
                                    </div>
                                    <div class="my-5 flex">
                                        <span class="text-lg">
                                            {{ $t('vehicle_type') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <Multiselect
                                        id="vehicleTypeMS"
                                        class="darky"
                                        v-model="selectedVehicleType"
                                        :options="vehicleTypeOptions"
                                        :key="refreshMS"
                                        label="name"
                                        track-by="name"
                                        :searchable="false"
                                        :can-clear="false"
                                        :can-deselect="false"
                                        :allow-empty="false"
                                        :object="true"
                                    >
                                    </Multiselect>
                                </div>
                                <div
                                    v-if="currentClient"
                                    class="mt-2 rounded-md p-4 border-2"
                                >
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('packages') + ' *' }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div
                                        v-if="
                                            containerOptions.length === 0 &&
                                            formData.OrderPackages
                                        "
                                        class="flex justify-center"
                                    >
                                        <button
                                            @click.stop="
                                                setContainerCreation(
                                                    formData.OrderPackages
                                                        .length - 1,
                                                )
                                            "
                                            class="themed-button items-center my-3 text-xs mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                                        >
                                            <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-xs rounded text-gray-500" -->
                                            <span
                                                class="inline-block ml-1 font-bold"
                                                >{{
                                                    $t('create_container')
                                                }}</span
                                            >
                                        </button>
                                    </div>
                                    <span v-else :key="refreshPacks">
                                        <span
                                            v-for="(
                                                oPack, packIndex
                                            ) in formData.OrderPackages"
                                            :key="packIndex"
                                        >
                                            <package-form
                                                :packToEdit="oPack"
                                                :packIndex="packIndex"
                                                :idClient="+formData.IdClient"
                                                :containerOptions="
                                                    containerOptions
                                                "
                                                :packsLength="
                                                    formData.OrderPackages
                                                        .length
                                                "
                                                :isDuplicate="isDuplicate"
                                                :isUpdate="
                                                    $route.params.id
                                                        ? true
                                                        : false
                                                "
                                                :globalRefresh="refreshPacks"
                                                :idContDefault="+idContDefault"
                                                @setPack="setPack($event)"
                                                @addPackage="addPackage()"
                                                @removePackage="
                                                    removePackage($event)
                                                "
                                                @newContainer="
                                                    setContainerCreation($event)
                                                "
                                            ></package-form>
                                        </span>
                                    </span>
                                </div>
                                <div
                                    v-if="canAccessONU"
                                    class="mt-2 rounded-md p-4 border-2"
                                >
                                    <div class="mb-5 flex justify-between">
                                        <span class="text-lg">
                                            <fa-icon
                                                icon="biohazard"
                                                class="theme-color mr-1"
                                            ></fa-icon>
                                            {{ $t('hazardous_products') }}
                                            <hr />
                                        </span>
                                        <icon-button
                                            v-if="canAccessONU"
                                            :icon="'plus'"
                                            class=""
                                            :noStyle="true"
                                            :iconStyle="'fa-md cursor-pointer theme-color'"
                                            :title="$t('add')"
                                            @click.prevent="addOnu()"
                                        />
                                    </div>
                                    <span v-if="onus.length > 0">
                                        <span
                                            v-for="(onu, onuIndex) in onus"
                                            :key="onuIndex"
                                            class="grid grid-cols-12 gap-1 mt-3 items-center"
                                        >
                                            <div
                                                :class="
                                                    onu.onuIcon === 'i-cursor'
                                                        ? 'col-span-3'
                                                        : 'col-span-4'
                                                "
                                            >
                                                <span
                                                    class="whitespace-nowrap text-sm"
                                                >
                                                    {{ $t('code') }}
                                                </span>
                                                <div
                                                    :key="refreshOnu"
                                                    class="articleMs flex px-3 darky p-2 rounded items-center"
                                                    :class="'border'"
                                                    :style="''"
                                                >
                                                    <!--  i-cursor-->

                                                    <Multiselect
                                                        class="darky py-0.5"
                                                        v-model="onus[onuIndex]"
                                                        :options="onuOptions"
                                                        :disabled="!canAddONU"
                                                        :key="refreshOnu"
                                                        :searchable="true"
                                                        :placeholder="
                                                            $t('select_one')
                                                        "
                                                        label="name"
                                                        track-by="name"
                                                        :can-clear="false"
                                                        :can-deselect="false"
                                                        :allow-empty="false"
                                                        :object="true"
                                                        @select="
                                                            setONU(
                                                                $event,
                                                                onuIndex,
                                                            )
                                                        "
                                                    >
                                                    </Multiselect>
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.onus
                                                        .$each.$response
                                                        .$errors[onuIndex].Id"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-2">
                                                <span class="text-sm">
                                                    {{ $t('net_weight') }}
                                                </span>
                                                <input
                                                    type="number"
                                                    :disabled="!canAddONU"
                                                    v-model="onu.NetWeight"
                                                    :min="1"
                                                    step="1"
                                                    class="border rounded px-3 py-2 h-10 w-full"
                                                    @change="liveRefresh()"
                                                />
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.onus
                                                        .$each.$response
                                                        .$errors[onuIndex]
                                                        .NetWeight"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="col-span-1 flex items-center justify-center mt-5"
                                            >
                                                <input
                                                    id="lq-checkbox"
                                                    type="checkbox"
                                                    v-model="onu.LQ"
                                                    class="themed-checkbox w-3 h-3 bg-gray-100 rounded border-gray-300"
                                                    :class="'cursor-pointer'"
                                                />
                                                <label
                                                    for="lq-checkbox"
                                                    class="ml-1 text-sm font-medium theme-color"
                                                    >LQ</label
                                                >
                                            </div>
                                            <div
                                                v-if="onu.Id"
                                                class="col-span-4 text-xs italic mt-2 overflow-auto"
                                            >
                                                {{ onu.detail }}
                                            </div>

                                            <div class="col-span-1 mt-5">
                                                <icon-button
                                                    v-if="canDeleteONU"
                                                    :icon="'trash-can'"
                                                    class="justify-end"
                                                    :noStyle="true"
                                                    :iconStyle="'fa-md cursor-pointer error-msg'"
                                                    :title="$t('delete')"
                                                    @click.prevent="
                                                        deleteOnu(
                                                            onuIndex,
                                                            onu.Id === ''
                                                                ? null
                                                                : onu.Id,
                                                        )
                                                    "
                                                />
                                            </div>
                                        </span>
                                    </span>
                                    <span
                                        v-else
                                        class="flex justify-center text-sm italic items-center my-2"
                                    >
                                        {{ $t('no_hazard_yet') }}
                                    </span>
                                </div>
                                <div class="mt-2 rounded-md p-4 border-2">
                                    <div class="mb-5 flex justify-between">
                                        <span class="text-lg">
                                            {{ $t('order_complement') }}
                                            <hr />
                                        </span>
                                        <icon-button
                                            v-if="canAddSales"
                                            :icon="'plus'"
                                            class=""
                                            :noStyle="true"
                                            :iconStyle="'fa-md cursor-pointer theme-color'"
                                            :title="$t('add')"
                                            @click.prevent="addSale()"
                                        />
                                    </div>
                                    <span v-if="extraSales.length > 0">
                                        <span
                                            v-for="(
                                                sale, saleIndex
                                            ) in extraSales"
                                            :key="saleIndex"
                                            class="grid grid-cols-12 gap-1 mt-3 items-center"
                                        >
                                            <div
                                                :class="
                                                    sale.articleIcon ===
                                                    'i-cursor'
                                                        ? 'col-span-3'
                                                        : 'col-span-4'
                                                "
                                            >
                                                <span
                                                    class="whitespace-nowrap text-sm"
                                                >
                                                    {{ $t('sale_name') }}
                                                </span>
                                                <div
                                                    :key="refreshSales"
                                                    class="articleMs flex px-3 darky p-2 rounded items-center"
                                                    :class="'border'"
                                                    :style="''"
                                                >
                                                    <fa-icon
                                                        :key="refreshSales"
                                                        :icon="
                                                            sale.articleIcon ??
                                                            'i-cursor'
                                                        "
                                                        :disabled="!canAddSales"
                                                        class="theme-color fa-xs fa-fade"
                                                        :class="
                                                            articleOptions.length >
                                                            0
                                                                ? 'cursor-pointer'
                                                                : ''
                                                        "
                                                        style="
                                                            --fa-animation-duration: 2s;
                                                        "
                                                        @click="
                                                            articleOptions.length >
                                                            0
                                                                ? selectOrCreateArticle(
                                                                      sale.articleIcon,
                                                                      saleIndex,
                                                                  )
                                                                : ''
                                                        "
                                                    ></fa-icon>
                                                    <!--  i-cursor-->

                                                    <input
                                                        v-if="
                                                            sale.articleIcon ===
                                                            'i-cursor'
                                                        "
                                                        type="text"
                                                        v-model="
                                                            sale.Designation
                                                        "
                                                        :disabled="!canAddSales"
                                                        class="text-xs ml-1 outline-none w-fit overflow-auto py-0.5"
                                                        @change="liveRefresh()"
                                                    />
                                                    <Multiselect
                                                        v-else
                                                        class="darky py-0.5"
                                                        v-model="
                                                            tempArticleId[
                                                                saleIndex
                                                            ]
                                                        "
                                                        :options="
                                                            articleOptions
                                                        "
                                                        :disabled="!canAddSales"
                                                        :key="refreshSales"
                                                        :searchable="true"
                                                        :placeholder="
                                                            $t('pick_article')
                                                        "
                                                        label="name"
                                                        track-by="name"
                                                        :can-clear="false"
                                                        :can-deselect="false"
                                                        :allow-empty="false"
                                                        :object="true"
                                                        @select="
                                                            setArticle(
                                                                $event,
                                                                saleIndex,
                                                            )
                                                        "
                                                    >
                                                    </Multiselect>
                                                </div>
                                            </div>
                                            <div class="col-span-2">
                                                <span class="text-sm">
                                                    {{ $t('quantity') }}
                                                </span>
                                                <input
                                                    type="number"
                                                    :disabled="!canAddSales"
                                                    v-model="sale.Quantity"
                                                    :min="1"
                                                    step="1"
                                                    class="border rounded px-3 py-2 h-10 w-full"
                                                    @change="liveRefresh()"
                                                />
                                            </div>

                                            <div
                                                v-if="
                                                    sale.articleIcon ===
                                                    'i-cursor'
                                                "
                                                class="col-span-2"
                                            >
                                                <span class="text-sm">
                                                    {{ $t('vat') }}
                                                </span>
                                                <div class="vatMS">
                                                    <Multiselect
                                                        class="darky"
                                                        :disabled="!canAddSales"
                                                        v-model="sale.VAT"
                                                        :options="vatOptions"
                                                        :key="refreshMS"
                                                        :searchable="false"
                                                        :can-clear="false"
                                                        :can-deselect="false"
                                                        :allow-empty="false"
                                                    >
                                                    </Multiselect>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    sale.articleIcon ===
                                                    'i-cursor'
                                                "
                                                class="col-span-2"
                                            >
                                                <span
                                                    class="text-sm mx-2 whitespace-nowrap"
                                                >
                                                    {{ `${$t('total_ttc')}` }}
                                                </span>
                                                <input
                                                    type="number"
                                                    :disabled="
                                                        !canAddSales ||
                                                        (sale.TotalHT > 0 &&
                                                            $route.name ===
                                                                'new_order')
                                                    "
                                                    v-model="sale.TotalTTC"
                                                    step="0.01"
                                                    class="border rounded px-3 py-2 h-10 w-full"
                                                    @change="
                                                        liveRefresh() &
                                                        (sale.TotalHT = 0)
                                                    "
                                                />
                                            </div>
                                            <div
                                                v-if="
                                                    sale.articleIcon ===
                                                    'i-cursor'
                                                "
                                                class="col-span-2"
                                            >
                                                <span
                                                    class="text-sm mx-2 whitespace-nowrap"
                                                >
                                                    {{ `${$t('total_ht')}` }}
                                                </span>
                                                <input
                                                    type="number"
                                                    :disabled="
                                                        !canAddSales ||
                                                        (sale.TotalTTC > 0 &&
                                                            $route.name ===
                                                                'new_order')
                                                    "
                                                    v-model="sale.TotalHT"
                                                    step="0.01"
                                                    class="border rounded px-3 py-2 h-10 w-full"
                                                    @change="
                                                        liveRefresh() &
                                                        (sale.TotalTTC = 0)
                                                    "
                                                />
                                            </div>
                                            <div
                                                v-if="
                                                    sale.articleIcon ===
                                                    'basket-shopping'
                                                "
                                                class="col-span-5 text-xs italic mt-2 overflow-auto"
                                            >
                                                {{ sale.article }}
                                            </div>

                                            <div class="col-span-1 mt-5">
                                                <icon-button
                                                    v-if="canDeleteSales"
                                                    :icon="'trash-can'"
                                                    class="justify-end"
                                                    :noStyle="true"
                                                    :iconStyle="'fa-md cursor-pointer error-msg'"
                                                    :title="$t('delete')"
                                                    @click.prevent="
                                                        deleteSale(
                                                            saleIndex,
                                                            sale.Id === ''
                                                                ? null
                                                                : sale.Id,
                                                        )
                                                    "
                                                />
                                            </div>
                                        </span>
                                    </span>
                                    <span
                                        v-else
                                        class="flex justify-center text-sm italic items-center my-2"
                                    >
                                        {{ $t('no_complement_yet') }}
                                    </span>
                                </div>
                            </div>
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="px-3">
                                    <div class="mb-3 items-center">
                                        <label
                                            for="priceCharged"
                                            class="font-semibold text-gray-600 pb-1 mr-2"
                                            >{{ $t('price_charged') }}</label
                                        >
                                        <div id="priceCharged" class="my-5">
                                            <Multiselect
                                                :disabled="
                                                    !canAccessOrderPricing
                                                "
                                                id="orderPricingMS"
                                                class="darky"
                                                v-model="selectedOrderPricing"
                                                :options="orderPricingOptions"
                                                :key="refreshMS"
                                                :placeholder="
                                                    $t('order_pricing_scenario')
                                                "
                                                label="name"
                                                track-by="name"
                                                :searchable="false"
                                                :can-clear="false"
                                                :can-deselect="false"
                                                :allow-empty="false"
                                                :object="true"
                                            >
                                            </Multiselect>
                                        </div>
                                        <div
                                            class="grid items-center gap-2 justify-center"
                                            :class="
                                                canRefreshPrice === true
                                                    ? 'grid-cols-6'
                                                    : 'grid-cols-4'
                                            "
                                        >
                                            <div
                                                class="col-span-2 justify-center"
                                            >
                                                <label for="calculatedPrice">
                                                    {{
                                                        $t('demo_price')
                                                    }}</label
                                                ><br />
                                                <input
                                                    :key="refreshPrice"
                                                    id="calculatedPrice"
                                                    type="number"
                                                    step="0.01"
                                                    disabled
                                                    v-model="calculatedPrice"
                                                    placeholder="0 €"
                                                    autocomplete="number"
                                                    class="border rounded w-2/3 px-3 py-2"
                                                />
                                            </div>
                                            <div
                                                class="col-span-2 justify-center"
                                            >
                                                <label for="priceCharged">
                                                    {{
                                                        $t('forced_price')
                                                    }}</label
                                                >
                                                <input
                                                    :key="refreshPrice"
                                                    id="priceCharged"
                                                    type="number"
                                                    step="0.01"
                                                    :disabled="
                                                        !canRefreshPrice ||
                                                        !canForcePrice
                                                    "
                                                    v-model="priceToApply"
                                                    placeholder="0 €"
                                                    autocomplete="number"
                                                    class="border rounded w-2/3 px-3 py-2"
                                                />
                                            </div>
                                            <button
                                                v-if="
                                                    canRefreshPrice === true &&
                                                    creating === false
                                                "
                                                type="button"
                                                @click.prevent="
                                                    calculatePrice()
                                                "
                                                class="col-span-2 mt-4 themed-button whitespace-nowrap text-sm focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                            >
                                                <span v-if="creating">
                                                    <fa-icon
                                                        icon="spinner"
                                                        spin-pulse
                                                    ></fa-icon
                                                ></span>
                                                <span v-else>{{
                                                    $t('update_price')
                                                }}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold text-gray-600 basis-1/4 pb-1 mr-2"
                                                >{{ $t('vehicle_type') }}</label
                                            >
                                            <input
                                                type="text"
                                                v-model="vehicleType"
                                                disabled
                                                autocomplete="name"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div> -->

                                    <div class="mb-5 mt-2 flex">
                                        <span class="text-lg">
                                            {{ $t('steps') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div
                                        v-if="
                                            ($route.name === 'update_order' ||
                                                $route.name ===
                                                    'update_recurrent') &&
                                            formData.OrderSteps.length > 0
                                        "
                                        :key="refreshSteps"
                                        class="mt-10 darky"
                                    >
                                        <ol
                                            v-for="(
                                                step, index
                                            ) in formData.OrderSteps"
                                            :key="index"
                                            class="px-4 border-l-2 border-r-2"
                                            :class="
                                                step.Type === 'pickup'
                                                    ? 'rounded-t-md pt-2 border-t-2'
                                                    : 'rounded-b-md mb-1 border-b-2'
                                            "
                                        >
                                            <span v-if="!step.index">
                                                <div class="flex justify-end">
                                                    <icon-button
                                                        v-if="
                                                            step.Type ===
                                                                'pickup' &&
                                                            formData.OrderSteps
                                                                .length > 2 &&
                                                            index > 1 &&
                                                            currentOrder &&
                                                            !currentOrder.isInvoiced
                                                        "
                                                        :icon="'trash-can'"
                                                        class="ml-3"
                                                        :noStyle="true"
                                                        :iconStyle="'fa-md cursor-pointer error-msg'"
                                                        :title="$t('delete')"
                                                        @click="
                                                            deleteStp(
                                                                index,
                                                                step.Id,
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="relative border-l border-gray-200"
                                                >
                                                    <li class="mb-2 ml-6">
                                                        <span
                                                            class="steps-timeline flex absolute -left-3 justify-center items-center w-6 h-6 rounded-full"
                                                        >
                                                            <fa-layer
                                                                v-if="
                                                                    step.Status ===
                                                                        'new' &&
                                                                    $route.name ===
                                                                        'update_order'
                                                                "
                                                                :title="
                                                                    $t(
                                                                        step.Status,
                                                                    )
                                                                "
                                                                class="fa-lg"
                                                            >
                                                                <fa-icon
                                                                    icon="ban"
                                                                    style="
                                                                        color: grey;
                                                                    "
                                                                ></fa-icon>
                                                                <fa-icon
                                                                    icon="route"
                                                                    transform="shrink-6"
                                                                ></fa-icon>
                                                            </fa-layer>
                                                            <fa-icon
                                                                v-else-if="
                                                                    $route.name ===
                                                                    'update_order'
                                                                "
                                                                :title="
                                                                    step.Status !==
                                                                    ''
                                                                        ? $t(
                                                                              step.Status,
                                                                          )
                                                                        : $t(
                                                                              'no_status_found',
                                                                          )
                                                                "
                                                                :icon="
                                                                    stepStatusIcon(
                                                                        step.Status,
                                                                    )
                                                                "
                                                            ></fa-icon>
                                                            <fa-icon
                                                                v-else
                                                                icon="route"
                                                            ></fa-icon>
                                                        </span>
                                                        <span
                                                            class="block max-w-full rounded border border-gray-200 p-3 darky"
                                                        >
                                                            <span
                                                                class="grid grid-cols-4 gap-1 border-b-4 mb-2"
                                                            >
                                                                <h3
                                                                    class="mb-1 col-span-3 items-center flex text-lg font-semibold"
                                                                >
                                                                    {{
                                                                        `${$t(
                                                                            step.Type ===
                                                                                'delivery'
                                                                                ? 'delivery_label'
                                                                                : step.Type,
                                                                        )} - ${
                                                                            step.AddressName
                                                                        }`
                                                                    }}
                                                                    <span
                                                                        v-if="
                                                                            $route.name ===
                                                                            'update_order'
                                                                        "
                                                                        class="italic text-xs ml-2 font-light custom-link"
                                                                    >
                                                                        {{
                                                                            step.Status !==
                                                                            ''
                                                                                ? $t(
                                                                                      step.Status,
                                                                                  )
                                                                                : $t(
                                                                                      'no_status_found',
                                                                                  )
                                                                        }}
                                                                        <span
                                                                            class="ml-1"
                                                                            >{{
                                                                                step.ExecutedDateTime &&
                                                                                step.Status ===
                                                                                    'executed' &&
                                                                                step.ExecutedDateTime !==
                                                                                    null
                                                                                    ? new Date(
                                                                                          step.ExecutedDateTime,
                                                                                      ).toLocaleString(
                                                                                          $i18n.locale,
                                                                                          {
                                                                                              year: 'numeric',
                                                                                              month: '2-digit',
                                                                                              day: '2-digit',
                                                                                              hour: '2-digit',
                                                                                              minute: '2-digit',
                                                                                          },
                                                                                      )
                                                                                    : ''
                                                                            }}
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        v-if="
                                                                            (index ===
                                                                                0 ||
                                                                                formData
                                                                                    .OrderSteps[
                                                                                    index -
                                                                                        1
                                                                                ]
                                                                                    .Status ===
                                                                                    'executed') &&
                                                                            step.IdDriver !==
                                                                                null &&
                                                                            step.IdDriver !==
                                                                                '' &&
                                                                            step.IdDriver !==
                                                                                0 &&
                                                                            canUpdateStepStatus ===
                                                                                true &&
                                                                            $route.name ===
                                                                                'update_order'
                                                                        "
                                                                        :key="
                                                                            refreshToggle
                                                                        "
                                                                    >
                                                                        <!-- step.ExecutedDateTime !==
                                                                    '' &&
                                                                step.ExecutedDateTime !==
                                                                    null && -->
                                                                        <fa-icon
                                                                            v-if="
                                                                                step.Status ===
                                                                                'executed'
                                                                            "
                                                                            :title="
                                                                                $t(
                                                                                    'force_inprogress',
                                                                                )
                                                                            "
                                                                            :icon="'toggle-on'"
                                                                            class="fa-md m-1 cursor-pointer success-msg"
                                                                            @click="
                                                                                updateStepStatus(
                                                                                    {
                                                                                        step,
                                                                                        index,
                                                                                        newStatus:
                                                                                            step.IdDriver
                                                                                                ? 'affected'
                                                                                                : 'new',
                                                                                        isLast:
                                                                                            index ===
                                                                                            formData
                                                                                                .OrderSteps
                                                                                                .length -
                                                                                                1,
                                                                                        lastStatus:
                                                                                            formData
                                                                                                .OrderSteps[
                                                                                                formData
                                                                                                    .OrderSteps
                                                                                                    .length -
                                                                                                    1
                                                                                            ]
                                                                                                .Status,
                                                                                    },
                                                                                )
                                                                            "
                                                                        ></fa-icon>
                                                                        <fa-icon
                                                                            v-else
                                                                            :title="
                                                                                $t(
                                                                                    'force_finish',
                                                                                )
                                                                            "
                                                                            :icon="'toggle-off'"
                                                                            class="fa-md m-1 cursor-pointer"
                                                                            @click="
                                                                                updateStepStatus(
                                                                                    {
                                                                                        step,
                                                                                        index,
                                                                                        newStatus:
                                                                                            'executed',
                                                                                        isLast:
                                                                                            index ===
                                                                                            formData
                                                                                                .OrderSteps
                                                                                                .length -
                                                                                                1,
                                                                                        lastStatus:
                                                                                            formData
                                                                                                .OrderSteps[
                                                                                                formData
                                                                                                    .OrderSteps
                                                                                                    .length -
                                                                                                    1
                                                                                            ]
                                                                                                .Status,
                                                                                    },
                                                                                )
                                                                            "
                                                                        ></fa-icon>
                                                                    </span>
                                                                </h3>
                                                                <div
                                                                    class="col-start-4 mt-2 ml-2 flex justify-end items-center"
                                                                    v-if="
                                                                        (index <=
                                                                            1 ||
                                                                            roundTrip ===
                                                                                false) &&
                                                                        ($route.name ===
                                                                            'new_order' ||
                                                                            ($route.name ===
                                                                                'update_order' &&
                                                                                currentOrder &&
                                                                                !currentOrder.isInvoiced))
                                                                    "
                                                                >
                                                                    <!-- v-if="
                                                                    step.Status ===
                                                                        'new' ||
                                                                    step.Status ===
                                                                        ''
                                                                " -->
                                                                    <icon-button
                                                                        :icon="'pen'"
                                                                        :noStyle="
                                                                            true
                                                                        "
                                                                        :iconStyle="'fa-md cursor-pointer '"
                                                                        :title="
                                                                            $t(
                                                                                'edit',
                                                                            )
                                                                        "
                                                                        @click="
                                                                            updateStp(
                                                                                index,
                                                                            )
                                                                        "
                                                                    />
                                                                </div>
                                                            </span>
                                                            <p
                                                                class="text-base font-normal"
                                                            >
                                                                {{
                                                                    step.Address1 +
                                                                    ' '
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        step.Address2 !==
                                                                            'null' &&
                                                                        step.Address2 !==
                                                                            null &&
                                                                        step.Address2 !==
                                                                            ''
                                                                    "
                                                                >
                                                                    {{
                                                                        step.Address2 +
                                                                        ' '
                                                                    }}
                                                                </span>

                                                                {{
                                                                    step.PostalCode +
                                                                    ' ' +
                                                                    step.City
                                                                }}
                                                            </p>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-if="
                                                                    step.IdClientAddress !==
                                                                        0 &&
                                                                    !showForm.includes(
                                                                        index,
                                                                    ) &&
                                                                    (step.Address1 ===
                                                                        '' ||
                                                                        step.PostalCode ===
                                                                            '' ||
                                                                        step.PostalCode ===
                                                                            '00000' ||
                                                                        step.City ===
                                                                            '')
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'address_data_missing',
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <time
                                                                class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
                                                                >{{
                                                                    recurrentOrder ===
                                                                    false
                                                                        ? $t(
                                                                              'on',
                                                                          ) +
                                                                          ' ' +
                                                                          step.stepDate +
                                                                          ' ' +
                                                                          $t(
                                                                              step.Moment,
                                                                          ) +
                                                                          ' '
                                                                        : $t(
                                                                              step.Moment,
                                                                          ) +
                                                                          ' '
                                                                }}{{
                                                                    step.Moment ===
                                                                    'between'
                                                                        ? `${
                                                                              step.FromDateTime
                                                                          } ${$t(
                                                                              'and',
                                                                          )} ${
                                                                              step.BeforeDateTime
                                                                          }`
                                                                        : step.Moment ===
                                                                            'before'
                                                                          ? step.BeforeDateTime
                                                                          : step.FromDateTime
                                                                }}
                                                            </time>
                                                            <div
                                                                class="items-center"
                                                            >
                                                                <label
                                                                    :for="
                                                                        'CommentStep' +
                                                                        index
                                                                    "
                                                                    class="text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'step_comment',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <textarea
                                                                    :id="
                                                                        'CommentStep' +
                                                                        index
                                                                    "
                                                                    type=""
                                                                    v-model="
                                                                        step.CommentStep
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                :class="
                                                                    canUpdateRefAddress &&
                                                                    step.ClientAddress &&
                                                                    (step
                                                                        .ClientAddress
                                                                        .ContactFirstName ||
                                                                        step
                                                                            .ClientAddress
                                                                            .ContactPhone ||
                                                                        step
                                                                            .ClientAddress
                                                                            .ContactEmail)
                                                                        ? 'cursor-pointer p-2 custom-hover'
                                                                        : ''
                                                                "
                                                                @click.prevent="
                                                                    openAddressUpdateExtraInfo(
                                                                        step.ClientAddress,
                                                                    )
                                                                "
                                                            >
                                                                <p
                                                                    v-if="
                                                                        step.ClientAddress &&
                                                                        (step
                                                                            .ClientAddress
                                                                            .ContactFirstName ||
                                                                            step
                                                                                .ClientAddress
                                                                                .ContactPhone ||
                                                                            step
                                                                                .ClientAddress
                                                                                .ContactEmail)
                                                                    "
                                                                    class="text-xs font-normal mb-1"
                                                                >
                                                                    {{
                                                                        `${$t(
                                                                            'contact',
                                                                        )}: ${
                                                                            step
                                                                                .ClientAddress
                                                                                .ContactFirstName
                                                                                ? step
                                                                                      .ClientAddress
                                                                                      .ContactFirstName
                                                                                : ''
                                                                        }  ${
                                                                            step
                                                                                .ClientAddress
                                                                                .ContactLastName
                                                                                ? step
                                                                                      .ClientAddress
                                                                                      .ContactLastName
                                                                                : ''
                                                                        } ${
                                                                            step
                                                                                .ClientAddress
                                                                                .ContactPhone &&
                                                                            (step
                                                                                .ClientAddress
                                                                                .ContactFirstName ||
                                                                                step
                                                                                    .ClientAddress
                                                                                    .ContactLastName)
                                                                                ? ' - ' +
                                                                                  step
                                                                                      .ClientAddress
                                                                                      .ContactPhone
                                                                                : step
                                                                                        .ClientAddress
                                                                                        .ContactPhone &&
                                                                                    step
                                                                                        .ClientAddress
                                                                                        .ContactEmail
                                                                                  ? step
                                                                                        .ClientAddress
                                                                                        .ContactPhone +
                                                                                    ' - '
                                                                                  : step
                                                                                          .ClientAddress
                                                                                          .ContactPhone
                                                                                    ? step
                                                                                          .ClientAddress
                                                                                          .ContactPhone
                                                                                    : ''
                                                                        } ${
                                                                            step
                                                                                .ClientAddress
                                                                                .ContactEmail
                                                                                ? step
                                                                                      .ClientAddress
                                                                                      .ContactEmail
                                                                                : ''
                                                                        }`
                                                                    }}
                                                                </p>

                                                                <p
                                                                    v-if="
                                                                        step.ClientAddress &&
                                                                        step
                                                                            .ClientAddress
                                                                            .AdditionalInformation
                                                                    "
                                                                    class="text-xs font-normal mb-1"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'additional_info',
                                                                        ) +
                                                                        ': ' +
                                                                        step
                                                                            .ClientAddress
                                                                            .AdditionalInformation
                                                                    }}
                                                                </p>
                                                                <p
                                                                    v-if="
                                                                        step.ClientAddress &&
                                                                        step
                                                                            .ClientAddress
                                                                            .DriverInformation
                                                                    "
                                                                    class="text-xs font-normal"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'driver_info',
                                                                        ) +
                                                                        ': ' +
                                                                        step
                                                                            .ClientAddress
                                                                            .DriverInformation
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </span>
                                                        <span
                                                            :key="
                                                                refreshDriverVehicleSelect
                                                            "
                                                            v-if="
                                                                !isOdd(index) &&
                                                                formData
                                                                    .OrderSteps
                                                                    .length >
                                                                    1 &&
                                                                formData.PaymentType !==
                                                                    'web'
                                                            "
                                                            class="grid gap-1 py-2 items-center justify-center"
                                                            :class="
                                                                $route.name ===
                                                                'update_order'
                                                                    ? 'grid-cols-5'
                                                                    : 'grid-cols-4'
                                                            "
                                                        >
                                                            <div
                                                                class="mb-3 col-span-2 items-center px-3"
                                                                :class="
                                                                    driverOptions.length ===
                                                                    0
                                                                        ? 'selectMS'
                                                                        : ''
                                                                "
                                                            >
                                                                <span
                                                                    v-if="
                                                                        canAssignDriver ===
                                                                        true
                                                                    "
                                                                >
                                                                    <div
                                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'driver',
                                                                            )
                                                                        }}
                                                                    </div>
                                                                    <span
                                                                        v-if="
                                                                            step.Status &&
                                                                            step.Status !==
                                                                                'affected' &&
                                                                            step.Status !==
                                                                                'new' &&
                                                                            step.Driver &&
                                                                            userStore
                                                                                .current
                                                                                .type !==
                                                                                'superadmin'
                                                                        "
                                                                    >
                                                                        {{
                                                                            step
                                                                                .Driver
                                                                                .FirstName +
                                                                            ' ' +
                                                                            step
                                                                                .Driver
                                                                                .LastName
                                                                        }}
                                                                    </span>
                                                                    <!-- :loading="
                                                                        driverOptions.length ===
                                                                        0
                                                                    " -->
                                                                    <driver-multiselect
                                                                        v-else-if="
                                                                            !step.Status ||
                                                                            step.Status ===
                                                                                'affected' ||
                                                                            step.Status ===
                                                                                'new' ||
                                                                            userStore
                                                                                .current
                                                                                .type ===
                                                                                'superadmin' ||
                                                                            recurrentOrder ===
                                                                                true
                                                                        "
                                                                        :driverOptions="
                                                                            driverOptions
                                                                        "
                                                                        :driverId="
                                                                            step.IdDriver
                                                                        "
                                                                        :index="
                                                                            index
                                                                        "
                                                                        :refreshMS="
                                                                            refreshMS
                                                                        "
                                                                        @selected="
                                                                            setIsSubContractor(
                                                                                $event,
                                                                                formData.OrderSteps,
                                                                            )
                                                                        "
                                                                        @clear="
                                                                            setIsSubContractor(
                                                                                {
                                                                                    driverId:
                                                                                        null,
                                                                                    stepIndex:
                                                                                        index,
                                                                                },
                                                                            )
                                                                        "
                                                                    ></driver-multiselect>
                                                                </span>
                                                            </div>
                                                            <div
                                                                class="mb-3 col-span-2 items-center px-3"
                                                                :class="
                                                                    vehicleOptions.length ===
                                                                    0
                                                                        ? 'selectMS'
                                                                        : ''
                                                                "
                                                            >
                                                                <span
                                                                    v-if="
                                                                        canAssignVehicle ===
                                                                        true
                                                                    "
                                                                >
                                                                    <div
                                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'vehicle',
                                                                            )
                                                                        }}
                                                                    </div>
                                                                    <span
                                                                        v-if="
                                                                            recurrentOrder ===
                                                                                false &&
                                                                            ((step.Status !==
                                                                                'affected' &&
                                                                                step.Status !==
                                                                                    'new') ||
                                                                                userStore
                                                                                    .current
                                                                                    .type !==
                                                                                    'superadmin')
                                                                        "
                                                                    >
                                                                        <span
                                                                            v-if="
                                                                                step.Vehicle
                                                                            "
                                                                        >
                                                                            {{
                                                                                step
                                                                                    .Vehicle
                                                                                    .Name
                                                                            }}
                                                                            {{
                                                                                step
                                                                                    .Vehicle
                                                                                    .Name
                                                                            }}</span
                                                                        ><span
                                                                            v-else
                                                                        >
                                                                            {{
                                                                                $t(
                                                                                    'no_vehicle_type_selected',
                                                                                )
                                                                            }}
                                                                        </span>
                                                                    </span>
                                                                    <Multiselect
                                                                        v-else-if="
                                                                            step.Status ===
                                                                                'affected' ||
                                                                            step.Status ===
                                                                                'new' ||
                                                                            userStore
                                                                                .current
                                                                                .type ===
                                                                                'superadmin' ||
                                                                            recurrentOrder ===
                                                                                true
                                                                        "
                                                                        :key="
                                                                            refreshMS
                                                                        "
                                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                        v-model="
                                                                            step.IdVehicle
                                                                        "
                                                                        :options="
                                                                            vehicleOptions
                                                                        "
                                                                        :loading="
                                                                            vehicleOptions.length ===
                                                                            0
                                                                        "
                                                                        label="name"
                                                                        track-by="name"
                                                                        :searchable="
                                                                            true
                                                                        "
                                                                        :placeholder="
                                                                            vehicleOptions.length
                                                                                ? $t(
                                                                                      'select_one',
                                                                                  )
                                                                                : $t(
                                                                                      'no_available_for_date',
                                                                                  )
                                                                        "
                                                                        :allow-empty="
                                                                            false
                                                                        "
                                                                        :can-clear="
                                                                            true
                                                                        "
                                                                    >
                                                                    </Multiselect>
                                                                </span>
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    $route.name ===
                                                                    'update_order'
                                                                "
                                                                :title="
                                                                    $t(
                                                                        'create_step_break',
                                                                    )
                                                                "
                                                                class="col-span-1 items-center px-3 mt-3"
                                                            >
                                                                <button
                                                                    type="button"
                                                                    class="themed-button items-center text-base mr-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                                    @click="
                                                                        setBreakForm(
                                                                            index,
                                                                        )
                                                                    "
                                                                >
                                                                    <fa-icon
                                                                        icon="bolt"
                                                                        class="fa-lg"
                                                                    ></fa-icon>
                                                                </button>
                                                            </div>
                                                        </span>
                                                        <span
                                                            :key="step"
                                                            v-if="
                                                                (!isOdd(
                                                                    index,
                                                                ) &&
                                                                    step.Driver &&
                                                                    step.Driver
                                                                        .SubContractor &&
                                                                    +step.Driver
                                                                        .SubContractor ===
                                                                        1) ||
                                                                (!isOdd(
                                                                    index,
                                                                ) &&
                                                                    step.IsSubContractor &&
                                                                    step.IsSubContractor ===
                                                                        true)
                                                            "
                                                        >
                                                            <div
                                                                class="grid grid-cols-6"
                                                            >
                                                                <div
                                                                    class="col-span-3 items-center justify-center m-3"
                                                                >
                                                                    <span
                                                                        class="whitespace-nowrap text-base mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'purchase_price',
                                                                            ) +
                                                                            ' *'
                                                                        }}
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        min="0.01"
                                                                        step="0.01"
                                                                        :disabled="
                                                                            (step.Status !==
                                                                                'affected' &&
                                                                                step.Status !==
                                                                                    'new') ||
                                                                            userStore
                                                                                .current
                                                                                .type !==
                                                                                'superadmin'
                                                                        "
                                                                        v-model="
                                                                            formData
                                                                                .OrderSteps[
                                                                                index +
                                                                                    1
                                                                            ]
                                                                                .PurchasePrice
                                                                        "
                                                                        class="border rounded px-3 py-2 h-10 mr-2"
                                                                    />
                                                                    <!-- :class="
                                                                            purchasePriceMissing ===
                                                                            true
                                                                                ? 'border-red-500'
                                                                                : ''
                                                                        " -->
                                                                    <div
                                                                        class="text-xs italic mt-1 mb-2"
                                                                        v-for="error of v$
                                                                            .formData
                                                                            .OrderSteps
                                                                            .$each
                                                                            .$response
                                                                            .$errors[
                                                                            index
                                                                        ]
                                                                            .PurchasePrice"
                                                                        :key="
                                                                            error.$uid
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="error-msg"
                                                                        >
                                                                            {{
                                                                                error.$message
                                                                            }}
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div
                                                                        v-if="
                                                                            purchasePriceMissing ===
                                                                            true
                                                                        "
                                                                        class="text-red-500 text-xs italic"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'required_field',
                                                                            )
                                                                        }}
                                                                    </div> -->
                                                                </div>
                                                                <div
                                                                    class="col-span-3 items-center justify-center m-3"
                                                                >
                                                                    <span
                                                                        class="whitespace-nowrap text-base mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'selling_price',
                                                                            )
                                                                        }}
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        min="0.01"
                                                                        step="0.01"
                                                                        :disabled="
                                                                            (step.Status !==
                                                                                'affected' &&
                                                                                step.Status !==
                                                                                    'new') ||
                                                                            userStore
                                                                                .current
                                                                                .type !==
                                                                                'superadmin'
                                                                        "
                                                                        v-model="
                                                                            formData
                                                                                .OrderSteps[
                                                                                index +
                                                                                    1
                                                                            ]
                                                                                .SellingPrice
                                                                        "
                                                                        class="border rounded px-3 py-2 h-10 mr-2"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    formData
                                                                        .OrderSteps[
                                                                        index +
                                                                            1
                                                                    ].Margin
                                                                "
                                                                class="items-center justify-center m-3"
                                                            >
                                                                {{
                                                                    `${$t('margin')} ${
                                                                        formData
                                                                            .OrderSteps[
                                                                            index +
                                                                                1
                                                                        ].Margin
                                                                    }`
                                                                }}
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    (step.Status ===
                                                                        'affected' &&
                                                                        step.Status ===
                                                                            'new') ||
                                                                    userStore
                                                                        .current
                                                                        .type ===
                                                                        'superadmin'
                                                                "
                                                                class="flex items-center justify-center m-3"
                                                            >
                                                                <button
                                                                    type="button"
                                                                    class="themed-button items-center text-base mr-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                                    @click.prevent="
                                                                        $route.name ===
                                                                        'new_order'
                                                                            ? createOrder(
                                                                                  false,
                                                                                  false,
                                                                                  false,
                                                                                  true,
                                                                              )
                                                                            : updateOrder(
                                                                                  false,
                                                                                  false,
                                                                                  false,
                                                                                  false,
                                                                                  true,
                                                                              )
                                                                    "
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'save_n_visualise',
                                                                        )
                                                                    }}
                                                                </button>
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    (step.Status ===
                                                                        'affected' &&
                                                                        step.Status ===
                                                                            'new') ||
                                                                    userStore
                                                                        .current
                                                                        .type ===
                                                                        'superadmin'
                                                                "
                                                                class="flex items-center justify-center m-3"
                                                            >
                                                                <input
                                                                    id="charter-checkbox"
                                                                    type="checkbox"
                                                                    v-model="
                                                                        formData
                                                                            .OrderSteps[
                                                                            index +
                                                                                1
                                                                        ]
                                                                            .SendCharter
                                                                    "
                                                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                                    :class="'cursor-pointer'"
                                                                />
                                                                <label
                                                                    for="charter-checkbox"
                                                                    class="ml-2 text-base font-medium theme-color"
                                                                    >{{
                                                                        $t(
                                                                            'send_charter',
                                                                        )
                                                                    }}</label
                                                                >
                                                            </div>
                                                        </span>
                                                        <span
                                                            v-else-if="
                                                                !isOdd(index) &&
                                                                formData
                                                                    .OrderSteps
                                                                    .length >
                                                                    1 &&
                                                                formData.PaymentType ===
                                                                    'web'
                                                            "
                                                            class="text-xs font-semibold theme-color mt-1 flex item-center px-5"
                                                        >
                                                            {{
                                                                $t(
                                                                    'web_driver_assign_info',
                                                                )
                                                            }}
                                                        </span>
                                                    </li>

                                                    <div
                                                        v-if="
                                                            showBreakForm ===
                                                                true &&
                                                            index ===
                                                                breakForm.index -
                                                                    1
                                                        "
                                                        class="px-2 border-1 border-green-500"
                                                    >
                                                        <div class="px-3">
                                                            <div
                                                                class="flex items-center justify-between mr-4"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'break_step_label',
                                                                    )
                                                                }}
                                                                <icon-button
                                                                    v-if="
                                                                        $route.name ===
                                                                            'update_order' &&
                                                                        currentOrder &&
                                                                        !currentOrder.isInvoiced
                                                                    "
                                                                    :icon="'trash-can'"
                                                                    class="ml-3"
                                                                    :noStyle="
                                                                        true
                                                                    "
                                                                    :iconStyle="'fa-md cursor-pointer  error-msg'"
                                                                    :title="
                                                                        $t(
                                                                            'delete',
                                                                        )
                                                                    "
                                                                    @click.prevent="
                                                                        setBreakForm(
                                                                            null,
                                                                            breakForm.index,
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <hr />

                                                            <div
                                                                v-if="
                                                                    createBreakAddressForm ===
                                                                    false
                                                                "
                                                                class="mb-2 mt-3 items-center flex flex-row"
                                                            >
                                                                <Multiselect
                                                                    :key="
                                                                        refreshMS
                                                                    "
                                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                    v-model="
                                                                        breakForm.IdClientAddress
                                                                    "
                                                                    :options="
                                                                        addressOptions
                                                                    "
                                                                    :loading="
                                                                        addressOptions.length ===
                                                                            0 &&
                                                                        formData.IdClient !==
                                                                            ''
                                                                    "
                                                                    label="name"
                                                                    track-by="name"
                                                                    :searchable="
                                                                        true
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'can_select_address',
                                                                        )
                                                                    "
                                                                    :allow-empty="
                                                                        false
                                                                    "
                                                                    :can-clear="
                                                                        false
                                                                    "
                                                                    @select="
                                                                        setBreakAddressInfo(
                                                                            breakForm.index,
                                                                            breakForm.IdClientAddress,
                                                                        )
                                                                    "
                                                                ></Multiselect>
                                                            </div>
                                                            <span
                                                                class="items-center flex justify-center"
                                                            >
                                                                <button
                                                                    v-if="
                                                                        createBreakAddressForm ===
                                                                        false
                                                                    "
                                                                    type="button"
                                                                    @click="
                                                                        showBreakAddressForm()
                                                                    "
                                                                    class="themed-button items-center my-2 text-sm mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                                                                >
                                                                    <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500" -->
                                                                    <span
                                                                        class="inline-block ml-1 font-bold"
                                                                        ><fa-icon
                                                                            icon="plus"
                                                                            class="mr-2"
                                                                        ></fa-icon
                                                                        >{{
                                                                            $t(
                                                                                'new_address_generic',
                                                                            )
                                                                        }}</span
                                                                    >
                                                                </button>
                                                            </span>
                                                            <p
                                                                v-if="
                                                                    breakForm.IdClientAddress !==
                                                                    0
                                                                "
                                                                class="text-xs flex mb-2 justify-center font-normal"
                                                            >
                                                                {{
                                                                    `${
                                                                        breakForm.Address1
                                                                    } ${
                                                                        breakForm.Address2 !==
                                                                            'null' &&
                                                                        breakForm.Address2 !==
                                                                            null &&
                                                                        breakForm.Address2 !==
                                                                            ''
                                                                            ? breakForm.Address2
                                                                            : ''
                                                                    } ${breakForm.PostalCode} ${breakForm.City}`
                                                                }}
                                                            </p>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-if="
                                                                    breakForm.IdClientAddress !==
                                                                        0 &&
                                                                    (breakForm.Address1 ===
                                                                        '' ||
                                                                        breakForm.PostalCode ===
                                                                            '' ||
                                                                        breakForm.PostalCode ===
                                                                            '00000' ||
                                                                        breakForm.City ===
                                                                            '')
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'address_data_missing',
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="items-center"
                                                            >
                                                                <label
                                                                    :for="
                                                                        'CommentStepbr' +
                                                                        breakForm.index
                                                                    "
                                                                    class="text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'step_comment',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <textarea
                                                                    :id="
                                                                        'CommentStepbr' +
                                                                        breakForm.index
                                                                    "
                                                                    type=""
                                                                    v-model="
                                                                        breakForm.CommentStep
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                :class="
                                                                    canUpdateRefAddress &&
                                                                    breakForm.IdClientAddress &&
                                                                    (breakForm.ContactFirstName ||
                                                                        breakForm.ContactLastName ||
                                                                        breakForm.ContactPhone ||
                                                                        breakForm.ContactEmail)
                                                                        ? 'cursor-pointer p-2 custom-hover'
                                                                        : ''
                                                                "
                                                                @click.prevent="
                                                                    openAddressUpdateExtraInfo(
                                                                        breakForm,
                                                                    )
                                                                "
                                                            >
                                                                <p
                                                                    v-if="
                                                                        breakForm.IdClientAddress !==
                                                                            0 &&
                                                                        (breakForm.ContacFirsttName ||
                                                                            breakForm.ContacLasttName ||
                                                                            breakForm.ContactPhone ||
                                                                            breakForm.ContactEmail)
                                                                    "
                                                                    class="text-xs flex mb-2 justify-center font-normal"
                                                                >
                                                                    {{
                                                                        `${$t(
                                                                            'contact',
                                                                        )}: ${
                                                                            breakForm.ContactFirstName
                                                                                ? breakForm.ContactFirstName
                                                                                : ''
                                                                        } ${
                                                                            breakForm.ContactLastName
                                                                                ? breakForm.ContactLAstName
                                                                                : ''
                                                                        }  ${
                                                                            breakForm.ContactPhone &&
                                                                            (breakForm.ContactLastName ||
                                                                                breakForm.ContactLastName)
                                                                                ? ' - ' +
                                                                                  breakForm.ContactPhone
                                                                                : breakForm.ContactPhone &&
                                                                                    breakForm.ContactEmail
                                                                                  ? breakForm.ContactPhone +
                                                                                    ' - '
                                                                                  : breakForm.ContactPhone
                                                                                    ? breakForm.ContactPhone
                                                                                    : ''
                                                                        } ${
                                                                            breakForm.ContactEmail
                                                                                ? breakForm.ContactEmail
                                                                                : ''
                                                                        }`
                                                                    }}
                                                                </p>

                                                                <p
                                                                    v-if="
                                                                        breakForm.IdClientAddress !==
                                                                            0 &&
                                                                        breakForm.AdditionalInformation
                                                                    "
                                                                    class="text-xs flex mb-2 justify-center font-normal"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'additional_info',
                                                                        ) +
                                                                        ': ' +
                                                                        breakForm.AdditionalInformation
                                                                    }}
                                                                </p>
                                                                <p
                                                                    v-if="
                                                                        breakForm.IdClientAddress !==
                                                                            0 &&
                                                                        breakForm.DriverInformation
                                                                    "
                                                                    class="text-xs flex mb-2 justify-center font-normal"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'driver_info',
                                                                        ) +
                                                                        ': ' +
                                                                        breakForm.DriverInformation
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            v-if="
                                                                createBreakAddressForm ===
                                                                true
                                                            "
                                                            class="px-3"
                                                        >
                                                            <div
                                                                class="mb-3 mt-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'address_name',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >
                                                                <input
                                                                    v-model="
                                                                        breakForm.AddressName
                                                                    "
                                                                    autocomplete="name"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                                <!-- @blur="
                                                                v$.stepToAdd
                                                                    .AddressName
                                                                    .$touch;
                                                            " -->
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .breakForm
                                                                    .AddressName
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="mb-3 mt-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'contact_firstname',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    v-model="
                                                                        breakForm.ContactFirstName
                                                                    "
                                                                    autocomplete="name"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="mb-3 mt-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'contact_lastname',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    v-model="
                                                                        breakForm.ContactLastName
                                                                    "
                                                                    autocomplete="name"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="mb-3 mt-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'email_address',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="email"
                                                                    autocomplete="email"
                                                                    v-model="
                                                                        breakForm.ContactEmail
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .breakForm
                                                                    .ContactEmail
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="mb-3 mt-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    for="contactphone"
                                                                    class="font-semibold basis-1/4 text-gray-600 whitespace-nowrap mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'phone',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    id="contactphone"
                                                                    v-model="
                                                                        breakForm.ContactPhone
                                                                    "
                                                                    autocomplete="tel"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .breakForm
                                                                    .ContactPhone
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="mb-3 mt-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'additional_info',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    v-model="
                                                                        breakForm.AdditionalInformation
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="mb-3 mt-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'driver_info',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    v-model="
                                                                        breakForm.DriverInformation
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>

                                                            <div
                                                                class="mb-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'address',
                                                                        ) +
                                                                        ' 1 *'
                                                                    }}
                                                                </label>
                                                                <input
                                                                    v-model="
                                                                        breakForm.Address1
                                                                    "
                                                                    :id="`autocompleteBreakform`"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                                <!-- @blur="
                                                                v$.stepToAdd
                                                                    .Address1
                                                                    .$touch;
                                                            " -->
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .breakForm
                                                                    .Address1
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="mb-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'address',
                                                                        )
                                                                    }}
                                                                    2</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    v-model="
                                                                        breakForm.Address2
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="mb-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'postal_code',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >
                                                                <input
                                                                    id="postal_code"
                                                                    autocomplete="postal-code"
                                                                    v-model="
                                                                        breakForm.PostalCode
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                                <!-- @blur=" v$.stepToAdd
                                                        .PostalCode .$touch; " -->
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .breakForm
                                                                    .PostalCode
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="mb-3 items-center flex flex-row"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'city',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="text"
                                                                    v-model="
                                                                        breakForm.City
                                                                    "
                                                                    id="locality"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                                <!-- @blur=" v$.stepToAdd
                                                        .City .$touch; " -->
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .breakForm
                                                                    .City
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                @click="
                                                                    showBreakAddressForm(
                                                                        false,
                                                                    )
                                                                "
                                                                class="transition duration-200 my-2 mt-2 mb-3 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                            >
                                                                <span
                                                                    class="inline-block ml-1 font-bold"
                                                                    ><fa-icon
                                                                        icon="undo"
                                                                        class="mr-2"
                                                                    ></fa-icon
                                                                    >{{
                                                                        $t(
                                                                            'back_to_predefined_addresses',
                                                                        )
                                                                    }}</span
                                                                >
                                                            </button>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                recurrentOrder ===
                                                                false
                                                            "
                                                            class="mb-3 items-center flex flex-row"
                                                        >
                                                            <label
                                                                class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                                >{{
                                                                    $t('on') +
                                                                    ' *'
                                                                }}</label
                                                            >
                                                            <input
                                                                type="date"
                                                                :min="minDate"
                                                                :max="maxDate"
                                                                v-model="
                                                                    breakForm.stepDate
                                                                "
                                                                @change="
                                                                    breakFormTimesAreCorrect(
                                                                        index,
                                                                    )
                                                                "
                                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                                @blur="
                                                                    setExtraInfos(
                                                                        index,
                                                                        breakForm.stepDate,
                                                                        breakForm.FromDateTime,
                                                                        breakForm.BeforeDateTime,
                                                                    )
                                                                "
                                                            />
                                                        </div>
                                                        <!-- @focus="
                                                            resetMinMaxDate(
                                                                index,
                                                            )
                                                        " -->
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .breakForm
                                                                .stepDate
                                                                .$errors"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                new Date(
                                                                    breakForm.stepDate,
                                                                ) >
                                                                    new Date(
                                                                        maxDate,
                                                                    ) ||
                                                                new Date(
                                                                    breakForm.stepDate,
                                                                ) <
                                                                    new Date(
                                                                        minDate,
                                                                    )
                                                            "
                                                            class="text-xs italic mt-1 mb-3"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    $t(
                                                                        new Date(
                                                                            breakForm.stepDate,
                                                                        ) >
                                                                            new Date(
                                                                                maxDate,
                                                                            )
                                                                            ? 'date_error_after'
                                                                            : 'date_error_before',
                                                                        1,
                                                                    )
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                breakForm.Hours &&
                                                                openingHoursForDay(
                                                                    breakForm.Hours,
                                                                    findStepDay(
                                                                        breakForm.stepDate,
                                                                    ),
                                                                ).length > 0
                                                            "
                                                            class="mb-3 text-xs"
                                                        >
                                                            <div
                                                                class="mb-1 justify-center flex"
                                                            >
                                                                {{
                                                                    `${$t(
                                                                        'opening_hours',
                                                                    )} ${$t(
                                                                        'for',
                                                                    )} ${$t(
                                                                        days[
                                                                            findStepDay(
                                                                                breakForm.stepDate,
                                                                            )
                                                                        ],
                                                                    )} ${
                                                                        breakForm.stepDate
                                                                    } `
                                                                }}
                                                            </div>
                                                            <div
                                                                v-for="(
                                                                    hour, index
                                                                ) in openingHoursForDay(
                                                                    breakForm.Hours,
                                                                    findStepDay(
                                                                        breakForm.stepDate,
                                                                    ),
                                                                )"
                                                                :key="index"
                                                                class="justify-center flex"
                                                            >
                                                                {{
                                                                    `${hour.start} - ${hour.end}`
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="flex justify-center items-center mb-3"
                                                        >
                                                            <span
                                                                class="mr-2 text-lg"
                                                            >
                                                                *
                                                            </span>
                                                            <div
                                                                id="moment-select"
                                                            >
                                                                <Multiselect
                                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                    v-model="
                                                                        breakForm.Moment
                                                                    "
                                                                    :options="
                                                                        stepMomentOptions
                                                                    "
                                                                    :loading="
                                                                        stepMomentOptions.length ===
                                                                        0
                                                                    "
                                                                    @select="
                                                                        breakFormTimesAreCorrect(
                                                                            index,
                                                                        )
                                                                    "
                                                                    label="name"
                                                                    track-by="name"
                                                                    :allow-empty="
                                                                        false
                                                                    "
                                                                    :can-clear="
                                                                        false
                                                                    "
                                                                ></Multiselect>
                                                            </div>
                                                            <div
                                                                class="ml-2"
                                                                v-if="
                                                                    breakForm.Moment ===
                                                                        'between' ||
                                                                    breakForm.Moment ===
                                                                        'from'
                                                                "
                                                            >
                                                                <input
                                                                    type="time"
                                                                    v-model="
                                                                        breakForm.FromDateTime
                                                                    "
                                                                    @blur="
                                                                        breakFormTimesAreCorrect(
                                                                            index,
                                                                        )
                                                                    "
                                                                    @input="
                                                                        resetBadTimes()
                                                                    "
                                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                                />
                                                                <div
                                                                    class="text-xs italic mt-1 mb-2"
                                                                    v-for="error of v$
                                                                        .breakForm
                                                                        .FromDateTime
                                                                        .$errors"
                                                                    :key="
                                                                        error.$uid
                                                                    "
                                                                >
                                                                    <div
                                                                        class="error-msg"
                                                                    >
                                                                        {{
                                                                            error.$message
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="ml-2"
                                                                v-if="
                                                                    breakForm.Moment ===
                                                                        'between' ||
                                                                    breakForm.Moment ===
                                                                        'before'
                                                                "
                                                            >
                                                                <input
                                                                    type="time"
                                                                    v-model="
                                                                        breakForm.BeforeDateTime
                                                                    "
                                                                    @blur="
                                                                        breakFormTimesAreCorrect(
                                                                            index,
                                                                        )
                                                                    "
                                                                    @input="
                                                                        resetBadTimes()
                                                                    "
                                                                    class="border text-sm rounded px-3 py-2 w-full"
                                                                />
                                                                <div
                                                                    class="text-xs italic mt-1 mb-2"
                                                                    v-for="error of v$
                                                                        .breakForm
                                                                        .BeforeDateTime
                                                                        .$errors"
                                                                    :key="
                                                                        error.$uid
                                                                    "
                                                                >
                                                                    <div
                                                                        class="error-msg"
                                                                    >
                                                                        {{
                                                                            error.$message
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-if="
                                                                badTimeRange &&
                                                                badTimeRangeItem ===
                                                                    breakForm
                                                            "
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'bad_time_range',
                                                                    )
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-if="
                                                                badTimeRangeSteps ||
                                                                delivBeforePickUp
                                                            "
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    `${$t(
                                                                        badTimeRangeSteps
                                                                            ? 'bad_time_range_steps_2'
                                                                            : 'bad_time_range_steps',
                                                                    )} : ${previousPickUpTimesBis(
                                                                        breakForm.stepDate,
                                                                        breakForm.Type ===
                                                                            'pickup'
                                                                            ? index +
                                                                                  1
                                                                            : index,
                                                                    )}`
                                                                }}
                                                            </div>
                                                        </div>

                                                        <span
                                                            :key="
                                                                refreshDriverVehicleSelect
                                                            "
                                                            v-if="
                                                                !isOdd(index) &&
                                                                formData.PaymentType !==
                                                                    'web' &&
                                                                formData.IdClient
                                                            "
                                                            class="grid grid-cols-4 gap-1 py-2"
                                                        >
                                                            <div
                                                                class="mb-3 col-span-2 items-center px-3"
                                                                :class="
                                                                    driverOptions.length ===
                                                                    0
                                                                        ? 'selectMS'
                                                                        : ''
                                                                "
                                                            >
                                                                <span
                                                                    v-if="
                                                                        canAssignDriver ===
                                                                        true
                                                                    "
                                                                >
                                                                    <div
                                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'driver',
                                                                            )
                                                                        }}
                                                                    </div>
                                                                    <!-- :loading="
                                                                    driverOptions.length ===
                                                                        0 &&
                                                                    formData.IdClient !==
                                                                        ''
                                                                " -->
                                                                    <driver-multiselect
                                                                        :driverOptions="
                                                                            driverOptions
                                                                        "
                                                                        :driverId="
                                                                            breakForm.IdDriverForNext
                                                                        "
                                                                        :index="
                                                                            index
                                                                        "
                                                                        :refreshMS="
                                                                            refreshMS
                                                                        "
                                                                        @selected="
                                                                            setIsSubContractor(
                                                                                $event,
                                                                                breakForm,
                                                                            )
                                                                        "
                                                                        @clear="
                                                                            setIsSubContractor(
                                                                                {
                                                                                    driverId:
                                                                                        null,
                                                                                    stepIndex:
                                                                                        index,
                                                                                },
                                                                                breakForm,
                                                                            )
                                                                        "
                                                                    ></driver-multiselect>
                                                                </span>
                                                            </div>
                                                            <div
                                                                class="mb-1 col-span-2 items-center px-3"
                                                                :class="
                                                                    vehicleOptions.length ===
                                                                    0
                                                                        ? 'selectMS'
                                                                        : ''
                                                                "
                                                            >
                                                                <span
                                                                    v-if="
                                                                        canAssignVehicle ===
                                                                        true
                                                                    "
                                                                >
                                                                    <div
                                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'vehicle',
                                                                            )
                                                                        }}
                                                                    </div>
                                                                    <Multiselect
                                                                        :key="
                                                                            refreshMS
                                                                        "
                                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                        v-model="
                                                                            breakForm.IdVehicleForNext
                                                                        "
                                                                        :options="
                                                                            vehicleOptions
                                                                        "
                                                                        :loading="
                                                                            vehicleOptions.length ===
                                                                                0 &&
                                                                            formData.IdClient !==
                                                                                ''
                                                                        "
                                                                        label="name"
                                                                        track-by="name"
                                                                        :searchable="
                                                                            true
                                                                        "
                                                                        :placeholder="
                                                                            vehicleOptions.length
                                                                                ? $t(
                                                                                      'select_one',
                                                                                  )
                                                                                : $t(
                                                                                      'no_available_for_date',
                                                                                  )
                                                                        "
                                                                        :allow-empty="
                                                                            false
                                                                        "
                                                                        :can-clear="
                                                                            true
                                                                        "
                                                                    ></Multiselect>
                                                                </span>
                                                            </div>
                                                        </span>
                                                        <span
                                                            :key="step"
                                                            v-if="
                                                                (!isOdd(
                                                                    index,
                                                                ) &&
                                                                    breakForm.Driver &&
                                                                    breakForm
                                                                        .Driver
                                                                        .SubContractor &&
                                                                    +breakForm
                                                                        .Driver
                                                                        .SubContractor ===
                                                                        1) ||
                                                                (!isOdd(
                                                                    index,
                                                                ) &&
                                                                    breakForm.IsSubContractor &&
                                                                    breakForm.IsSubContractor ===
                                                                        true)
                                                            "
                                                        >
                                                            <div
                                                                class="grid grid-cols-6"
                                                            >
                                                                <div
                                                                    class="col-span-3 items-center justify-center m-3"
                                                                >
                                                                    <span
                                                                        class="whitespace-nowrap text-base mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'purchase_price',
                                                                            ) +
                                                                            ' *'
                                                                        }}
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        min="0.01"
                                                                        step="0.01"
                                                                        v-model="
                                                                            breakForm[
                                                                                index +
                                                                                    1
                                                                            ]
                                                                                .PurchasePrice
                                                                        "
                                                                        class="border rounded px-3 py-2 h-10 mr-2"
                                                                        :class="
                                                                            purchasePriceMissing ===
                                                                            true
                                                                                ? 'border-red-500'
                                                                                : ''
                                                                        "
                                                                    />
                                                                    <div
                                                                        v-if="
                                                                            purchasePriceMissing ===
                                                                            true
                                                                        "
                                                                        class="text-red-500 text-xs italic"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'required_field',
                                                                            )
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-span-3 items-center justify-center m-3"
                                                                >
                                                                    <span
                                                                        class="whitespace-nowrap text-base mr-2"
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                'selling_price',
                                                                            )
                                                                        }}
                                                                    </span>
                                                                    <input
                                                                        type="number"
                                                                        min="0.01"
                                                                        step="0.01"
                                                                        v-model="
                                                                            breakForm[
                                                                                index +
                                                                                    1
                                                                            ]
                                                                                .SellingPrice
                                                                        "
                                                                        class="border rounded px-3 py-2 h-10 mr-2"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    breakForm[
                                                                        index +
                                                                            1
                                                                    ].Margin
                                                                "
                                                                class="items-center justify-center m-3"
                                                            >
                                                                {{
                                                                    `${$t('margin')} ${
                                                                        breakForm[
                                                                            index +
                                                                                1
                                                                        ].Margin
                                                                    }`
                                                                }}
                                                            </div>
                                                            <div
                                                                class="flex items-center justify-center m-3"
                                                            >
                                                                <input
                                                                    id="charter-checkbox"
                                                                    type="checkbox"
                                                                    v-model="
                                                                        breakForm[
                                                                            index +
                                                                                1
                                                                        ]
                                                                            .SendCharter
                                                                    "
                                                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                                    :class="'cursor-pointer'"
                                                                />
                                                                <label
                                                                    for="charter-checkbox"
                                                                    class="ml-2 text-base font-medium theme-color"
                                                                    >{{
                                                                        $t(
                                                                            'send_charter',
                                                                        )
                                                                    }}</label
                                                                >
                                                            </div>
                                                        </span>
                                                        <span
                                                            v-else-if="
                                                                !isOdd(index) &&
                                                                formData.PaymentType ===
                                                                    'web' &&
                                                                formData.IdClient
                                                            "
                                                            class="text-xs font-semibold theme-color flex item-center px-5"
                                                        >
                                                            {{
                                                                $t(
                                                                    'web_driver_assign_info',
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                        </ol>
                                    </div>
                                    <div
                                        v-if="
                                            canSetRoundTrip === true &&
                                            (formData.OrderSteps.length === 2 ||
                                                roundTrip) &&
                                            ($route.name === 'new_order' ||
                                                ($route.name ===
                                                    'update_order' &&
                                                    currentOrder &&
                                                    !currentOrder.isInvoiced))
                                        "
                                        class="flex items-center justify-center mt-4"
                                    >
                                        <input
                                            id="round-trip-checkbox"
                                            type="checkbox"
                                            :disabled="roundTrip"
                                            v-model="roundTrip"
                                            @change="setRoundTrip()"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                            :class="'cursor-pointer'"
                                        />
                                        <label
                                            for="round-trip-checkbox"
                                            class="ml-2 text-base font-medium theme-color"
                                            >{{ $t('round_trip') }}</label
                                        >
                                    </div>
                                    <!-- v-if="
                                            isB2C ||
                                            (formData.IdClient !== '' &&
                                                formData.IdDriver !== '')
                                        " -->
                                    <div v-if="stepsToAdd.length">
                                        <div
                                            class="mt-2 rounded-md p-4 border-2"
                                        >
                                            <div
                                                v-for="(
                                                    stepToAdd, index
                                                ) in stepsToAdd"
                                                :key="index"
                                                class="px-2 border-l-2 border-r-2"
                                                :class="
                                                    stepToAdd.Type === 'pickup'
                                                        ? 'rounded-t-md pt-2 border-t-2'
                                                        : 'rounded-b-md mb-1 border-b-2'
                                                "
                                            >
                                                <div class="px-3">
                                                    <div
                                                        class="flex items-center justify-between mr-4"
                                                    >
                                                        {{
                                                            $t(
                                                                stepToAdd.Type ===
                                                                    'pickup'
                                                                    ? 'pick_up_label'
                                                                    : 'delivery_label',
                                                            )
                                                        }}
                                                        <icon-button
                                                            v-if="
                                                                stepToAdd.Type ===
                                                                    'pickup' &&
                                                                ((stepsToAdd.length >
                                                                    2 &&
                                                                    index >
                                                                        0) ||
                                                                    formData
                                                                        .OrderSteps
                                                                        .length) &&
                                                                roundTrip ===
                                                                    false &&
                                                                ($route.name ===
                                                                    'new_order' ||
                                                                    ($route.name ===
                                                                        'update_order' &&
                                                                        currentOrder &&
                                                                        !currentOrder.isInvoiced))
                                                            "
                                                            :icon="'trash-can'"
                                                            class="ml-3"
                                                            :noStyle="true"
                                                            :iconStyle="'fa-md cursor-pointer  error-msg'"
                                                            :title="
                                                                $t('delete')
                                                            "
                                                            @click="
                                                                removeTempSteps(
                                                                    index,
                                                                )
                                                            "
                                                        />
                                                    </div>
                                                    <hr />

                                                    <div
                                                        v-if="
                                                            !showForm.includes(
                                                                index,
                                                            )
                                                        "
                                                        class="mb-2 mt-3 items-center flex flex-row"
                                                    >
                                                        <Multiselect
                                                            :disabled="
                                                                roundTrip ===
                                                                    true &&
                                                                (index === 2 ||
                                                                    index === 3)
                                                            "
                                                            :key="refreshMS"
                                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                            v-model="
                                                                stepToAdd.IdClientAddress
                                                            "
                                                            :options="
                                                                addressOptions
                                                            "
                                                            :loading="
                                                                addressOptions.length ===
                                                                    0 &&
                                                                formData.IdClient !==
                                                                    ''
                                                            "
                                                            label="name"
                                                            track-by="name"
                                                            :searchable="true"
                                                            :placeholder="
                                                                $t(
                                                                    'can_select_address',
                                                                )
                                                            "
                                                            :allow-empty="false"
                                                            :can-clear="false"
                                                            @select="
                                                                setAddressInfo(
                                                                    stepToAdd.IdClientAddress,
                                                                    index,
                                                                )
                                                            "
                                                        ></Multiselect>
                                                    </div>
                                                    <p
                                                        v-if="
                                                            stepToAdd.IdClientAddress !==
                                                                0 &&
                                                            !showForm.includes(
                                                                index,
                                                            )
                                                        "
                                                        class="text-xs flex mb-2 justify-center font-normal"
                                                    >
                                                        {{
                                                            `${
                                                                stepToAdd.Address1
                                                            } ${
                                                                stepToAdd.Address2 !==
                                                                    'null' &&
                                                                stepToAdd.Address2 !==
                                                                    null &&
                                                                stepToAdd.Address2 !==
                                                                    ''
                                                                    ? stepToAdd.Address2
                                                                    : ''
                                                            } 
                                                            ${
                                                                stepToAdd.PostalCode
                                                            } ${
                                                                stepToAdd.City
                                                            } `
                                                        }}
                                                    </p>

                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-if="
                                                            stepToAdd.IdClientAddress !==
                                                                0 &&
                                                            !showForm.includes(
                                                                index,
                                                            ) &&
                                                            (stepToAdd.Address1 ===
                                                                '' ||
                                                                stepToAdd.PostalCode ===
                                                                    '' ||
                                                                stepToAdd.PostalCode ===
                                                                    '00000' ||
                                                                stepToAdd.City ===
                                                                    '')
                                                        "
                                                    >
                                                        <div class="error-msg">
                                                            {{
                                                                $t(
                                                                    'address_data_missing',
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="items-center">
                                                        <label
                                                            :for="
                                                                'CommentStep' +
                                                                index
                                                            "
                                                            class="text-gray-600 pb-1 mr-2"
                                                            >{{
                                                                $t(
                                                                    'step_comment',
                                                                )
                                                            }}</label
                                                        >
                                                        <textarea
                                                            :id="
                                                                'CommentStep' +
                                                                index
                                                            "
                                                            type=""
                                                            v-model="
                                                                stepToAdd.CommentStep
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        :class="
                                                            canUpdateRefAddress &&
                                                            stepToAdd.IdClientAddress &&
                                                            (stepToAdd.ContactLastName ||
                                                                stepToAdd.ContactFirstName ||
                                                                stepToAdd.ContactPhone ||
                                                                stepToAdd.ContactEmail)
                                                                ? 'cursor-pointer p-2 custom-hover'
                                                                : ''
                                                        "
                                                        @click.prevent="
                                                            openAddressUpdateExtraInfo(
                                                                stepToAdd,
                                                            )
                                                        "
                                                    >
                                                        <p
                                                            v-if="
                                                                stepToAdd.IdClientAddress !==
                                                                    0 &&
                                                                !showForm.includes(
                                                                    index,
                                                                ) &&
                                                                (stepToAdd.ContactLastName ||
                                                                    stepToAdd.ContactFirstName ||
                                                                    stepToAdd.ContactPhone ||
                                                                    stepToAdd.ContactEmail)
                                                            "
                                                            class="text-xs flex mb-2 justify-center font-normal"
                                                        >
                                                            {{
                                                                `${$t(
                                                                    'contact',
                                                                )}: ${
                                                                    stepToAdd.ContactFirstName
                                                                        ? stepToAdd.ContactFirstName
                                                                        : ''
                                                                }  ${
                                                                    stepToAdd.ContactLasttName
                                                                        ? stepToAdd.ContactLasttName
                                                                        : ''
                                                                } ${
                                                                    stepToAdd.ContactPhone &&
                                                                    (stepToAdd.ContactFirstName ||
                                                                        stepToAdd.ContactLastName)
                                                                        ? ' - ' +
                                                                          stepToAdd.ContactPhone
                                                                        : stepToAdd.ContactPhone &&
                                                                            stepToAdd.ContactEmail
                                                                          ? stepToAdd.ContactPhone +
                                                                            ' - '
                                                                          : stepToAdd.ContactPhone
                                                                            ? stepToAdd.ContactPhone
                                                                            : ''
                                                                } ${
                                                                    stepToAdd.ContactEmail
                                                                        ? stepToAdd.ContactEmail
                                                                        : ''
                                                                }`
                                                            }}
                                                        </p>

                                                        <p
                                                            v-if="
                                                                stepToAdd.IdClientAddress !==
                                                                    0 &&
                                                                stepToAdd.AdditionalInformation &&
                                                                !showForm.includes(
                                                                    index,
                                                                )
                                                            "
                                                            class="text-xs flex mb-2 justify-center font-normal"
                                                        >
                                                            {{
                                                                $t(
                                                                    'additional_info',
                                                                ) +
                                                                ': ' +
                                                                stepToAdd.AdditionalInformation
                                                            }}
                                                        </p>
                                                        <p
                                                            v-if="
                                                                stepToAdd.IdClientAddress !==
                                                                    0 &&
                                                                stepToAdd.DriverInformation &&
                                                                !showForm.includes(
                                                                    index,
                                                                )
                                                            "
                                                            class="text-xs flex mb-2 justify-center font-normal"
                                                        >
                                                            {{
                                                                $t(
                                                                    'driver_info',
                                                                ) +
                                                                ': ' +
                                                                stepToAdd.DriverInformation
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <span
                                                    class="items-center flex justify-center"
                                                >
                                                    <button
                                                        v-if="
                                                            !showForm.includes(
                                                                index,
                                                            ) &&
                                                            roundTrip === false
                                                        "
                                                        type="button"
                                                        @click="
                                                            showAddressForm(
                                                                index,
                                                            )
                                                        "
                                                        class="themed-button items-center my-2 text-sm mr-2 focus:outline-none flex justify-center px-4 py-2 rounded cursor-pointer text-white duration-200 ease-in-out transition"
                                                    >
                                                        <!-- class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500" -->
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            ><fa-icon
                                                                icon="plus"
                                                                class="mr-2"
                                                            ></fa-icon
                                                            >{{
                                                                $t(
                                                                    'new_address_generic',
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                </span>
                                                <div
                                                    v-if="
                                                        showForm.includes(index)
                                                    "
                                                    class="px-3"
                                                >
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'address_name',
                                                                ) + ' *'
                                                            }}</label
                                                        >
                                                        <input
                                                            :disabled="
                                                                roundTrip ===
                                                                true
                                                            "
                                                            v-model="
                                                                stepToAdd.AddressName
                                                            "
                                                            autocomplete="name"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur="
                                                                v$.stepToAdd
                                                                    .AddressName
                                                                    .$touch;
                                                            " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].AddressName"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'contact_firstname',
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.ContactFirstName
                                                            "
                                                            autocomplete="name"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'contact_lastname',
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.ContactLastName
                                                            "
                                                            autocomplete="name"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'email_address',
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            type="email"
                                                            autocomplete="email"
                                                            v-model="
                                                                stepToAdd.ContactEmail
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].ContactEmail"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            for="contactphone"
                                                            class="font-semibold basis-1/4 text-gray-600 whitespace-nowrap mr-2 block"
                                                            >{{
                                                                $t('phone')
                                                            }}</label
                                                        >
                                                        <input
                                                            id="contactphone"
                                                            v-model="
                                                                stepToAdd.ContactPhone
                                                            "
                                                            autocomplete="tel"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].ContactPhone"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'additional_info',
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.AdditionalInformation
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        class="mb-3 mt-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold text-gray-600 basis-1/4 whitespace-nowrap mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'driver_info',
                                                                )
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.DriverInformation
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>

                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                            >{{
                                                                $t('address') +
                                                                ' 1 *'
                                                            }}
                                                        </label>
                                                        <input
                                                            v-model="
                                                                stepToAdd.Address1
                                                            "
                                                            :disabled="
                                                                roundTrip ===
                                                                true
                                                            "
                                                            :id="`autocompleteAddStep_${index}`"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur="
                                                                v$.stepToAdd
                                                                    .Address1
                                                                    .$touch;
                                                            " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].Address1"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                            >{{
                                                                $t('address')
                                                            }}
                                                            2</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.Address2
                                                            "
                                                            :disabled="
                                                                roundTrip ===
                                                                true
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                    </div>
                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'postal_code',
                                                                ) + ' *'
                                                            }}</label
                                                        >
                                                        <input
                                                            id="postal_code"
                                                            autocomplete="postal-code"
                                                            v-model="
                                                                stepToAdd.PostalCode
                                                            "
                                                            :disabled="
                                                                roundTrip ===
                                                                true
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur=" v$.stepToAdd
                                                        .PostalCode .$touch; " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].PostalCode"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="mb-3 items-center flex flex-row"
                                                    >
                                                        <label
                                                            class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 mr-2 block"
                                                            >{{
                                                                $t('city') +
                                                                ' *'
                                                            }}</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                stepToAdd.City
                                                            "
                                                            :disabled="
                                                                roundTrip ===
                                                                true
                                                            "
                                                            id="locality"
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <!-- @blur=" v$.stepToAdd
                                                        .City .$touch; " -->
                                                    </div>
                                                    <div
                                                        class="text-xs italic mt-1 mb-2"
                                                        v-for="error of v$
                                                            .stepsToAdd.$each
                                                            .$response.$errors[
                                                            index
                                                        ].City"
                                                        :key="error.$uid"
                                                    >
                                                        <div class="error-msg">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        @click="
                                                            showAddressForm(
                                                                index,
                                                            )
                                                        "
                                                        class="transition duration-200 my-2 mt-2 mb-3 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            ><fa-icon
                                                                icon="undo"
                                                                class="mr-2"
                                                            ></fa-icon
                                                            >{{
                                                                $t(
                                                                    'back_to_predefined_addresses',
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                </div>
                                                <div
                                                    v-if="
                                                        recurrentOrder === false
                                                    "
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('on') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="date"
                                                        :min="minDate"
                                                        :max="maxDate"
                                                        v-model="
                                                            stepToAdd.stepDate
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                        @blur="
                                                            setExtraInfos(
                                                                index,
                                                                stepToAdd.stepDate,
                                                                stepToAdd.FromDateTime,
                                                                stepToAdd.BeforeDateTime,
                                                            )
                                                        "
                                                        @change="
                                                            timesAreCorrect(
                                                                false,
                                                                index,
                                                            )
                                                        "
                                                        @focus="
                                                            resetMinMaxDate(
                                                                index,
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$
                                                        .stepsToAdd.$each
                                                        .$response.$errors[
                                                        index
                                                    ].stepDate"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="
                                                        new Date(
                                                            stepToAdd.stepDate,
                                                        ) > new Date(maxDate) ||
                                                        new Date(
                                                            stepToAdd.stepDate,
                                                        ) < new Date(minDate)
                                                    "
                                                    class="text-xs italic mt-1 mb-3"
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $t(
                                                                new Date(
                                                                    stepToAdd.stepDate,
                                                                ) >
                                                                    new Date(
                                                                        maxDate,
                                                                    )
                                                                    ? 'date_error_after'
                                                                    : 'date_error_before',
                                                                1,
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="
                                                        stepToAdd.Hours &&
                                                        openingHoursForDay(
                                                            stepToAdd.Hours,
                                                            findStepDay(
                                                                stepToAdd.stepDate,
                                                            ),
                                                        ).length > 0
                                                    "
                                                    class="mb-3 text-xs"
                                                >
                                                    <div
                                                        class="mb-1 justify-center flex"
                                                    >
                                                        {{
                                                            `${$t(
                                                                'opening_hours',
                                                            )} ${$t(
                                                                'for',
                                                            )} ${$t(
                                                                days[
                                                                    findStepDay(
                                                                        stepToAdd.stepDate,
                                                                    )
                                                                ],
                                                            )} ${
                                                                stepToAdd.stepDate
                                                            } `
                                                        }}
                                                    </div>
                                                    <div
                                                        v-for="(
                                                            hour, index
                                                        ) in openingHoursForDay(
                                                            stepToAdd.Hours,
                                                            findStepDay(
                                                                stepToAdd.stepDate,
                                                            ),
                                                        )"
                                                        :key="index"
                                                        class="justify-center flex"
                                                    >
                                                        {{
                                                            `${hour.start} - ${hour.end}`
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="flex justify-center items-center mb-3"
                                                >
                                                    <span class="mr-2 text-lg">
                                                        *
                                                    </span>
                                                    <div id="moment-select">
                                                        <Multiselect
                                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                            v-model="
                                                                stepToAdd.Moment
                                                            "
                                                            :options="
                                                                stepMomentOptions
                                                            "
                                                            :loading="
                                                                stepMomentOptions.length ===
                                                                0
                                                            "
                                                            @select="
                                                                timesAreCorrect(
                                                                    false,
                                                                    index,
                                                                )
                                                            "
                                                            label="name"
                                                            track-by="name"
                                                            :allow-empty="false"
                                                            :can-clear="false"
                                                        ></Multiselect>
                                                    </div>
                                                    <div
                                                        class="ml-2"
                                                        v-if="
                                                            stepToAdd.Moment ===
                                                                'between' ||
                                                            stepToAdd.Moment ===
                                                                'from'
                                                        "
                                                    >
                                                        <input
                                                            type="time"
                                                            v-model="
                                                                stepToAdd.FromDateTime
                                                            "
                                                            @blur="
                                                                timesAreCorrect(
                                                                    false,
                                                                    index,
                                                                )
                                                            "
                                                            @input="
                                                                resetBadTimes()
                                                            "
                                                            class="border text-sm rounded px-3 py-2 w-full"
                                                        />
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .stepsToAdd
                                                                .$each.$response
                                                                .$errors[index]
                                                                .FromDateTime"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="ml-2"
                                                        v-if="
                                                            stepToAdd.Moment ===
                                                                'between' ||
                                                            stepToAdd.Moment ===
                                                                'before'
                                                        "
                                                    >
                                                        <input
                                                            type="time"
                                                            v-model="
                                                                stepToAdd.BeforeDateTime
                                                            "
                                                            @blur="
                                                                timesAreCorrect(
                                                                    false,
                                                                    index,
                                                                )
                                                            "
                                                            @input="
                                                                resetBadTimes()
                                                            "
                                                            class="border text-sm rounded px-3 py-2 w-full"
                                                        />
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .stepsToAdd
                                                                .$each.$response
                                                                .$errors[index]
                                                                .BeforeDateTime"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-if="
                                                        badTimeRange &&
                                                        badTimeRangeItem ===
                                                            stepToAdd
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            $t('bad_time_range')
                                                        }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-if="
                                                        badTimeRangeSteps ||
                                                        delivBeforePickUp
                                                    "
                                                >
                                                    <div class="error-msg">
                                                        {{
                                                            `${$t(
                                                                stepToAdd.Type ===
                                                                    'pickup'
                                                                    ? 'bad_time_range_steps_2'
                                                                    : 'bad_time_range_steps',
                                                            )} : ${previousPickUpTimesBis(
                                                                stepToAdd.Type ===
                                                                    'pickup'
                                                                    ? index + 1
                                                                    : index - 1,
                                                            )}`
                                                        }}
                                                    </div>
                                                </div>

                                                <span
                                                    :key="
                                                        refreshDriverVehicleSelect
                                                    "
                                                    v-if="
                                                        !isOdd(index) &&
                                                        (!formData.PaymentType ||
                                                            formData.PaymentType !==
                                                                'web') &&
                                                        formData.IdClient
                                                    "
                                                    class="grid grid-cols-4 gap-1 py-2"
                                                >
                                                    <div
                                                        class="mb-3 col-span-2 items-center px-3"
                                                        :class="
                                                            driverOptions.length ===
                                                            0
                                                                ? 'selectMS'
                                                                : ''
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                canAssignDriver ===
                                                                true
                                                            "
                                                        >
                                                            <div
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                            >
                                                                {{
                                                                    $t('driver')
                                                                }}
                                                            </div>
                                                            <!-- :loading="
                                                                    driverOptions.length ===
                                                                        0 &&
                                                                    formData.IdClient !==
                                                                        ''
                                                                " -->
                                                            <driver-multiselect
                                                                :driverOptions="
                                                                    driverOptions
                                                                "
                                                                :driverId="
                                                                    stepToAdd.IdDriver
                                                                "
                                                                :index="index"
                                                                :refreshMS="
                                                                    refreshMS
                                                                "
                                                                @selected="
                                                                    setIsSubContractor(
                                                                        $event,
                                                                        stepsToAdd,
                                                                    )
                                                                "
                                                                @clear="
                                                                    setIsSubContractor(
                                                                        {
                                                                            driverId:
                                                                                null,
                                                                            stepIndex:
                                                                                index,
                                                                        },
                                                                        stepsToAdd,
                                                                    )
                                                                "
                                                            ></driver-multiselect>
                                                        </span>
                                                    </div>

                                                    <div
                                                        class="mb-1 col-span-2 items-center px-3"
                                                        :class="
                                                            vehicleOptions.length ===
                                                            0
                                                                ? 'selectMS'
                                                                : ''
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                canAssignVehicle ===
                                                                true
                                                            "
                                                        >
                                                            <div
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'vehicle',
                                                                    )
                                                                }}
                                                            </div>
                                                            <Multiselect
                                                                :key="refreshMS"
                                                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                                v-model="
                                                                    stepToAdd.IdVehicle
                                                                "
                                                                :options="
                                                                    vehicleOptions
                                                                "
                                                                :loading="
                                                                    vehicleOptions.length ===
                                                                        0 &&
                                                                    formData.IdClient !==
                                                                        ''
                                                                "
                                                                label="name"
                                                                track-by="name"
                                                                :searchable="
                                                                    true
                                                                "
                                                                :placeholder="
                                                                    vehicleOptions.length
                                                                        ? $t(
                                                                              'select_one',
                                                                          )
                                                                        : $t(
                                                                              'no_available_for_date',
                                                                          )
                                                                "
                                                                :allow-empty="
                                                                    false
                                                                "
                                                                :can-clear="
                                                                    true
                                                                "
                                                            ></Multiselect>
                                                        </span>
                                                    </div>
                                                </span>
                                                <span
                                                    v-else-if="
                                                        !isOdd(index) &&
                                                        formData.PaymentType ===
                                                            'web' &&
                                                        formData.IdClient
                                                    "
                                                    class="text-xs font-semibold theme-color flex item-center px-5"
                                                >
                                                    {{
                                                        $t(
                                                            'web_driver_assign_info',
                                                        )
                                                    }}
                                                </span>
                                                <span
                                                    :key="step"
                                                    v-if="
                                                        (!isOdd(index) &&
                                                            stepToAdd.Driver &&
                                                            stepToAdd.Driver
                                                                .SubContractor &&
                                                            +stepToAdd.Driver
                                                                .SubContractor ===
                                                                1) ||
                                                        (!isOdd(index) &&
                                                            stepToAdd.IsSubContractor &&
                                                            stepToAdd.IsSubContractor ===
                                                                true)
                                                    "
                                                >
                                                    <div
                                                        class="grid grid-cols-6"
                                                    >
                                                        <div
                                                            class="col-span-3 items-center justify-center m-3"
                                                        >
                                                            <span
                                                                class="whitespace-nowrap text-base mr-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'purchase_price',
                                                                    ) + ' *'
                                                                }}
                                                            </span>
                                                            <input
                                                                type="number"
                                                                min="0.01"
                                                                step="0.01"
                                                                v-model="
                                                                    stepsToAdd[
                                                                        index +
                                                                            1
                                                                    ]
                                                                        .PurchasePrice
                                                                "
                                                                class="border rounded px-3 py-2 h-10 mr-2 w-1/2"
                                                            />
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .stepsToAdd
                                                                    .$each
                                                                    .$response
                                                                    .$errors[
                                                                    index
                                                                ].PurchasePrice"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <!-- :class="
                                                                    purchasePriceMissing ===
                                                                    true
                                                                        ? 'border-red-500'
                                                                        : ''
                                                                " -->
                                                            <!-- <div
                                                                v-if="
                                                                    purchasePriceMissing ===
                                                                    true
                                                                "
                                                                class="text-red-500 text-xs italic"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'required_field',
                                                                    )
                                                                }}
                                                            </div> -->
                                                        </div>
                                                        <div
                                                            class="col-span-3 items-center justify-center m-3"
                                                        >
                                                            <span
                                                                class="whitespace-nowrap text-base mr-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'selling_price',
                                                                    )
                                                                }}
                                                            </span>
                                                            <input
                                                                type="number"
                                                                min="0.01"
                                                                step="0.01"
                                                                v-model="
                                                                    stepsToAdd[
                                                                        index +
                                                                            1
                                                                    ]
                                                                        .SellingPrice
                                                                "
                                                                class="border rounded px-3 py-2 h-10 mr-2"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            stepsToAdd[
                                                                index + 1
                                                            ].Margin
                                                        "
                                                        class="items-center justify-center m-3"
                                                    >
                                                        {{
                                                            `${$t('margin')} ${
                                                                stepsToAdd[
                                                                    index + 1
                                                                ].Margin
                                                            }`
                                                        }}
                                                    </div>
                                                    <div
                                                        class="flex items-center justify-center m-3"
                                                    >
                                                        <button
                                                            type="button"
                                                            class="themed-button items-center text-base mr-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                            @click.prevent="
                                                                $route.name ===
                                                                'new_order'
                                                                    ? createOrder(
                                                                          false,
                                                                          false,
                                                                          false,
                                                                          true,
                                                                      )
                                                                    : updateOrder(
                                                                          false,
                                                                          false,
                                                                          false,
                                                                          false,
                                                                          true,
                                                                      )
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    'save_n_visualise',
                                                                )
                                                            }}
                                                        </button>
                                                    </div>
                                                    <div
                                                        class="flex items-center justify-center m-3"
                                                    >
                                                        <input
                                                            id="charter-checkbox"
                                                            type="checkbox"
                                                            v-model="
                                                                stepsToAdd[
                                                                    index + 1
                                                                ].SendCharter
                                                            "
                                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                            :class="'cursor-pointer'"
                                                        />
                                                        <label
                                                            for="charter-checkbox"
                                                            class="ml-2 text-base font-medium theme-color"
                                                            >{{
                                                                $t(
                                                                    'send_charter',
                                                                )
                                                            }}</label
                                                        >
                                                    </div>
                                                </span>
                                            </div>
                                            <div
                                                v-if="
                                                    formData.OrderSteps
                                                        .length === 0
                                                "
                                                class="flex items-center justify-center mt-4"
                                            >
                                                <input
                                                    id="round-trip-checkbox"
                                                    type="checkbox"
                                                    v-model="roundTrip"
                                                    :disabled="
                                                        stepsToAdd[1]
                                                            .Address1 === '' ||
                                                        stepsToAdd[1]
                                                            .PostalCode ===
                                                            '' ||
                                                        stepsToAdd[1].City ===
                                                            '' ||
                                                        stepsToAdd[1]
                                                            .AddressName === ''
                                                    "
                                                    @change="setRoundTrip()"
                                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                    :class="
                                                        stepsToAdd[1]
                                                            .Address1 === '' ||
                                                        stepsToAdd[1]
                                                            .PostalCode ===
                                                            '' ||
                                                        stepsToAdd[1].City ===
                                                            '' ||
                                                        stepsToAdd[1]
                                                            .AddressName === ''
                                                            ? 'cursor-not-allowed'
                                                            : 'cursor-pointer'
                                                    "
                                                />
                                                <label
                                                    for="round-trip-checkbox"
                                                    class="ml-2 text-base font-medium theme-color"
                                                    >{{
                                                        $t('round_trip')
                                                    }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            $route.name === 'new_order' ||
                                            (stepsToAdd.length &&
                                                stepsToAdd[
                                                    stepsToAdd.length - 1
                                                ].IdClientAddress !== 0) ||
                                            formData.OrderSteps.length ||
                                            stepsToAdd.length
                                        "
                                        class="flex p-4 items-center justify-center mt-4"
                                    >
                                        <span
                                            v-if="
                                                roundTrip === false &&
                                                ($route.name === 'new_order' ||
                                                    ($route.name ===
                                                        'update_order' &&
                                                        currentOrder &&
                                                        !currentOrder.isInvoiced))
                                            "
                                        >
                                            <button
                                                type="button"
                                                :disabled="
                                                    badTimeRangeSteps ||
                                                    delivBeforePickUp ||
                                                    badTimeRange ||
                                                    roundTrip === true
                                                "
                                                class="themed-button items-center text-base mr-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                @click="addStepsToAdd()"
                                            >
                                                <fa-icon
                                                    icon="plus"
                                                    class="mr-2 fa-sm"
                                                ></fa-icon>
                                                {{ $t('add_steps') }}
                                            </button>
                                        </span>
                                        <button
                                            v-if="
                                                stepsToAdd.length &&
                                                formData.OrderSteps.length
                                            "
                                            type="button"
                                            class="text-base focus:outline-none items-center flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                            @click="addStepsToAdd(true)"
                                        >
                                            <fa-icon
                                                icon="xmark"
                                                class="mr-2 fa-sm"
                                            ></fa-icon>
                                            {{ $t('cancel') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    :disabled="
                                        creating ||
                                        badTimeRangeSteps ||
                                        delivBeforePickUp ||
                                        badTimeRange ||
                                        v$.stepsToAdd.$silentErrors.length
                                    "
                                    type="submit"
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon icon="spinner" spin-pulse></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </div> -->

                        <!-- <div class="mt-2 rounded-md p-4 border-2">
                            <div class="mb-5 flex">
                                <span class="text-lg">
                                    {{ $t('pricing') }}
                                    <hr />
                                </span>
                            </div>
                        </div> -->
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                        <div class="p-2 mt-4">
                            <div class="flex justify-center">
                                <button
                                    type="button"
                                    @click="back()"
                                    class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                >
                                    {{ $t('cancel') }}
                                </button>
                                <button
                                    v-if="
                                        !currentOrder ||
                                        $route.name === 'new_order' ||
                                        $route.name === 'update_recurrent' ||
                                        ($route.name === 'update_order' &&
                                            currentOrder &&
                                            !currentOrder.isInvoiced)
                                    "
                                    :disabled="
                                        creating ||
                                        badTimeRangeSteps ||
                                        delivBeforePickUp ||
                                        badTimeRange
                                    "
                                    type="submit"
                                    @mousedown="dontRefreshPrice = true"
                                    @click.prevent="
                                        $route.params.id
                                            ? updateOrder()
                                            : createOrder()
                                    "
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{
                                            $t(
                                                $route.name === 'new_order' ||
                                                    !currentOrder
                                                    ? 'save'
                                                    : 'update',
                                            )
                                        }}
                                    </span>
                                </button>
                                <button
                                    v-if="
                                        !currentOrder ||
                                        $route.name === 'new_order' ||
                                        $route.name === 'update_recurrent' ||
                                        ($route.name === 'update_order' &&
                                            currentOrder &&
                                            !currentOrder.isInvoiced)
                                    "
                                    :disabled="
                                        creating ||
                                        badTimeRangeSteps ||
                                        delivBeforePickUp ||
                                        badTimeRange
                                    "
                                    @mousedown="dontRefreshPrice = true"
                                    type="submit"
                                    @click.stop="
                                        $route.params.id
                                            ? updateOrder(true)
                                            : createOrder(true)
                                    "
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{
                                            $t(
                                                !currentOrder ||
                                                    $route.name === 'new_order'
                                                    ? 'save_and_copy_order'
                                                    : 'update_and_copy_order',
                                            )
                                        }}
                                    </span>
                                </button>
                                <button
                                    v-else-if="currentOrder"
                                    :disabled="
                                        creating ||
                                        badTimeRangeSteps ||
                                        delivBeforePickUp ||
                                        badTimeRange
                                    "
                                    type="submit"
                                    @mousedown="dontRefreshPrice = true"
                                    @click.prevent="updateOrder(true, true)"
                                    class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <span v-if="creating">
                                        <fa-icon
                                            icon="spinner"
                                            spin-pulse
                                        ></fa-icon
                                    ></span>
                                    <span v-else>
                                        {{ $t('copy_order') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <modal
                    v-if="show"
                    :headerText="'add_step'"
                    :buttonText="'save'"
                    :mxWidth="'w-2/3'"
                    :buttonAlign="'justify-center'"
                    id="addStepModal"
                    :spin="false"
                    :creating="
                        (new Date(newStep.stepDate) > new Date(maxDate) ||
                            new Date(newStep.stepDate) < new Date(minDate)) &&
                        recurrentOrder === false
                    "
                    @closeModal="closeModal()"
                    @sendFromModal="submitModal(true, stepToUpdate)"
                >
                    <template #body>
                        <div class="px-3 mt-5">
                            <div class="mb-3 mt-3items-center flex flex-row">
                                <Multiselect
                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                    v-model="deliveryAddressId"
                                    :options="addressOptions"
                                    :loading="addressOptions.length === 0"
                                    label="name"
                                    track-by="name"
                                    :searchable="true"
                                    :placeholder="$t('can_select_address')"
                                    :allow-empty="false"
                                    :can-clear="false"
                                    @select="setAddressInfo(deliveryAddressId)"
                                ></Multiselect>
                            </div>
                            <hr />
                        </div>
                        <div class="px-3">
                            <div class="mb-3 mt-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('address_name') + ' *' }}</label
                                >
                                <input
                                    v-model="newStep.AddressName"
                                    @blur="v$.newStep.AddressName.$touch"
                                    autocomplete="name"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.AddressName.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="flex justify-center mt-3 mb-3">
                                <div class="flex items-center mr-4">
                                    <input
                                        id="inline-radio"
                                        type="radio"
                                        v-model="newStep.Type"
                                        value="pickup"
                                        name="inline-radio-group"
                                        class="cursor-pointer darky"
                                    />
                                    <label
                                        for="inline-radio"
                                        class="ml-2 text-base font-medium"
                                        >{{ $t('pick_up_label') + ' *' }}</label
                                    >
                                </div>
                                <div class="flex items-center mr-4">
                                    <input
                                        id="inline-2-radio"
                                        type="radio"
                                        v-model="newStep.Type"
                                        class="cursor-pointer darky"
                                        value="delivery"
                                        name="inline-radio-group"
                                    />
                                    <label
                                        for="inline-2-radio"
                                        class="ml-2 text-base font-medium"
                                        >{{
                                            $t('delivery_label') + ' *'
                                        }}</label
                                    >
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.Type.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>

                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    for="autocompleteAddStep"
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('address') + ' 1 *' }}
                                </label>
                                <input
                                    v-model="newStep.Address1"
                                    id="autocompleteAddStep"
                                    @blur="v$.newStep.Address1.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.Address1.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>

                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    for="autocompleteAddStep_2"
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('address') }} 2</label
                                >
                                <input
                                    id="autocompleteAddStep_2"
                                    type="text"
                                    v-model="newStep.Address2"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    for="postal_code"
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('postal_code') + ' *' }}</label
                                >
                                <input
                                    id="postal_code"
                                    autocomplete="postal-code"
                                    v-model="newStep.PostalCode"
                                    @blur="v$.newStep.PostalCode.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.PostalCode.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    for="locality"
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('city') + ' *' }}</label
                                >
                                <input
                                    type="text"
                                    v-model="newStep.City"
                                    id="locality"
                                    @blur="v$.newStep.City.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.City.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div
                                v-if="recurrentOrder === false"
                                class="mb-3 items-center flex flex-row"
                            >
                                <label
                                    for="stpdate"
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('on') + ' *' }}</label
                                >
                                <input
                                    id="stpdate"
                                    type="date"
                                    :min="minDate"
                                    :max="maxDate"
                                    v-model="newStep.stepDate"
                                    @blur="v$.newStep.stepDate.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.newStep.stepDate.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    (new Date(newStep.stepDate) >
                                        new Date(maxDate) ||
                                        new Date(newStep.stepDate) <
                                            new Date(minDate)) &&
                                    recurrentOrder === false
                                "
                                class="text-xs italic mt-1 mb-3"
                            >
                                <div class="error-msg">
                                    {{
                                        $t(
                                            new Date(newStep.stepDate) >
                                                new Date(maxDate)
                                                ? 'date_error_after'
                                                : 'date_error_before',
                                            1,
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    newStep.Hours &&
                                    openingHoursForDay(
                                        newStep.Hours,
                                        findStepDay(newStep.stepDate),
                                    ).length > 0
                                "
                                class="mb-3"
                            >
                                <div class="mb-1 justify-center flex">
                                    {{
                                        `${$t('opening_hours')} ${$t(
                                            'for',
                                        )} ${$t(
                                            days[findStepDay(newStep.stepDate)],
                                        )} ${newStep.stepDate} `
                                    }}
                                </div>
                                <div
                                    v-for="(hour, index) in openingHoursForDay(
                                        newStep.Hours,
                                        findStepDay(newStep.stepDate),
                                    )"
                                    :key="index"
                                    class="justify-center flex"
                                >
                                    {{ `${hour.start} - ${hour.end}` }}
                                </div>
                            </div>
                            <div class="flex justify-center items-center mb-3">
                                <span class="mr-2 text-lg"> * </span>
                                <div id="moment-select">
                                    <Multiselect
                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                        v-model="newStep.Moment"
                                        :options="stepMomentOptions"
                                        :loading="
                                            stepMomentOptions.length === 0
                                        "
                                        label="name"
                                        track-by="name"
                                        :allow-empty="false"
                                        :can-clear="false"
                                    ></Multiselect>
                                </div>
                                <div
                                    class="ml-2"
                                    v-if="
                                        newStep.Moment === 'between' ||
                                        newStep.Moment === 'from'
                                    "
                                >
                                    <input
                                        type="time"
                                        v-model="newStep.FromDateTime"
                                        @blur="v$.newStep.FromDateTime.$touch"
                                        @input="resetBadTimes()"
                                        class="border text-sm rounded px-3 py-2 w-full"
                                    />
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.newStep.FromDateTime
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="ml-2"
                                    v-if="
                                        newStep.Moment === 'between' ||
                                        newStep.Moment === 'before'
                                    "
                                >
                                    <input
                                        type="time"
                                        v-model="newStep.BeforeDateTime"
                                        @blur="v$.newStep.BeforeDateTime.$touch"
                                        @input="resetBadTimes()"
                                        class="border text-sm rounded px-3 py-2 w-full"
                                    />
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.newStep
                                            .BeforeDateTime.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="badTimeRange"
                            >
                                <div class="error-msg">
                                    {{ $t('bad_time_range') }}
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="badTimeRangeSteps"
                            >
                                <div class="error-msg">
                                    {{
                                        `${$t(
                                            'bad_time_range_steps',
                                        )} : ${previousPickUpTimes(
                                            stepToUpdate,
                                        )}`
                                    }}
                                </div>
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="delivBeforePickUp"
                            >
                                <div class="error-msg">
                                    {{
                                        `${$t(
                                            'bad_time_range_steps_2',
                                        )} : ${previousPickUpTimes(
                                            stepToUpdate,
                                        )}
                                        `
                                    }}
                                </div>
                            </div>
                        </div>
                        <span
                            class="flex mt-1 italic text-xs items-center justify-center"
                        >
                            {{ $t('required_fields') }}
                        </span>
                    </template>
                </modal>

                <modal
                    v-if="showAddressUpdateExtraInfo && canUpdateRefAddress"
                    :headerText="'update_address'"
                    :buttonText="'save'"
                    :mxWidth="'w-2/3'"
                    :buttonAlign="'justify-center'"
                    id="updateAddressModal"
                    :spin="false"
                    @closeModal="openAddressUpdateExtraInfo()"
                    @sendFromModal="updateClientAddressExtraInfo()"
                >
                    <template #body>
                        <div class="px-3">
                            <div
                                class="mb-3 mt-3 flex custom-link justify-center italic"
                            >
                                {{ $t('ref_address_extra_info_update') }}
                            </div>
                            <div class="mb-3 mt-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('contact_firstname') }}</label
                                >
                                <input
                                    v-model="
                                        clientAddressExtraInfo.ContactFirstName
                                    "
                                    autocomplete="name"
                                    type="text"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div class="mb-3 mt-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('contact_lastname') }}</label
                                >
                                <input
                                    v-model="
                                        clientAddressExtraInfo.ContactLastName
                                    "
                                    autocomplete="name"
                                    type="text"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>

                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('phone') }}
                                </label>
                                <input
                                    v-model="
                                        clientAddressExtraInfo.ContactPhone
                                    "
                                    autocomplete="tel"
                                    type="tel"
                                    @blur="
                                        v$.clientAddressExtraInfo.ContactPhone
                                            .$touch;
                                    "
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.clientAddressExtraInfo
                                    .ContactPhone.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('email_address') }}
                                </label>
                                <input
                                    v-model="
                                        clientAddressExtraInfo.ContactEmail
                                    "
                                    type="email"
                                    autocomplete="email"
                                    @blur="
                                        v$.clientAddressExtraInfo.ContactEmail
                                            .$touch;
                                    "
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.clientAddressExtraInfo
                                    .ContactEmail.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('additional_info') }}
                                </label>
                                <textarea
                                    v-model="
                                        clientAddressExtraInfo.AdditionalInformation
                                    "
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div class="mb-3 items-center flex flex-row">
                                <label
                                    class="font-semibold basis-1/4 whitespace-nowrap text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('driver_info') }}
                                </label>
                                <textarea
                                    v-model="
                                        clientAddressExtraInfo.DriverInformation
                                    "
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                        </div>
                    </template>
                </modal>

                <modal
                    v-if="showDeleteConfirmModal"
                    :headerText="deleteModalHeader"
                    :buttonText="'confirm'"
                    id="deletemodal"
                    :mxWidth="'max-w-2xl'"
                    :buttonAlign="'justify-center'"
                    @closeModal="stepsDeleteConfirmation(false)"
                    @sendFromModal="stepsDeleteConfirmation(true)"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center mt-8">
                                {{ $t(deleteModalText) }}
                            </div>
                            <div
                                class="flex text-sm italic justify-center mt-4"
                            >
                                {{ $t(elementToDelete) }}
                            </div>
                        </div></template
                    ></modal
                >
                <modal
                    v-if="webClient"
                    :headerText="'informations'"
                    :buttonText="'got_it'"
                    id="clientTypeInfoModal"
                    :mxWidth="'max-w-2xl'"
                    :buttonAlign="'justify-center'"
                    @closeModal="webClient = false"
                    @sendFromModal="webClient = false"
                >
                    <template #body>
                        <div class="mx-8">
                            <div class="flex justify-center mt-8">
                                {{
                                    $t(
                                        'occasional_client_order_not_displayed_until_payment',
                                    )
                                }}
                            </div>
                            <!-- <div
                                class="flex text-sm italic justify-center mt-4"
                            >
                                {{ $t(elementToDelete) }}
                            </div> -->
                        </div></template
                    ></modal
                >
                <create-container-modal
                    v-if="containerCreation === true"
                    :idClient="idClientForContainer"
                    @containerCreated="containerCreated($event)"
                    @closeContainerModal="unsetContainerCreation"
                ></create-container-modal>
                <tour-opti-warning-modal
                    v-if="showConfirmTourOptiModal"
                    :canBeForced="canBeForced"
                    :confirmModalText="confirmModalText"
                    @tourOptiConfirmation="actionConfirmation($event)"
                ></tour-opti-warning-modal>
            </div>
            <!-- B2C -->
            <B2C
                v-else-if="isB2C !== null && isB2C === true"
                :reference="formData.Reference"
                class="darky"
                @toUpdate="$emit('goToUpdateOrder', $event)"
                @packageTracking="$emit('packageTracking', $event)"
            />
        </div>
    </main>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { email, helpers, required, requiredIf } from '@vuelidate/validators'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'
import clientMultiselectDebouncer from '@/mixins/clientMultiselectDebouncer'
import TourOptiWarningModal from '@/components/elements/TourOptiWarningModal.vue'
import DriverMultiselect from '@/components/elements/DriverMultiselect.vue'
import DropdownMenu from '@/components/atoms/DropdownMenu.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import Modal from '@/components/atoms/Modal.vue'
import BackTo from '@/components/elements/BackTo.vue'
import CreateContainerModal from '@/components/elements/CreateContainerModal.vue'
import PackageForm from '@/components/elements/PackageForm.vue'
import copyToClipboard from '@/mixins/copyToClipboard.js'
import googleScriptLoader from '@/mixins/googleScript'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import keys from '@/resources/keys'
import prefixes from '@/resources/prefixes'
import { useArticleStore } from '@/stores/articleStore'
import { useClientStore } from '@/stores/clientStore'
import { useDriverStore } from '@/stores/driverStore'
import { useHazardousProductStore } from '@/stores/hazardousProductStore'
import { useOrderStore } from '@/stores/orderStore'
import { useRecurrentOrderStore } from '@/stores/recurrentOrderStore'
import { useTourStore } from '@/stores/tourStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import { useVehicleStore } from '@/stores/vehicleStore'
import B2C from './B2CCreateOrUpdate.vue'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)

const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

const dateIsValid = helpers.regex(
    /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
)

const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
)

export default {
    name: 'CreateOrUpdateOrder',
    components: {
        IconButton,
        Multiselect,
        DriverMultiselect,
        Modal,
        BackTo,
        B2C,
        PackageForm,
        CreateContainerModal,
        DropdownMenu,
        TourOptiWarningModal,
    },
    mixins: [copyToClipboard, googleScriptLoader, clientMultiselectDebouncer],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]
        const prefix =
            prefixes[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]
        return { key, prefix, v$: useVuelidate() }
    },
    watch: {
        recurrentOrder() {
            this.setOrderGroupForm()
            if (this.canLiveRefreshPrice) {
                this.calculatePrice()
            }
        },
        roundTrip() {
            if (this.canLiveRefreshPrice) {
                this.calculatePrice()
            }
        },
        'formData.OrderClientType': function () {
            if (
                this.formData.OrderClientType !== 'occasional' &&
                this.formData.PaymentType === 'web'
            ) {
                this.formData.PaymentType = 'transfer'
            }
            if (
                this.formData.OrderClientType === 'month' &&
                this.formData.PaymentType === 'clientCredit'
            ) {
                this.formData.PaymentType = 'transfer'
            }
        },
        'formData.IdUserContact': function () {
            if (this.formData.IdUserContact && this.contactOptions.length > 0) {
                this.selectedContact = this.contactOptions.find(
                    (el) => +el.value === +this.formData.IdUserContact,
                )
            } else {
                this.selectedContact = null
            }
            this.refreshMS += 1
        },
        recurrenceStartDate() {
            if (this.recurrentOrder === true) {
                this.stepsToAdd.forEach((stp) => {
                    stp.stepDate = this.recurrenceStartDate
                })
                this.minDate = this.recurrenceStartDate
                this.loadAvailableDrivers(this.minDate)
                this.loadAvailableVehicles(this.minDate)
            }
        },
        recurrenceEndDate() {
            if (this.recurrentOrder === true) {
                this.maxDate = this.recurrenceEndDate
            }
        },
        $route(to, from) {
            if (to.name === 'update_recurrent') {
                this.recurrentOrder = true
            }

            let currentClientId = null
            let newForm = null
            const newPackages = []
            if (
                this.userStore.current.type === 'admin' ||
                this.userStore.current.type === 'superadmin'
            ) {
                const universeSearch = {
                    sortDirection: 'ASC',
                    sortField: 'Name',
                    perPage: 10000,
                    page: 1,
                    query: [],
                    filter: [],
                }
                this.searchUniverses(universeSearch).then((resUniverses) => {
                    this.universeOptions = []

                    resUniverses.data.Records.forEach((universe) => {
                        this.universeOptions.push({
                            name: universe.Name,
                            value: +universe.Id,
                            dbField: 'IdUniverse',
                            realName: universe.Name,
                        })
                    })
                    this.refreshMS += 1
                })
            } else if (this.isB2C === false) {
                this.universeOptions = []

                this.userStore.userUniverses.forEach((universe) => {
                    this.universeOptions.push({
                        name: universe.name,
                        value: +universe.value,
                        dbField: 'IdUniverse',
                        realName: universe.name,
                    })
                })

                this.refreshMS += 1
            }

            if (
                from.name === 'update_order' &&
                to.name === 'new_order' &&
                this.orderToDuplicate !== null &&
                this.isB2C === false
            ) {
                this.extraSales = []
                this.universeFilterForClientSearch = {
                    dbField: 'IdUniverse',
                    value: this.universeOptions,
                }

                this.fetchContactsAddressesAndContainers(
                    this.orderToDuplicate.client_id,
                    true,
                    false,
                )
                this.newDeliveryName = `${this.$t(
                    'delivery_title',
                )} ${new Date().toISOString().substr(0, 10)}`

                this.isDuplicate = true
                this.isEditPackage = false

                this.orderToDuplicate.order_steps.forEach((step) => {
                    if (step.Moment === 'between') {
                        step.stepDate =
                            step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' '),
                            ) > this.minDate
                                ? step.FromDateTime.substring(
                                      0,
                                      step.FromDateTime.indexOf(' '),
                                  )
                                : this.minDate
                        step.FromDateTime = step.FromDateTime.substring(
                            step.FromDateTime.indexOf(' ') + 1,
                        )
                        step.FromDateTime = `${step.FromDateTime.substring(
                            0,
                            step.FromDateTime.length - 3,
                        )}`
                        step.BeforeDateTime = step.BeforeDateTime.substring(
                            step.BeforeDateTime.indexOf(' ') + 1,
                        )
                        step.BeforeDateTime = `${step.BeforeDateTime.substring(
                            0,
                            step.BeforeDateTime.length - 3,
                        )}`
                    } else if (step.Moment === 'from') {
                        step.stepDate =
                            step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' '),
                            ) > this.minDate
                                ? step.FromDateTime.substring(
                                      0,
                                      step.FromDateTime.indexOf(' '),
                                  )
                                : this.minDate
                        step.FromDateTime = step.FromDateTime.substring(
                            step.FromDateTime.indexOf(' ') + 1,
                        )
                        step.FromDateTime = `${step.FromDateTime.substring(
                            0,
                            step.FromDateTime.length - 3,
                        )}`
                        step.BeforeDateTime = ''
                    } else if (step.Moment === 'before') {
                        step.stepDate =
                            step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.indexOf(' '),
                            ) > this.minDate
                                ? step.BeforeDateTime.substring(
                                      0,
                                      step.BeforeDateTime.indexOf(' '),
                                  )
                                : this.minDate

                        step.BeforeDateTime = step.BeforeDateTime.substring(
                            step.BeforeDateTime.indexOf(' ') + 1,
                        )
                        step.BeforeDateTime = `${step.BeforeDateTime.substring(
                            0,
                            step.BeforeDateTime.length - 3,
                        )}`
                        step.FromDateTime = ''
                    }
                    step.Status = 'new'
                    step.ExecutedDateTime = null
                    step.DeliveryCode = null
                    step.Id = ''
                    step.CreationUserId = this.user.id
                    step.ModificationUserId = this.user.id
                    step.ModificationDate = ''
                    step.CreationDate = ''
                    step.IdOrder = null
                    step.IdInvoice = null
                    step.IdClientAddress = +step.IdClientAddress
                    step.CommentStep = ''

                    // delete (step.clientAddresses)

                    step.IdVehicle = +step.IdVehicle
                    step.IdDriver = +step.IdDriver

                    this.stepsToAdd.push(structuredClone(step))
                })
                this.minDate = this.stepsToAdd[0].stepDate
                this.maxDate =
                    this.stepsToAdd[this.stepsToAdd.length - 1].stepDate

                this.orderToDuplicate.order_packages.forEach((pack) => {
                    const newPack = {
                        PackageNumber: pack.PackageNumber,
                        Width: pack.Width,
                        Length: pack.Length,
                        Height: pack.Height,
                        Weight: pack.Weight,
                        SameOpIndexes: pack.SameOpIndexes,
                        Value: +pack.Value,
                        Quantity: +pack.Quantity,
                        TemperatureOption: pack.TemperatureOption,
                        IdContainer: pack.IdContainer,
                        Id: '',
                        CreationUserId: this.user.id,
                        ModificatioUserId: this.user.id,
                        packageTypeErrors: [],
                        packageValueErrors: [],
                        packageWeightErrors: [],
                        packageReferenceErrors: [],
                        packageWidthErrors: [],
                        packageLengthErrors: [],
                        packageHeightErrors: [],
                        packageQuantityErrors: [],

                        containerNameError: false,
                    }
                    newPackages.push(structuredClone(newPack))
                })
                newForm = structuredClone(this.orderToDuplicate)
                this.orderToDuplicate = null

                currentClientId = newForm.client_id
                this.loadAvailableDrivers(false)
                this.loadAvailableVehicles(this.stepsToAdd[0].stepDate)
                this.refreshMS += 1
                this.refreshDriverVehicleSelect += 1
                this.currentOr = null
            } else if (
                (from.name === 'update_recurrent' ||
                    from.name === 'recurrent_orders') &&
                to.name === 'new_order' &&
                this.recurrentToDuplicate !== null &&
                this.isB2C === false
            ) {
                this.extraSales = []
                this.universeFilterForClientSearch = {
                    dbField: 'IdUniverse',
                    value: this.universeOptions,
                }

                this.fetchContactsAddressesAndContainers(
                    this.recurrentToDuplicate.client_id,
                    true,
                    false,
                )
                this.recurrentOrder = true

                this.isDuplicate = true
                this.isEditPackage = false
                this.recurrentToDuplicate.order_steps.forEach((step) => {
                    if (step.Moment === 'between') {
                        step.stepDate =
                            step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' '),
                            ) > this.minDate
                                ? step.FromDateTime.substring(
                                      0,
                                      step.FromDateTime.indexOf(' '),
                                  )
                                : this.minDate
                        step.FromDateTime = step.FromDateTime.substring(
                            step.FromDateTime.indexOf(' ') + 1,
                        )
                        step.FromDateTime = `${step.FromDateTime.substring(
                            0,
                            step.FromDateTime.length - 3,
                        )}`
                        step.BeforeDateTime = step.BeforeDateTime.substring(
                            step.BeforeDateTime.indexOf(' ') + 1,
                        )
                        step.BeforeDateTime = `${step.BeforeDateTime.substring(
                            0,
                            step.BeforeDateTime.length - 3,
                        )}`
                    } else if (step.Moment === 'from') {
                        step.stepDate =
                            step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' '),
                            ) > this.minDate
                                ? step.FromDateTime.substring(
                                      0,
                                      step.FromDateTime.indexOf(' '),
                                  )
                                : this.minDate
                        step.FromDateTime = step.FromDateTime.substring(
                            step.FromDateTime.indexOf(' ') + 1,
                        )
                        step.FromDateTime = `${step.FromDateTime.substring(
                            0,
                            step.FromDateTime.length - 3,
                        )}`
                        step.BeforeDateTime = ''
                    } else if (step.Moment === 'before') {
                        step.stepDate =
                            step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.indexOf(' '),
                            ) > this.minDate
                                ? step.BeforeDateTime.substring(
                                      0,
                                      step.BeforeDateTime.indexOf(' '),
                                  )
                                : this.minDate

                        step.BeforeDateTime = step.BeforeDateTime.substring(
                            step.BeforeDateTime.indexOf(' ') + 1,
                        )
                        step.BeforeDateTime = `${step.BeforeDateTime.substring(
                            0,
                            step.BeforeDateTime.length - 3,
                        )}`
                        step.FromDateTime = ''
                    }
                    step.Status = 'new'
                    step.ExecutedDateTime = null
                    step.DeliveryCode = null
                    step.Id = ''
                    step.CreationUserId = this.user.id
                    step.ModificationUserId = this.user.id
                    step.ModificationDate = ''
                    step.CreationDate = ''
                    step.IdRecurrent = null
                    step.IdInvoice = null
                    step.IdClientAddress = +step.IdClientAddress
                    step.CommentStep = ''
                    // delete (step.clientAddresses)

                    step.IdVehicle = +step.IdVehicle
                    step.IdDriver = +step.IdDriver

                    this.stepsToAdd.push(structuredClone(step))
                })
                // this.minDate = this.stepsToAdd[0].stepDate
                // this.minMax =
                //     this.stepsToAdd[this.stepsToAdd.length - 1].stepDate

                this.recurrentToDuplicate.order_packages.forEach((pack) => {
                    const newPack = {
                        PackageNumber: pack.PackageNumber,
                        Width: pack.Width,
                        Length: pack.Length,
                        Height: pack.Height,
                        Weight: pack.Weight,
                        SameOpIndexes: pack.SameOpIndexes,
                        Value: +pack.Value,
                        Quantity: +pack.Quantity,
                        TemperatureOption: pack.TemperatureOption,
                        IdContainer: pack.IdContainer,
                        Id: '',
                        CreationUserId: this.user.id,
                        ModificatioUserId: this.user.id,
                        packageTypeErrors: [],
                        packageValueErrors: [],
                        packageWeightErrors: [],
                        packageReferenceErrors: [],
                        packageWidthErrors: [],
                        packageLengthErrors: [],
                        packageHeightErrors: [],
                        packageQuantityErrors: [],

                        containerNameError: false,
                    }

                    newPackages.push(structuredClone(newPack))
                })
                newForm = structuredClone(this.recurrentToDuplicate)
                // this.orderToDuplicate = null

                this.repetitionFrequency = newForm.RepetitionFrequency

                let startDate = newForm.begin_date
                if (newForm.begin_date < this.minDate) {
                    startDate = this.minDate
                }
                let endDate = newForm.end_date
                if (
                    newForm.end_date !== '0000-00-00' &&
                    newForm.end_date < startDate
                ) {
                    endDate = startDate
                }
                let choice = 'never'
                if (newForm.end_date && newForm.end_date !== '0000-00-00') {
                    choice = 'on'
                }
                if (newForm.OccurrenceNb > 0) {
                    choice = 'after'
                }

                this.recurrentForm = {
                    id: newForm.id,
                    // recurrentEndChoice: newForm,
                    recurrentName: newForm.Name,
                    endsAfterNumber: newForm.OccurrenceNb,
                    occurrenceFrequency: {
                        value: newForm.OccurrenceFrequency,
                        name:
                            newForm.OccurrenceFrequency === 'daily'
                                ? this.$t(
                                      'day',
                                      this.repetitionFrequency === 1
                                          ? this.repetitionFrequency
                                          : 2,
                                  )
                                : this.$t(
                                      newForm.OccurrenceFrequency.substring(
                                          0,
                                          newForm.OccurrenceFrequency.length -
                                              2,
                                      ),
                                      this.repetitionFrequency === 1
                                          ? this.repetitionFrequency
                                          : 2,
                                  ),
                    },
                    price: newForm.price,
                    forced_price: newForm.forced_price,
                    recurrentEndChoice: choice,
                    recurrenceEndDate: endDate,
                    //  new Date(Date.now() + 3600 * 1000 * 24)
                    //     .toISOString()
                    //     .substr(0, 10),
                    recurrenceStartDate: startDate,

                    pickedDaysForRecurrence: newForm.Repetition.split(','),
                }

                currentClientId = newForm.client_id
                this.recurrentToDuplicate = null
                this.loadAvailableDrivers(
                    this.stepsToAdd[0].stepDate,

                    false,
                )
                this.loadAvailableVehicles(this.stepsToAdd[0].stepDate)
                this.refreshDriverVehicleSelect += 1
                this.currentOr = null
            } else if (
                !this.$route.params.id &&
                this.isB2C === false &&
                this.isDuplicate === false
            ) {
                if (from.name === 'recurrent_orders') {
                    this.recurrentOrder = true
                }
                // this.packageHeight = 0
                // this.packageQuantity = 1
                // this.containerName = ''
                // this.selectedTemp = {
                //     name: 'no_temp_specified',
                //     value: 'none',
                //     icon: 'temperature-half',
                //     noTemp: true,
                // }

                // this.pickedContainerId = ''
                this.extraSales = []
                this.isEditPackage = false
                this.clientStore.$patch({
                    containers: null,
                    currentContacts: null,
                    current: null,
                    currentClientContactIds: null,
                    currentContainer: null,
                })
                this.deliveryAddressId = null
                this.currentOrderIntervall = null
                this.contactOptions = []
                this.containerOptions = []

                this.noContactForClient = false
                this.driverOptions = []
                this.vehicleOptions = []
                this.universeOptions = []
                if (!this.$route.params.id) {
                    this.currentOr = null
                    if (
                        this.userStore.current.type === 'admin' ||
                        this.userStore.current.type === 'superadmin'
                    ) {
                        const universeSearch = {
                            sortDirection: 'ASC',
                            sortField: 'Name',
                            perPage: 10000,
                            page: 1,
                            query: [],
                            filter: [],
                        }
                        this.searchUniverses(universeSearch).then((resUniv) => {
                            this.universeOptions = []

                            resUniv.data.Records.forEach((universe) => {
                                this.universeOptions.push({
                                    name: universe.Name,
                                    value: +universe.Id,
                                    dbField: 'IdUniverse',
                                    realName: universe.Name,
                                })
                            })
                            // this.refreshMS += 1
                        })
                    } else {
                        this.userStore.userUniverses.forEach((universe) => {
                            this.universeOptions = []

                            this.universeOptions.push({
                                name: universe.name,
                                value: +universe.value,
                                dbField: 'IdUniverse',
                                realName: universe.name,
                            })
                        })

                        // this.refreshMS += 1
                    }
                    this.universeFilterForClientSearch = {
                        value: this.universeOptions,
                        dbField: 'IdUniverse',
                    }
                }

                this.date = new Date()
                this.formData = {
                    WithVAT: true,
                    Name: '',
                    IdClient: '',
                    IdUserContact: '',
                    IdDriver: '',
                    IdInvoice: '',
                    Status: 'new',
                    Method: 'normal',
                    VehicleType: '',
                    OrderClientType: '',
                    PaymentType: '',
                    IdUniverse: 0,
                    Price: 0,
                    ForcedPrice: 0,
                    Latitude: '',
                    Longitude: '',
                    Sales: null,
                    CommentInternal: '',
                    CommentClient: '',
                    OrderStepsHTML: null,
                    CreationUserId: this.user.id,
                    OrderSteps: [],
                    OrderPackages: [],
                    DateBegin: '',
                    DateEnd: '',
                    OrderOptions: null,
                    // InvoiceNumber: `${
                    //     this.prefix.invoice
                    // }${this.date.getFullYear()}${this.date.getMonth()}${this.date.getDate()}${this.date.getHours()}${this.date.getMinutes()}${this.date.getSeconds()}`,
                    OrderOption: [],
                    ModificationUserId: '',
                    PacketNumber: 0,
                }
                this.stepsToAdd = [
                    {
                        Type: 'pickup',
                        Status: 'new',
                        StepNumber: 1,
                        IdClientAddress: 0,
                        AddressName: '',
                        Address1: '',
                        Address2: '',
                        Address3: '',
                        Address4: '',
                        PostalCode: '',
                        CommentStep: '',
                        City: '',
                        Country: 'FRA',
                        Latitude: '',
                        Longitude: '',
                        Moment: 'between',
                        FromDateTime: '08:00',
                        BeforeDateTime: '18:00',
                        TypeVehicle: '',
                        Hours: null,
                        IdVehicle: '',
                        IdDriver: '',
                        stepDate: this.minDate,
                        IsSubContractor: false,
                    },
                    {
                        Type: 'delivery',
                        Status: 'new',
                        StepNumber: 2,
                        IdClientAddress: 0,
                        AddressName: '',
                        CommentStep: '',

                        Address1: '',
                        Address2: '',
                        Address3: '',
                        Address4: '',
                        PostalCode: '',
                        City: '',
                        Country: 'FRA',
                        Latitude: '',
                        Longitude: '',
                        Moment: 'between',
                        FromDateTime: '08:00',
                        BeforeDateTime: '18:00',
                        TypeVehicle: '',
                        Hours: null,
                        IdVehicle: '',
                        IdDriver: '',
                        stepDate: this.minDate,
                        IsSubContractor: false,
                    },
                ]
                this.repetitionFrequency = 1
                this.recurrentForm = {
                    recurrentEndChoice: 'never',
                    recurrentName: '',
                    endsAfterNumber: 1,
                    occurrenceFrequency: {
                        value: 'daily',
                        name: this.$t(
                            'day',
                            this.repetitionFrequency === 1
                                ? this.repetitionFrequency
                                : 2,
                        ),
                    },
                    recurrenceEndDate: '',
                    //  new Date(Date.now() + 3600 * 1000 * 24)
                    //     .toISOString()
                    //     .substr(0, 10),
                    recurrenceStartDate: new Date().toISOString().substr(0, 10),

                    pickedDaysForRecurrence: [],
                }

                this.loadAvailableDrivers(
                    this.stepsToAdd[0].stepDate,

                    false,
                )
                this.loadAvailableVehicles(this.stepsToAdd[0].stepDate, false)
                this.refreshDriverVehicleSelect += 1
            }

            if (
                (from.name === 'update_order' ||
                    from.name === 'update_recurrent' ||
                    from.name === 'recurrent_orders') &&
                to.name === 'new_order' &&
                newForm &&
                this.isB2C === false
            ) {
                this.roundTrip = newForm.roundTrip

                // this.refreshMS += 1
                //     }
                // }, 200)
                this.onuOptions = []
                this.onus = []

                this.searchOnus({
                    query: [],
                    filter: [],
                    perPage: 10000,
                    page: 1,
                    sortField: 'Code',
                    sortDirection: 'ASC',
                }).then((res) => {
                    if (res.status === 204) {
                        this.onuOptions = []
                    } else {
                        res.data.Records.forEach((onu) => {
                            this.onuOptions.push({
                                name: `${onu.Code} • GP ${onu.GroupONU} • ${onu.Description}`,
                                value: +onu.Id,
                            })
                        })
                    }

                    if (newForm.onus.length > 0) {
                        this.onus = structuredClone(newForm.onus)
                        this.onus.forEach((onu, index) => {
                            if (onu.Id && onu.Id > 0) {
                                const evt = {
                                    value: +onu.Id,
                                }

                                this.setONU(evt, index, onu.NetWeight)
                            }
                        })
                        this.refreshMS += 1
                        this.refreshOnu += 1
                    }
                })
                this.extraSales =
                    newForm.sales.length > 0 ? newForm.sales[0].SaleDetails : []

                this.articleOptions = []
                this.searchArticles({
                    query: [],
                    filter: [],
                    perPage: 10000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: 'ASC',
                }).then((res) => {
                    if (res.status === 204) {
                        this.articleOptions = []
                    } else {
                        res.data.Records.forEach((article) => {
                            this.articleOptions.push({
                                name: article.Name,
                                value: +article.Id,
                            })
                        })
                    }

                    this.extraSales = []
                    this.tempArticleId = []
                    if (newForm.sales.length > 0) {
                        this.extraSales = structuredClone(
                            newForm.sales[0].SaleDetails,
                        )
                        this.extraSales.forEach((sale, index) => {
                            if (sale.IdArticle && sale.IdArticle > 0) {
                                sale.articleIcon = 'basket-shopping'

                                const evt = {
                                    value: +sale.IdArticle,
                                }
                                this.tempArticleId.push({
                                    value: +sale.IdArticle,
                                })
                                this.setArticle(evt, index)
                            } else {
                                sale.articleIcon = 'i-cursor'
                                this.tempArticleId.push({
                                    value: null,
                                })
                            }
                        })
                        this.refreshMS += 1
                        this.refreshSales += 1
                    }
                })
                this.formData = {
                    WithVAT: newForm.with_vat === 1,
                    CommentClient: newForm.comment_client,
                    CommentInternal: newForm.comment_internal,
                    Name: '',
                    CreationUserId: this.user.id,
                    DateBegin: newForm.begin_date,
                    DateEnd: newForm.end_date,
                    DateReception: null,
                    Duration: newForm.duration,
                    Id: '',
                    IdClient: currentClientId,
                    IdDriver: newForm.driver_id,
                    Price: newForm.price,
                    ForcedPrice: newForm.forced_price,
                    IdVehicle: newForm.vehicle_id,
                    VehicleType: newForm.vehicle_type,
                    IdExternal: newForm.id_external,
                    IdInvoice: '',
                    IdUserContact: +newForm.user_contact_id,
                    Method: newForm.method,
                    ModificationUserId: this.user.id,
                    OrderPackages: newPackages,
                    OrderSteps: [],
                    OrderOptions: newForm.order_options ?? null,
                    OrderOption: newForm.order_option ?? [],
                    Sales: newForm.sales.length > 0 ? newForm.sales[0] : null,
                    PacketNumber: newForm.packet_number,
                    // PacketValue: newForm.packet_value,
                    // Reference: '',
                    Status: 'new',
                    OrderClientType: newForm.type,
                    PaymentType: newForm.payment_type,
                    Weight: newForm.weight,
                    IdPricing: newForm.id_pricing,
                }

                if (newForm.forced_price) {
                    this.priceToApply = newForm.forced_price
                    this.calculatedPrice = newForm.price
                } else {
                    this.priceToApply = newForm.price
                    this.calculatedPrice = newForm.price
                }
                // this.extraSales =
                //     newForm.sales.length > 0 ? newForm.sales[0].SaleDetails : []
                if (newForm.orderGroup !== null) {
                    this.selectedGroup = {
                        value: +newForm.orderGroup.Id,
                        name: newForm.orderGroup.Name,
                    }
                    if (newForm.orderGroup.IdPricing) {
                        this.selectedTourPricing = this.tourPricingOptions.find(
                            (option) =>
                                +option.value === +newForm.orderGroup.IdPricing,
                        )
                        // {
                        //     value: +newForm.orderGroup.IdPricing,
                        //     name: newForm.orderGroup.Pricing.Name,
                        // }
                    } else {
                        this.selectedTourPricing = null
                    }
                }
                this.selectedMethod = this.methodOptions.find(
                    (option) => option.value === newForm.method,
                )
                if (newForm.id_pricing) {
                    this.selectedOrderPricing = this.orderPricingOptions.find(
                        (option) => +option.value === +newForm.id_pricing,
                    )
                }
                if (
                    newForm.idUniverse !== null &&
                    newForm.idUniverse !== 0 &&
                    newForm.idUniverse !== '0'
                ) {
                    this.formData.IdUniverse = +newForm.idUniverse
                    // this.refreshMS += 1
                } else {
                    this.formData.IdUniverse = +this.universeOptions.find(
                        (universe) => universe.realName === 'Courses',
                    ).value
                    // this.refreshMS += 1
                }

                this.selectedVehicleType = this.vehicleTypeOptions.find(
                    (option) => option.value === newForm.vehicle_type,
                )
                this.refreshMS += 1
                this.refreshPacks += 1
                // this.extraSales =
                //     newForm.sales.length > 0 ? newForm.sales[0].SaleDetails : []
            }

            // this.refresh += 1
            this.refreshMS += 1
            // this.refreshDriverVehicleSelect += 1
        },

        selectedMethod() {
            this.formData.Method = this.selectedMethod?.value

            this.setOrderGroupForm()
            if (this.formData.IdClient) {
                this.setPricings(this.formData.IdClient)
            }

            this.liveRefresh()
        },
        selectedTourPricing() {
            this.formData.TourPricingId = this.selectedTourPricing?.value
        },
        selectedOrderPricing() {
            this.formData.IdPricing = this.selectedOrderPricing?.value
            this.liveRefresh()
        },
        selectedVehicleType() {
            this.formData.VehicleType = this.selectedVehicleType?.value ?? ''
            this.liveRefresh()
        },

        deliveryAddressId() {
            if (this.deliveryAddressId !== null) {
                this.setAddressInfo(this.deliveryAddressId)

                this.refreshMS += 1
            }
        },
        currentOrder() {
            const stepsWithDate = []
            if (
                this.currentOrder &&
                this.isB2C === false &&
                this.$route.params.id &&
                this.isDuplicate === false
            ) {
                // this.fetchContactsAddressesAndContainers(
                //     this.currentOrder.client_id
                // )
                this.setPricings(this.currentOrder.client_id)

                this.universeFilterForClientSearch = {
                    value: this.currentOrder.client_id,
                    dbField: 'Id',
                }
                if (this.currentOrder.order_steps.length > 0) {
                    this.currentOrder.order_steps.forEach((step) => {
                        step.IdVehicle = +step.IdVehicle
                        step.IdDriver = +step.IdDriver

                        if (step.Moment === 'between') {
                            step.stepDate = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' '),
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                step.FromDateTime.indexOf(' ') + 1,
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.length - 3,
                            )
                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                step.BeforeDateTime.indexOf(' ') + 1,
                            )
                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.length - 3,
                            )
                        } else if (step.Moment === 'from') {
                            step.stepDate = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.indexOf(' '),
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                step.FromDateTime.indexOf(' ') + 1,
                            )
                            step.FromDateTime = step.FromDateTime.substring(
                                0,
                                step.FromDateTime.length - 3,
                            )
                            step.BeforeDateTime = ''
                        } else if (step.Moment === 'before') {
                            step.stepDate = step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.indexOf(' '),
                            )

                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                step.BeforeDateTime.indexOf(' ') + 1,
                            )
                            step.BeforeDateTime = step.BeforeDateTime.substring(
                                0,
                                step.BeforeDateTime.length - 3,
                            )
                            step.FromDateTime = ''
                        }
                        stepsWithDate.push(structuredClone(step))
                    })
                }

                this.formData = {
                    WithVAT: +this.currentOrder.with_vat === 1,
                    CommentClient: this.currentOrder.comment_client,
                    CommentInternal: this.currentOrder.comment_internal,
                    CreationUserId: this.currentOrder.creation_user_id,
                    DateBegin: this.currentOrder.begin_date,
                    DateEnd: this.currentOrder.end_date,
                    DateReception: this.currentOrder.reception_date,
                    Duration: this.currentOrder.duration,
                    Id: this.currentOrder.id,
                    IdClient: this.currentOrder.client_id,
                    IdDriver: this.currentOrder.driver_id,
                    IdVehicle: this.currentOrder.vehicle_id,
                    VehicleType: this.currentOrder.vehicle_type,
                    IdExternal: this.currentOrder.id_external,
                    IdInvoice: this.currentOrder.invoice_id,
                    IdUserContact: +this.currentOrder.user_contact_id,
                    Method: this.currentOrder.method,
                    ModificationUserId: this.currentOrder.modification_user_id,
                    OrderPackages: this.currentOrder.order_packages,
                    OrderSteps: stepsWithDate,
                    OrderOptions: this.currentOrder.order_options,
                    Sales:
                        this.currentOrder.sales.length > 0
                            ? this.currentOrder.sales[0]
                            : null,
                    // PacketNumber: this.currentOrder.packet_number,
                    PacketValue: this.currentOrder.packet_value,
                    Reference: this.currentOrder.reference,
                    Status: this.currentOrder.status,
                    OrderClientType: this.currentOrder.type,
                    PaymentType: this.currentOrder.payment_type,
                    IdUniverse: this.currentOrder.idUniverse,
                    Weight: this.currentOrder.weight,
                    Price: this.currentOrder.price,
                    ForcedPrice: this.currentOrder.forced_price,
                    IdOrderGroup: this.currentOrder?.orderGroup?.Id,
                    GroupName: '',
                    IdPricing: this.currentOrder.id_pricing,
                }
                if (this.currentOrder.forced_price) {
                    this.priceToApply = this.currentOrder.forced_price
                    this.calculatedPrice = this.currentOrder.price
                } else if (this.priceToApply === 0) {
                    this.priceToApply = this.currentOrder.price
                    this.calculatedPrice = this.currentOrder.price
                }

                this.articleOptions = []
                this.onus = []

                this.searchOnus({
                    query: [],
                    filter: [],
                    perPage: 10000,
                    page: 1,
                    sortField: 'Code',
                    sortDirection: 'ASC',
                }).then((res) => {
                    if (res.status === 204) {
                        this.onuOptions = []
                    } else {
                        res.data.Records.forEach((onu) => {
                            this.onuOptions.push({
                                name: `${onu.Code} • GP ${onu.GroupONU} • ${onu.Description}`,
                                value: +onu.Id,
                            })
                        })
                    }

                    if (
                        this.currentOrder &&
                        this.currentOrder.onus &&
                        this.currentOrder.onus.length > 0
                    ) {
                        this.onus = structuredClone(this.currentOrder.onus)
                        this.onus.forEach((onu, index) => {
                            if (onu.Id && +onu.Id > 0) {
                                const evt = {
                                    value: +onu.Id,
                                }
                                // this.onus.push({
                                //     value: +onu.Id,
                                // })
                                this.setONU(evt, index, onu.NetWeight)
                            }
                        })
                        this.refreshMS += 1
                        this.refreshOnu += 1
                    }
                })
                this.articleOptions = []
                this.extraSales =
                    this.currentOrder.sales.length > 0
                        ? this.currentOrder.sales[0].SaleDetails
                        : []
                this.searchArticles({
                    query: [],
                    filter: [],
                    perPage: 10000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: 'ASC',
                }).then((res) => {
                    if (res.status === 204) {
                        this.articleOptions = []
                    } else {
                        res.data.Records.forEach((article) => {
                            this.articleOptions.push({
                                name: article.Name,
                                value: +article.Id,
                            })
                        })
                    }

                    this.extraSales = []
                    this.tempArticleId = []
                    if (this.currentOrder.sales.length > 0) {
                        this.extraSales = structuredClone(
                            this.currentOrder.sales[0].SaleDetails,
                        )
                        this.extraSales.forEach((sale, index) => {
                            if (sale.IdArticle && sale.IdArticle > 0) {
                                sale.articleIcon = 'basket-shopping'

                                const evt = {
                                    value: +sale.IdArticle,
                                }
                                this.tempArticleId.push({
                                    value: +sale.IdArticle,
                                })
                                this.setArticle(evt, index)
                            } else {
                                sale.articleIcon = 'i-cursor'
                                this.tempArticleId.push({
                                    value: null,
                                })
                            }
                        })
                        this.refreshMS += 1
                        this.refreshSales += 1
                    }
                })

                if (this.currentOrder.orderGroup !== null) {
                    this.selectedGroup = {
                        value: +this.currentOrder.orderGroup.Id,
                        name: this.currentOrder.orderGroup.Name,
                    }
                    if (this.currentOrder.orderGroup.IdPricing) {
                        this.selectedTourPricing = {
                            value: +this.currentOrder.orderGroup.IdPricing,
                            name: this.currentOrder.orderGroup.Pricing.Name,
                        }
                    } else {
                        this.selectedTourPricing = null
                    }
                }
                this.fetchContactsAddressesAndContainers(
                    this.currentOrder.client_id,
                    this.isDuplicate,
                    false,
                )
                // console.log(this.currentOrder.id_pricing)
                // console.log(this.orderPricingOptions)
                // console.log(
                //     this.orderPricingOptions.find(
                //         (option) =>
                //             +option.value === +this.currentOrder.id_pricing
                //     )
                // )

                this.refreshMS += 1

                if (
                    this.currentOrder.idUniverse !== null &&
                    this.currentOrder.idUniverse !== 0 &&
                    this.currentOrder.idUniverse !== '0'
                ) {
                    this.formData.IdUniverse = +this.currentOrder.idUniverse
                    this.refreshMS += 1
                } else {
                    this.formData.IdUniverse = +this.universeOptions.find(
                        (universe) => universe.realName === 'Courses',
                    ).value
                    this.refreshMS += 1
                }

                this.isEditPackage = true
                this.selectedMethod = this.methodOptions.find(
                    (option) => option.value === this.currentOrder.method,
                )
                // vehicule tupe pas repris ?? sur les rec , vérifier sur les orders
                if (this.currentOrder.vehicle_type) {
                    this.selectedVehicleType = this.vehicleTypeOptions.find(
                        (option) =>
                            option.value === this.currentOrder.vehicle_type,
                    )
                    this.refreshMS += 1
                }
                this.roundTrip = this.currentOrder.roundTrip

                if (this.$route.name === 'update_recurrent') {
                    // this.packageQuantity =
                    //     this.currentOrder.order_packages[0].Quantity
                    this.repetitionFrequency =
                        this.currentOrder.RepetitionFrequency
                    this.recurrentForm = {
                        id: this.currentOrder.id,
                        // recurrentEndChoice: this.currentOrder,
                        recurrentName: this.currentOrder.Name,
                        endsAfterNumber: this.currentOrder.OccurrenceNb,
                        occurrenceFrequency: {
                            value: this.currentOrder.OccurrenceFrequency,
                            name:
                                this.currentOrder.OccurrenceFrequency ===
                                'daily'
                                    ? this.$t(
                                          'day',
                                          this.repetitionFrequency === 1
                                              ? this.repetitionFrequency
                                              : 2,
                                      )
                                    : this.$t(
                                          this.currentOrder.OccurrenceFrequency.substring(
                                              0,
                                              this.currentOrder
                                                  .OccurrenceFrequency.length -
                                                  2,
                                          ),
                                          this.repetitionFrequency === 1
                                              ? this.repetitionFrequency
                                              : 2,
                                      ),
                        },
                        price: this.currentOrder.price,
                        forced_price: this.currentOrder.forced_price,
                        recurrentEndChoice:
                            this.currentOrder.end_date &&
                            this.currentOrder.end_date !== '0000-00-00'
                                ? 'on'
                                : this.currentOrder.OccurrenceNb > 0
                                  ? 'after'
                                  : 'never',
                        recurrenceEndDate: this.currentOrder.end_date,
                        //  new Date(Date.now() + 3600 * 1000 * 24)
                        //     .toISOString()
                        //     .substr(0, 10),
                        recurrenceStartDate: this.currentOrder.begin_date,

                        pickedDaysForRecurrence:
                            this.currentOrder.Repetition.split(','),
                    }
                    this.refreshMS += 1
                }

                if (this.currentOrder.id_pricing) {
                    this.selectedOrderPricing = this.orderPricingOptions.find(
                        (option) =>
                            +option.value === +this.currentOrder.id_pricing,
                    )
                    this.refreshMS += 1
                }
                this.refreshMS += 1
                this.refreshDriverVehicleSelect += 1
            }
        },
        orderPricingOptions() {
            if (this.currentOrder && this.currentOrder.id_pricing) {
                this.selectedOrderPricing = this.orderPricingOptions.find(
                    (option) => +option.value === +this.currentOrder.id_pricing,
                )
                this.refreshMS += 1
            }
        },
    },

    // TODO au changement de route faire en sorte  de refresh le contenu entre new et update
    created() {
        window.scrollTo(0, 0)
        this.universeOptions = []
        this.articleOptions = []
        // this.fetchVehicleTypes().then((res) => {
        //     this.vehicleTypeOptions = []
        //     res.data.forEach((type) => {
        //         const name = `${type.Title} ${this.$t(type.Type)} ${this.$t(
        //             type.Powered
        //         )}`
        //         const option = {
        //             name,
        //             value: +type.Id,
        //         }
        //         if (!this.vehicleTypeOptions.includes(option)) {
        //             this.vehicleTypeOptions.push(option)
        //         }
        //     })
        // })

        const isB2CInterval = setInterval(() => {
            if (this.isB2C !== null) {
                clearInterval(isB2CInterval)

                if (this.isB2C === false) {
                    if (
                        this.$route.name === 'new_order' &&
                        this.recurrentOrder === false
                    ) {
                        this.newDeliveryName = `${this.$t(
                            'delivery_title',
                        )} ${new Date().toISOString().substr(0, 10)}`
                        this.stepsToAdd = [
                            {
                                Type: 'pickup',
                                Status: 'new',
                                StepNumber: 1,
                                IdClientAddress: 0,
                                AddressName: '',
                                Address1: '',
                                Address2: '',
                                CommentStep: '',

                                Address3: '',
                                Address4: '',
                                PostalCode: '',
                                City: '',
                                Country: 'FRA',
                                Latitude: '',
                                Longitude: '',
                                Moment: 'between',
                                FromDateTime: '08:00',
                                BeforeDateTime: '18:00',
                                TypeVehicle: '',
                                Hours: null,
                                IdVehicle: '',
                                IdDriver: '',
                                stepDate: this.minDate,
                            },
                            {
                                Type: 'delivery',
                                Status: 'new',
                                StepNumber: 2,
                                CommentStep: '',

                                IdClientAddress: 0,
                                AddressName: '',
                                Address1: '',
                                Address2: '',
                                Address3: '',
                                Address4: '',
                                PostalCode: '',
                                City: '',
                                Country: 'FRA',
                                Latitude: '',
                                Longitude: '',
                                Moment: 'between',
                                FromDateTime: '08:00',
                                BeforeDateTime: '18:00',
                                TypeVehicle: '',
                                Hours: null,
                                IdVehicle: '',
                                IdDriver: '',
                                stepDate: this.minDate,
                            },
                        ]
                    }
                    const cookie = this.$cookies.get(
                        'clientIdForUpdateFromOrder',
                    )
                    if (cookie && this.$route.name === 'new_order') {
                        this.clientStore.fetchOne(cookie).then((res) => {
                            const option = {
                                name: `${res.data.Name}`,
                                value: +res.data.Id,
                                dbField: 'IdClient',
                                ext_id: +res.data.IdExternal,
                                client_type:
                                    res.data.ClientType ?? 'occasional',
                                paymentType: res.data.PaymentType,
                                hasProcessor: !!res.data.ProcessorImportId,
                            }
                            this.clientOptions.push(option)

                            this.formData.IdClient = res.data.Id
                            this.fetchContactsAddressesAndContainers(
                                this.formData.IdClient,
                                false,
                                false,
                            )
                            this.$cookies.remove('clientIdForUpdateFromOrder')
                        })
                    }
                    this.universeOptions = []

                    if (
                        this.userStore.current.type === 'admin' ||
                        this.userStore.current.type === 'superadmin'
                    ) {
                        const universeSearch = {
                            sortDirection: 'ASC',
                            sortField: 'Name',
                            perPage: 10000,
                            page: 1,
                            query: [],
                            filter: [],
                        }
                        this.searchUniverses(universeSearch).then((res) => {
                            this.universeOptions = []

                            this.universes.forEach((universe) => {
                                this.universeOptions.push({
                                    name: universe.Name,
                                    value: +universe.Id,
                                    dbField: 'IdUniverse',
                                    realName: universe.Name,
                                })
                            })
                            this.refreshMS += 1
                        })
                    } else {
                        this.universeOptions = []

                        this.userStore.userUniverses.forEach((universe) => {
                            this.universeOptions.push({
                                name: universe.name,
                                value: +universe.value,
                                dbField: 'IdUniverse',
                                realName: universe.name,
                            })
                        })

                        this.refreshMS += 1
                    }
                    this.clientStore.$patch({
                        containers: null,
                        currentContacs: null,
                        all: null,
                    })

                    this.universeFilterForClientSearch = {
                        dbField: 'IdUniverse',
                        value: this.universeOptions,
                    }
                    this.noContactForClient = false
                    let loadDriverStart = '8:00'
                    let loadDriverEnd = '18:00'

                    if (this.formData.OrderSteps.length) {
                        this.setMinDate(this.formData.OrderSteps.length - 1)
                        loadDriverStart =
                            this.formData.OrderSteps[0].FromDateTime
                        loadDriverEnd =
                            this.formData.OrderSteps[0].BeforeDateTime
                    }

                    this.loadAvailableDrivers(
                        this.minDate,

                        false,
                    )
                    this.loadAvailableVehicles(this.minDate)

                    this.refreshMS += 1
                    this.refreshDriverVehicleSelect += 1
                }
            }
        }, 200)
    },
    data() {
        return {
            newPosition: null,
            confirmModalTextOptiErrorInfo: '',
            confirmModalTextOptiError: '',
            canBeForced: true,
            selectedContact: null,
            showConfirmTourOptiModal: false,
            confirmAction: null,

            dontRefreshPrice: false,
            containerOptions: [],
            onus: [],
            refreshOnu: 0,
            tempArticleId: [],
            articleOptions: [],
            onuOptions: [],
            showBreakForm: false,
            breakForm: null,
            createBreakAddressForm: false,
            showAddressUpdateExtraInfo: false,
            clientAddressExtraInfo: {},
            priceToApply: 0,
            calculatedPrice: 0,
            selectedTourPricing: null,
            selectedOrderPricing: null,
            tourPricingOptions: [],
            orderPricingOptions: [],
            refreshSales: 0,
            extraSales: [],
            selectedGroup: null,
            alreadyRefreshing: false,
            refreshPrice: 0,
            selectedVehicleType: {
                name: this.$t('not_specified'),
                value: '',
            },
            orderGroupOptions: [],
            vehicleTypeOptions: [
                { name: this.$t('not_specified'), value: '' },
                { name: this.$t('bike'), value: 'bike' },
                { name: this.$t('electric_bike'), value: 'electricBike' },
                { name: this.$t('cargo_bike'), value: 'cargoBike' },
                {
                    name: this.$t('delivery_tricycle'),
                    value: 'deliveryTricycle',
                },
                { name: this.$t('car'), value: 'car' },
                { name: this.$t('electric_car'), value: 'electricCar' },

                { name: this.$t('van'), value: 'van' },
                { name: this.$t('truck'), value: 'truck' },
                { name: this.$t('boat'), value: 'boat' },
            ],
            idClientForContainer: null,
            refreshPacks: 0,
            idContDefault: null,
            refreshDriverVehicleSelect: 0,
            universeOptions: [],
            webClient: false,
            repetitionFrequency: 1,
            recurrentForm: {
                recurrentEndChoice: 'never',
                recurrentName: '',
                endsAfterNumber: 1,

                occurrenceFrequency: {
                    value: 'daily',
                    name: this.$t(
                        'day',
                        this.repetitionFrequency === 1
                            ? this.repetitionFrequency
                            : 2,
                    ),
                },
                recurrenceEndDate: '',
                //  new Date(Date.now() + 3600 * 1000 * 24)
                //     .toISOString()
                //     .substr(0, 10),
                recurrenceStartDate: new Date().toISOString().substr(0, 10),

                pickedDaysForRecurrence: [],
            },
            recurrenceDays: [
                { name: 'mon', value: '1' },
                { name: 'tue', value: '2' },
                { name: 'wed', value: '3' },
                { name: 'thu', value: '4' },
                { name: 'fri', value: '5' },
                { name: 'sat', value: '6' },
                {
                    name: 'sun',
                    value: '7',
                },
            ],

            recurrentOrder: this.$route.name === 'update_recurrent',
            roundTrip: false,
            isDuplicate: false,
            days: [
                'noday',
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ],
            confirmDelete: null,
            showDeleteConfirmModal: false,
            elementToDelete: '',
            showForm: [],
            minDate: new Date().toISOString().substr(0, 10),
            maxDate: '',
            containerCreation: false,
            date: new Date(),
            refreshMS: 0,
            isEditPackage: false,

            selectedMethod: {
                name: 'normal',
                value: 'normal',
                icon: 'gauge',
                style: 'color:green;',
                dbField: 'Method',
            },
            // TODO ajouter tour en bdd

            noContactForClient: false,
            price: null,
            creating: false,
            show: false,
            newStep: null,
            noClientError: false,
            noDriverError: false,
            noContactError: false,
            refreshSteps: 0,
            refresh: 0,
            cards: [],
            badTimeRange: false,
            badTimeRangeItem: null,
            badTimeRangeSteps: false,
            delivBeforePickUp: false,
            stepToUpdate: null,

            stepMomentOptions: [
                { name: this.$t('between'), value: 'between' },
                { name: this.$t('before'), value: 'before' },
                { name: this.$t('from'), value: 'from' },
            ],
            refreshToggle: 0,
            stepsToAdd: [],
            formData: {
                WithVAT: true,
                IdUniverse: 0,
                Name: '',
                IdClient: '',
                IdUserContact: '',
                IdDriver: '',
                IdVehicle: '',
                VehicleType: '',
                IdInvoice: '',
                // Reference: '',
                Price: 0,
                ForcedPrice: 0,
                Status: 'new',
                Method: 'normal',
                OrderClientType: '',
                PaymentType: '',
                Latitude: '',
                Longitude: '',
                CommentInternal: '',
                CommentClient: '',
                OrderStepsHTML: null,
                CreationUserId: '',
                OrderSteps: [],
                OrderPackages: [],
                DateBegin: '',
                DateEnd: '',
                OrderOptions: null,
                InvoiceNumber: '',
                OrderOption: [],
                ModificationUserId: '',
                PacketNumber: 0,
                GroupName: '',
                ONUs: [],
                Sales: null,
            },

            driverOptions: [],
            vehicleOptions: [],
            contactOptions: [],
            addressOptions: [],
            deliveryAddressId: null,
            currentOrderIntervall: null,
            newDeliveryName: '',
        }
    },
    validations() {
        return {
            newDeliveryName: {
                required: requiredIf(function (abc) {
                    return this.$route.name === 'new_order'
                }),
            },
            clientAddressExtraInfo: {
                ContactEmail: {
                    email,
                },

                ContactPhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
            },
            formData: {
                IdUniverse: { required },
                IdClient: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false
                    }),
                },
                // IdDriver: {
                //     required: requiredIf(function (abc) {
                //         return this.isB2C === false
                //     }),
                // },
                OrderClientType: {
                    required: requiredIf(function (abc) {
                        return this.recurrentOrder === false
                    }),
                },
                PaymentType: {
                    required: requiredIf(function (abc) {
                        return this.recurrentOrder === false
                    }),
                },
                OrderSteps: {
                    $each: helpers.forEach({
                        AddressName: {
                            required: requiredIf(function (abc) {
                                return this.isB2C === false
                            }),
                        },
                        Address1: {
                            required: requiredIf(function (abc) {
                                return this.isB2C === false
                            }),
                        },
                        PostalCode: {
                            required: requiredIf(function (abc) {
                                return this.isB2C === false
                            }),
                            isZipCode: helpers.withMessage(
                                this.$t('bad_zip_format'),
                                isZipCode,
                            ),
                        },
                        ContactEmail: {
                            email,
                        },

                        ContactPhone: {
                            isPhoneNumber: helpers.withMessage(
                                this.$t('bad_phone_format'),
                                isPhoneNumber,
                            ),
                        },
                        // Type: {
                        //     required: requiredIf(function (ab) {
                        //         return this.show === true
                        //     }),
                        // },
                        City: {
                            required: requiredIf(function (abc) {
                                return this.isB2C === false
                            }),
                        },
                        FromDateTime: {
                            required: requiredIf(function (abc, stepToAdd) {
                                const i = this.formData.OrderSteps.findIndex(
                                    (step) =>
                                        step.StepNumber ===
                                        stepToAdd.StepNumber,
                                )
                                return (
                                    this.showAddressUpdateExtraInfo === false &&
                                    (this.formData.OrderSteps[i].Moment ===
                                        'between' ||
                                        this.formData.OrderSteps[i].Moment ===
                                            'from') &&
                                    this.isB2C === false
                                )
                            }),
                        },
                        BeforeDateTime: {
                            required: requiredIf(function (abc, stepToAdd) {
                                const i = this.formData.OrderSteps.findIndex(
                                    (step) =>
                                        step.StepNumber ===
                                        stepToAdd.StepNumber,
                                )
                                return (
                                    this.showAddressUpdateExtraInfo === false &&
                                    (this.formData.OrderSteps[i].Moment ===
                                        'between' ||
                                        this.formData.OrderSteps[i].Moment ===
                                            'before') &&
                                    this.isB2C === false
                                )
                            }),
                        },
                    }),
                },
            },
            recurrentForm: {
                recurrentName: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false && this.recurrentOrder === true
                        )
                    }),
                },
                endsAfterNumber: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false &&
                            this.recurrentOrder === true &&
                            this.recurrentForm.recurrentEndChoice === 'after'
                        )
                    }),
                },

                recurrenceEndDate: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false &&
                            this.recurrentOrder === true &&
                            this.recurrentForm.recurrentEndChoice === 'on'
                        )
                    }),
                },
                recurrenceStartDate: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false && this.recurrentOrder === true
                        )
                    }),
                },
            },
            // repetitionFrequency: {
            //     required: requiredIf(function (abc) {
            //         return this.isB2C === false && this.recurrentOrder === true
            //     }),
            // },

            newStep: {
                AddressName: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                },
                Address1: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                },
                PostalCode: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },
                Type: {
                    required: requiredIf(function (ab) {
                        return this.show === true
                    }),
                },
                City: {
                    required: requiredIf(function (abc) {
                        return this.isB2C === false && this.show === true
                    }),
                },
                FromDateTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.showAddressUpdateExtraInfo === false &&
                            (this.newStep.Moment === 'between' ||
                                this.newStep.Moment === 'from') &&
                            this.isB2C === false &&
                            this.show === true
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                BeforeDateTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.showAddressUpdateExtraInfo === false &&
                            (this.newStep.Moment === 'between' ||
                                this.newStep.Moment === 'before') &&
                            this.isB2C === false &&
                            this.show === true
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                stepDate: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false &&
                            this.show === true &&
                            this.recurrentOrder === false &&
                            this.showAddressUpdateExtraInfo === false
                        )
                    }),
                    dateIsValid:
                        this.recurrentOrder === false &&
                        helpers.withMessage(
                            this.$t('bad_date_format'),
                            dateIsValid,
                        ),
                },
                ContactEmail: {
                    email,
                },

                ContactPhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
            },
            stepsToAdd: {
                $each: helpers.forEach({
                    AddressName: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                    },
                    Address1: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                    },
                    PostalCode: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                        isZipCode: helpers.withMessage(
                            this.$t('bad_zip_format'),
                            isZipCode,
                        ),
                    },
                    ContactEmail: {
                        email,
                    },
                    ContactPhone: {
                        isPhoneNumber: helpers.withMessage(
                            this.$t('bad_phone_format'),
                            isPhoneNumber,
                        ),
                    },
                    // Type: {
                    //     required: requiredIf(function (ab) {
                    //         return this.show === true
                    //     }),
                    // },
                    City: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false
                        }),
                    },
                    FromDateTime: {
                        required: requiredIf(function (abc, stepToAdd) {
                            const i = this.stepsToAdd.findIndex(
                                (step) =>
                                    step.StepNumber === stepToAdd.StepNumber,
                            )
                            return (
                                this.showAddressUpdateExtraInfo === false &&
                                (this.stepsToAdd[i].Moment === 'between' ||
                                    this.stepsToAdd[i].Moment === 'from') &&
                                this.isB2C === false
                            )
                        }),
                        timeIsValid: helpers.withMessage(
                            this.$t('bad_time_format'),
                            timeIsValid,
                        ),
                    },
                    BeforeDateTime: {
                        required: requiredIf(function (abc, stepToAdd) {
                            const i = this.stepsToAdd.findIndex(
                                (step) =>
                                    step.StepNumber === stepToAdd.StepNumber,
                            )
                            return (
                                this.showAddressUpdateExtraInfo === false &&
                                (this.stepsToAdd[i].Moment === 'between' ||
                                    this.stepsToAdd[i].Moment === 'before') &&
                                this.isB2C === false
                            )
                        }),
                        timeIsValid: helpers.withMessage(
                            this.$t('bad_time_format'),
                            timeIsValid,
                        ),
                    },
                    stepDate: {
                        required: requiredIf(function (abc) {
                            return (
                                this.isB2C === false &&
                                this.recurrentOrder === false
                            )
                        }),
                        dateIsValid:
                            this.recurrentOrder === false &&
                            helpers.withMessage(
                                this.$t('bad_date_format'),
                                dateIsValid,
                            ),
                    },
                }),
            },
            breakForm: {
                AddressName: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false && this.showBreakForm === true
                        )
                    }),
                },
                Address1: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false && this.showBreakForm === true
                        )
                    }),
                },
                PostalCode: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false && this.showBreakForm === true
                        )
                    }),
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },
                Type: {
                    required: requiredIf(function (ab) {
                        return this.showBreakForm === true
                    }),
                },
                City: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false && this.showBreakForm === true
                        )
                    }),
                },
                FromDateTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.showAddressUpdateExtraInfo === false &&
                            (this.breakForm.Moment === 'between' ||
                                this.breakForm.Moment === 'from') &&
                            this.isB2C === false &&
                            this.showBreakForm === true
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                BeforeDateTime: {
                    required: requiredIf(function (abc) {
                        return (
                            this.showAddressUpdateExtraInfo === false &&
                            (this.breakForm.Moment === 'between' ||
                                this.breakForm.Moment === 'before') &&
                            this.isB2C === false &&
                            this.showBreakForm === true
                        )
                    }),
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                stepDate: {
                    required: requiredIf(function (abc) {
                        return (
                            this.isB2C === false &&
                            this.showBreakForm === true &&
                            this.recurrentOrder === false &&
                            this.showAddressUpdateExtraInfo === false
                        )
                    }),
                    dateIsValid:
                        this.recurrentOrder === false &&
                        helpers.withMessage(
                            this.$t('bad_date_format'),
                            dateIsValid,
                        ),
                },
                ContactEmail: {
                    email,
                },

                ContactPhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
            },
            onus: {
                $each: helpers.forEach({
                    Id: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false && this.onus.length > 0
                        }),
                    },
                    NetWeight: {
                        required: requiredIf(function (abc) {
                            return this.isB2C === false && this.onus.length > 0
                        }),
                    },
                }),
            },
        }
    },

    computed: {
        vatOptions() {
            let vatOptions = [0, 2.1, 5.5, 10, 20]
            if (
                window.location.host.substring(
                    0,
                    window.location.host.indexOf('.'),
                ) === 'demo01'
            ) {
                vatOptions = [0, 1.05, 1.75, 2.5, 8.5]
            }
            return vatOptions
        },
        canAccessRecurrent() {
            return (
                (this.$route.name === 'new_order' &&
                    checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.recurrents.create,
                    )) ||
                (this.$route.name === 'update_recurrent' &&
                    checkAccess(
                        this.userStore,
                        this.ordersActionsAccess.recurrents.update,
                    ))
            )
        },
        canCreateIdExternal() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.idExternal.create,
            )
        },
        canUpdateClient() {
            return checkAccess(this.userStore, actionsAccess.clients.update)
        },
        canCreateClient() {
            return checkAccess(this.userStore, actionsAccess.clients.create)
        },
        canUpdateIdExternal() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.idExternal.update,
            )
        },
        canLiveRefreshPrice() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.livePrice,
            )
        },
        canRefreshPrice() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.updatePrice,
            )
        },
        canForcePrice() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.forcePrice,
            )
        },
        ...mapState(useUniverseStore, {
            universes: 'all',
        }),
        ...mapState(useArticleStore, {
            articles: 'all',
        }),
        ...mapState(useHazardousProductStore, {
            allOnus: 'all',
        }),
        frequenceOptions() {
            return [
                {
                    value: 'daily',
                    name: this.$t(
                        'day',
                        this.repetitionFrequency === 1
                            ? this.repetitionFrequency
                            : 2,
                    ),
                },
                {
                    value: 'weekly',
                    name: this.$t(
                        'week',
                        this.repetitionFrequency === 1
                            ? this.repetitionFrequency
                            : 2,
                    ),
                },
                {
                    value: 'monthly',
                    name: this.$t(
                        'month',
                        this.repetitionFrequency === 1
                            ? this.repetitionFrequency
                            : 2,
                    ),
                },
                {
                    value: 'yearly',
                    name: this.$t(
                        'year',
                        this.repetitionFrequency === 1
                            ? this.repetitionFrequency
                            : 2,
                    ),
                },
            ]
        },
        ordersActionsAccess() {
            return actionsAccess.orders
        },
        currentOrder() {
            return this.$route.name === 'update_order'
                ? this.currentOr
                : this.currentRec
        },
        methodOptions() {
            const options = [
                {
                    name: 'normal',
                    value: 'normal',
                    icon: 'gauge',
                    style: 'color:green;',
                    dbField: 'Method',
                },
                {
                    name: 'fast',
                    value: 'fast',
                    icon: 'gauge-high',
                    style: 'color:orange;',
                    dbField: 'Method',
                },
                {
                    name: 'express',
                    value: 'express',
                    icon: 'rocket',
                    style: 'color: red;',
                    dbField: 'Method',
                },
            ]
            if (this.canAccessGroupsCreate || this.canAccessGroupsUpdate) {
                options.push({
                    name: 'order_group',
                    value: 'orderGrouping',
                    icon: 'arrows-to-circle',
                    style: 'color: blue;',
                    dbField: 'Method',
                })
            }
            return options
        },
        orderTypeOptions() {
            return [
                { name: this.$t('occasional'), value: 'occasional' },
                // { name: this.$t('web'), value: 'web' },
                { name: this.$t('month_invoice'), value: 'month' },
            ]
        },

        paymentTypeOptions() {
            let options = [
                { name: this.$t('check'), value: 'check' },
                { name: this.$t('credit_card'), value: 'creditcard' },
                { name: this.$t('cash'), value: 'cash' },
                { name: this.$t('transfer'), value: 'transfer' },
                { name: this.$t('client_credit'), value: 'clientCredit' },
            ]
            if (
                this.formData.OrderClientType === 'occasional' ||
                this.formData.OrderClientType === ''
            ) {
                options = [
                    { name: this.$t('web'), value: 'web' },
                    { name: this.$t('check'), value: 'check' },
                    { name: this.$t('credit_card'), value: 'creditcard' },
                    { name: this.$t('cash'), value: 'cash' },
                    { name: this.$t('transfer'), value: 'transfer' },
                    { name: this.$t('client_credit'), value: 'clientCredit' },
                ]
            }
            if (
                this.formData.OrderClientType === 'month' ||
                this.formData.OrderClientType === ''
            ) {
                options = [
                    { name: this.$t('check'), value: 'check' },
                    { name: this.$t('credit_card'), value: 'creditcard' },
                    { name: this.$t('cash'), value: 'cash' },
                    { name: this.$t('transfer'), value: 'transfer' },
                ]
            }
            return options
        },
        ...mapStores(useClientStore, useUserStore),
        ...mapWritableState(useOrderStore, {
            currentOr: 'current',
            orderToDuplicate: 'orderToDuplicate',
        }),
        ...mapWritableState(useRecurrentOrderStore, {
            currentRec: 'current',
            recurrentToDuplicate: 'recurrentToDuplicate',
            // orderToDuplicate: 'orderToDuplicate',
        }),
        ...mapState(useVehicleStore, {
            vehicles: 'all',
        }),
        ...mapState(useDriverStore, {
            drivers: 'all',
            currentDriver: 'current',
        }),
        ...mapState(useClientStore, {
            currentClient: 'current',
            clients: 'all',
            businessHours: 'businessHours',
            contacts: 'currentContacts',
            clientAddresses: 'addresses',
            currentDeliveryAddress: 'currentAddress',
            containers: 'containers',
            currentContainer: 'currentContainer',
            breakAddress: 'breakAddress',
        }),
        ...mapState(useUserStore, {
            user: 'current',
            isB2C: 'isB2C',
            mapMarker: 'mapMarker',
        }),
        // selectedContact() {
        //     let contact = null

        //     if (this.formData.IdUserContact && this.contactOptions.length > 0) {
        //         contact = this.contactOptions.find(
        //             (el) => +el.value === +this.formData.IdUserContact,
        //         )
        //     }
        //     return contact
        // },
        canAssignDriver() {
            return (
                checkAccess(
                    this.userStore,
                    this.ordersActionsAccess.assign.driver,
                ) ||
                checkAccess(
                    this.userStore,
                    this.ordersActionsAccess.recurrents.update,
                )
            )
        },
        canUpdateRefAddress() {
            return checkAccess(
                this.userStore,
                actionsAccess.clients.addresses.update,
            )
        },
        canAssignVehicle() {
            return (
                checkAccess(
                    this.userStore,
                    this.ordersActionsAccess.assign.vehicle,
                ) ||
                checkAccess(
                    this.userStore,
                    this.ordersActionsAccess.recurrents.update,
                )
            )
        },
        canAccessSales() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.articles.list,
            )
        },
        canAddSales() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.sales.addUpdateInOrder,
            )
        },
        canUpdateSales() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.sales.addUpdateInOrder,
            )
        },
        canDeleteSales() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.sales.deleteInOrder,
            )
        },
        canAccessONU() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.hazardous.list,
            )
        },
        canAddONU() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.hazardous.addUpdateInOrder,
            )
        },
        canDeleteONU() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.hazardous.deleteInOrder,
            )
        },
        canSetRoundTrip() {
            let canSet = checkAccess(
                this.userStore,
                actionsAccess.roundTripOrder,
            )
            if (this.canAccessRecurrent === true) {
                canSet = checkAccess(this.userStore, actionsAccess.roundTripRec)
            }
            return canSet
        },
        canUpdateStepStatus() {
            if (this.isB2C === true) {
                return false
            }
            return (
                this.$route.name === 'update_order' &&
                this.currentOrder &&
                this.formData.OrderSteps.length ===
                    this.currentOrder.order_steps.length &&
                checkAccess(
                    this.userStore,
                    this.ordersActionsAccess.updateStatus,
                )
            )
        },
        canAccessGroupsCreate() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.groups.create,
            )
        },
        canAccessGroupsUpdate() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.groups.update,
            )
        },
        canAccessTourPricing() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.tourPricing,
            )
        },
        canAccessOrderPricing() {
            return checkAccess(
                this.userStore,
                this.ordersActionsAccess.orderPricing,
            )
        },
    },
    methods: {
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete.action
            this.newPosition = confirmDelete.newPosition

            this.searchingCursor = confirmDelete.action
            this.showConfirmTourOptiModal = false
        },

        setIsSubContractor(evt, stepsToUse = this.formData.OrderSteps) {
            if (evt.driverId) {
                const isSubContractor = this.driverOptions.find(
                    (dr) => dr.value === evt.driverId,
                ).IsSubContractor

                stepsToUse[evt.stepIndex].IdDriver = evt.driverId

                stepsToUse[evt.stepIndex].IsSubContractor = isSubContractor
                stepsToUse[evt.stepIndex + 1].IsSubContractor = isSubContractor

                stepsToUse[evt.stepIndex + 1].SendCharter = isSubContractor

                if (isSubContractor === false) {
                    delete stepsToUse[evt.stepIndex].PurchasePrice
                    delete stepsToUse[evt.stepIndex].SellingPrice
                    delete stepsToUse[evt.stepIndex].Margin
                } else {
                    stepsToUse[evt.stepIndex + 1].PurchasePrice = 0
                    stepsToUse[evt.stepIndex + 1].SellingPrice = 0
                    stepsToUse[evt.stepIndex + 1].Margin = 0
                }
            } else {
                stepsToUse[evt.stepIndex].IdDriver = null
                stepsToUse[evt.stepIndex].IsSubContractor = false
                stepsToUse[evt.stepIndex + 1].IsSubContractor = false

                stepsToUse[evt.stepIndex + 1].SendCharter = false
                stepsToUse[evt.stepIndex].SendCharter = false

                delete stepsToUse[evt.stepIndex + 1].PurchasePrice
                delete stepsToUse[evt.stepIndex + 1].SellingPrice
                delete stepsToUse[evt.stepIndex + 1].Margin
            }
        },
        selectOrCreateArticle(currentIcon, extraSaleIndex) {
            this.extraSales[extraSaleIndex].articleIcon =
                currentIcon === 'basket-shopping'
                    ? 'i-cursor'
                    : 'basket-shopping'
            if (this.extraSales[extraSaleIndex].articleIcon === 'i-cursor') {
                this.extraSales[extraSaleIndex].IdArticle = 0
                this.extraSales[extraSaleIndex].article = 0

                this.tempArticleId[extraSaleIndex].value = null
            }
        },
        setArticle(article, extraSaleIndex) {
            const currentArticle = this.articles.find(
                (art) => +art.id === +article.value,
            )
            if (currentArticle !== -1) {
                if (!article.name) {
                    this.tempArticleId[extraSaleIndex].name =
                        currentArticle.name
                }
                let complString = ''
                if (currentArticle.ht) {
                    complString += ` • ${this.$t('ht')}: ${currentArticle.ht}`
                }
                if (currentArticle.ttc) {
                    complString += ` • ${this.$t('ttc')}: ${currentArticle.ttc}`
                }
                if (currentArticle.vat) {
                    complString += ` • ${this.$t('vat')}: ${currentArticle.vat}`
                }
                if (currentArticle.price_percent) {
                    complString += ` • ${this.$t('price_percent')}: ${currentArticle.price_percent}`
                }
                if (currentArticle.price_pack_ht) {
                    complString += ` • ${this.$t('price_pack_ht')}: ${currentArticle.price_pack_ht}`
                }
                if (currentArticle.coef) {
                    complString += ` • ${this.$t('coef')}: ${currentArticle.coef}`
                }
                this.extraSales[extraSaleIndex].article =
                    `${currentArticle.description} ${complString}`
                // this.extraSales[extraSaleIndex].tempArticleId.value = +currentArticle.id
                this.extraSales[extraSaleIndex].IdArticle = +currentArticle.id

                this.liveRefresh()
                this.refreshSales += 1
            }
        },
        addOnu() {
            this.onuOptions = []

            this.searchOnus({
                query: [],
                filter: [],
                perPage: 10000,
                page: 1,
                sortField: 'Code',
                sortDirection: 'ASC',
            }).then((res) => {
                if (res.status === 204) {
                    this.onuOptions = []
                } else {
                    res.data.Records.forEach((onu) => {
                        this.onuOptions.push({
                            name: `${onu.Code} • GP ${onu.GroupONU ? onu.GroupONU : this.$t('not_specified')} • ${onu.Description}`,
                            value: +onu.Id,
                        })
                    })
                }

                this.onus.push({
                    Id: 0,
                    LQ: false,
                    NetWeight: 0,
                    detail: null,
                })
            })
            this.loadAvailableDrivers(this.minDate, false, true)

            this.refreshOnu += 1
        },
        setONU(onu, onuIndex, netWeight = 0) {
            const currentOnu = this.allOnus.find(
                (art) => +art.id === +onu.value,
            )
            if (currentOnu !== -1) {
                if (!onu.name) {
                    this.onus[onuIndex].name =
                        `${currentOnu.code} • ${currentOnu.description}`
                }

                let complString = ''

                if (currentOnu.class) {
                    complString += ` • ${this.$t('onu_class')}: ${currentOnu.class}`
                }
                if (currentOnu.group_onu) {
                    complString += ` • ${this.$t('group_onu')}: ${currentOnu.group_onu}`
                }

                this.onus[onuIndex].detail =
                    `${currentOnu.description} ${complString}`
                this.onus[onuIndex].Id = +currentOnu.id
                this.onus[onuIndex].Code = currentOnu.code
                this.onus[onuIndex].LQ = +currentOnu.lq === 1
                this.onus[onuIndex].NetWeight = netWeight
                if (!this.onus[onuIndex].value) {
                    this.onus[onuIndex].value = onu.value
                }
                this.liveRefresh()
                this.refreshOnu += 1
            }
        },
        deleteOnu(onuIndex, id = null) {
            this.onus.splice(onuIndex, 1)
            if (id) {
                // TODO delete la onu lié à la commande dans la bdd avec l'id
            }
            if (this.onus.length === 0) {
                this.loadAvailableDrivers(this.minDate, false, false)
            }
        },
        viewImportedPDF(file) {
            let apiUrl = `/uploads/orderFiles/${file.Name}`
            if (file.Type && file.Type === 'user') {
                apiUrl = `/uploads/order/userUploads/${file.Name}`
            }
            if (file.Type && file.Type === 'slip') {
                apiUrl = `/uploads/order/transportSplit/${file.Name}`
            }
            if (file.Type && file.Type === 'charter') {
                apiUrl = `/uploads/orderCharters/${file.Name}` /// uploads/order/transportSplit/${file.Name}`
            }
            this.axios
                .get(apiUrl, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session',
                        )}`,
                    },
                    signal: this.$controller.signal,
                })
                .then((res) => {
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = url
                    link.target = '_external'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    // URL.revokeObjectURL(url)
                    // link.setAttribute('download', file)

                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)
                    // URL.revokeObjectURL(url)
                })
        },
        setOrderGroupForm() {
            this.formData.GroupName = ''
            // this.selectedGroup = null
            if (this.formData.Method === 'orderGrouping') {
                this.orderGroupOptions = []
                if (this.recurrentOrder === false) {
                    const date =
                        this.$route.name === 'update_order'
                            ? this.formData.DateBegin
                            : this.stepsToAdd[0].stepDate
                    this.fetchOrderGroups(date).then((orderGroupOptions) => {
                        if (orderGroupOptions.status === 200) {
                            orderGroupOptions.data.Records.forEach((group) => {
                                const option = {
                                    value: +group.Id,
                                    name: group.Name,
                                }
                                this.orderGroupOptions.push(option)
                            })
                        }
                    })
                } else {
                    this.fetchRecurrentGroups().then((orderGroupOptions) => {
                        if (orderGroupOptions.status === 200) {
                            orderGroupOptions.data.Records.forEach((group) => {
                                const option = {
                                    value: +group.Id,
                                    name: group.Name,
                                }
                                this.orderGroupOptions.push(option)
                            })
                        }
                    })
                }
            }
        },
        deleteSale(saleIndex, id = null) {
            this.extraSales.splice(saleIndex, 1)
            if (id) {
                // TODO delete la sale dans la bdd avec l'id
            }
        },
        addSale() {
            this.articleOptions = []

            this.searchArticles({
                query: [],
                filter: [],
                perPage: 10000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }).then((res) => {
                if (res.status === 204) {
                    this.articleOptions = []
                } else {
                    res.data.Records.forEach((article) => {
                        this.articleOptions.push({
                            name: article.Name,
                            value: +article.Id,
                        })
                    })
                }
                this.extraSales.push({
                    Designation: '',
                    VAT: 0,
                    TotalAmountVAT: 0,
                    Quantity: 1,
                    Reference: '',
                    UnitPriceHT: 0,
                    TotalHT: 0,
                    TotalTTC: 0,
                    CreationUserId: this.userStore.current.id,
                    Id: '',
                    IdSale: '',
                    IdArticle: 0,

                    articleIcon:
                        this.articleOptions.length > 0
                            ? 'basket-shopping'
                            : 'i-cursor',
                    article: null,
                })
            })

            this.refreshSales += 1
            this.refreshMS += 1
        },

        liveRefresh() {
            if (this.canLiveRefreshPrice === true) {
                this.refreshPrice += 1
                this.calculatePrice(true)
            }
        },
        selectPaymentType() {
            this.refreshDriverVehicleSelect += 1
            this.liveRefresh()
        },
        selectOrderClientType() {
            this.liveRefresh()
        },
        unsetContainerCreation() {
            this.containerCreation = false
            this.idClientForContainer = null
            this.creating = false
            this.refreshPacks += 1
            this.liveRefresh()
        },
        setContainerCreation(packIndex = null) {
            this.idClientForContainer = this.formData.IdClient
            if (packIndex === null) {
                this.addPackage()
            }

            this.containerCreation = true
        },
        openAddressUpdateExtraInfo(address = null) {
            this.resetFormsValidations()

            if (address !== null) {
                this.clientAddressExtraInfo = {
                    Id: address.IdClientAddress
                        ? address.IdClientAddress
                        : address.Id,
                    ContactEmail: address.ContactEmail,
                    ContactPhone: address.ContactPhone,
                    ContactFirstName: address.ContactFirstName,
                    ContactLastName: address.ContactLastName,
                    AdditionalInformation: address.AdditionalInformation,
                    DriverInformation: address.DriverInformation,
                    ModificationUserId: this.userStore.current.id,
                }
                this.showAddressUpdateExtraInfo = true
            } else {
                this.showAddressUpdateExtraInfo = false
                this.clientAddressExtraInfo = {}
            }
        },
        async updateClientAddressExtraInfo() {
            this.resetFormsValidations()

            const payload = {
                id: this.clientAddressExtraInfo.Id,
                form: this.clientAddressExtraInfo,
            }
            const isValid = await this.v$.clientAddressExtraInfo.$validate()
            if (isValid === true) {
                this.clientStore
                    .updateExtraInfosDeliveryAddress(payload)
                    .then(() => {
                        this.refreshMS += 1
                        this.openAddressUpdateExtraInfo()
                        this.$toast.success(this.$t('address_update_ok'))
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('address_update_ko'))
                    })
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        containerCreated(container) {
            const clientId = this.currentClient.id ?? this.currentClient.Id

            // this.idContDefault = +container.Id
            this.formData.OrderPackages[
                this.formData.OrderPackages.length - 1
            ] = {
                PackageNumber:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].PackageNumber,
                Width: +container.Width,
                Length: +container.Length,
                Height: +container.Height,
                Weight: +container.Weight,
                SameOpIndexes: [],
                Value: +this.formData.OrderPackages[
                    this.formData.OrderPackages.length - 1
                ].Value,
                Quantity:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].Quantity,
                TemperatureOption:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].TemperatureOption,
                IdContainer: +container.Id,
                Id: this.formData.OrderPackages[
                    this.formData.OrderPackages.length - 1
                ].Id,
                PlombNumber:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].PlombNumber,
                ReferenceExternal:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].ReferenceExternal,
                CreationUserId:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].CreationUserId,
                ModificatioUserId:
                    this.formData.OrderPackages[
                        this.formData.OrderPackages.length - 1
                    ].ModificationUserId,
                packageTypeErrors: [],
                packageValueErrors: [],
                packageWeightErrors: [],
                packageReferenceErrors: [],
                packageWidthErrors: [],
                packageLengthErrors: [],
                packageHeightErrors: [],
                packageQuantityErrors: [],

                containerNameError: false,
            }
            this.containerOptions = []

            this.clientStore.fetchContainers(clientId).then((resContainers) => {
                resContainers.data.Records.forEach((cnt) => {
                    this.containerOptions.push({
                        name:
                            cnt.Name.includes('_') ||
                            cnt.Name === 'pallet' ||
                            cnt.Name === 'envelope'
                                ? this.$t(cnt.Name)
                                : cnt.Name,
                        value: +cnt.Id,
                        icon: cnt.Name.includes('pallet')
                            ? 'pallet'
                            : cnt.Name.includes('envelope') ||
                                cnt.Name.includes('enveloppe')
                              ? 'envelope'
                              : cnt.Name.includes('box')
                                ? 'box'
                                : 'star',
                    })
                })

                this.unsetContainerCreation()
                if (this.canLiveRefreshPrice === true) {
                    this.calculatePrice(true)
                }
            })
        },
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        ...mapActions(useArticleStore, {
            searchArticles: 'search',
        }),
        ...mapActions(useHazardousProductStore, {
            searchOnus: 'search',
        }),
        ...mapActions(useTourStore, ['fetchByDate']),
        ...mapActions(useOrderStore, ['fetchOne', 'fetchOrderGroups']),
        setRepetitionFrequency(freq) {
            this.recurrentForm.occurrenceFrequency = freq
            this.liveRefresh()
        },
        addDayToPicked(day) {
            if (this.recurrentForm.pickedDaysForRecurrence.includes(day)) {
                this.recurrentForm.pickedDaysForRecurrence.splice(
                    this.recurrentForm.pickedDaysForRecurrence.indexOf(day),
                    1,
                )
            } else {
                this.recurrentForm.pickedDaysForRecurrence.push(day)
            }
            this.liveRefresh()
        },
        setPack(newPack) {
            this.formData.OrderPackages[newPack.index] = newPack.pack
            this.liveRefresh()
        },
        setRoundTrip() {
            if (this.roundTrip === false && this.stepsToAdd.length > 2) {
                this.stepsToAdd.pop()
                this.stepsToAdd.pop()
            }
            if (this.roundTrip === true) {
                const newPickup = this.stepsToAdd.length
                    ? structuredClone(
                          this.stepsToAdd[this.stepsToAdd.length - 1],
                      )
                    : structuredClone(
                          this.formData.OrderSteps[
                              this.formData.OrderSteps.length - 1
                          ],
                      )
                const newDelivery = this.stepsToAdd.length
                    ? structuredClone(
                          this.stepsToAdd[this.stepsToAdd.length - 2],
                      )
                    : structuredClone(
                          this.formData.OrderSteps[
                              this.formData.OrderSteps.length - 2
                          ],
                      )
                if (
                    this.showForm === false &&
                    newPickup.IdClientAddress === 0
                ) {
                    this.$toast.warning(this.$t('previous_step_incomplete'))
                } else if (
                    this.showForm === false &&
                    newDelivery.IdClientAddress === newPickup.IdClientAddress
                ) {
                    this.$toast.warning(this.$t('same_address'))
                } else {
                    if (!this.addressOptions.length) {
                        const clientAddressesPayload = {
                            clientId: this.formData.IdClient,
                            query: [],
                            filter: [],
                            perPage: 100000000000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }
                        this.clientStore
                            .fetchAddresses(clientAddressesPayload)
                            .then(() => {
                                const clientAddresses = setInterval(() => {
                                    if (this.clientAddresses) {
                                        this.clientAddresses.forEach(
                                            (address) => {
                                                clearInterval(clientAddresses)

                                                const option = {
                                                    name: address.name,
                                                    value: address.id,
                                                }
                                                if (
                                                    !this.addressOptions.includes(
                                                        option,
                                                    )
                                                ) {
                                                    this.addressOptions.push(
                                                        option,
                                                    )
                                                }
                                            },
                                        )
                                        this.refreshMS += 1
                                    }
                                }, 1000)
                            })
                    }

                    newPickup.Type = 'pickup'
                    newPickup.Id = ''
                    newPickup.IdVehicle = ''
                    newPickup.IdDriver = ''
                    newPickup.StepNumber = +newPickup.StepNumber + 1
                    newPickup.CommentStep = ''

                    newDelivery.Type = 'delivery'
                    newDelivery.Id = ''
                    newDelivery.IdVehicle = ''
                    newDelivery.IdDriver = ''
                    newDelivery.StepNumber = +newPickup.StepNumber + 1

                    newDelivery.CommentStep = ''

                    this.loadAvailableDrivers(newPickup.stepDate)
                    this.loadAvailableVehicles(newPickup.stepDate)
                    if (this.canLiveRefreshPrice === true) {
                        this.calculatePrice(true)
                    }
                    this.stepsToAdd.push(newPickup)
                    this.stepsToAdd.push(newDelivery)
                    this.refreshMS += 1
                }
            }
        },
        stepsDeleteConfirmation(confirmDelete) {
            this.confirmDelete = confirmDelete
            this.creating = confirmDelete
            this.showDeleteConfirmModal = false
        },
        showBreakAddressForm(show = true) {
            if (show === true) {
                this.breakForm.Address1 = ''
                this.breakForm.IdClientAddress = 0
                this.breakForm.AddressName = ''
                this.breakForm.Address2 = ''
                this.breakForm.Address3 = ''
                this.breakForm.Address4 = ''
                this.breakForm.PostalCode = ''
                this.breakForm.City = ''
                this.breakForm.Country = 'FRA'
                this.breakForm.Latitude = ''
                this.breakForm.Longitude = ''
                this.breakForm.ContactPhone = ''
                this.breakForm.ContactFirstName = ''
                this.breakForm.CommentStep = ''
                this.breakForm.ContactLastName = ''

                this.breakForm.ContactEmail = ''
                this.breakForm.AdditionalInformation = ''
                this.breakForm.DriverInformation = ''
            }

            this.createBreakAddressForm = !this.createBreakAddressForm

            // this.stepsToAdd.forEach((stp, index) => {
            this.loadGoogleScript(`autocompleteBreakform`)
            // })
        },
        showAddressForm(index) {
            this.stepsToAdd[index].Address1 = ''
            this.stepsToAdd[index].IdClientAddress = 0
            this.stepsToAdd[index].AddressName = ''
            this.stepsToAdd[index].Address2 = ''
            this.stepsToAdd[index].Address3 = ''
            this.stepsToAdd[index].Address4 = ''
            this.stepsToAdd[index].PostalCode = ''
            this.stepsToAdd[index].City = ''
            this.stepsToAdd[index].Country = 'FRA'
            this.stepsToAdd[index].Latitude = ''
            this.stepsToAdd[index].Longitude = ''
            this.stepsToAdd[index].ContactPhone = ''
            this.stepsToAdd[index].ContactFirstName = ''
            this.stepsToAdd[index].ContactLastName = ''
            this.stepsToAdd[index].CommentStep = ''
            this.stepsToAdd[index].ContactEmail = ''
            this.stepsToAdd[index].AdditionalInformation = ''
            this.stepsToAdd[index].DriverInformation = ''

            const isShown = this.showForm.findIndex((el) => el === index)

            if (isShown !== -1) {
                this.showForm.splice(isShown, 1)
            } else {
                this.showForm.push(index)
            }
            if (this.showForm.includes(index)) {
                // this.stepsToAdd.forEach((stp, index) => {
                this.loadGoogleScript(`autocompleteAddStep_${index}`, index)
                // })
            }
        },
        removeTempSteps(index) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `delete_steps_pair_info`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.stepsToAdd.forEach((stp, i) => {
                            if (i > index) {
                                stp.StepNumber = +stp.StepNumber - 2
                                this.stepsToAdd[i] = stp
                            }
                        })
                        this.stepsToAdd.splice(index, 2)
                        if (this.canLiveRefreshPrice === true) {
                            this.calculatePrice(true)
                        }
                    }
                    this.creating = false
                }
            }, 150)
        },
        setExtraInfos(index, stepDate, start, end) {
            this.setMinMaxDates(index)
            this.loadAvailableDrivers(stepDate)
            this.loadAvailableVehicles(stepDate)
        },
        findStepDay(date) {
            return new Date(date).getDay()
        },
        openingHoursForDay(businessHours, dayNumber) {
            const hours = []
            businessHours.forEach((bHour) => {
                if (+bHour.Day === dayNumber && +bHour.Open === 1) {
                    hours.push({
                        start: bHour.HourBegin.substring(0, 5),
                        end: bHour.HourEnd.substring(0, 5),
                    })
                }
            })
            return hours
        },
        resetBadTimes() {
            this.badTimeRange = false
            this.badTimeRangeItem = null
            this.badTimeRangeSteps = false
            this.delivBeforePickUp = false
        },

        updateStepStatus(step) {
            const clone = structuredClone(step.step)
            // this.searchingCursor = true
            clearInterval(this.ordersInterval)
            clone.Status = step.newStatus
            // this.formData.Status = step.newStatus

            clone.ModificationUserId = this.user.id
            const from = `${clone.stepDate} ${clone.FromDateTime}`

            const before = `${clone.stepDate} ${clone.BeforeDateTime}`
            if (clone.Moment === 'between') {
                clone.FromDateTime = from
                clone.BeforeDateTime = before
            } else if (clone.Moment === 'from') {
                clone.FromDateTime = from
                clone.BeforeDateTime = ''
            } else if (clone.Moment === 'before') {
                clone.FromDateTime = ''
                clone.BeforeDateTime = before
            }
            if (step.newStatus !== 'executed') {
                clone.ExecutedDateTime = null
            } else {
                clone.ExecutedDateTime = new Date().toISOString()
            }
            const payload = {
                id: clone.Id,
                form: clone,
            }
            this.updateStep(payload)
                .then((res) => {
                    step.step.Status = step.newStatus
                    const updatedStepIndex =
                        this.currentOrder.order_steps.findIndex(
                            (stp) => +stp.Id === +step.step.Id,
                        )
                    if (step.newStatus !== 'executed') {
                        step.step.ExecutedDateTime = null
                        this.currentOrder.order_steps.forEach(
                            (stpToUpdate, index) => {
                                if (updatedStepIndex < index) {
                                    const stpToUpdateClone =
                                        structuredClone(stpToUpdate)
                                    // this.searchingCursor = true
                                    clearInterval(this.ordersInterval)
                                    stpToUpdateClone.Status = step.newStatus
                                    // this.formData.Status = step.newStatus

                                    stpToUpdateClone.ModificationUserId =
                                        this.user.id
                                    const fromUp = `${stpToUpdateClone.stepDate} ${stpToUpdateClone.FromDateTime}`

                                    const beforeUp = `${stpToUpdateClone.stepDate} ${stpToUpdateClone.BeforeDateTime}`
                                    if (stpToUpdateClone.Moment === 'between') {
                                        stpToUpdateClone.FromDateTime = fromUp
                                        stpToUpdateClone.BeforeDateTime =
                                            beforeUp
                                    } else if (
                                        stpToUpdateClone.Moment === 'from'
                                    ) {
                                        stpToUpdateClone.FromDateTime = fromUp
                                        stpToUpdateClone.BeforeDateTime = ''
                                    } else if (
                                        stpToUpdateClone.Moment === 'before'
                                    ) {
                                        stpToUpdateClone.FromDateTime = ''
                                        stpToUpdateClone.BeforeDateTime =
                                            beforeUp
                                    }

                                    stpToUpdateClone.Status = stpToUpdate.Driver
                                        ? 'affected'
                                        : 'new'
                                    stpToUpdateClone.ExecutedDateTime = null
                                    stpToUpdateClone.ModificationUserId =
                                        this.userStore.current.id
                                    const payloadStepAfter = {
                                        id: stpToUpdateClone.Id,
                                        form: stpToUpdateClone,
                                    }

                                    this.updateStep(payloadStepAfter).then(
                                        (res) => {
                                            this.fetchOne(this.$route.params.id)
                                        },
                                    )
                                }
                            },
                        )
                    } else {
                        step.step.ExecutedDateTime = new Date(
                            res.data.ExecutedDateTime,
                        ).toISOString()
                        if (
                            updatedStepIndex + 1 <=
                            this.currentOrder.order_steps.length
                        ) {
                            const stpToUpdate =
                                this.currentOrder.order_steps[
                                    updatedStepIndex + 1
                                ]
                            const stpToUpdateClone =
                                structuredClone(stpToUpdate)
                            // this.searchingCursor = true
                            clearInterval(this.ordersInterval)
                            stpToUpdateClone.Status = step.newStatus
                            // this.formData.Status = step.newStatus

                            stpToUpdateClone.ModificationUserId = this.user.id
                            const fromUp = `${stpToUpdateClone.stepDate} ${stpToUpdateClone.FromDateTime}`

                            const beforeUp = `${stpToUpdateClone.stepDate} ${stpToUpdateClone.BeforeDateTime}`
                            if (stpToUpdateClone.Moment === 'between') {
                                stpToUpdateClone.FromDateTime = fromUp
                                stpToUpdateClone.BeforeDateTime = beforeUp
                            } else if (stpToUpdateClone.Moment === 'from') {
                                stpToUpdateClone.FromDateTime = fromUp
                                stpToUpdateClone.BeforeDateTime = ''
                            } else if (stpToUpdateClone.Moment === 'before') {
                                stpToUpdateClone.FromDateTime = ''
                                stpToUpdateClone.BeforeDateTime = beforeUp
                            }

                            stpToUpdateClone.Status = stpToUpdate.Driver
                                ? 'affected'
                                : 'new'
                            stpToUpdateClone.ExecutedDateTime = null
                            stpToUpdateClone.ModificationUserId =
                                this.userStore.current.id
                            const payloadStepAfter = {
                                id: stpToUpdateClone.Id,
                                form: stpToUpdateClone,
                            }
                            this.updateStep(payloadStepAfter).then((res) => {
                                this.fetchOne(this.$route.params.id)
                            })
                        }
                    }

                    this.fetchOne(this.$route.params.id)

                    this.refreshToggle += 1
                })
                .catch((err) => {
                    this.searchingCursor = false
                    this.searchingCursor = false
                    if (err.data.status === 400) {
                        this.$toast.error(this.$t('previous_step_not_done'))
                    } else this.$toast.error(this.$t('error_occured'))
                })
        },
        previousPickUpTimesBis(breakStepDate, index = null) {
            let times = null
            const stepsToUse = this.$route.params.id
                ? this.formData.OrderSteps
                : this.stepsToAdd
            const previousStep =
                index === 0 ? stepsToUse[index] : stepsToUse[index - 1]

            times = `${this.$t('short_from')} ${previousStep.FromDateTime}`
            if (previousStep.stepDate === breakStepDate) {
                if (previousStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        previousStep.FromDateTime
                    } ${this.$t('short_before')} ${previousStep.BeforeDateTime}`
                } else if (previousStep.Moment === 'before') {
                    times = `${this.$t('before')} ${previousStep.BeforeDateTime}`
                }
            }

            const nextStep = stepsToUse[index + 1]
            if (nextStep.stepDate === breakStepDate) {
                // if (index && !this.isOdd(index)) {
                times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                if (nextStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        nextStep.FromDateTime
                    } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                } else if (nextStep.Moment === 'before') {
                    times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                }
                // }
                // if (index && this.isOdd(index) === 1) {
                //     const nextStep = stepsToUse[index]
                //     times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                //     if (nextStep.Moment === 'between') {
                //         times = `${this.$t('short_from')} ${
                //             nextStep.FromDateTime
                //         } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                //     } else if (nextStep.Moment === 'before') {
                //         times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                //     }
                // }
            }
            return times
        },
        previousPickUpTimes(update = null) {
            let times = null
            const previousStep =
                this.formData.OrderSteps[this.formData.OrderSteps.length - 1]
            times = `${this.$t('short_from')} ${previousStep.FromDateTime}`
            if (previousStep.Moment === 'between') {
                times = `${this.$t('short_from')} ${
                    previousStep.FromDateTime
                } ${this.$t('short_before')} ${previousStep.BeforeDateTime}`
            } else if (previousStep.Moment === 'before') {
                times = `${this.$t('before')} ${previousStep.BeforeDateTime}`
            }

            if (update && !this.isOdd(update)) {
                const nextStep = this.formData.OrderSteps[update + 1]
                times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                if (nextStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        nextStep.FromDateTime
                    } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                } else if (nextStep.Moment === 'before') {
                    times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                }
            }
            if (update && this.isOdd(update) === 1) {
                const nextStep = this.formData.OrderSteps[update - 1]
                times = `${this.$t('short_from')} ${nextStep.FromDateTime}`
                if (nextStep.Moment === 'between') {
                    times = `${this.$t('short_from')} ${
                        nextStep.FromDateTime
                    } ${this.$t('short_before')} ${nextStep.BeforeDateTime}`
                } else if (nextStep.Moment === 'before') {
                    times = `${this.$t('before')} ${nextStep.BeforeDateTime}`
                }
            }

            return times
        },

        toCreateClient() {
            this.$cookies.remove('clientIdForUpdateFromOrder')

            this.$router.push({ name: 'new_client' })
        },
        toUpdateClient(id) {
            this.$cookies.set('clientIdForUpdateFromOrder', id)

            this.$router.push({
                name: 'update_client',
                params: { id },
            })
        },
        setMinMaxDates(stepIndex) {
            if (this.stepsToAdd[stepIndex - 1]) {
                this.minDate = this.stepsToAdd[stepIndex - 1].stepDate
            }
            if (this.stepsToAdd[stepIndex + 1]) {
                this.maxDate = this.stepsToAdd[stepIndex + 1].stepDate
            }
        },
        resetMinMaxDate(index) {
            this.minDate = new Date().toISOString().substr(0, 10)
            if (
                this.isOdd(index) !== 1 &&
                this.stepsToAdd[index + 1].stepDate !==
                    this.stepsToAdd[index].stepDate
            ) {
                this.maxDate = this.stepsToAdd[index + 1].stepDate
            } else {
                this.maxDate = ''
            }
        },
        setMinDate(stepIndex) {
            if (this.formData.OrderSteps[stepIndex - 1]) {
                this.minDate = this.formData.OrderSteps[stepIndex - 1].stepDate
            }
        },
        setMaxDate(stepIndex) {
            if (this.formData.OrderSteps[stepIndex + 1]) {
                this.maxDate = this.formData.OrderSteps[stepIndex + 1].stepDate
            }
        },
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            availableDriversForDate: 'availableDriversForDate',
        }),
        ...mapActions(useOrderStore, [
            'updateStep',
            'createDelivery',
            'updateOrderClient',
            'deleteStep',
            'updateDeliveryPrice',
        ]),
        ...mapActions(useRecurrentOrderStore, [
            'createRecurrentOrder',
            'updateRecurrentOrder',
            'updateRecurrentStep',
            'deleteRecurrentStep',
            'fetchRecurrentGroups',
        ]),
        ...mapActions(useVehicleStore, {
            availableVehiclesForDate: 'availableVehiclesForDate',
            searchVehicles: 'search',
        }),

        loadAvailableVehicles(date) {
            this.vehicleOptions = []
            const formattedDate = date.replace('-', '')
            if (this.recurrentOrder === false) {
                this.availableVehiclesForDate(
                    formattedDate.replace('-', ''),
                ).then((res) => {
                    if (res && res.status === 200) {
                        res.data.forEach((vehicle) => {
                            const name = `${vehicle.Name} - ${this.$t(
                                vehicle.Type,
                            )}`
                            const option = {
                                name,
                                value: +vehicle.Id,
                            }
                            if (!this.vehicleOptions.includes(option)) {
                                this.vehicleOptions.push(option)
                            }
                        })
                    }
                })
            } else {
                const vehiclesPayload = {
                    query: [],
                    filter: [{ dbField: 'Active', value: 1 }],
                    perPage: 100000000000,
                    page: 1,
                    sortField: 'Name',
                    sortDirection: 'ASC',
                }

                this.searchVehicles(vehiclesPayload).then((res) => {
                    if (res && res.status === 200) {
                        res.data.Records.forEach((vehicle) => {
                            const name = `${vehicle.Name} - ${this.$t(
                                vehicle.Type,
                            )}`
                            const option = {
                                name,
                                value: +vehicle.Id,
                            }
                            if (!this.vehicleOptions.includes(option)) {
                                this.vehicleOptions.push(option)
                            }
                        })
                    }
                })
            }
        },
        loadAvailableDrivers(date, refreshPrice = true, onuOnly = false) {
            const interval = setInterval(() => {
                if (this.formData.IdUniverse !== 0) {
                    clearInterval(interval)
                    this.driverOptions = []
                    if (this.recurrentOrder === false) {
                        // TODO uncomment when available call works
                        // const formattedDate = date.replace('-', '')

                        const payload = {
                            date,
                            onuOnly,
                            // start: `${start}:00`,
                            // end: `${end}:00`,
                            universes: this.formData.IdUniverse,
                        }
                        this.availableDriversForDate(payload).then((res) => {
                            if (res && res.status === 200 && res.data) {
                                res.data.forEach((driver) => {
                                    const name = `${driver.FirstName} ${driver.LastName}`
                                    const option = {
                                        name,
                                        value: +driver.Id,
                                        IsSubContractor:
                                            +driver.SubContractor === 1,
                                        isOnu: +driver.ONU === 1,
                                        subContractor:
                                            +driver.SubContractor === 1,
                                    }
                                    if (!this.driverOptions.includes(option)) {
                                        this.driverOptions.push(option)
                                    }
                                })
                            }
                        })
                    } else {
                        const driversPayload = {
                            query: [],
                            filter: [
                                { dbField: 'Active', value: 1 },
                                {
                                    dbField: 'UserUniverse.IdUniverse',
                                    value: [this.formData.IdUniverse],
                                },
                            ],
                            perPage: 100000000000,
                            page: 1,
                            sortField: 'LastName',
                            sortDirection: 'ASC',
                        }

                        this.searchDrivers(driversPayload).then((res) => {
                            if (res && res.status === 200) {
                                res.data.Records.forEach((driver) => {
                                    const name = `${driver.FirstName} ${driver.LastName}`
                                    const option = {
                                        name,
                                        value: +driver.Id,
                                        IsSubContractor:
                                            +driver.SubContractor === 1,
                                        isOnu: +driver.ONU === 1,
                                        subContractor:
                                            +driver.SubContractor === 1,
                                    }
                                    if (!this.driverOptions.includes(option)) {
                                        this.driverOptions.push(option)
                                    }
                                })
                            }
                        })
                    }
                    if (refreshPrice === true) {
                        this.liveRefresh()
                    }
                }
            }, 100)
        },
        isOdd(num) {
            return num % 2
        },
        stepStatusIcon(status) {
            let icon = 'circle-question'
            if (status === 'inProgress') {
                icon = 'truck-fast'
            }
            if (status === 'affected') {
                icon = 'route'
            }
            if (status === 'executed') {
                icon = 'flag-checkered'
            }
            if (status === 'affectationError') {
                icon = 'xmark'
            }
            return icon
        },
        resetFormsValidations() {
            this.v$.newStep.$reset()
            this.v$.formData.$reset()
            this.v$.clientAddressExtraInfo.$reset()
            this.v$.stepsToAdd.$reset()
            this.v$.recurrentForm.$reset()
            this.formData.OrderPackages.forEach((pack) => {
                pack.packageTypeErrors = []
                pack.packageValueErrors = []
                pack.packageWeightErrors = []
                pack.packageReferenceErrors = []
                pack.packageQuantityErrors = []

                pack.packageWidthErrors = []
                pack.packageLengthErrors = []
                pack.packageHeightErrors = []
                pack.containerNameError = false
            })
        },
        setDriverAndVehicleToOtherSteps(steps, wrongAddress = false) {
            steps.forEach((step, index) => {
                if (wrongAddress === false) {
                    if (!this.isOdd(index) && index !== steps.length - 1) {
                        steps[index + 1].IdDriver = step.IdDriver
                        steps[index + 1].IdVehicle = step.IdVehicle
                        // if (step.IsSubContractor === true) {
                        //     steps[index + 1].PurchasePrice = step.PurchasePrice
                        //     steps[index + 1].SellingPrice = step.SellingPrice
                        //     steps[index + 1].Margin = step.Margin

                        //     steps[index + 1].SendCharter = step.SendCharter
                        // }
                    }
                    if (this.roundTrip === true) {
                        step.IdDriver = steps[0].IdDriver
                        step.IdVehicle = steps[0].IdVehicle
                    }
                } else {
                    // delete step.Latitude
                    // delete step.Longitude
                    step.IdDriver = null
                    step.Status = 'new'
                    // wrongAddress = true
                    this.formData.IdDriver = null
                    // step.IdDriver = null
                    // step.Status = 'new'
                    step.IdVehicle = steps[0].IdVehicle
                }
            })
            return steps
        },
        removePackage(index) {
            this.formData.OrderPackages.splice(index, 1)
            this.refreshPacks += 1
            this.liveRefresh()
        },
        addPackage() {
            if (this.isDuplicate === false) {
                this.resetFormsValidations()
                this.formData.OrderPackages.push({
                    PackageNumber: this.formData.OrderPackages.length + 1,
                    Width: 0,
                    Length: 0,
                    Height: 0,
                    Weight: 0,
                    SameOpIndexes: [],
                    Value: 0,
                    PlombNumber: '',
                    ReferenceExternal: '',
                    Quantity: 1,
                    TemperatureOption: 'none',
                    IdContainer:
                        this.idContDefault > 0
                            ? this.idContDefault
                            : this.currentClient &&
                                this.currentClient.default_container > 0
                              ? this.currentClient.default_container
                              : this.userStore.current.env.default_container,
                    Id: '',
                    CreationUserId: this.user.id,
                    ModificatioUserId: this.user.id,
                    packageTypeErrors: [],
                    packageValueErrors: [],
                    packageWeightErrors: [],
                    packageReferenceErrors: [],
                    packageWidthErrors: [],
                    packageLengthErrors: [],
                    packageHeightErrors: [],
                    packageQuantityErrors: [],

                    containerNameError: false,
                })
                this.refreshPacks += 1
            }
        },
        deletePackage(packageIndex) {
            this.formData.OrderPackages.splice(packageIndex, 1)
            this.refreshPacks += 1
            this.liveRefresh()
        },
        setBreakForm(indexBefore = null) {
            if (indexBefore !== null) {
                this.breakForm = {
                    Type: 'delivery',
                    index: indexBefore + 1,
                    Status: this.formData.OrderSteps[indexBefore].IdDriver
                        ? 'affected'
                        : 'new',
                    Step: 'client',
                    StepNumber:
                        this.formData.OrderSteps[indexBefore].StepNumber + 1,
                    IdClientAddress: 0,
                    AddressName: '',
                    Address1: '',
                    Address2: '',
                    Address3: '',
                    Address4: '',
                    PostalCode: '',
                    City: '',
                    Country: 'FRA',
                    Latitude: '',
                    Longitude: '',
                    Moment: 'between',
                    FromDateTime: '08:00',
                    BeforeDateTime: '18:00',
                    TypeVehicle: '',
                    Hours: null,
                    IdVehicle: this.formData.OrderSteps[indexBefore].IdVehicle,
                    IdDriver: this.formData.OrderSteps[indexBefore].IdDriver,
                    stepDate: this.formData.OrderSteps[indexBefore].stepDate,
                    ContactPhone: '',
                    ContactFirstName: '',
                    ContactLastName: '',

                    ContactEmail: '',
                    AdditionalInformation: '',
                    DriverInformation: '',
                    IdDriverForNext: 0,
                    IdVehicleForNext: 0,
                    // PurchasePrice: 0,
                    // SellingPrice: 0,
                    // Margin: 0,
                }
            } else {
                this.breakForm = null
            }
            this.refresh += 1
            this.refresMS += 1
            this.showBreakForm = !this.showBreakForm
        },
        addBreak() {
            const newSteps = []
            let breakStepsAdded = false
            this.formData.OrderSteps.forEach((step, index) => {
                if (index === 0) {
                    newSteps.push(step)
                } else if (index === this.breakForm.index) {
                    newSteps.push(this.breakForm) // new deliv break

                    const pickBreakStep = structuredClone(this.breakForm)
                    pickBreakStep.Type = 'pickup'
                    pickBreakStep.IdDriver = +pickBreakStep.IdDriverForNext
                    pickBreakStep.IdVehicle = +pickBreakStep.IdVehicleForNext
                    pickBreakStep.Status = pickBreakStep.IdDriver
                        ? 'affected'
                        : 'new'
                    pickBreakStep.CommentStep = ''
                    pickBreakStep.StepNumber = +this.breakForm.StepNumber + 1
                    newSteps.push(pickBreakStep) // new pickup break
                    const stepUp = structuredClone(step)
                    stepUp.StepNumber = +this.breakForm.StepNumber + 2
                    stepUp.IdDriver = +pickBreakStep.IdDriverForNext
                    stepUp.IdVehicle = +pickBreakStep.IdVehicleForNext
                    stepUp.Status = pickBreakStep.IdDriver ? 'affected' : 'new'
                    newSteps.push(stepUp) // push the step after the break
                    breakStepsAdded = true
                } else if (breakStepsAdded === true) {
                    const stepUp = structuredClone(step)

                    stepUp.StepNumber =
                        newSteps[newSteps.length - 1].StepNumber + 1
                    newSteps.push(stepUp)
                } else {
                    newSteps.push(step)
                }
            })

            this.formData.OrderSteps = newSteps
        },
        addStepsToAdd(remove = false) {
            if (remove === true) {
                this.confirmDelete = null
                this.showDeleteConfirmModal = true
                this.deleteModalHeader = 'delete_modal_generic_header'
                this.deleteModalText = 'delete_all_unsaved_steps'
                this.elementToDelete = `delete_all_unsaved_steps_info`

                const deleteInterval = setInterval(() => {
                    if (this.confirmDelete !== null) {
                        clearInterval(deleteInterval)

                        if (this.confirmDelete === true) {
                            this.stepsToAdd = []
                        }
                        this.creating = false
                    }
                }, 150)
            } else {
                // const isValid = this.v$.stepsToAdd.$validate()
                // if (isValid) {
                const newPickup = this.stepsToAdd.length
                    ? structuredClone(
                          this.stepsToAdd[this.stepsToAdd.length - 1],
                      )
                    : structuredClone(
                          this.formData.OrderSteps[
                              this.formData.OrderSteps.length - 1
                          ],
                      )
                const realPrevPickUp = this.stepsToAdd.length
                    ? structuredClone(
                          this.stepsToAdd[this.stepsToAdd.length - 2],
                      )
                    : structuredClone(
                          this.formData.OrderSteps[
                              this.formData.OrderSteps.length - 2
                          ],
                      )
                if (
                    this.showForm === false &&
                    newPickup.IdClientAddress === 0
                ) {
                    this.$toast.warning(this.$t('previous_step_incomplete'))
                } else if (
                    this.showForm === false &&
                    realPrevPickUp.IdClientAddress === newPickup.IdClientAddress
                ) {
                    this.$toast.warning(this.$t('same_address'))
                } else {
                    if (this.addressOptions.length === 0) {
                        const clientAddressesPayload = {
                            clientId: this.formData.IdClient,
                            query: [],
                            filter: [],
                            perPage: 100000000000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }
                        this.clientStore
                            .fetchAddresses(clientAddressesPayload)
                            .then(() => {
                                const clientAddresses = setInterval(() => {
                                    if (this.clientAddresses) {
                                        this.clientAddresses.forEach(
                                            (address) => {
                                                clearInterval(clientAddresses)

                                                const option = {
                                                    name: address.name,
                                                    value: address.id,
                                                }
                                                if (
                                                    !this.addressOptions.includes(
                                                        option,
                                                    )
                                                ) {
                                                    this.addressOptions.push(
                                                        option,
                                                    )
                                                }
                                            },
                                        )
                                        this.refreshMS += 1
                                    }
                                }, 1000)
                            })
                    }
                    // const pickup = this.stepsToAdd.length
                    //     ? structuredClone(
                    //           this.stepsToAdd[this.stepsToAdd.length - 1]
                    //       )
                    //     : structuredClone(
                    //           this.formData.OrderSteps[
                    //               this.formData.OrderSteps.length - 1
                    //           ]
                    //       )
                    newPickup.Type = 'pickup'
                    newPickup.Id = ''
                    newPickup.IdVehicle = ''
                    newPickup.IdDriver = ''
                    newPickup.Status = 'new'

                    newPickup.StepNumber = +newPickup.StepNumber + 1
                    newPickup.IdClientAddress = +newPickup.IdClientAddress
                    const newDelivery = {
                        Type: 'delivery',
                        Status: 'new',
                        StepNumber: +newPickup.StepNumber + 1,
                        IdClientAddress: 0,
                        AddressName: '',
                        CommentStep: '',

                        Address1: '',
                        Address2: '',
                        Address3: '',
                        Address4: '',
                        PostalCode: '',
                        City: '',
                        Country: 'FRA',
                        Latitude: '',
                        Longitude: '',
                        Moment: 'between',
                        FromDateTime: '08:00',
                        BeforeDateTime: '18:00',
                        TypeVehicle: '',
                        Hours: null,
                        IdVehicle: '',
                        IdDriver: '',
                        stepDate: newPickup.stepDate,
                        ContactPhone: '',
                        ContactFirstName: '',
                        ContactLastName: '',
                        ContactEmail: '',
                        AdditionalInformation: '',
                        DriverInformation: '',
                        // PurchasePrice: 0,
                        // SellingPrice: 0,
                        // Margin: 0,
                    }
                    this.loadAvailableDrivers(newPickup.stepDate)
                    this.loadAvailableVehicles(newPickup.stepDate)

                    this.stepsToAdd.push(newPickup)
                    this.stepsToAdd.push(newDelivery)

                    this.refreshMS += 1
                    if (this.canLiveRefreshPrice === true) {
                        this.calculatePrice(true)
                    }
                }
                // } else {
                //     this.$toast.error('form_errors')
                // }
            }
        },
        showStepModal() {
            if (this.formData.OrderSteps.length) {
                this.setMinDate(this.formData.OrderSteps.length)
            }
            // rechercher les adresses uniquement du client si B2C
            const clientAddressesPayload = {
                clientId: this.formData.IdClient,
                query: [],
                filter: [],
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            if (!this.addressOptions.length) {
                this.clientStore
                    .fetchAddresses(clientAddressesPayload)
                    .then(() => {
                        const clientAddresses = setInterval(() => {
                            if (this.clientAddresses) {
                                this.clientAddresses.forEach((address) => {
                                    clearInterval(clientAddresses)

                                    const option = {
                                        name: address.name,
                                        value: address.id,
                                    }
                                    if (!this.addressOptions.includes(option)) {
                                        this.addressOptions.push(option)
                                    }
                                })
                            }
                        }, 1000)
                    })
            }
            this.loadGoogleScript('autocompleteAddStep')

            this.newStep = {
                Type:
                    this.formData.OrderSteps.length &&
                    this.isOdd(this.formData.OrderSteps.length)
                        ? 'delivery'
                        : 'pickup',
                Status: 'new',
                StepNumber: this.formData.OrderSteps.length + 1,
                IdClientAddress: 0,
                AddressName: '',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                PostalCode: '',
                City: '',
                Country: 'FRA',
                Latitude: '',
                Longitude: '',
                Moment: 'between',
                FromDateTime: '08:00',
                BeforeDateTime: '18:00',
                TypeVehicle: '',
                Hours: null,
                IdVehicle: '',
                IdDriver: '',
                stepDate: this.minDate,
                ContactPhone: '',
                ContactFirstName: '',
                ContacLastName: '',

                ContactEmail: '',
                AdditionalInformation: '',
                DriverInformation: '',
                // PurchasePrice: 0,
                // SellingPrice: 0,
                // Margin: 0,
            }
            this.show = true
        },
        updateStp(step) {
            this.maxDate = ''
            this.minDate = this.date.toISOString().substr(0, 10)

            this.setMaxDate(step)
            this.setMinDate(step)
            const clientAddressesPayload = {
                clientId:
                    this.isB2C === true
                        ? this.currentClient.id
                        : this.formData.IdClient,
                query: [],
                filter: [],
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            this.clientStore.fetchAddresses(clientAddressesPayload).then(() => {
                const clientAddresses = setInterval(() => {
                    if (this.clientAddresses) {
                        this.clientAddresses.forEach((address) => {
                            clearInterval(clientAddresses)

                            const option = {
                                name: address.name,
                                value: address.id,
                            }
                            if (!this.addressOptions.includes(option)) {
                                this.addressOptions.push(option)
                            }
                        })
                    }
                }, 1000)
            })
            // const vm = this
            this.loadGoogleScript('autocompleteAddStep')
            this.newStep = structuredClone(this.formData.OrderSteps[step])
            if (
                this.newStep.stepDate === '0000-00-00' &&
                this.recurrentOrder === true
            ) {
                if (this.minDate === '0000-00-00') {
                    this.minDate = new Date().toISOString().substr(0, 10)
                }
                this.newStep.stepDate = this.minDate
            }

            this.stepToUpdate = step
            this.show = true
        },

        async arePackagesValid() {
            let result = true
            this.formData.OrderPackages.forEach((pack) => {
                if (result === true) {
                    if (
                        pack.IdContainer === '' ||
                        pack.IdContainer === null ||
                        +pack.IdContainer === 0 ||
                        pack.IdContainer === undefined
                    ) {
                        pack.packageTypeErrors.push('package error container')
                        result = false
                    }
                    // if (pack.Reference === '' || pack.Reference === null) {
                    //     pack.packageReferenceErrors.push(
                    //         'package error reference'
                    //     )

                    //     result = false
                    // }
                    // if (pack.Quantity === 0) {
                    //     pack.packageQuantityErrors.push('package error value')

                    // }
                    if (
                        pack.Weight === 0 ||
                        pack.Weight === null ||
                        pack.Weight === '' ||
                        pack.Weight === undefined
                    ) {
                        pack.packageWeightErrors.push('package error weight')

                        result = false
                    }
                    if (
                        pack.Width === 0 ||
                        pack.Width === null ||
                        pack.Width === '' ||
                        pack.Width === undefined
                    ) {
                        pack.packageWidthErrors.push('package error width')

                        result = false
                    }
                    if (
                        pack.Length === 0 ||
                        pack.Length === null ||
                        pack.Length === '' ||
                        pack.Length === undefined
                    ) {
                        pack.packageLengthErrors.push('package error length')

                        result = false
                    }
                    if (
                        pack.Quantity === 0 ||
                        pack.Quantity === null ||
                        pack.Quantity === '' ||
                        pack.Quantity === undefined
                    ) {
                        pack.packageQuantityErrors.push(
                            'package error quantity',
                        )

                        result = false
                    }
                    if (pack.Height === 0) {
                        pack.packageHeightErrors.push('package error height')

                        result = false
                    }
                }
            })
            this.refreshMS += 1

            // this.packageValueErrors.length === 0 &&
            return result
        },
        deleteStp(step, id) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `delete_steps_pair_info`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        const stepsToDelete = [
                            id,
                            this.formData.OrderSteps[step + 1].Id,
                        ]
                        this.formData.OrderSteps.splice(step, 2)
                        stepsToDelete.forEach((stpId) => {
                            if (this.recurrentOrder === false) {
                                this.deleteStep(stpId)
                                this.roundTrip = false
                            } else {
                                this.deleteRecurrentStep(stpId, true)
                                this.roundTrip = false
                            }
                        })
                    }
                    this.creating = false
                    if (this.canLiveRefreshPrice === true) {
                        this.calculatePrice(true)
                    }
                }
            }, 150)

            // const stepsToDelete = [id, this.formData.OrderSteps[step + 1].Id]
            // this.formData.OrderSteps.splice(step, 2)
            // stepsToDelete.forEach((stpId) => {
            //     this.deleteStep(stpId)
            // })
        },
        setBreakAddressInfo(delivBreakIndex, addressId) {
            this.clientStore.fetchOneAddress({ id: addressId, break: true })
            const breakAddressInterval = setInterval(() => {
                if (this.breakAddress) {
                    clearInterval(breakAddressInterval)
                    this.breakForm.AddressName = this.breakAddress.name
                    this.breakForm.Address1 = this.breakAddress.address
                    this.breakForm.Address2 = this.breakAddress.address2 || ''
                    this.breakForm.Address3 = this.breakAddress.address3 || ''
                    this.breakForm.Address4 = this.breakAddress.address4 || ''
                    this.breakForm.PostalCode = this.breakAddress.postal_code
                    this.breakForm.City = this.breakAddress.city
                    this.breakForm.Country = 'FRA'
                    this.breakForm.Latitude = this.breakAddress.lat
                    this.breakForm.Longitude = this.breakAddress.lng
                    this.breakForm.ContactPhone =
                        this.breakAddress.contact_phone
                    this.breakForm.ContactEmail =
                        this.breakAddress.contact_email
                    this.breakForm.ContactFirstName =
                        this.breakAddress.contact_firstname
                    this.breakForm.ContactLastName =
                        this.breakAddress.contact_lastname

                    this.breakForm.AdditionalInformation =
                        this.breakAddress.additional_info
                    this.breakForm.DriverInformation =
                        this.breakAddress.driver_info
                }
            }, 1000)
        },
        setAddressInfo(addressId, newStepIndex = null) {
            this.clientStore.fetchOneAddress({ id: addressId })
            const currentAddressInterval = setInterval(() => {
                if (this.currentDeliveryAddress) {
                    clearInterval(currentAddressInterval)
                    if (newStepIndex === null) {
                        this.newStep = {
                            Type: this.newStep.Type ?? '',
                            // Type: this.currentDeliveryAddress.address_type ==? this.currentDeliveryAddress.address_type : 'pickup',
                            Status: 'new',
                            ContactPhone:
                                this.currentDeliveryAddress.contact_phone,
                            ContacLastName:
                                this.currentDeliveryAddress.contact_lastname,
                            ContactFirstName:
                                this.currentDeliveryAddress.contact_firstname,
                            ContactEmail:
                                this.currentDeliveryAddress.contact_email,
                            AdditionalInformation:
                                this.currentDeliveryAddress.additional_info,
                            DriverInformation:
                                this.currentDeliveryAddress.driver_info,
                            StepNumber:
                                this.newStep.StepNumber ??
                                this.formData.OrderSteps.length + 1,
                            IdClientAddress: this.currentDeliveryAddress.id,
                            AddressName: this.currentDeliveryAddress.name,
                            Address1: this.currentDeliveryAddress.address,
                            Address2:
                                this.currentDeliveryAddress.address2 || '',
                            Address3:
                                this.currentDeliveryAddress.address3 || '',
                            Address4:
                                this.currentDeliveryAddress.address4 || '',
                            PostalCode: this.currentDeliveryAddress.postal_code,
                            City: this.currentDeliveryAddress.city,
                            Country: 'FRA',
                            Latitude: this.currentDeliveryAddress.lat,
                            Longitude: this.currentDeliveryAddress.lng,
                            Moment: this.newStep.Moment ?? 'between',
                            FromDateTime: this.newStep.FromDateTime ?? '08:00',
                            BeforeDateTime:
                                this.newStep.BeforeDatetime ?? '18:00',
                            TypeVehicle: this.newStep.TypeVehicle ?? '',
                            IdDriver: this.newStep.IdDriver ?? '',
                            IdVehicle: this.newStep.IdVehicle ?? '',
                            Hours: this.currentDeliveryAddress.hours ?? null,
                            PurchasePrice: this.newStep.PurchasePrice,
                            SellingPrice: this.newStep.SellingPrice,
                            Margin: this.newStep.Margin,
                            stepDate:
                                this.newStep.stepDate ??
                                new Date().toISOString().substr(0, 10),
                            Id: this.newStep.Id ?? '',
                        }
                    } else {
                        this.stepsToAdd[newStepIndex].IdClientAddress =
                            this.currentDeliveryAddress.id
                        this.stepsToAdd[newStepIndex].AddressName =
                            this.currentDeliveryAddress.name
                        this.stepsToAdd[newStepIndex].ContactPhone =
                            this.currentDeliveryAddress.contact_phone
                        this.stepsToAdd[newStepIndex].ContactEmail =
                            this.currentDeliveryAddress.contact_email
                        this.stepsToAdd[newStepIndex].ContactFirstName =
                            this.currentDeliveryAddress.contact_firstname
                        this.stepsToAdd[newStepIndex].ContactLastName =
                            this.currentDeliveryAddress.contact_lastname
                        this.stepsToAdd[newStepIndex].Address1 =
                            this.currentDeliveryAddress.address
                        this.stepsToAdd[newStepIndex].Address2 =
                            this.currentDeliveryAddress.address2 || ''
                        this.stepsToAdd[newStepIndex].Address3 =
                            this.currentDeliveryAddress.address3 || ''
                        this.stepsToAdd[newStepIndex].Address4 =
                            this.currentDeliveryAddress.address4 || ''
                        this.stepsToAdd[newStepIndex].PostalCode =
                            this.currentDeliveryAddress.postal_code
                        this.stepsToAdd[newStepIndex].City =
                            this.currentDeliveryAddress.city
                        this.stepsToAdd[newStepIndex].Country =
                            this.currentDeliveryAddress.country
                        this.stepsToAdd[newStepIndex].Latitude =
                            this.currentDeliveryAddress.lat
                        this.stepsToAdd[newStepIndex].Longitude =
                            this.currentDeliveryAddress.lng
                        this.stepsToAdd[newStepIndex].ContactPhone =
                            this.currentDeliveryAddress.contact_phone
                        this.stepsToAdd[newStepIndex].ContactEmail =
                            this.currentDeliveryAddress.contact_email
                        this.stepsToAdd[newStepIndex].ContactFirstName =
                            this.currentDeliveryAddress.contact_firstname
                        this.stepsToAdd[newStepIndex].ContactLastName =
                            this.currentDeliveryAddress.contact_lastname
                        this.stepsToAdd[newStepIndex].AdditionalInformation =
                            this.currentDeliveryAddress.additional_info
                        this.stepsToAdd[newStepIndex].DriverInformation =
                            this.currentDeliveryAddress.driver_info
                        this.stepsToAdd[newStepIndex].TypeVehicle =
                            this.stepsToAdd[newStepIndex].TypeVehicle === ''
                                ? null
                                : this.formData.VehicleType
                        // this.stepsToAdd[newStepIndex].PurchasePrice = 0
                        // this.stepsToAdd[newStepIndex].SellingPrice = 0
                        // this.stepsToAdd[newStepIndex].Margin = 0
                        // Type: this.stepsToAdd[newStepIndex].Type ?? '',
                        // // Type: this.currentDeliveryAddress.address_type ==? this.currentDeliveryAddress.address_type : 'pickup',
                        // Status: 'new',
                        // StepNumber:
                        //     this.stepsToAdd[newStepIndex].StepNumber ??
                        //     this.formData.OrderSteps.length + 1,
                        // Moment:
                        //     this.stepsToAdd[newStepIndex].Moment ??
                        //     'between',
                        // FromDateTime:
                        //     this.stepsToAdd[newStepIndex].FromDateTime ??
                        //     '08:00',
                        // BeforeDateTime:
                        //     this.stepsToAdd[newStepIndex].BeforeDatetime ??
                        //     '18:00',
                        // TypeVehicle:
                        //     this.stepsToAdd[newStepIndex].TypeVehicle ?? '',
                        // IdDriver:
                        //     this.stepsToAdd[newStepIndex].IdDriver ?? '',
                        // IdVehicle:
                        //     this.stepsToAdd[newStepIndex].IdVehicle ?? '',
                        // Hours: this.currentDeliveryAddress.hours ?? null,

                        // stepDate:
                        //     this.stepsToAdd[newStepIndex].stepDate ??
                        //     new Date().toISOString().substr(0, 10),
                        // Id: this.stepsToAdd[newStepIndex].Id ?? '',
                    }
                    if (
                        this.roundTrip === true &&
                        this.$route.name === 'new_order'
                    ) {
                        if (this.stepsToAdd[newStepIndex].StepNumber === 1) {
                            this.stepsToAdd[3].IdClientAddress =
                                this.currentDeliveryAddress.id
                            this.stepsToAdd[3].AddressName =
                                this.currentDeliveryAddress.name
                            this.stepsToAdd[3].Address1 =
                                this.currentDeliveryAddress.address
                            this.stepsToAdd[3].Address2 =
                                this.currentDeliveryAddress.address2 || ''
                            this.stepsToAdd[3].Address3 =
                                this.currentDeliveryAddress.address3 || ''
                            this.stepsToAdd[3].Address4 =
                                this.currentDeliveryAddress.address4 || ''
                            this.stepsToAdd[3].PostalCode =
                                this.currentDeliveryAddress.postal_code
                            this.stepsToAdd[3].City =
                                this.currentDeliveryAddress.city
                            this.stepsToAdd[3].Country =
                                this.currentDeliveryAddress.country
                            this.stepsToAdd[3].Latitude =
                                this.currentDeliveryAddress.lat
                            this.stepsToAdd[3].Longitude =
                                this.currentDeliveryAddress.lng
                            this.stepsToAdd[3].ContactPhone =
                                this.currentDeliveryAddress.contact_phone
                            this.stepsToAdd[3].ContactEmail =
                                this.currentDeliveryAddress.contact_email
                            this.stepsToAdd[3].ContactLastName =
                                this.currentDeliveryAddress.contact_lastname
                            this.stepsToAdd[3].ContactFirstName =
                                this.currentDeliveryAddress.contact_firstname
                            this.stepsToAdd[3].AdditionalInformation =
                                this.currentDeliveryAddress.additional_info
                            this.stepsToAdd[3].DriverInformation =
                                this.currentDeliveryAddress.driver_info
                            // this.stepsToAdd[3].PurchasePrice = 0
                            // this.stepsToAdd[3].SellingPrice = 0
                            // this.stepsToAdd[3].Margin = 0
                        }
                        if (this.stepsToAdd[newStepIndex].StepNumber === 2) {
                            this.stepsToAdd[2].IdClientAddress =
                                this.currentDeliveryAddress.id
                            this.stepsToAdd[2].AddressName =
                                this.currentDeliveryAddress.name
                            this.stepsToAdd[2].Address1 =
                                this.currentDeliveryAddress.address
                            this.stepsToAdd[2].Address2 =
                                this.currentDeliveryAddress.address2 || ''
                            this.stepsToAdd[2].Address3 =
                                this.currentDeliveryAddress.address3 || ''
                            this.stepsToAdd[2].Address4 =
                                this.currentDeliveryAddress.address4 || ''
                            this.stepsToAdd[2].PostalCode =
                                this.currentDeliveryAddress.postal_code
                            this.stepsToAdd[2].City =
                                this.currentDeliveryAddress.city
                            this.stepsToAdd[2].Country =
                                this.currentDeliveryAddress.country
                            this.stepsToAdd[2].Latitude =
                                this.currentDeliveryAddress.lat
                            this.stepsToAdd[2].Longitude =
                                this.currentDeliveryAddress.lng
                            this.stepsToAdd[2].ContactPhone =
                                this.currentDeliveryAddress.contact_phone
                            this.stepsToAdd[2].ContactLastName =
                                this.currentDeliveryAddress.contact_lastname
                            this.stepsToAdd[2].ContactFirstName =
                                this.currentDeliveryAddress.contact_firstname
                            this.stepsToAdd[2].ContactEmail =
                                this.currentDeliveryAddress.contact_email
                            this.stepsToAdd[2].AdditionalInformation =
                                this.currentDeliveryAddress.additional_info
                            this.stepsToAdd[2].DriverInformation =
                                this.currentDeliveryAddress.driver_info
                            // this.stepsToAdd[2].PurchasePrice = 0
                            // this.stepsToAdd[2].SellingPrice = 0
                            // this.stepsToAdd[2].Margin = 0
                        }
                    }
                }
                if (this.canLiveRefreshPrice === true) {
                    this.calculatePrice(true)
                }
            }, 1000)
        },
        breakFormTimesAreCorrect(index) {
            this.resetBadTimes()
            this.badTimeRange = false
            this.badTimeRangeItem = null
            const stpToCompare = this.$route.params.id
                ? this.formData.OrderSteps[index]
                : this.stepsToAdd[index]
            if (
                (this.breakForm.FromDateTime > this.breakForm.BeforeDateTime ||
                    this.breakForm.FromDateTime ===
                        this.breakForm.BeforeDateTime) &&
                this.breakForm.Moment === 'between'
            ) {
                this.badTimeRange = true
                this.badTimeRangeItem = this.breakForm
            }
            this.badTimeRangeSteps = false
            if (
                this.breakForm.stepDate === stpToCompare.stepDate &&
                (((stpToCompare.Moment === 'from' ||
                    stpToCompare.Moment === 'between') &&
                    this.breakForm.Moment === 'before' &&
                    stpToCompare.FromDateTime >=
                        this.breakForm.BeforeDateTime) ||
                    (stpToCompare.Moment === 'from' &&
                        this.breakForm.Moment === 'between' &&
                        stpToCompare.FromDateTime >=
                            this.breakForm.BeforeDateTime) ||
                    (stpToCompare.Moment === 'between' &&
                        this.breakForm.Moment === 'between' &&
                        ((stpToCompare.FromDateTime >=
                            this.breakForm.BeforeDateTime &&
                            stpToCompare.FromDateTime >=
                                this.breakForm.FromDateTime) ||
                            stpToCompare.FromDateTime >=
                                this.breakForm.BeforeDateTime)))
            ) {
                this.badTimeRangeSteps = true
            }

            const nextStpToCompare = this.$route.params.id
                ? this.formData.OrderSteps[index + 1]
                : this.stepsToAdd[index + 1]
            this.delivBeforePickUp = false
            if (
                this.breakForm.stepDate === nextStpToCompare.stepDate &&
                (((nextStpToCompare.Moment === 'before' ||
                    nextStpToCompare.Moment === 'between') &&
                    this.breakForm.Moment === 'from' &&
                    nextStpToCompare.BeforeDateTime <=
                        this.breakForm.FromDateTime) ||
                    (nextStpToCompare.Moment === 'before' &&
                        this.breakForm.Moment === 'between' &&
                        nextStpToCompare.BeforeDateTime <=
                            this.breakForm.FromDateTime) ||
                    (nextStpToCompare.Moment === 'between' &&
                        this.breakForm.Moment === 'between' &&
                        ((nextStpToCompare.FromDateTime <=
                            this.breakForm.BeforeDateTime &&
                            nextStpToCompare.FromDateTime <=
                                this.breakForm.FromDateTime) ||
                            nextStpToCompare.FromDateTime <=
                                this.breakForm.BeforeDateTime)))
            ) {
                this.delivBeforePickUp = true
            }
            // vérifier le step d'après aussi
        },
        timesAreCorrect(breakForm = false, stepToUpdate = null) {
            this.resetBadTimes()
            this.badTimeRange = false
            this.badTimeRangeItem = null
            // const isValid = await this.v$.stepsToAdd.$validate()
            if (
                (this.stepsToAdd[stepToUpdate].FromDateTime >
                    this.stepsToAdd[stepToUpdate].BeforeDateTime ||
                    this.stepsToAdd[stepToUpdate].FromDateTime ===
                        this.stepsToAdd[stepToUpdate].BeforeDateTime) &&
                this.stepsToAdd[stepToUpdate].Moment === 'between'
            ) {
                this.badTimeRange = true
                this.badTimeRangeItem = this.stepsToAdd[stepToUpdate]
            }

            if (!this.isOdd(this.stepsToAdd[stepToUpdate].StepNumber)) {
                this.badTimeRangeSteps = false
                const previousStep = this.stepsToAdd[stepToUpdate - 1]
                // console.log(
                //     this.stepsToAdd[stepToUpdate].stepDate ===
                //         previousStep.stepDate,
                // )
                if (
                    this.stepsToAdd[stepToUpdate].stepDate ===
                        previousStep.stepDate &&
                    (((previousStep.Moment === 'from' ||
                        previousStep.Moment === 'between') &&
                        this.stepsToAdd[stepToUpdate].Moment === 'before' &&
                        previousStep.FromDateTime >=
                            this.stepsToAdd[stepToUpdate].BeforeDateTime) ||
                        (previousStep.Moment === 'from' &&
                            this.stepsToAdd[stepToUpdate].Moment ===
                                'between' &&
                            previousStep.FromDateTime >=
                                this.stepsToAdd[stepToUpdate].BeforeDateTime) ||
                        (previousStep.Moment === 'between' &&
                            this.stepsToAdd[stepToUpdate].Moment ===
                                'between' &&
                            ((previousStep.FromDateTime >=
                                this.stepsToAdd[stepToUpdate].BeforeDateTime &&
                                previousStep.FromDateTime >=
                                    this.stepsToAdd[stepToUpdate]
                                        .FromDateTime) ||
                                previousStep.FromDateTime >=
                                    this.stepsToAdd[stepToUpdate]
                                        .BeforeDateTime)))
                ) {
                    this.badTimeRangeSteps = true
                }
            }

            if (
                this.isOdd(this.stepsToAdd[stepToUpdate].StepNumber) === 1 &&
                stepToUpdate !== null
            ) {
                this.delivBeforePickUp = false
                const nextStep = this.stepsToAdd[stepToUpdate + 1]
                if (
                    this.stepsToAdd[stepToUpdate].stepDate ===
                        nextStep.stepDate &&
                    (((this.stepsToAdd[stepToUpdate].Moment === 'from' ||
                        this.stepsToAdd[stepToUpdate].Moment === 'between') &&
                        nextStep.Moment === 'before' &&
                        this.stepsToAdd[stepToUpdate].FromDateTime >=
                            nextStep.BeforeDateTime) ||
                        (this.stepsToAdd[stepToUpdate].Moment === 'from' &&
                            nextStep.Moment === 'between' &&
                            this.stepsToAdd[stepToUpdate].FromDateTime >=
                                nextStep.BeforeDateTime) ||
                        (this.stepsToAdd[stepToUpdate].Moment === 'between' &&
                            nextStep.Moment === 'between' &&
                            ((this.stepsToAdd[stepToUpdate].FromDateTime >=
                                nextStep.BeforeDateTime &&
                                this.stepsToAdd[stepToUpdate].FromDateTime >=
                                    nextStep.FromDateTime) ||
                                this.stepsToAdd[stepToUpdate].FromDateTime >=
                                    nextStep.BeforeDateTime)))
                ) {
                    this.delivBeforePickUp = true
                }
            }
        },
        async submitModal(addNew = false, stepToUpdate = null) {
            this.badTimeRange = false
            const loadDriverStart = this.newStep.FromDateTime
            const loadDriverEnd = this.newStep.BeforeDateTime
            const isValid = await this.v$.newStep.$validate()
            if (
                (this.newStep.FromDateTime > this.newStep.BeforeDateTime ||
                    this.newStep.FromDateTime ===
                        this.newStep.BeforeDateTime) &&
                this.newStep.Moment === 'between'
            ) {
                this.badTimeRange = true
            }

            if (!this.isOdd(this.newStep.StepNumber)) {
                this.badTimeRangeSteps = false
                const previousStep =
                    this.formData.OrderSteps[
                        this.formData.OrderSteps.length - 1
                    ]

                if (
                    ((previousStep.Moment === 'from' ||
                        previousStep.Moment === 'between') &&
                        this.newStep.Moment === 'before' &&
                        previousStep.FromDateTime >=
                            this.newStep.BeforeDateTime) ||
                    (previousStep.Moment === 'from' &&
                        this.newStep.Moment === 'between' &&
                        previousStep.FromDateTime >=
                            this.newStep.BeforeDateTime) ||
                    (previousStep.Moment === 'between' &&
                        this.newStep.Moment === 'between' &&
                        ((previousStep.FromDateTime >=
                            this.newStep.BeforeDateTime &&
                            previousStep.FromDateTime >=
                                this.newStep.FromDateTime) ||
                            previousStep.FromDateTime >=
                                this.newStep.BeforeDateTime))
                ) {
                    this.badTimeRangeSteps = true
                }
            }

            if (
                this.isOdd(this.newStep.StepNumber) === 1 &&
                stepToUpdate !== null
            ) {
                this.delivBeforePickUp = false
                const nextStep = this.formData.OrderSteps[stepToUpdate + 1]
                if (
                    ((this.newStep.Moment === 'from' ||
                        this.newStep.Moment === 'between') &&
                        nextStep.Moment === 'before' &&
                        this.newStep.FromDateTime >= nextStep.BeforeDateTime) ||
                    (this.newStep.Moment === 'from' &&
                        nextStep.Moment === 'between' &&
                        this.newStep.FromDateTime >= nextStep.BeforeDateTime) ||
                    (this.newStep.Moment === 'between' &&
                        nextStep.Moment === 'between' &&
                        ((this.newStep.FromDateTime >=
                            nextStep.BeforeDateTime &&
                            this.newStep.FromDateTime >=
                                nextStep.FromDateTime) ||
                            this.newStep.FromDateTime >=
                                nextStep.BeforeDateTime))
                ) {
                    this.delivBeforePickUp = true
                }
                // !! REMOVE BELOW ELSE WHEN DONE
            }
            if (
                isValid &&
                addNew &&
                this.badTimeRange === false &&
                this.badTimeRangeSteps === false &&
                this.delivBeforePickUp === false
            ) {
                this.newStep.CreationUserId =
                    this.formData.OrderSteps[stepToUpdate] &&
                    this.formData.OrderSteps[stepToUpdate].CreationUserId
                        ? this.formData.OrderSteps[stepToUpdate].CreationUserId
                        : this.user.id
                if (stepToUpdate !== null) {
                    this.formData.OrderSteps[stepToUpdate] = this.newStep
                } else {
                    this.formData.OrderSteps.push(this.newStep)
                }
                if (this.formData.OrderSteps.length === 1) {
                    this.formData.DateBegin = this.newStep.stepDate
                    this.formData.DateEnd = this.newStep.stepDate
                } else {
                    this.formData.DateEnd = this.newStep.stepDate
                }

                // this.closeModal()
                if (
                    this.$route.name === 'update_order' ||
                    (this.$route.name === 'update_recurrent' &&
                        stepToUpdate !== null &&
                        this.formData.OrderSteps[stepToUpdate])
                ) {
                    const formDataCopy = structuredClone(
                        this.formData.OrderSteps[stepToUpdate],
                    )
                    let from = `${formDataCopy.stepDate} ${formDataCopy.FromDateTime}`

                    let before = `${formDataCopy.stepDate} ${formDataCopy.BeforeDateTime}`
                    if (formDataCopy.Moment === 'between') {
                        formDataCopy.FromDateTime = from
                        formDataCopy.BeforeDateTime = before
                    } else if (formDataCopy.Moment === 'from') {
                        formDataCopy.FromDateTime = from
                        formDataCopy.BeforeDateTime = ''
                    } else if (formDataCopy.Moment === 'before') {
                        formDataCopy.FromDateTime = ''
                        formDataCopy.BeforeDateTime = before
                    }
                    let otherStepToUpdate = null
                    if (this.roundTrip === true) {
                        if (+formDataCopy.StepNumber === 1) {
                            otherStepToUpdate = structuredClone(
                                this.formData.OrderSteps[3],
                            )
                            otherStepToUpdate.IdClientAddress =
                                this.currentDeliveryAddress.id
                            otherStepToUpdate.AddressName =
                                this.currentDeliveryAddress.name
                            otherStepToUpdate.Address1 =
                                this.currentDeliveryAddress.address
                            otherStepToUpdate.Address2 =
                                this.currentDeliveryAddress.address2 || ''
                            otherStepToUpdate.Address3 =
                                this.currentDeliveryAddress.address3 || ''
                            otherStepToUpdate.Address4 =
                                this.currentDeliveryAddress.address4 || ''
                            otherStepToUpdate.PostalCode =
                                this.currentDeliveryAddress.postal_code
                            otherStepToUpdate.City =
                                this.currentDeliveryAddress.city
                            otherStepToUpdate.Country =
                                this.currentDeliveryAddress.country
                            otherStepToUpdate.Latitude =
                                this.currentDeliveryAddress.lat
                            otherStepToUpdate.Longitude =
                                this.currentDeliveryAddress.lng
                            otherStepToUpdate.ContactPhone =
                                this.currentDeliveryAddress.contact_phone
                            otherStepToUpdate.ContactFirstName =
                                this.currentDeliveryAddress.contact_firstname
                            otherStepToUpdate.ContactLastName =
                                this.currentDeliveryAddress.contact_lastname
                            otherStepToUpdate.ContactEmail =
                                this.currentDeliveryAddress.contact_email
                            otherStepToUpdate.TypeVehicle =
                                otherStepToUpdate.TypeVehicle ?? ''
                            otherStepToUpdate.AdditionalInformation =
                                this.currentDeliveryAddress.additional_info
                            otherStepToUpdate.DriverInformation =
                                this.currentDeliveryAddress.driver_info

                            this.formData.OrderSteps[3] =
                                structuredClone(otherStepToUpdate)
                        }
                        if (+formDataCopy.StepNumber === 2) {
                            otherStepToUpdate = structuredClone(
                                this.formData.OrderSteps[2],
                            )

                            otherStepToUpdate.IdClientAddress =
                                this.currentDeliveryAddress.id
                            otherStepToUpdate.AddressName =
                                this.currentDeliveryAddress.name
                            otherStepToUpdate.Address1 =
                                this.currentDeliveryAddress.address
                            otherStepToUpdate.Address2 =
                                this.currentDeliveryAddress.address2 || ''
                            otherStepToUpdate.Address3 =
                                this.currentDeliveryAddress.address3 || ''
                            otherStepToUpdate.Address4 =
                                this.currentDeliveryAddress.address4 || ''
                            otherStepToUpdate.PostalCode =
                                this.currentDeliveryAddress.postal_code
                            otherStepToUpdate.City =
                                this.currentDeliveryAddress.city
                            otherStepToUpdate.Country =
                                this.currentDeliveryAddress.country
                            otherStepToUpdate.Latitude =
                                this.currentDeliveryAddress.lat
                            otherStepToUpdate.Longitude =
                                this.currentDeliveryAddress.lng
                            otherStepToUpdate.TypeVehicle =
                                otherStepToUpdate.TypeVehicle ?? ''
                            otherStepToUpdate.ContactPhone =
                                this.currentDeliveryAddress.contact_phone
                            otherStepToUpdate.ContactEmail =
                                this.currentDeliveryAddress.contact_email
                            otherStepToUpdate.ContactFirstName =
                                this.currentDeliveryAddress.contact_firstname
                            otherStepToUpdate.ContactLastName =
                                this.currentDeliveryAddress.contact_lastname
                            otherStepToUpdate.AdditionalInformation =
                                this.currentDeliveryAddress.additional_info
                            otherStepToUpdate.DriverInformation =
                                this.currentDeliveryAddress.driver_info
                            // otherStepToUpdate = otherStepToUpdate
                            this.formData.OrderSteps[2] =
                                structuredClone(otherStepToUpdate)
                        }
                        from = `${otherStepToUpdate.stepDate} ${otherStepToUpdate.FromDateTime}`

                        before = `${otherStepToUpdate.stepDate} ${otherStepToUpdate.BeforeDateTime}`
                        if (otherStepToUpdate.Moment === 'between') {
                            otherStepToUpdate.FromDateTime = from
                            otherStepToUpdate.BeforeDateTime = before
                        } else if (otherStepToUpdate.Moment === 'from') {
                            otherStepToUpdate.FromDateTime = from
                            otherStepToUpdate.BeforeDateTime = ''
                        } else if (otherStepToUpdate.Moment === 'before') {
                            otherStepToUpdate.FromDateTime = ''
                            otherStepToUpdate.BeforeDateTime = before
                        }
                    }
                    if (this.recurrentOrder === false) {
                        this.updateStep({
                            id: formDataCopy.Id,
                            form: formDataCopy,
                        }).then(() => {
                            if (otherStepToUpdate !== null) {
                                this.updateStep({
                                    id: otherStepToUpdate.Id,
                                    form: otherStepToUpdate,
                                }).then(() => {
                                    this.vehicleOptions = []
                                    this.loadAvailableVehicles(
                                        this.newStep.stepDate,
                                    )
                                    this.driverOptions = []
                                    // TODO STOCKER LES HEURES
                                    this.loadAvailableDrivers(
                                        this.newStep.stepDate,
                                    )
                                    this.closeModal()

                                    this.refreshSteps += 1
                                })
                            } else {
                                this.vehicleOptions = []
                                this.loadAvailableVehicles(
                                    this.newStep.stepDate,
                                )
                                this.driverOptions = []
                                this.loadAvailableDrivers(this.newStep.stepDate)
                                this.closeModal()

                                this.refreshSteps += 1
                            }
                        })
                    } else {
                        // formDataCopy.IdRecurrent =
                        //     this.formData.OrderSteps[stepToUpdate].IdRecurrent
                        this.updateRecurrentStep({
                            id: formDataCopy.Id,
                            form: formDataCopy,
                        }).then(() => {
                            if (otherStepToUpdate !== null) {
                                this.updateRecurrentStep({
                                    id: otherStepToUpdate.Id,
                                    form: otherStepToUpdate,
                                }).then(() => {
                                    this.vehicleOptions = []
                                    this.loadAvailableVehicles(
                                        this.newStep.stepDate,
                                    )
                                    this.driverOptions = []
                                    this.loadAvailableDrivers(
                                        this.newStep.stepDate,
                                    )
                                    this.closeModal()
                                    this.refresh += 1
                                    this.refreshSteps += 1
                                })
                            } else {
                                this.vehicleOptions = []
                                this.loadAvailableVehicles(
                                    this.newStep.stepDate,
                                )
                                this.driverOptions = []
                                this.loadAvailableDrivers(this.newStep.stepDate)
                                this.closeModal()
                                this.refresh += 1

                                this.refreshSteps += 1
                            }
                        })
                    }
                } else {
                    this.vehicleOptions = []
                    this.loadAvailableVehicles(this.newStep.stepDate)
                    this.driverOptions = []
                    this.loadAvailableDrivers(this.newStep.stepDate)
                    this.closeModal()

                    this.refreshSteps += 1
                }
            }
        },
        closeModal() {
            this.stepToUpdate = null
            this.newStep = null
            this.show = false
            // this.addressOptions = []
            this.resetBadTimes()
            this.clientStore.$patch({ addresses: null, currentAddress: null })

            this.v$.newStep.$reset()
            this.refresh += 1
            this.refreshSteps += 1
        },
        setPricings(clientId) {
            if (this.formData.Method === 'orderGrouping') {
                const tourPricingPayload = {
                    clientId,
                    type: 'tour',
                }
                this.clientStore
                    .fetchClientPricing(tourPricingPayload)
                    .then((res) => {
                        this.tourPricingOptions = []
                        if (res && res.status === 200) {
                            res.data.forEach((pricing) => {
                                if (
                                    !this.tourPricingOptions.find(
                                        (option) =>
                                            +option.value === +pricing.Id,
                                    )
                                ) {
                                    this.tourPricingOptions.push({
                                        name: pricing.Name,
                                        value: +pricing.Id,
                                    })
                                }
                            })
                        }
                        this.refreshMS += 1
                    })
            }

            const orderPricingPayload = {
                clientId,
                type: 'order',
            }
            this.clientStore
                .fetchClientPricing(orderPricingPayload)
                .then((res) => {
                    this.orderPricingOptions = []
                    if (res && res.status === 200) {
                        res.data.forEach((pricing) => {
                            if (
                                !this.orderPricingOptions.find(
                                    (option) => +option.value === +pricing.Id,
                                )
                            ) {
                                this.orderPricingOptions.push({
                                    name: pricing.Name,
                                    value: +pricing.Id,
                                })
                            }
                        })
                    }
                    this.refreshMS += 1
                })

            this.refreshMS += 1
        },
        fetchContactsAddressesAndContainers(
            clientId,
            duplicate = false,
            refreshPrice = true,
        ) {
            this.setPricings(clientId)
            this.containerOptions = []
            this.clientStore.fetchContainers(clientId).then((resContainers) => {
                resContainers.data.Records.forEach((container) => {
                    this.containerOptions.push({
                        name:
                            container.Name.includes('_') ||
                            container.Name === 'pallet' ||
                            container.Name === 'envelope'
                                ? this.$t(container.Name)
                                : container.Name,
                        value: container.Id,
                        icon: container.Name.includes('pallet')
                            ? 'pallet'
                            : container.Name.includes('envelope') ||
                                container.Name.includes('enveloppe')
                              ? 'envelope'
                              : container.Name.includes('box')
                                ? 'box'
                                : 'star',
                    })
                })
                if (!this.isEditPackage && duplicate === false) {
                    // this.idContDefault =
                    //     this.currentClient.default_container > 0
                    //         ? this.currentClient.default_container
                    //         : this.userStore.current.env.defaultContainer
                    this.formData.OrderPackages = []
                }
                if (duplicate === false) {
                    this.clientStore.$patch({
                        containers: null,
                        currentContacts: null,
                        current: null,
                    })

                    this.clientStore.fetchOne(clientId).then((res) => {
                        // this.formData.IdExternal = res.data.IdExternal
                        // this.formData.OrderClientType = res.data.ClientType

                        // this.formData.PaymentType = res.data.PaymentType
                        this.formData.OrderClientType =
                            this.formData.OrderClientType === ''
                                ? res.data.ClientType
                                : this.formData.OrderClientType
                        this.formData.PaymentType =
                            this.formData.PaymentType === ''
                                ? res.data.PaymentType
                                : this.formData.PaymentType
                        this.formData.WithVAT = res.data.WithVAT
                            ? +res.data.WithVAT === 1
                            : false
                        // this.formData.IdUserContact = res.data.IdDefaultUser ? +res.data.IdDefaultUser : this.contactOptions[0].value
                        if (
                            this.formData.IdUniverse === '' ||
                            this.formData.IdUniverse === 0
                        ) {
                            if (
                                res.data.IdUniverse !== null &&
                                res.data.IdUniverse !== 0 &&
                                res.data.IdUniverse !== '0'
                            ) {
                                this.formData.IdUniverse = +res.data.IdUniverse
                            } else {
                                this.formData.IdUniverse =
                                    +this.universeOptions.find(
                                        (universe) =>
                                            universe.realName === 'Courses',
                                    ).value
                            }
                        }
                        if (this.currentOrder) {
                            this.formData.IdUserContact =
                                this.currentOrder.user_contact_id

                            this.currentOrder.order_packages.forEach((pkg) => {
                                this.idContDefault =
                                    pkg.IdContainer > 0
                                        ? pkg.IdContainer
                                        : this.currentOrder.client
                                                .IdDefaultContainer > 0
                                          ? this.currentOrder.client
                                                .IdDefaultContainer
                                          : this.userStore.current.env
                                                .default_container
                            })
                            this.formData.WithVAT = this.currentOrder.with_vat
                                ? +this.currentOrder.with_vat === 1
                                : false
                        } else {
                            this.formData.WithVAT = true
                            this.idContDefault =
                                res.data.IdDefaultContainer > 0
                                    ? +res.data.IdDefaultContainer
                                    : +this.userStore.current.env
                                          .defaultContainer
                            this.addPackage()
                        }

                        this.refreshMS += 1
                    })
                }
            })
            this.noContactForClient = false

            this.contactOptions = []
            this.clientStore
                .fetchClientContacts({
                    clientId,
                    fromOrders: true,
                })
                .then((resContact) => {
                    if (resContact && resContact.status === 200) {
                        if (
                            resContact.data.Records &&
                            resContact.data.Records.length
                        ) {
                            resContact.data.Records.forEach((contact) => {
                                this.noContactForClient = false
                                const option = {
                                    name: `${contact.FirstName}  ${contact.LastName}`,
                                    value: +contact.IdUser,
                                    FirstName: contact.FirstName,
                                    LastName: contact.LastName,
                                    Email: contact.Email,
                                    Phone: contact.Phone,
                                }
                                this.contactOptions.push(option)
                            })

                            this.formData.IdUserContact = this.currentOrder
                                ? +this.currentOrder.user_contact_id
                                : this.formData.IdUserContact !== ''
                                  ? this.formData.IdUserContact
                                  : this.currentClient
                                    ? this.currentClient.IdDefaultUser
                                    : this.contactOptions[0].value
                            this.selectedContact = this.contactOptions.find(
                                (contact) =>
                                    +contact.value ===
                                    +this.formData.IdUserContact,
                            )
                        } else {
                            this.noContactForClient = true
                        }
                    } else {
                        this.noContactForClient = true
                    }
                })

            // .finally(() => {
            //     this.pickedContainerId =
            //         this.currentClient.default_container > 0
            //             ? this.currentClient.default_container
            //             : this.userStore.current.env.defaultContainer
            //     this.addPackage()
            // })
            this.addressOptions = []
            const clientAddressesPayload = {
                clientId,
                query: [],
                filter: [],
                perPage: 100000000000,
                page: 1,
                sortField: 'Name',
                sortDirection: 'ASC',
            }
            this.clientStore
                .fetchAddresses(clientAddressesPayload)
                .then((resAddresses) => {
                    resAddresses.data.Records.forEach((address) => {
                        const option = {
                            name: address.Name,
                            value: address.Id,
                        }
                        if (!this.addressOptions.includes(option)) {
                            this.addressOptions.push(option)
                        }
                    })
                })
            if (refreshPrice === true) {
                this.liveRefresh()
            }
        },

        back() {
            this.packageTypeErrors = []
            this.packageReferenceErrors = []
            // const env = window.location.hostname
            let back = 'orders'
            // env !== 'becycle.biiim-solutions.com' &&
            // env !== 'localhost' &&
            // env !== 'dev.biiim-solutions.com'
            //     ? 'orders'
            //     : 'becycle_orders'
            // this.$router.push({ name: 'orders' })
            // const backPath = this.$router.options.history.state.back
            if (this.recurrentOrder === true) {
                back = 'recurrent_orders'
            }
            // const backPath = this.$router.options.history.state.back
            this.$router.push({ name: back })

            this.$emit('back', 'orders')
        },
        fillInAddress(autocomplete, index = null, modal = false) {
            let addressToFillIn = this.newStep
            if (index !== null && this.createBreakAddressForm === false) {
                addressToFillIn = this.stepsToAdd[index]
            }
            if (index === null && this.showBreakForm === true) {
                addressToFillIn = this.breakForm
            }
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            addressToFillIn.Address2 = ''
            addressToFillIn.PostalCode = ''
            addressToFillIn.City = ''
            const userInput = addressToFillIn.Address1
            addressToFillIn.Latitude = place.geometry.location.lat()
            addressToFillIn.Longitude = place.geometry.location.lng()
            addressToFillIn.Address1 = ''
            // console.log(userInput)
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    addressToFillIn.PostalCode = val
                } else if (addressType === 'locality') {
                    addressToFillIn.City = val
                } else if (addressType === 'street_number') {
                    addressToFillIn.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    addressToFillIn.Address1 =
                        addressToFillIn.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${addressToFillIn.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    if (val !== addressToFillIn.Address1) {
                        addressToFillIn.Address2 = `${val}`
                    }
                }

                // TODO voir ce que ça casse dans la génération des adresses rm le 4 nov
                // console.log(place.types[0] )

                //                 console.log(val)
                //                 console.log(addressType)
                //                 console.log('------------------------')
                // if (
                //     (place.types[0] !== 'street_number' ||
                //         place.types[0] !== 'route') &&
                //     addressType === 'locality' &&
                //     addressToFillIn.Address1 === userInput
                // ) {
                //     addressToFillIn.Address1 = ` ${val}`
                // }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    addressToFillIn.Address2 === '' &&
                    !place.types[0].includes('premise')
                ) {
                    if (addressToFillIn.Address1 === '') {
                        addressToFillIn.Address1 = place.name
                    } else {
                        addressToFillIn.Address2 = place.name
                    }
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    addressToFillIn.Name === ''
                ) {
                    if (!modal) {
                        addressToFillIn.Name = place.name
                    }
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    addressToFillIn.AddressName === ''
                ) {
                    if (modal) {
                        addressToFillIn.AddressName = place.name
                    }
                }
            })
            if (addressToFillIn.Address1 === addressToFillIn.Address2) {
                addressToFillIn.Address2 = ''
            }
        },
        async calculatePrice(liveRefresh = false) {
            if (this.creating === false && this.dontRefreshPrice === false) {
                this.resetFormsValidations()

                const packages = []
                this.formData.OrderPackages.forEach((pack) => {
                    const packageForm = {
                        IdOrder: '',
                        // IdVehicleType:
                        //     this.formData.OrderPackages[0].IdVehicleType,
                        Reference: pack.Reference,
                        PackageNumber: pack.PackageNumber,
                        PlombNumber: pack.PlombNumber,
                        ReferenceExternal: pack.ReferenceExternal,
                        Width: pack.Width,
                        Length: pack.Length,
                        Height: pack.Height,
                        Weight: pack.Weight,
                        Value: pack.Value,
                        Price: pack.Price,
                        Id: pack.Id,
                        IdContainer: pack.IdContainer,
                        LabelFile: pack.LabelFile,
                        LastDeliveryCode: pack.LastDeliveryCode,
                        LastDeliveryCodeDate: pack.LastDeliveryCodeDate,
                        TemperatureOption: pack.TemperatureOption,
                        ModificationUserId: this.user.id,
                        CreationUserId: pack.CreationUserId,
                        Quantity: pack.Quantity,
                    }

                    packages.push(packageForm)

                    if (
                        pack.SameOpIndexes &&
                        pack.Quantity !== pack.SameOpIndexes.length + 1
                    ) {
                        if (pack.Quantity > pack.SameOpIndexes.length + 1) {
                            const diff =
                                pack.Quantity - (pack.SameOpIndexes.length + 1)
                            for (let index = 0; index < diff; index += 1) {
                                const newPackage = {
                                    IdOrder: pack.IdOrder,
                                    // IdVehicleType: pack.IdVehicleType,
                                    Width: pack.Width,
                                    Length: pack.Length,
                                    Height: pack.Height,
                                    Weight: pack.Weight,
                                    Value: pack.Value,
                                    Price: pack.Price,
                                    IdContainer: pack.IdContainer,
                                    LabelFile: pack.LabelFile,
                                    LastDeliveryCode: pack.LastDeliveryCode,
                                    LastDeliveryCodeDate:
                                        pack.LastDeliveryCodeDate,
                                    TemperatureOption: pack.TemperatureOption,
                                    ModificationUserId: this.user.id,
                                    CreationUserId: this.user.id,
                                }
                                packages.push(newPackage)
                            }
                        } else {
                            const diff =
                                pack.SameOpIndexes.length + 1 - pack.Quantity
                            for (let index = 0; index < diff; index += 1) {
                                packages.pop()
                            }
                        }
                    }
                })
                if (
                    this.formData.Sales &&
                    this.formData.Sales.length > 0 &&
                    this.extraSales.length
                ) {
                    this.formData.Sales.SaleDetails = this.extraSales
                } else if (
                    (!this.formData.Sales ||
                        this.formData.Sales.length === 0) &&
                    this.extraSales.length
                ) {
                    this.formData.Sales = {
                        IdClient: this.formData.IdClient,
                        ClientName:
                            this.currentClient.company_name ??
                            this.currentClient.Name,
                        FirstName: '',
                        LastName: '',
                        IdClientAddress:
                            this.currentClient.id_address_facturation,
                        Address1: this.currentClient.address,
                        Address2: this.currentClient.address2 ?? '',
                        Address3: this.currentClient.address3 ?? '',
                        Address4: this.currentClient.address4 ?? '',
                        PostalCode: this.currentClient.postal_code,
                        City: this.currentClient.city,
                        Country: this.currentClient.country,
                        AmountHT1: 0,
                        AmountVAT1: 0,
                        VAT1: 0,
                        AmountHT2: 0,
                        AmountVAT2: 0,
                        VAT2: 0,
                        AmountHT3: 0,
                        AmountVAT3: 0,
                        VAT3: 0,
                        TotalHT: 0,
                        TotalTTC: 0,
                        SaleDetails: this.extraSales,
                    }
                }
                const stepsToUse = []
                if (this.formData.OrderSteps.length > 0) {
                    this.formData.OrderSteps.forEach((step) => {
                        stepsToUse.push(step)
                    })
                }
                if (this.stepsToAdd.length > 0) {
                    this.stepsToAdd.forEach((step) => {
                        stepsToUse.push(step)
                    })
                }
                if (
                    this.alreadyRefreshing === false &&
                    packages.length > 0 &&
                    packages[0].Height !== 0 &&
                    stepsToUse.length > 0 &&
                    stepsToUse[0].PostalCode !== '' &&
                    stepsToUse[1].PostalCode !== ''
                ) {
                    this.alreadyRefreshing = true
                    this.creating = true
                    if ((await this.arePackagesValid()) === true) {
                        this.formData.CreationUserId = this.user.id

                        // this.formData.PacketNumber = this.formData.OrderPackages.length
                        // todo voir ici car step date va cahnger a chaque création de new step faire un tableau ? mais pb si update du step avec un tableau à remettre a jour si le step cahnge de place

                        const clone = structuredClone(stepsToUse)
                        this.formData.DateBegin = clone[0].stepDate
                        this.formData.DateEnd = clone[clone.length - 1].stepDate

                        clone.forEach((step) => {
                            step.CreationUserId = this.user.id
                            step.ModificationUserId = this.user.id

                            step.Price = this.price
                            // if (this.recurrentOrder === false) {
                            const from = `${step.stepDate} ${step.FromDateTime}`

                            const before = `${step.stepDate} ${step.BeforeDateTime}`
                            if (step.Moment === 'between') {
                                step.FromDateTime = from
                                step.BeforeDateTime = before
                            } else if (step.Moment === 'from') {
                                step.FromDateTime = from
                                step.BeforeDateTime = ''
                            } else if (step.Moment === 'before') {
                                step.FromDateTime = ''
                                step.BeforeDateTime = before
                            }
                            if (
                                step.TypeVehicle === '' ||
                                step.TypeVehicle === null
                            ) {
                                step.TypeVehicle =
                                    this.formData.VehicleType === ''
                                        ? null
                                        : this.formData.VehicleType
                            }
                            // }
                            delete step.stepDate
                        })
                        if (this.isB2C === true) {
                            this.formData.IdClient = this.currentClient.id
                        }
                        this.setDriverAndVehicleToOtherSteps(
                            this.formData.OrderSteps,
                        )

                        //  this.v$.formData.$touch()
                        const isValid = await this.v$.formData.$validate()

                        // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                        if (isValid) {
                            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.

                            const deliveryForm = {
                                // Reference: `${
                                //     this.prefix.delivery
                                // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                                CreationUserId: this.user.id,
                                IdClient: this.formData.IdClient,
                                Name: this.newDeliveryName,
                                OrdersClient: [
                                    {
                                        IdPricing:
                                            this.selectedOrderPricing?.value,

                                        OrderClientType:
                                            this.formData.OrderClientType,
                                        IdUniverse: this.formData.IdUniverse,
                                        PaymentStatus:
                                            this.formData.PaymentType === 'web'
                                                ? 'waiting'
                                                : 'none',
                                        PaymentType: this.formData.PaymentType,
                                        Recurrent: 0,
                                        RoundTrip:
                                            this.roundTrip === true ? 1 : 0,
                                        IdExternal: '',
                                        Price: this.calculatedPrice,
                                        ForcedPrice: this.priceToApply,
                                        VehicleType:
                                            this.formData.VehicleType === ''
                                                ? null
                                                : this.formData.VehicleType,
                                        Orders: [
                                            {
                                                IdClient:
                                                    this.formData.IdClient,
                                                IdUserContact:
                                                    this.formData.IdUserContact,
                                                IdExternal: '',
                                                IdDriver:
                                                    this.formData.IdDriver ??
                                                    '',
                                                IdInvoice:
                                                    this.formData.IdInvoice ??
                                                    '',
                                                Reference:
                                                    this.formData.Reference,
                                                Status: this.formData.Status,
                                                Method: this.formData.Method,

                                                CommentInternal:
                                                    this.formData
                                                        .CommentInternal ?? '',
                                                CommentClient:
                                                    this.formData
                                                        .CommentClient ?? '',
                                                OrderStepsHTML: null,
                                                CreationUserId: this.user.id,
                                                OrderSteps: clone,
                                                OrderPackages: packages,

                                                DateBegin:
                                                    this.formData.DateBegin,
                                                DateEnd: this.formData.DateEnd,
                                                OrderOptions: null,
                                                OrderOption: [],
                                                Sale: this.formData.Sales,
                                            },
                                        ],
                                    },
                                ],
                            }
                            this.updateDeliveryPrice(deliveryForm)
                                .then((response) => {
                                    if (response) {
                                        this.creating = false
                                        this.formData.Price =
                                            response.data.OrdersClient[0].Price
                                        this.priceToApply = response.data
                                            .OrdersClient[0].ForcedPrice
                                            ? response.data.OrdersClient[0]
                                                  .ForcedPrice
                                            : response.data.OrdersClient[0]
                                                  .Price
                                        this.calculatedPrice =
                                            response.data.OrdersClient[0].Price
                                        this.refreshPrice += 1
                                        this.alreadyRefreshing = false

                                        // this.refresh += 1
                                    }
                                })
                                .catch(() => {
                                    this.creating = false
                                    if (liveRefresh === false) {
                                        this.$toast.error(
                                            this.$t('error_updating_price'),
                                        )
                                    }
                                    this.alreadyRefreshing = false
                                })
                        } else {
                            this.creating = false
                            if (liveRefresh === false) {
                                this.$toast.error(this.$t('form_errors'))
                            }
                            this.alreadyRefreshing = false

                            return false
                        }
                    } else {
                        this.creating = false
                        if (liveRefresh === false) {
                            this.$toast.error(this.$t('bad_packages'))
                        }
                        this.alreadyRefreshing = false

                        return false
                    }
                }
            }
            return true
        },

        async createOrder(
            duplicate = false,
            acceptUnassigned = false,
            force = false,
            visualise = false,
        ) {
            this.canBeForced = true
            this.creating = true
            this.orderToDuplicate = null
            this.isDuplicate = duplicate

            this.resetFormsValidations()
            const areStepsValid = await this.v$.stepsToAdd.$validate()

            if (areStepsValid === false) {
                this.creating = false
                this.$toast.error(this.$t('form_errors'))
                return false
            }

            if ((await this.arePackagesValid()) === true) {
                this.formData.CreationUserId = this.user.id
                // this.formData.PacketNumber = this.formData.OrderPackages.length
                // todo voir ici car step date va cahnger a chaque création de new step faire un tableau ? mais pb si update du step avec un tableau à remettre a jour si le step cahnge de place
                this.formData.DateBegin = this.stepsToAdd[0].stepDate
                this.formData.DateEnd =
                    this.stepsToAdd[this.stepsToAdd.length - 1].stepDate

                const clone = structuredClone(this.stepsToAdd)
                let wrongAddress = false
                clone.forEach((step) => {
                    step.CreationUserId = this.user.id
                    step.ModificationUserId = this.user.id

                    step.Price = this.price

                    const from = `${step.stepDate} ${step.FromDateTime}`

                    const before = `${step.stepDate} ${step.BeforeDateTime}`
                    if (step.Moment === 'between') {
                        step.FromDateTime = from
                        step.BeforeDateTime = before
                    } else if (step.Moment === 'from') {
                        step.FromDateTime = from
                        step.BeforeDateTime = ''
                    } else if (step.Moment === 'before') {
                        step.FromDateTime = ''
                        step.BeforeDateTime = before
                    }
                    if (step.TypeVehicle === '' || step.TypeVehicle === null) {
                        step.TypeVehicle =
                            this.formData.VehicleType === ''
                                ? null
                                : this.formData.VehicleType
                    }
                    // }
                    if (visualise === true && step.SendCharter) {
                        step.SendCharter = false
                    }

                    delete step.stepDate
                })
                this.formData.OrderSteps = structuredClone(clone)
                clone.forEach((step) => {
                    if (
                        Number.isNaN(step.Latitude) ||
                        Number.isNaN(step.Longitude) ||
                        step.Latitude === '' ||
                        step.Latitude === null ||
                        step.Latitude === '0' ||
                        +step.Latitude === 0 ||
                        step.Longitude === '' ||
                        step.Longitude === null ||
                        step.Longitude === '0' ||
                        +step.Longitude === 0
                    ) {
                        wrongAddress = true
                        this.formData.IdDriver = null
                    }
                })

                if (this.isB2C === true) {
                    this.formData.IdClient = this.currentClient.id
                }
                this.setDriverAndVehicleToOtherSteps(clone, wrongAddress)

                //  this.v$.formData.$touch()
                // const isFormCorrect = this.v$.formData.$invalid
                const isValid = await this.v$.formData.$validate()
                const hasName = await this.v$.newDeliveryName.$validate()
                this.formData.Status =
                    this.formData.PaymentType === 'web'
                        ? 'waitingforpayment'
                        : 'new'
                this.formData.Price = this.calculatedPrice
                this.formData.ForcedPrice = this.priceToApply
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (isValid) {
                    if (this.extraSales.length) {
                        this.formData.Sales = {
                            IdClient: this.formData.IdClient,
                            ClientName:
                                this.currentClient.company_name ??
                                this.currentClient.Name,
                            FirstName: '',
                            LastName: '',
                            IdClientAddress:
                                this.currentClient.id_address_facturation,
                            Address1: this.currentClient.address,
                            Address2: this.currentClient.address2 ?? '',
                            Address3: this.currentClient.address3 ?? '',
                            Address4: this.currentClient.address4 ?? '',
                            PostalCode: this.currentClient.postal_code,
                            City: this.currentClient.city,
                            Country: this.currentClient.country,
                            AmountHT1: 0,
                            AmountVAT1: 0,
                            VAT1: 0,
                            AmountHT2: 0,
                            AmountVAT2: 0,
                            VAT2: 0,
                            AmountHT3: 0,
                            AmountVAT3: 0,
                            VAT3: 0,
                            TotalHT: 0,
                            TotalTTC: 0,
                            SaleDetails: this.extraSales,
                        }
                    }
                    if (this.recurrentOrder === false) {
                        if (hasName) {
                            const deliveryForm = {
                                // Reference: `${
                                //     this.prefix.delivery
                                // }${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`,
                                CreationUserId: this.user.id,
                                IdClient: this.formData.IdClient,
                                Name: this.newDeliveryName,
                                OrdersClient: [
                                    {
                                        GroupName: this.formData.GroupName,
                                        GroupedOrdersId: this.selectedGroup
                                            ? this.selectedGroup.value
                                            : null,
                                        TourPricingId:
                                            this.selectedTourPricing?.value,
                                        IdPricing:
                                            this.selectedOrderPricing?.value,
                                        OrderClientType:
                                            this.formData.OrderClientType,
                                        IdUniverse: this.formData.IdUniverse,
                                        PaymentStatus:
                                            this.formData.PaymentType === 'web'
                                                ? 'waiting'
                                                : 'none',
                                        PaymentType: this.formData.PaymentType,
                                        Recurrent: 0,
                                        RoundTrip:
                                            this.roundTrip === true ? 1 : 0,
                                        IdExternal: this.formData.IdExternal,
                                        Price: this.formData.Price,
                                        ForcedPrice: this.formData.ForcedPrice,
                                        VehicleType:
                                            this.formData.VehicleType === ''
                                                ? null
                                                : this.formData.VehicleType,
                                        WithVAT: this.formData.WithVAT,
                                        Position: this.newPosition,
                                        Orders: [
                                            {
                                                acceptUnassigned,
                                                force,
                                                IdClient:
                                                    this.formData.IdClient,
                                                IdUserContact:
                                                    this.formData.IdUserContact,
                                                IdExternal:
                                                    this.formData.IdExternal,
                                                IdDriver:
                                                    this.formData.IdDriver ??
                                                    '',
                                                IdInvoice:
                                                    this.formData.IdInvoice ??
                                                    '',
                                                Reference:
                                                    this.formData.Reference,
                                                Status: this.formData.Status,
                                                Method: this.formData.Method,

                                                CommentInternal:
                                                    this.formData
                                                        .CommentInternal ?? '',
                                                CommentClient:
                                                    this.formData
                                                        .CommentClient ?? '',
                                                OrderStepsHTML: null,
                                                CreationUserId: this.user.id,
                                                OrderSteps: clone,
                                                OrderPackages:
                                                    this.formData.OrderPackages,

                                                DateBegin:
                                                    this.formData.DateBegin,
                                                DateEnd: this.formData.DateEnd,
                                                OrderOptions: null,
                                                OrderOption: [],
                                                Sale: this.formData.Sales,
                                                ONUs: this.onus,
                                            },
                                        ],
                                    },
                                ],
                            }
                            try {
                                const response =
                                    await this.createDelivery(deliveryForm)

                                if (response) {
                                    this.newPosition = null

                                    this.isDuplicate = false
                                    // if (this.formData.PaymentType === 'web') {
                                    //     this.webClient = true
                                    // }
                                    // const webInfo = setInterval(() => {
                                    //     if (this.webClient === false) {
                                    // clearInterval(webInfo)
                                    this.$toast.open({
                                        message:
                                            response.data.OrdersClient[0]
                                                .Reference,
                                        type: 'default',
                                        duration: 0,
                                        onDismiss: this.copyToClipboard(
                                            response.data.OrdersClient[0]
                                                .Reference,
                                            false,
                                        ),
                                    })
                                    if (duplicate === true) {
                                        this.isDuplicate = true
                                        this.refreshMS += 1

                                        this.$toast.success(
                                            this.$t('order_create_ok'),
                                        )

                                        this.creating = false
                                    } else if (visualise === false) {
                                        this.$router.push({
                                            name: 'orders',
                                        })
                                        this.$toast.success(
                                            this.$t('order_create_ok'),
                                        )

                                        if (wrongAddress === true) {
                                            this.$toast.warning(
                                                this.$t(
                                                    'address_not_geolocated',
                                                ),
                                            )
                                        }
                                        const env = window.location.hostname
                                        const back = 'orders'
                                        // env !== 'becycle.biiim-solutions.com' &&
                                        // env !== 'localhost' &&
                                        // env !== 'dev.biiim-solutions.com'
                                        //     ? 'orders'
                                        //     : 'becycle_orders'
                                        this.$emit('created', back)
                                    } else {
                                        this.fetchOne(
                                            response.data.OrdersClient[0]
                                                .Orders[0].Id,
                                        )
                                            .then((res) => {
                                                const orderInt = setInterval(
                                                    () => {
                                                        if (this.currentOrder) {
                                                            clearInterval(
                                                                orderInt,
                                                            )
                                                            this.refresh += 1
                                                            const file =
                                                                this.currentOrder.files.find(
                                                                    (file) =>
                                                                        file.Id ===
                                                                        this
                                                                            .currentOrder
                                                                            .charters[0]
                                                                            .Id,
                                                                )
                                                            this.creating = false
                                                            this.dontRefreshPrice = false
                                                            if (file) {
                                                                this.viewImportedPDF(
                                                                    file,
                                                                )
                                                            } else {
                                                                this.$toast.error(
                                                                    this.$t(
                                                                        'error_occured_while_generating_charter',
                                                                    ),
                                                                )
                                                            }
                                                        }
                                                    },
                                                    20,
                                                )
                                            })
                                            .finally(() => {
                                                this.creating = false
                                                this.dontRefreshPrice = false
                                                this.$emit(
                                                    'goToUpdateOrder',
                                                    response.data
                                                        .OrdersClient[0]
                                                        .Orders[0].Id,
                                                )
                                            })
                                    }
                                }
                            } catch (err) {
                                // cas à ajouter tour_opti_error_forced
                                if (
                                    err.response.status === 400 &&
                                    err.response.data.message !==
                                        'tour_opti_error_deliv_before_pickup'
                                ) {
                                    if (
                                        err.response.data.message !==
                                            'tour_opti_error_steps_not_assigned' &&
                                        err.response.data.message !==
                                            'tour_opti_error_forced'
                                    ) {
                                        this.canBeForced = false
                                    }
                                    this.processing = true
                                    this.confirmAction = null
                                    this.showConfirmTourOptiModal = true
                                    this.confirmModalText =
                                        err.response.data.message
                                    this.elementsToConfirm = []

                                    const confirmInterval = setInterval(
                                        async () => {
                                            if (this.confirmAction !== null) {
                                                clearInterval(confirmInterval)
                                                this.elementsToConfirm = []
                                                if (
                                                    this.confirmAction === true
                                                ) {
                                                    this.confirmAction = null
                                                    acceptUnassigned =
                                                        err.response.data
                                                            .message ===
                                                        'tour_opti_error_steps_not_assigned'
                                                    force =
                                                        err.response.data
                                                            .message !==
                                                            'tour_opti_error_steps_not_assigned' ||
                                                        err.response.data
                                                            .message ===
                                                            'tour_opti_error_forced'
                                                    this.createOrder(
                                                        duplicate,
                                                        acceptUnassigned,
                                                        force,
                                                        visualise,
                                                    )
                                                } else {
                                                    this.creating = false

                                                    this.confirmAction = null
                                                    this.confirmModalTextOptiErrorInfo =
                                                        ''
                                                    this.confirmModalTextOptiError =
                                                        ''

                                                    this.$toast.info(
                                                        this.$t(
                                                            'action_canceled',
                                                        ),
                                                    )
                                                }
                                            }
                                        },
                                        150,
                                    )
                                } else {
                                    this.creating = false
                                    if (
                                        err.response.data.message ===
                                        'tour_opti_error_deliv_before_pickup'
                                    ) {
                                        this.$toast.info(
                                            this.$t(
                                                'tour_opti_error_deliv_before_pickup',
                                            ),
                                        )
                                    } else {
                                        this.$toast.error(
                                            `${this.$t('order_create_ko')}`,
                                        )
                                    }
                                }

                                // this.creating = false
                                // this.$toast.error(
                                //     `${this.$t('order_create_ko')}`,
                                // )
                                // return false
                            }
                        } else {
                            this.creating = false

                            this.$toast.error(this.$t('form_errors'))
                            return false
                        }
                    } else {
                        if (
                            this.recurrentForm.occurrenceFrequency.value ===
                                'weekly' &&
                            this.recurrentForm.pickedDaysForRecurrence
                                .length === 0
                        ) {
                            this.$toast.error(this.$t('picked_days_error'))
                            this.creating = false
                            return false
                        }
                        const recurrentFormIsValid =
                            await this.v$.recurrentForm.$validate()
                        if (recurrentFormIsValid) {
                            this.formData.OrderPackages[0].Reference = null

                            const recurrentForm = {
                                IdExternal: this.formData.IdExternal,
                                IdClient: this.formData.IdClient,
                                IdUniverse: this.formData.IdUniverse,
                                IdFacturationClient:
                                    this.formData.IdFacturationClient,
                                IdUserContact: +this.formData.IdUserContact,
                                Name: this.recurrentForm.recurrentName,
                                Method: this.formData.Method,
                                RoundTrip: this.roundTrip === true ? 1 : 0,
                                VehicleType: this.formData.VehicleType,
                                Informations: '',
                                Price: this.formData.Price,
                                ForcedPrice: this.formData.ForcedPrice,
                                TourPricingId: this.selectedTourPricing?.value,
                                IdPricing: this.selectedOrderPricing?.value,
                                CommentInternal: this.formData.CommentInternal,
                                Repetition:
                                    this.recurrentForm.pickedDaysForRecurrence.toString(),
                                OccurrenceNb:
                                    this.recurrentForm.recurrentEndChoice ===
                                        'never' ||
                                    this.recurrentForm.recurrentEndChoice ===
                                        'on'
                                        ? ''
                                        : this.recurrentForm.endsAfterNumber,
                                OccurrenceFrequency:
                                    this.recurrentForm.occurrenceFrequency
                                        .value,
                                RepetitionFrequency: this.repetitionFrequency,
                                DateEndRecurrent:
                                    this.recurrentForm.recurrentEndChoice ===
                                        'never' ||
                                    this.recurrentForm.recurrentEndChoice ===
                                        'after'
                                        ? ''
                                        : this.recurrentForm.recurrenceEndDate,
                                DateStartRecurrent:
                                    this.recurrentForm.recurrenceStartDate,
                                Active: 1,
                                CreationUserId: this.user.id,
                                ModificationUserId: this.user.id,
                                OrderPackages: this.formData.OrderPackages,
                                OrderSteps: clone,
                                GroupName: this.formData.GroupName,
                                GroupedOrdersId: this.selectedGroup
                                    ? this.selectedGroup.value
                                    : null,
                                Sale: this.formData.Sales,
                                WithVAT: this.formData.WithVAT,
                            }
                            try {
                                const resp =
                                    await this.createRecurrentOrder(
                                        recurrentForm,
                                    )
                                if (resp) {
                                    this.isDuplicate = false
                                    if (duplicate === true) {
                                        this.isDuplicate = true
                                        this.refreshMS += 1

                                        this.$toast.success(
                                            this.$t('order_create_ok'),
                                        )

                                        this.creating = false
                                    } else {
                                        this.$router.push({
                                            name: 'recurrent_orders',
                                        })
                                        this.$toast.success(
                                            this.$t('order_create_ok'),
                                        )
                                        const env = window.location.hostname
                                        const back = 'recurrent_orders'
                                        // env !== 'becycle.biiim-solutions.com' &&
                                        // env !== 'localhost' &&
                                        // env !== 'dev.biiim-solutions.com'
                                        //     ? 'orders'
                                        //     : 'becycle_orders'
                                        this.$emit('created', back)
                                    }
                                }
                            } catch (err) {
                                this.creating = false
                                this.$toast.error(
                                    `${this.$t('order_create_ko')}`,
                                )
                                return false
                            }
                            this.creating = false
                        } else {
                            this.creating = false
                            return false
                        }
                    }
                } else {
                    this.creating = false

                    this.$toast.error(this.$t('form_errors'))

                    return false
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('bad_packages'))
                return false
            }
        },
        // TRAVAIL ICI
        // TODO mettre en place le update + le duplicate sur le create et l'uupdae des recurrentes

        async updateOrder(
            duplicate = false,
            noUpdate = false,
            acceptUnassigned = false,
            force = false,
            visualise = false,
        ) {
            // TODO remove les validations merci neuenue
            this.canBeForced = true
            this.creating = true
            this.isDuplicate = duplicate
            this.resetFormsValidations()
            const areStepsValid = await this.v$.stepsToAdd.$validate()
            if (areStepsValid === false) {
                this.creating = false
                this.$toast.error(this.$t('form_errors'))
                return false
            }

            if ((await this.arePackagesValid()) === true) {
                this.formData.ModificationUserId = this.user.id

                this.formData.PacketNumber = this.formData.OrderPackages.length
                const clone = structuredClone(this.stepsToAdd)
                if (clone.length) {
                    clone.forEach((toAdd) => {
                        toAdd.CreationUserId = this.user.id
                        toAdd.ModificationUserId = this.user.id

                        this.formData.OrderSteps.push(toAdd)
                    })
                }
                let wrongAddress = false
                if (this.breakForm !== null) {
                    this.addBreak()
                }
                const stepsClone = structuredClone(this.formData.OrderSteps)
                stepsClone.forEach((step, index) => {
                    if (index === 0) {
                        this.formData.DateBegin = step.stepdate
                    }
                    const from = `${step.stepDate} ${step.FromDateTime}`

                    const before = `${step.stepDate} ${step.BeforeDateTime}`

                    if (step.Moment === 'between') {
                        step.FromDateTime = from
                        step.BeforeDateTime = before
                    } else if (step.Moment === 'from') {
                        step.FromDateTime = from
                        step.BeforeDateTime = ''
                    } else if (step.Moment === 'before') {
                        step.FromDateTime = ''
                        step.BeforeDateTime = before
                    }
                    step.ModificationUserId = this.user.id
                    if (this.recurrentOrder === true) {
                        step.IdRecurrent = this.currentOrder.id
                    }
                    if (step.TypeVehicle === '' || step.TypeVehicle === null) {
                        step.TypeVehicle =
                            this.formData.VehicleType === ''
                                ? null
                                : this.formData.VehicleType
                    }
                    if (visualise === true && step.SendCharter) {
                        step.SendCharter = false
                    }

                    delete step.stepdate
                })

                const isValid = await this.v$.formData.$validate()
                // const areOrderStepsValid = await this.v$.formData.OrderSteps.$validate()

                if (isValid === true) {
                    this.formData.Price = this.calculatedPrice
                    this.formData.ForcedPrice = this.priceToApply

                    const steps = []
                    stepsClone.forEach((step) => {
                        step.IdOrder = this.currentOrder.id
                        this.formData.OrderSteps.forEach((step) => {
                            if (
                                Number.isNaN(step.Latitude) ||
                                Number.isNaN(step.Longitude) ||
                                step.Latitude === '' ||
                                step.Latitude === null ||
                                step.Latitude === '0' ||
                                +step.Latitude === 0 ||
                                step.Longitude === '' ||
                                step.Longitude === null ||
                                step.Longitude === '0' ||
                                +step.Longitude === 0
                            ) {
                                wrongAddress = true
                                this.formData.IdDriver = null
                            }
                        })
                        steps.push(step)
                    })
                    this.setDriverAndVehicleToOtherSteps(steps, wrongAddress)

                    if (
                        this.formData.Sales &&
                        this.formData.Sales !== null &&
                        this.extraSales.length
                    ) {
                        this.formData.Sales.SaleDetails = this.extraSales
                    } else if (
                        (!this.formData.Sales ||
                            this.formData.Sales === null) &&
                        this.extraSales.length
                    ) {
                        this.formData.Sales = {
                            IdClient: this.formData.IdClient,
                            ClientName:
                                this.currentClient.company_name ??
                                this.currentClient.Name,
                            FirstName: '',
                            LastName: '',
                            IdClientAddress:
                                this.currentClient.id_address_facturation,
                            Address1: this.currentClient.address,
                            Address2: this.currentClient.address2 ?? '',
                            Address3: this.currentClient.address3 ?? '',
                            Address4: this.currentClient.address4 ?? '',
                            PostalCode: this.currentClient.postal_code,
                            City: this.currentClient.city,
                            Country: this.currentClient.country,
                            AmountHT1: 0,
                            AmountVAT1: 0,
                            VAT1: 0,
                            AmountHT2: 0,
                            AmountVAT2: 0,
                            VAT2: 0,
                            AmountHT3: 0,
                            AmountVAT3: 0,
                            VAT3: 0,
                            TotalHT: 0,
                            TotalTTC: 0,
                            SaleDetails: this.extraSales,
                        }
                    }
                    if (this.recurrentOrder === false) {
                        const packages = []

                        this.formData.OrderPackages.forEach((pack) => {
                            let packageForm = {
                                IdOrder: this.currentOrder.id,
                                // IdVehicleType:
                                //     this.formData.OrderPackages[0].IdVehicleType,
                                Reference: pack.Reference,
                                PackageNumber: pack.PackageNumber,
                                PlombNumber: pack.PlombNumber,
                                ReferenceExternal: pack.ReferenceExternal,
                                Width: pack.Width,
                                Length: pack.Length,
                                Height: pack.Height,
                                Weight: pack.Weight,
                                Value: pack.Value,
                                Price: pack.Price,
                                Id: pack.Id,
                                IdContainer: pack.IdContainer,
                                LabelFile: pack.LabelFile,
                                LastDeliveryCode: pack.LastDeliveryCode,
                                LastDeliveryCodeDate: pack.LastDeliveryCodeDate,
                                TemperatureOption: pack.TemperatureOption,
                                ModificationUserId: this.user.id,
                                CreationUserId: pack.CreationUserId,
                                Quantity: pack.Quantity,
                            }

                            packages.push(packageForm)

                            pack.SameOpIndexes.forEach((sameToUpdate) => {
                                const realCopy =
                                    this.currentOrder.real_order_packages[
                                        sameToUpdate
                                    ]
                                packageForm = {
                                    IdOrder: this.currentOrder.id,
                                    // IdVehicleType:
                                    //     this.formData.OrderPackages[0].IdVehicleType,
                                    Reference: realCopy.Reference,
                                    PackageNumber: realCopy.PackageNumber,

                                    Width: pack.Width,
                                    Length: pack.Length,
                                    Height: pack.Height,
                                    Weight: pack.Weight,
                                    Value: pack.Value,
                                    PlombNumber: pack.PlombNumber,
                                    ReferenceExternal: pack.ReferenceExternal,
                                    Price: pack.Price,
                                    Id: realCopy.Id,
                                    IdContainer: pack.IdContainer,
                                    LabelFile: realCopy.LabelFile,
                                    LastDeliveryCode: realCopy.LastDeliveryCode,
                                    LastDeliveryCodeDate:
                                        realCopy.LastDeliveryCodeDate,
                                    TemperatureOption: pack.TemperatureOption,
                                    ModificationUserId: this.user.id,
                                    CreationUserId: realCopy.CreationUserId,
                                }
                                packages.push(packageForm)
                            })

                            if (
                                pack.Quantity !==
                                pack.SameOpIndexes.length + 1
                            ) {
                                if (
                                    pack.Quantity >
                                    pack.SameOpIndexes.length + 1
                                ) {
                                    const diff =
                                        pack.Quantity -
                                        (pack.SameOpIndexes.length + 1)
                                    for (
                                        let index = 0;
                                        index < diff;
                                        index += 1
                                    ) {
                                        const newPackage = {
                                            IdOrder: pack.IdOrder,
                                            // IdVehicleType: pack.IdVehicleType,
                                            Width: pack.Width,
                                            Length: pack.Length,
                                            Height: pack.Height,
                                            Weight: pack.Weight,
                                            Value: pack.Value,
                                            PlombNumber: pack.PlombNumber,
                                            ReferenceExternal:
                                                pack.ReferenceExternal,
                                            Price: pack.Price,
                                            IdContainer: pack.IdContainer,
                                            LabelFile: pack.LabelFile,
                                            LastDeliveryCode:
                                                pack.LastDeliveryCode,
                                            LastDeliveryCodeDate:
                                                pack.LastDeliveryCodeDate,
                                            TemperatureOption:
                                                pack.TemperatureOption,
                                            ModificationUserId: this.user.id,
                                            CreationUserId: this.user.id,
                                        }
                                        packages.push(newPackage)
                                    }
                                } else {
                                    const diff =
                                        pack.SameOpIndexes.length +
                                        1 -
                                        pack.Quantity
                                    for (
                                        let index = 0;
                                        index < diff;
                                        index += 1
                                    ) {
                                        packages.pop()
                                    }
                                }
                            }
                        })
                        const form = {
                            IdDelivery: this.currentOrder.idDelivery,
                            ModificationUserId: this.user.id,
                            IdExternal: this.formData.IdExternal,
                            Reference: this.formData.Reference,

                            RoundTrip: this.roundTrip === true ? 1 : 0,
                            Recurrent: this.currentOrder.recurrent,
                            PaymentStatus: this.currentOrder.paymentStatus,
                            OrderClientType: this.formData.OrderClientType,
                            PaymentType: this.formData.PaymentType,
                            IdUniverse: this.formData.IdUniverse,
                            Price: this.formData.Price,
                            ForcedPrice: this.formData.ForcedPrice,
                            VehicleType:
                                this.formData.VehicleType === ''
                                    ? null
                                    : this.formData.VehicleType,
                            GroupName: this.formData.GroupName,
                            GroupedOrdersId: this.selectedGroup
                                ? this.selectedGroup.value
                                : null,
                            IdPricing: this.selectedOrderPricing?.value,
                            TourPricingId: this.selectedTourPricing?.value,
                            WithVAT: this.formData.WithVAT,
                            Position: this.newPosition,

                            Orders: [
                                {
                                    acceptUnassigned,
                                    force,
                                    IdExternal: this.formData.IdExternal,
                                    Id: this.formData.Id,
                                    OrderOptions: [],
                                    IdDelivery: this.currentOrder.idDelivery,
                                    IdOrderClient:
                                        this.currentOrder.idOrderClient,
                                    IdClient: this.formData.IdClient,
                                    IdUserContact: this.formData.IdUserContact,
                                    IdDriver: this.formData.IdDriver,
                                    IdInvoice: this.formData.IdInvoice,
                                    DateBegin: this.formData.DateBegin,
                                    DateEnd: this.formData.DateEnd,
                                    DateReception: this.formData.DateReception,
                                    Reference: this.currentOrder.Reference,
                                    Status: this.formData.Status,
                                    Method: this.formData.Method,

                                    CommentInternal:
                                        this.formData.CommentInternal,
                                    CommentClient: this.formData.CommentClient,
                                    Duration: this.formData.Duration,
                                    PacketNumber: null,
                                    Weight: this.formData.Weight,
                                    PacketValue: null,
                                    ModificationUserId: this.user.id,
                                    Sale: this.formData.Sales,
                                    OrderPackages: packages,
                                    OrderSteps: steps,
                                    ONUs: this.onus,
                                },
                            ],
                        }
                        const params = {
                            id: this.currentOrder.idOrderClient,
                            form,
                            duplicate,
                        }
                        if (duplicate === true && noUpdate === true) {
                            this.newPosition = null

                            this.fetchOne(this.$route.params.id)
                                .then((res) => {
                                    this.orderToDuplicate = structuredClone(
                                        this.currentOr,
                                    )

                                    this.creating = false
                                    this.currentOr = null

                                    this.$router.push({ name: 'new_order' })
                                    return true
                                })
                                .catch((err) => {
                                    this.$toast.error(this.$t('error_occured'))
                                    this.creating = false
                                    return false
                                })
                        } else if (noUpdate === false) {
                            try {
                                const response =
                                    await this.updateOrderClient(params)
                                if (response.status === 200) {
                                    this.newPosition = null

                                    if (duplicate === true) {
                                        this.isDuplicate = true

                                        this.$toast.success(
                                            this.$t('order_update_ok'),
                                        )

                                        this.creating = false
                                        this.currentOr = null
                                        this.dontRefreshPrice = false

                                        this.$router.push({ name: 'new_order' })
                                        return true
                                    }
                                    if (visualise === false) {
                                        this.currentOr = null
                                        const backPath =
                                            this.$router.options.history.state
                                                .back

                                        this.$router.push(backPath)

                                        this.$toast.success(
                                            this.$t('order_update_ok'),
                                        )
                                        if (wrongAddress === true) {
                                            this.$toast.info(
                                                this.$t(
                                                    'address_not_geolocated',
                                                ),
                                            )
                                        }
                                        const back = 'orders'

                                        this.$emit('updated', back)
                                        this.creating = false
                                        this.dontRefreshPrice = false
                                    } else {
                                        this.fetchOne(
                                            this.$route.params.id,
                                        ).then((res) => {
                                            const orderInt = setInterval(() => {
                                                if (this.currentOrder) {
                                                    clearInterval(orderInt)
                                                    this.refresh += 1
                                                    const file =
                                                        this.currentOrder.files.find(
                                                            (file) =>
                                                                file.Id ===
                                                                this
                                                                    .currentOrder
                                                                    .charters[0]
                                                                    .Id,
                                                        )

                                                    this.creating = false
                                                    this.dontRefreshPrice = false
                                                    if (file) {
                                                        this.viewImportedPDF(
                                                            file,
                                                        )
                                                    } else {
                                                        this.$toast.error(
                                                            this.$t(
                                                                'error_occured_while_generating_charter',
                                                            ),
                                                        )
                                                    }
                                                }
                                            }, 20)
                                        })
                                    }

                                    return true
                                }
                                this.$toast.error(this.$t('order_update_ko'))
                                this.$toast.error(
                                    this.$t(response.data.message),
                                )
                                this.creating = false
                                this.dontRefreshPrice = false
                                return false
                            } catch (err) {
                                if (
                                    err.response.status === 400 &&
                                    err.response.data.message !==
                                        'tour_opti_error_deliv_before_pickup'
                                ) {
                                    this.processing = true
                                    this.confirmAction = null

                                    if (
                                        err.response.data.message !==
                                            'tour_opti_error_steps_not_assigned' &&
                                        err.response.data.message !==
                                            'tour_opti_error_forced'
                                    ) {
                                        this.canBeForced = false
                                    }
                                    this.showConfirmTourOptiModal = true
                                    this.confirmModalText =
                                        err.response.data.message
                                    this.elementsToConfirm = []

                                    const confirmInterval = setInterval(
                                        async () => {
                                            if (this.confirmAction !== null) {
                                                clearInterval(confirmInterval)
                                                this.elementsToConfirm = []
                                                if (
                                                    this.confirmAction === true
                                                ) {
                                                    this.confirmAction = null
                                                    acceptUnassigned =
                                                        err.response.data
                                                            .message ===
                                                        'tour_opti_error_steps_not_assigned'
                                                    force =
                                                        err.response.data
                                                            .message !==
                                                            'tour_opti_error_steps_not_assigned' ||
                                                        err.response.data
                                                            .message ===
                                                            'tour_opti_error_forced'
                                                    this.updateOrder(
                                                        duplicate,
                                                        noUpdate,
                                                        acceptUnassigned,
                                                        force,
                                                        visualise,
                                                    )
                                                } else {
                                                    this.creating = false

                                                    this.confirmAction = null
                                                    this.confirmModalTextOptiErrorInfo =
                                                        ''
                                                    this.confirmModalTextOptiError =
                                                        ''

                                                    this.$toast.info(
                                                        this.$t(
                                                            'action_canceled',
                                                        ),
                                                    )
                                                }
                                            }
                                        },
                                        150,
                                    )
                                } else {
                                    this.dontRefreshPrice = false

                                    this.creating = false
                                    if (
                                        err.response.data.message ===
                                        'tour_opti_error_deliv_before_pickup'
                                    ) {
                                        this.$toast.info(
                                            this.$t(
                                                'tour_opti_error_deliv_before_pickup',
                                            ),
                                        )
                                    } else {
                                        this.$toast.error(
                                            `${this.$t('order_create_ko')}`,
                                        )
                                    }
                                }

                                return false
                            }
                        }
                    } else {
                        // this.$toast.info(this.$t('not_implemented'))
                        // this.creating = false
                        // return true
                        if (
                            this.recurrentForm.occurrenceFrequency.value ===
                                'weekly' &&
                            this.recurrentForm.pickedDaysForRecurrence
                                .length === 0
                        ) {
                            this.$toast.error(this.$t('picked_days_error'))
                            this.creating = false
                            return false
                        }

                        this.formData.OrderPackages.forEach((pack) => {
                            pack.Reference = null
                            pack.IdRecurrent = this.currentOrder.id
                        })

                        const recurrentFormIsValid =
                            await this.v$.recurrentForm.$validate()
                        if (recurrentFormIsValid) {
                            const recurrentForm = {
                                Id: this.currentOrder.id,
                                IdExternal: this.formData.IdExternal,

                                Reference: this.formData.Reference,

                                IdClient: this.formData.IdClient,
                                RoundTrip: this.roundTrip === true ? 1 : 0,
                                IdUniverse: this.formData.IdUniverse,
                                Price: this.formData.Price,
                                ForcedPrice: this.formData.ForcedPrice,
                                IdUserContact: +this.formData.IdUserContact,
                                Name: this.recurrentForm.recurrentName,
                                Method: this.formData.Method,
                                VehicleType:
                                    this.formData.VehicleType === ''
                                        ? null
                                        : this.formData.VehicleType,
                                CommentInternal: this.formData.CommentInternal,
                                Repetition:
                                    this.recurrentForm.pickedDaysForRecurrence.toString(),
                                OccurrenceNb:
                                    this.recurrentForm.recurrentEndChoice ===
                                        'never' ||
                                    this.recurrentForm.recurrentEndChoice ===
                                        'on'
                                        ? ''
                                        : this.recurrentForm.endsAfterNumber,
                                OccurrenceFrequency:
                                    this.recurrentForm.occurrenceFrequency
                                        .value,
                                RepetitionFrequency: this.repetitionFrequency,
                                DateEndRecurrent:
                                    this.recurrentForm.recurrentEndChoice ===
                                        'never' ||
                                    this.recurrentForm.recurrentEndChoice ===
                                        'after'
                                        ? ''
                                        : this.recurrentForm.recurrenceEndDate,
                                DateStartRecurrent:
                                    this.recurrentForm.recurrenceStartDate,
                                Active: this.currentOrder.Active,

                                CreationUserId:
                                    this.currentOrder.creation_user_id,
                                ModificationUserId: this.user.id,
                                WithVAT: this.formData.WithVAT,

                                OrderPackages: this.formData.OrderPackages,
                                OrderSteps: steps,
                                Sale: this.formData.Sales,
                                GroupName: this.formData.GroupName,
                                GroupedOrdersId: this.selectedGroup
                                    ? this.selectedGroup.value
                                    : null,
                            }
                            try {
                                const params = {
                                    id: this.currentOrder.id,
                                    recurrentForm,
                                    duplicate,
                                }
                                const resp =
                                    await this.updateRecurrentOrder(params)
                                if (resp) {
                                    this.isDuplicate = false
                                    if (duplicate === true) {
                                        this.isDuplicate = true

                                        this.$toast.success(
                                            this.$t('order_update_ok'),
                                        )
                                        this.currentRec = null
                                        this.$router.push({ name: 'new_order' })

                                        this.creating = false
                                        this.dontRefreshPrice = false

                                        return true
                                    }

                                    this.$router.push({
                                        name: 'recurrent_orders',
                                    })
                                    this.$toast.success(
                                        this.$t('order_update_ok'),
                                    )
                                    const env = window.location.hostname
                                    const back = 'recurrent_orders'
                                    // env !== 'becycle.biiim-solutions.com' &&
                                    // env !== 'localhost' &&
                                    // env !== 'dev.biiim-solutions.com'
                                    //     ? 'orders'
                                    //     : 'becycle_orders'
                                    this.creating = false
                                    this.dontRefreshPrice = false

                                    this.$emit('updated', back)
                                    return true
                                }
                            } catch (err) {
                                this.creating = false
                                this.$toast.error(
                                    `${this.$t('order_update_ko')}`,
                                )
                                this.dontRefreshPrice = false

                                return false
                            }
                            this.dontRefreshPrice = false

                            this.creating = false
                        } else {
                            this.dontRefreshPrice = false

                            this.creating = false
                            return false
                        }
                    }
                } else {
                    this.creating = false
                    this.dontRefreshPrice = false

                    this.$toast.error(this.$t('form_errors'))
                    return false
                }
            } else {
                this.creating = false
                this.dontRefreshPrice = false

                this.$toast.error(this.$t('bad_packages'))
                return false
            }
        },
    },
    unmounted() {
        clearInterval(this.currentOrderInterval)
        this.currentOr = null
        this.currentRec = null
        this.clientStore.$patch({
            addresses: null,
            currentAddress: null,
            containers: null,
            currentContacts: null,
            currentContainer: null,
            current: null,
        })
        this.formData = {
            WithVAT: true,
            IdUniverse: 0,
            Name: '',
            IdClient: '',
            IdUserContact: '',
            IdDriver: '',
            IdVehicle: '',
            VehicleType: '',
            IdInvoice: '',
            // Reference: '',
            Price: 0,
            ForcedPrice: 0,
            Status: 'new',
            Method: 'normal',
            OrderClientType: '',
            PaymentType: '',
            Latitude: '',
            Longitude: '',
            CommentInternal: '',
            CommentClient: '',
            OrderStepsHTML: null,
            CreationUserId: '',
            OrderSteps: [],
            OrderPackages: [],
            DateBegin: '',
            DateEnd: '',
            OrderOptions: null,
            InvoiceNumber: '',
            OrderOption: [],
            ModificationUserId: '',
            PacketNumber: 0,
            GroupName: '',
            ONUs: [],
            Sales: null,
        }
    },
}
</script>
<style lang="scss" scoped>
.stop-stretching {
    width: 1%;
    white-space: nowrap;
}
#moment-select ::v-deep(.multiselect) {
    height: auto;
    width: 11rem;
}
.selectMS ::v-deep(.multiselect) {
    .multiselect-placeholder {
        font-size: small !important;
    }
}
.vatMS ::v-deep(.multiselect) {
    .multiselect-single-label-text {
        overflow: unset;
    }
}
.articleMs ::v-deep(.multiselect) {
    border: unset !important;
    font-size: x-small !important;

    min-height: unset !important;
    .multiselect-placeholder {
        font-size: x-small !important;
        white-space: nowrap;
        overflow: hidden;
    }
    .multiselect-single-label-text {
        white-space: nowrap;
        text-overflow: unset;
        font-size: x-small !important;
        white-space: nowrap;
        padding-right: calc(2.3rem + var(--ms-px, 0.875rem));
    }
    .multiselect-wrapper {
        min-height: unset !important;
        font-size: x-small !important;
        overflow-y: auto !important;
    }
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: x-small !important;
    }
    .multiselect-wrapper .multiselect-caret {
        z-index: unset !important;
    }
}
</style>
