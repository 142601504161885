<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'users'" />
            <div v-else>
                <filtrable-table
                    id="usersList"
                    :class="searchingCursor === true ? 'cursor-progress' : ''"
                    :items="users"
                    :columns="columns"
                    :storeToSort="'users'"
                    :page="page"
                    :count="count"
                    :loading="loading"
                    @perPageChanged="changePerPage($event)"
                    @updateSort="displaySearchResults($event)"
                    :perPage="perPage"
                    :sortDirection="sortDirection"
                    :sortableFields="sortableFields"
                    :totalPages="totalPages"
                    :searchQuery="searchQuery"
                    :refresh="refresh"
                    :canAddNew="canAddNew()"
                    :itemActions="itemActions"
                    :multiActions="multiActions"
                    @removeroles="removeUserRole($event)"
                    @removeuniverses="removeUserUniverse($event)"
                    @addRolesToUsers="showAddRolesToUsers($event)"
                    @addUniversesToUsers="showAddUniversesToUsers($event)"
                    @pageChanged="setPage($event)"
                    @searched="debounceSearch"
                    @sortDirectionChanged="updateSortDirection($event)"
                    @selected="upFilter($event)"
                    @create="$emit('create')"
                    @clear="clearFilter($event)"
                    @update="goToUpdateOne($event)"
                    @delete="deleteOne($event)"
                    @deleteAll="deleteAll($event)"
                    @sendResetPasswordLink="sendLink($event)"
                >
                </filtrable-table>
            </div>
        </div>
        <modal
            v-if="showAddRolesModal"
            :headerText="'add_roles'"
            :tcChoice="3"
            :buttonText="'save'"
            :creating="searchingCursor"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="showAddRolesToUsers()"
            @sendFromModal="addRolesToUsers()"
        >
            <template #body>
                <div class="mb-3 mt-2 px-8">
                    <span class="items-center justify-center mt-2 flex pb-2">
                        {{
                            `${usersToAddRolesIds.length} ${$t(
                                'selected_users',
                                usersToAddRolesIds.length > 1 ? 2 : 1,
                            )}`
                        }}
                    </span>
                    <div
                        class="h-full overflow-y-auto border-t-2 border-b-2 themed-border"
                    >
                        <div
                            v-for="(role, index) in roles"
                            :key="index"
                            :class="index === roles.length - 1 ? 'mb-1' : ''"
                        >
                            <div class="flex items-center mt-1 justify-start">
                                <input
                                    :id="`checkbox-${index}`"
                                    type="checkbox"
                                    v-model="isCheckedRole(role.Id).value"
                                    @change="checkRole(role.Id)"
                                    class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                />
                                <label
                                    :for="`checkbox-${index}`"
                                    class="ml-2 font-medium"
                                >
                                    {{
                                        `
                                            ${role.Name} :  ${role.Description}`
                                    }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
        <modal
            v-if="showAddUniversesModal"
            :headerText="'add_universes'"
            :tcChoice="3"
            :buttonText="'save'"
            :creating="searchingCursor"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="showAddUniversesToUsers()"
            @sendFromModal="addUniversesToUsers()"
        >
            <template #body>
                <div class="mb-3 mt-2 px-8">
                    <span class="items-center justify-center mt-2 flex pb-2">
                        {{
                            `${usersToAddUniversesIds.length} ${$t(
                                'selected_users',
                                usersToAddUniversesIds.length > 1 ? 2 : 1,
                            )}`
                        }}
                    </span>
                    <div class="border-t-2 border-b-2 themed-border">
                        <div class="mb-2 items-center mt-2">
                            <Multiselect
                                class="border darky rounded px-3 py-2 mt-1 w-full"
                                v-model="universesToAddIds"
                                mode="tags"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="true"
                                :options="universesOptions"
                                :allow-empty="false"
                                :can-deselect="false"
                                :can-clear="false"
                                label="name"
                                track-by="name"
                            ></Multiselect>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
        <modal
            v-if="showDeleteConfirmModal"
            :headerText="deleteModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :mxWidth="'max-w-2xl'"
            :buttonAlign="'justify-center'"
            @closeModal="deleteConfirmation(false)"
            @sendFromModal="deleteConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center mt-8">
                        {{ $t(deleteModalText) }}
                    </div>

                    <div class="flex justify-center mt-2">
                        {{ elementToDelete }}
                    </div>
                    <div class="flex text-xs italic justify-center mt-4">
                        {{ $t('delete_modal_generic_text_2') }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useAllUsersStore } from '@/stores/allUsersStore'
import { useRoleStore } from '@/stores/roleStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'
import societyEventBus from '@/mixins/societyEventBusMixin'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Users',
    mixins: [filterList, societyEventBus],

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
    },
    data() {
        return {
            refresh: 0,
            usersToAddRolesIds: null,
            showAddRolesModal: false,
            rolesToAddIds: [],
            usersToAddUniversesIds: null,
            showAddUniversesModal: false,
            universesToAddIds: [],
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            showDeleteConfirmModal: false,
            confirmDelete: null,
            elementToDelete: '',
            deleteModalHeader: '',
            deleteModalText: '',
            searchingCursor: false,
            usersInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        users() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        clearInterval(this.usersInterval)
        const filteredList = this.$cookies.get('filtered')
        this.columns = [
            // {
            //     name: 'active',
            //     filtrable: true,
            //     dbField: 'Active',
            //     type: 'select',
            //     sortable: false,
            //     withIconNoText: true,
            //     selected: {
            //         name: this.$t('active'),
            //         value: 1,
            //         dbField: 'Active',
            //     },
            //     options: [
            //         {
            //             name: 'active',
            //             value: 1,
            //             dbField: 'Active',
            //         },
            //         {
            //             name: 'deactivated',
            //             value: 0,
            //             dbField: 'Active',
            //         },
            //     ],
            // },
            {
                name: 'firstname',
                filtrable: true,
                dbField: 'FirstName',
                type: 'search',
                sortable: true,
            },
            {
                name: 'lastname',
                filtrable: true,
                dbField: 'LastName',
                type: 'search',
                sortable: true,
            },
            {
                name: 'email',
                filtrable: true,
                dbField: 'Email',
                type: 'search',
                sortable: true,
            },
            {
                name: 'login_only',
                filtrable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.usersActionsAccess.update,
                ),
                routeName: 'update_user',

                dbField: 'Login',
                type: 'search',
                sortable: true,
            },
            {
                name: 'type',
                filtrable: true,
                type: 'select',
                dbField: 'Type',
                sortable: false,
                options: this.clientTypeOptions,
            },
            {
                name: 'universes',
                dbField: 'Universe',
                filtrable: false,
                sortable: false,
                enum: true,
                enumNoGrid: true,
                canRemoveEnum: checkAccess(
                    this.userStore,
                    this.usersActionsAccess.assignUniverse,
                ),
            },
            {
                name: 'roles',
                filtrable: false,
                dbField: 'Role',
                sortable: false,
                enum: true,
                enumNoGrid: true,
                canRemoveEnum: checkAccess(
                    this.userStore,
                    this.usersActionsAccess.assignRole,
                ),
            },
            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        const roleSearch = {
            sortDirection: 'ASC',
            sortField: 'Name',
            perPage: 100000,
            page: this.page,
            query: [],
            filter: [],
        }
        this.searchRoles(roleSearch)

        if (filteredList) {
            this.search = filteredList.query
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                if (!this.filter.find((f) => f.dbField === 'universes')) {
                    const universeFilter = {
                        dbField: 'universes',
                        value:
                            this.selectedUniverses.length > 0
                                ? this.selectedUniverses
                                : this.universesOptions,
                    }
                    this.filter.push(universeFilter)
                }

                this.setResults()
                this.eventBus.on('trigger-universe-search', (param) => {
                    this.searchCustomUniverses(param)
                })
                this.eventBus.on('remove-from-selected-universe', (index) => {
                    this.removeFromSelectedUniverses(index)
                })
            }
        }, 10)

        window.scrollTo(0, 0)
    },

    computed: {
        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        ...mapState(useUserStore, {
            connectedUser: 'current',
        }),
        usersActionsAccess() {
            return actionsAccess.users
        },
        ...mapWritableState(useAllUsersStore, {
            users: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore),

        ...mapState(useRoleStore, { roles: 'all' }),

        itemActions() {
            const actions = []

            if (checkAccess(this.userStore, this.usersActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (checkAccess(this.userStore, this.usersActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }
            if (
                checkAccess(this.userStore, this.usersActionsAccess.resetPass)
            ) {
                actions.push({
                    name: 'send_rp_link',
                    action: 'sendResetPasswordLink',
                    icon: 'envelope',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.usersActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            if (
                checkAccess(this.userStore, this.usersActionsAccess.assignRole)
            ) {
                actions.push({
                    name: 'add_roles',
                    tc: 3,
                    action: 'addRolesToUsers',
                    icon: 'masks-theater',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.usersActionsAccess.assignUniverse,
                )
            ) {
                actions.push({
                    name: 'add_universes',
                    tc: 3,
                    action: 'addUniversesToUsers',
                    icon: ['fab', 'grav'],
                })
            }

            return actions
        },
        clientTypeOptions() {
            const options = [
                {
                    name: 'client',
                    value: 'client',
                    dbField: 'Type',
                },
                {
                    name: 'driver',
                    value: 'driver',
                    dbField: 'Type',
                },
                {
                    name: 'operator',
                    value: 'operator',
                    dbField: 'Type',
                },
            ]
            if (
                this.userStore.current &&
                this.userStore.current.type === 'admin'
            ) {
                options.push({
                    name: 'admin',
                    value: 'admin',
                    dbField: 'Type',
                })
            }
            if (
                this.userStore.current &&
                this.userStore.current.type === 'superadmin'
            ) {
                options.push({
                    name: 'admin',
                    value: 'admin',
                    dbField: 'Type',
                })
                options.push({
                    name: 'super_admin',
                    value: 'superadmin',
                    dbField: 'Type',
                })
            }

            return options
        },
    },
    methods: {
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        upFilter(event) {
            clearInterval(this.usersInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(this.userStore, this.usersActionsAccess.create)
        },
        ...mapActions(useRoleStore, {
            searchRoles: 'search',
        }),

        ...mapActions(useAllUsersStore, {
            searchUsers: 'search',
            delete: 'delete',
            deleteUserRole: 'deleteUserRole',
            addUserRole: 'addUserRole',
            deleteUserUniverse: 'deleteUserUniverse',
            addUserUniverse: 'addUserUniverse',
        }),
        searchCustomUniverses(clear = false) {
            clearInterval(this.usersInterval)

            let filter = null
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                // this.selectedUniverses = []
                filter = {
                    dbField: 'universes',
                    value: this.universesOptions, // TDO universOptions?
                }
            } else {
                filter = {
                    dbField: 'universes',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.usersInterval)

            this.searchCustomUniverses()
        },
        sendLink(id) {
            const user = this.users.find(
                (userToResetPW) => userToResetPW.id === id,
            )
            this.userStore
                .forgotPassword({
                    Email: user.email,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.$toast.success(this.$t('reset_password_link_sent'))
                    } else {
                        this.$toast.error(
                            this.$t('reset_password_link_not_sent'),
                        )
                    }
                })
                .catch((error) => {
                    this.$toast.error(this.$t('reset_password_link_not_sent'))
                })
        },
        goToUpdateOne(id) {
            clearInterval(this.usersInterval)

            this.$emit('toUpdate', id)
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.delete(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('user_delete_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('user_delete_ko'))
            }
        },
        removeUserRole(userRole) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `${this.$t('role')} : ${userRole.Name}`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.searchingCursor = true
                        this.deleteUserRole(userRole.IdUserRole)
                            .then(() => {
                                this.userStore.refreshConnectedUser(true)

                                this.setResults()
                                this.searchingCursor = false

                                this.$toast.success(this.$t('deleted_ok'))
                            })
                            .catch(() => {
                                this.searchingCursor = false

                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    }
                }
            }, 150)
        },
        removeUserUniverse(userUniverse) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `${this.$t('universe', 1)} : ${
                userUniverse.Name
            }`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.searchingCursor = true
                        this.deleteUserUniverse(userUniverse.IdUserUniverse)
                            .then(() => {
                                this.setResults()
                                this.userStore.refreshConnectedUser(false, true)

                                this.searchingCursor = false

                                this.$toast.success(this.$t('deleted_ok'))
                            })
                            .catch(() => {
                                this.searchingCursor = false

                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    }
                }
            }, 150)
        },
        deleteConfirmation(confirmDelete) {
            this.confirmDelete = confirmDelete
            this.searchingCursor = confirmDelete
            this.showDeleteConfirmModal = false
        },
        deleteAll(elementsTodelete) {
            this.searchingCursor = true

            elementsTodelete.forEach((el, index) => {
                this.delete(el)
                if (index === elementsTodelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        isCheckedRole(roleId) {
            if (this.rolesToAddIds.find((el) => el === roleId)) {
                return { value: true }
            }
            return { value: false }
        },
        checkRole(roleId) {
            const roleIndex = this.rolesToAddIds.findIndex(
                (el) => el === roleId,
            )
            if (roleIndex === -1) {
                this.rolesToAddIds.push(roleId)
            } else {
                this.rolesToAddIds.splice(roleIndex, 1)
            }
        },
        showAddRolesToUsers(usersIds = []) {
            this.rolesToAddIds = []
            this.usersToAddRolesIds = usersIds

            this.showAddRolesModal = !this.showAddRolesModal
        },
        addRolesToUsers() {
            this.searchingCursor = true

            this.usersToAddRolesIds.forEach((userId, index) => {
                this.rolesToAddIds.forEach((roleId) => {
                    const payload = {
                        IdUser: userId,
                        IdRole: roleId,
                        CreationUserId: this.userStore.current.id,
                        ModificationUserId: this.userStore.current.id,
                    }
                    this.addUserRole(payload)
                })
                if (index === this.usersToAddRolesIds.length - 1) {
                    this.searchingCursor = false
                    this.userStore.refreshConnectedUser(true)
                    this.setResults()

                    this.showAddRolesToUsers()
                }
            })
        },
        showAddUniversesToUsers(usersIds = []) {
            this.universesToAddIds = []
            this.usersToAddUniversesIds = usersIds

            this.showAddUniversesModal = !this.showAddUniversesModal
        },
        addUniversesToUsers() {
            this.searchingCursor = true
            this.usersToAddUniversesIds.forEach((userId, index) => {
                this.universesToAddIds.forEach((universeId) => {
                    const payload = {
                        IdUser: userId,
                        IdUniverse: universeId,
                        CreationUserId: this.userStore.current.id,
                        ModificationUserId: this.userStore.current.id,
                    }
                    this.addUserUniverse(payload)
                })
                if (index === this.usersToAddUniversesIds.length - 1) {
                    this.userStore.refreshConnectedUser(false, true)
                    this.searchingCursor = false
                    this.setResults()
                    this.showAddUniversesToUsers()
                }
            })
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.usersInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.usersInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })

            this.setRealSearch(this.search)

            this.searchUsers(search)
            this.refresh += 1
            this.searchingCursor = false

            this.$cookies.set('filtered', search)
            this.usersInterval = setInterval(() => {
                this.searchUsers(search)
            }, 20000)

            window.scrollTo(0, 0)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.usersInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },
        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
    unmounted() {
        clearInterval(this.usersInterval)
        this.users = null
        this.count = null
        this.eventBus.all.clear()
    },
}
</script>
