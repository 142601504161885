import Invoices from '../views/billings/List.vue'
import UnpaidInvoices from '../views/billings/UnpaidInvoices.vue'
import Wrapper from '../views/billings/Wrapper.vue'

const invoicings = [
    {
        path: '/invoicing',
        name: 'invoicings',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'BILLINGS_FULL', 'BILLINGS_LIST'],
            showUniverseSearch: true,
            showSocietySearch: true,
        },
        children: [
            {
                path: 'invoices',
                name: 'invoices',
                component: Invoices,
                meta: {
                    layout: 'full-layout',
                    authorisations: [
                        'FULL_ACCESS',
                        'BILLINGS_FULL',
                        'BILLINGS_LIST',
                    ],
                    showUniverseSearch: true,
                    showSocietySearch: true,
                },
            },
            {
                path: 'unpaid-invoices',
                name: 'unpaid_invoices',
                component: UnpaidInvoices,
                meta: {
                    layout: 'full-layout',
                    authorisations: [
                        'FULL_ACCESS',
                        'BILLINGS_FULL',
                        'BILLINGS_LIST',
                    ],
                    showUniverseSearch: true,
                    showSocietySearch: true,
                },
            },
            {
                path: 'my-invoices',
                name: 'my_invoices',
                component: Invoices,
                meta: {
                    layout: 'full-layout',
                    authorisations: [
                        'FULL_ACCESS',
                        'BILLINGS_FULL',
                        'BILLINGS_LIST',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: false,
                },
            },
        ],
    },
]

export default invoicings
