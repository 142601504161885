<template>
    <main class="px-4 pt-2 rounded-md darky h-full" style="width: 96vw">
        <back-to @back="back()" />
        <div
            id="create-cLient"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div>
                <div class="p-5">
                    <form @keydown.enter.prevent @submit.prevent="update()">
                        <div>
                            <div class="rounded-md p-4">
                                <div class="grid grid-cols-6 gap-4">
                                    <div
                                        class="col-span-3 rounded-md border-2 p-4"
                                        :class="
                                            userStore.isB2C === true
                                                ? 'col-span-3 '
                                                : 'col-span-6'
                                        "
                                    >
                                        <div class="px-3">
                                            <div class="mb-5 flex">
                                                <span class="text-lg">
                                                    {{
                                                        $t(
                                                            'society_informations',
                                                        )
                                                    }}
                                                    <hr />
                                                </span>
                                            </div>
                                            <div
                                                id="siret"
                                                class="mb-3 items-center"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{
                                                        $t(
                                                            'registration_number',
                                                        ) + ' *'
                                                    }}
                                                </label>
                                                <input
                                                    autocomplete="siret"
                                                    v-model="societyForm.Siret"
                                                    @blur="setBySiret()"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.societyForm
                                                    .Siret.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                id="name"
                                                class="mb-3 items-center"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{
                                                        $t('company_name') +
                                                        ' *'
                                                    }}
                                                </label>
                                                <input
                                                    autocomplete="name"
                                                    v-model="societyForm.Name"
                                                    @blur="
                                                        v$.societyForm.Name
                                                            .$touch
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.societyForm
                                                    .Name.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div
                                                id="slogan"
                                                class="mb-3 items-center"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{ $t('slogan') }}
                                                </label>
                                                <input
                                                    autocomplete="slogan"
                                                    v-model="societyForm.Slogan"
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                id="address"
                                                class="mb-3 items-center"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{ $t('address') + ' *' }}
                                                </label>
                                                <input
                                                    id="autocomplete"
                                                    v-model="
                                                        societyForm.Address1
                                                    "
                                                    @blur="
                                                        v$.societyForm.Address1.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{
                                                        $t('postal_code') + ' *'
                                                    }}
                                                </label>
                                                <input
                                                    autocomplete="postal_code"
                                                    v-model="
                                                        societyForm.PostalCode
                                                    "
                                                    @blur="
                                                        v$.societyForm.PostalCode.$touch()
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.societyForm
                                                    .PostalCode.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                            <div class="mb-3 items-center">
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                    >{{ $t('city') + ' *' }}
                                                </label>
                                                <input
                                                    autocomplete="city"
                                                    v-model="societyForm.City"
                                                    @blur="
                                                        v$.societyForm.City
                                                            .$touch
                                                    "
                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                />
                                            </div>
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.societyForm
                                                    .City.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="map"
                                            style="width: 100%; height: 500px"
                                        ></div>
                                    </div>
                                </div>
                                <span
                                    class="flex mt-1 italic text-xs items-center justify-center"
                                >
                                    {{ $t('required_fields') }}
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import IconButton from '@/components/atoms/IconButton.vue'
import BackTo from '@/components/elements/BackTo.vue'
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { mapStores } from 'pinia'
import { ref } from 'vue'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)
let marker = null
export default {
    name: 'MyAccount',
    components: { IconButton, BackTo },
    mixins: [googleScriptLoader, gmapStyles],

    setup() {
        const isPasswordStrong = ref(false)

        return { isPasswordStrong, v$: useVuelidate() }
    },
    created() {
        this.map = null
        this.loadGoogleScript('autocomplete')
    },
    // TODO ajouter la modif du mdp comme dans suppliers
    mounted() {
        window.scrollTo(0, 0)
        this.userStore.fetchSociety(this.$route.params.id).then((res) => {
            this.societyForm = {
                Name: res.data.Name,
                Slogan: res.data.Slogan,
                Address1: res.data.Address1,
                Address2: res.data.Address2,
                Address3: res.data.Address3,
                Address4: res.data.Address4,
                PostalCode: res.data.PostalCode,
                City: res.data.City,
                Country: res.data.Country ?? 'FRA',
                Latitude: +res.data.Latitude,
                Longitude: +res.data.Longitude,
                PaymentProvider: res.data.PaymentProvider,
                Siren: res.data.Siren,
                Nic: res.data.Nic,
                Siret: res.data.Siret,
                CodeAPE: res.data.CodeAPE,
            }
            const googleInterval = setInterval(() => {
                if (google) {
                    clearInterval(googleInterval)
                    let center = null
                    if (
                        this.societyForm.Latitude &&
                        this.societyForm.Longitude
                    ) {
                        center = {
                            lat: +this.societyForm.Latitude,
                            lng: +this.societyForm.Longitude,
                        }
                        this.map = new google.maps.Map(
                            document.getElementById('map'),
                            {
                                center,
                                zoom: 15,
                            },
                        )
                        marker = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,

                            position: center,
                            title: this.societyForm.Name,
                            draggable: true,
                        })
                        marker.setMap(this.map)
                        const eventScreen =
                            'ontouchstart' in document.documentElement
                                ? 'touchend'
                                : 'dragend'

                        marker.addListener(eventScreen, (evt) => {
                            this.markerDragged(evt)
                        })
                        this.setGoogleMapStyle(false)
                    }
                }
            }, 20)
        })

        // todo charger la map
    },
    data() {
        return {
            updating: false,
            societyForm: {},
            refreshMS: 0,
        }
    },
    validations() {
        return {
            societyForm: {
                Name: { required },
                Address1: { required },
                Siret: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },

                City: { required },
                // Longitude: { required },
                // Latitude: { required },
            },
        }
    },
    computed: {
        ...mapStores(useUserStore),
    },

    methods: {
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.$emit('back', backPath.slice(0, 1))
        },
        markerDragged(position) {
            this.societyForm.Latitude = position.latLng.lat()
            this.societyForm.Longitude = position.latLng.lng()
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the clientForm.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.societyForm.Address1 = ''

            this.societyForm.PostalCode = ''
            this.societyForm.City = ''

            const userInput = this.societyForm.Address1
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name

                if (addressType === 'postal_code') {
                    this.societyForm.PostalCode = val
                } else if (addressType === 'locality') {
                    this.societyForm.City = val
                } else if (addressType === 'street_number') {
                    this.societyForm.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.societyForm.Address1 =
                        this.societyForm.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.societyForm.Address1} ${val}`
                            : `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.societyForm.Address1 === userInput
                ) {
                    this.societyForm.Address1 = ` ${val}`
                }
            })
        },

        async update() {
            this.updating = true

            if (this.needPwdReset === true) {
                this.societyForm.Password = this.password
            }
            const pwOk =
                (await this.v$.password.$validate()) &&
                (await this.v$.confirmPassword.$validate())
            if (this.needPwdReset === false || pwOk === true) {
                const issocietyFormCorrect =
                    await this.v$.societyForm.$validate()

                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (
                    // isClientFormCorrect === true &&
                    issocietyFormCorrect === true
                ) {
                    const params = {
                        id: this.$route.params.id,
                        form: this.societyForm,
                    }
                    try {
                        let res = null

                        res = await this.userStore.updateSociety(params)

                        if (res && res.status === 200) {
                            this.needPwdReset = false
                            this.userStore.refreshConnectedUser(
                                false,
                                true,
                                this.userStore.current.id,
                            )

                            this.$toast.success(this.$t('user_update_ok'))
                            this.updating = false

                            this.back()
                        } else {
                            this.updating = false
                            this.$toast.error(`${this.$t('user_update_ko')}`)
                        }
                    } catch (err) {
                        this.updating = false

                        this.$toast.error(`${this.$t('user_update_ko')}`)
                        this.$toast.error(err.data.message)
                    }
                } else {
                    this.updating = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.updating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {},
}
</script>
