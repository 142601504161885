<template>
    <div class="px-4 darky pt-2 rounded-md h-full">
        <fetch-data-error v-if="fetchError" :dataType="'templates'" />

        <span v-else style="width: 96vw">
            <div
                class="darky bg-white mb-20 mt-10 z-20 px-4 w-full fixed"
                style="width: -webkit-fill-available"
            >
                <div class="font-bold text-xl mt-16 mb-4 items-center">
                    <fa-icon :icon="listIcon" class="mr-2"></fa-icon>
                    {{ $t(listName) }}
                </div>
                <div class="grid grid-cols-2 gap-2 items-center">
                    <span class="col-span-1 items-center flex">
                        <search-input
                            :key="refresh"
                            :searchBy="'search_name'"
                            :dbField="'Name'"
                            :small="false"
                            :inputHeight="'height: 2.5rem;'"
                            :searchQuery="searchQuery"
                            :searchIcon="'magnifying-glass'"
                            :animated="'fa-fade'"
                            @searched="searchByName($event)"
                        >
                            <!-- @changeSearch="$emit('changeSearch', index)" -->
                        </search-input>
                        <span class="ml-2 w-1/3 mt-1">
                            <Multiselect
                                class="multiselect-no-pr darky"
                                v-model="selectedActive"
                                :options="activeOptions"
                                label="name"
                                track-by="name"
                                :searchable="false"
                                :can-deselect="false"
                                :allow-empty="false"
                                :can-clear="false"
                                :object="false"
                                @select="changeActiveStatus($event)"
                            ></Multiselect>
                        </span>
                    </span>

                    <span class="col-span-1 flex justify-end">
                        <button
                            v-if="templateType !== 'email' && canCreateTemplate"
                            type="button"
                            @click.prevent="openAddOrUpdate()"
                            class="themed-button text-base focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                        >
                            <fa-icon icon="plus" class="fa-sm mr-2"></fa-icon>

                            {{ $t('add_template') }}
                        </button>
                    </span>
                </div>
                <hr class="my-4" />
            </div>
            <div
                v-if="count === 0"
                class="flex items-center text-zinc-500 justify-center mt-56"
            >
                {{ $t('no_records_found') }}
            </div>
            <span v-else>
                <span v-if="templateType === 'email'">
                    <div
                        v-for="(emailTemplate, indexTemplate) in templates"
                        :key="indexTemplate"
                    >
                        <div
                            class="h-full"
                            :class="indexTemplate === 0 ? 'mt-56' : ''"
                        >
                            <div class="grid grid-cols-12 gap-1">
                                <span
                                    class="col-span-3 flex items-center justify-center"
                                >
                                    <h3
                                        class="text-md font-bold my-1 theme-color items-center"
                                    >
                                        <fa-icon
                                            :icon="
                                                +emailTemplate.Active === 1
                                                    ? 'check'
                                                    : 'xmark'
                                            "
                                            class="fa-xl mr-1"
                                            :class="
                                                +emailTemplate.Active === 1
                                                    ? 'success-msg'
                                                    : 'error-msg'
                                            "
                                            :title="
                                                $t(
                                                    +emailTemplate.Active === 1
                                                        ? 'activated'
                                                        : 'deactivated',
                                                )
                                            "
                                        ></fa-icon>
                                        {{ $t(emailTemplate.Name) }}
                                    </h3>
                                </span>
                                <span class="col-span-9 w-full h-full">
                                    <iframe
                                        :srcdoc="
                                            emailTemplate
                                                .CommunicationTemplateContent
                                                .FieldHtmlPart
                                        "
                                        class="h-96"
                                        style="width: -webkit-fill-available"
                                    >
                                    </iframe>
                                </span>
                            </div>
                            <hr class="my-4" />
                        </div>
                    </div>
                </span>
                <span v-else>
                    <span
                        v-if="templateType === 'whatsapp'"
                        class="grid grid-cols-3 mt-56"
                    >
                        <div
                            v-for="(waTemplate, indexTemplate) in templates"
                            :key="indexTemplate"
                        >
                            <div class="justify-center col-span-1">
                                <h3
                                    class="text-md font-bold my-1"
                                    :class="
                                        canUpdateTemplate === false
                                            ? 'theme-color'
                                            : 'custom-link cursor-pointer'
                                    "
                                    @click.prevent="
                                        canUpdateTemplate
                                            ? openAddOrUpdate(waTemplate)
                                            : ''
                                    "
                                >
                                    <fa-icon
                                        :icon="
                                            +waTemplate.Active === 1
                                                ? 'check'
                                                : 'xmark'
                                        "
                                        class="fa-xl mr-1"
                                        :class="
                                            +waTemplate.Active === 1
                                                ? 'success-msg'
                                                : 'error-msg'
                                        "
                                        :title="
                                            $t(
                                                +waTemplate.Active === 1
                                                    ? 'activated'
                                                    : 'deactivated',
                                            )
                                        "
                                    ></fa-icon>
                                    {{ $t(waTemplate.Name) }}
                                </h3>
                                <whats-app-template
                                    :avatarUrl="`/logo/${userStore.logo}header-nobg.png`"
                                    :contactName="
                                        waTemplate.CommunicationTemplateContent
                                            .FieldSenderName
                                    "
                                    :messages="[
                                        {
                                            sender: waTemplate
                                                .CommunicationTemplateContent
                                                .FieldSenderName,
                                            text: waTemplate
                                                .CommunicationTemplateContent
                                                .FieldTextPart,
                                        },
                                    ]"
                                ></whats-app-template>
                            </div>
                        </div>
                    </span>
                    <span v-else class="grid grid-cols-3 mt-56">
                        <div
                            v-for="(smsTemplate, indexTemplate) in templates"
                            :key="indexTemplate"
                        >
                            <div class="justify-center col-span-1">
                                <h3
                                    class="text-md font-bold my-1"
                                    :class="
                                        canUpdateTemplate === false
                                            ? 'theme-color'
                                            : 'custom-link cursor-pointer'
                                    "
                                    @click.prevent="
                                        canUpdateTemplate
                                            ? openAddOrUpdate(smsTemplate)
                                            : ''
                                    "
                                >
                                    <fa-icon
                                        :icon="
                                            +smsTemplate.Active === 1
                                                ? 'check'
                                                : 'xmark'
                                        "
                                        class="fa-xl mr-1"
                                        :class="
                                            +smsTemplate.Active === 1
                                                ? 'success-msg'
                                                : 'error-msg'
                                        "
                                        :title="
                                            $t(
                                                +smsTemplate.Active === 1
                                                    ? 'activated'
                                                    : 'deactivated',
                                            )
                                        "
                                    ></fa-icon>
                                    {{ $t(smsTemplate.Name) }}
                                </h3>
                                <sms-template
                                    :headerText="
                                        smsTemplate.CommunicationTemplateContent
                                            .FieldSenderName
                                    "
                                    :messages="[
                                        {
                                            sender: smsTemplate
                                                .CommunicationTemplateContent
                                                .FieldSenderName,
                                            text: smsTemplate
                                                .CommunicationTemplateContent
                                                .FieldTextPart,
                                        },
                                    ]"
                                >
                                </sms-template>
                            </div>
                        </div>
                    </span>
                </span>
            </span>
            <list-pagination
                class="max-w-screen-2xl"
                v-if="count > 0"
                :perPage="perPage"
                :page="page"
                :totalPages="totalPages"
                :count="count"
                @back="back()"
                @next="next()"
                :openDirection="'top'"
                @perPageChanged="changePerPage($event)"
            ></list-pagination>
        </span>

        <create-or-update-template
            v-if="showCreateOrUpdate"
            :templateType="templateType"
            :currentTemplate="currentTemplate"
            @close="openAddOrUpdate()"
        >
        </create-or-update-template>
    </div>
</template>
<script>
import ListPagination from '@/components/elements/ListPagination.vue'
import SearchInput from '@/components/elements/SearchInput.vue'
import SmsTemplate from '@/components/elements/SmsTemplate.vue'
import WhatsAppTemplate from '@/components/elements/WhatsAppTemplate.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useNotificationStore } from '@/stores/notificationStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState, mapStores } from 'pinia'
import CreateOrUpdateTemplate from './CreateOrUpdateTemplate.vue'


export default {
    name: 'TemplatesList',
    inject: ['eventBus'],
    components: {
        ListPagination,
        SearchInput,
        WhatsAppTemplate,
        Multiselect,
        SmsTemplate,
        CreateOrUpdateTemplate,
    },
    data() {
        return {
            currentTemplate: null,
            sortDirection: 'DESC',
            sortField: 'Name',
            perPage: 10,
            page: 1,
            refresh: 0,
            debounce: null,
            searchQuery: '',
            eventTypeInterval: null,
            formData: {},
            payload: {},
            filter: [],
            templateType: 'email',
            listName: 'available_email_templates',
            listIcon: 'envelopes-bulk',
            showCreateOrUpdate: false,
            selectedActive: 1,
            activeOptions: [
                { value: 1, name: this.$t('active') },
                { value: 0, name: this.$t('deactivated') },
                { value: 2, name: this.$t('all') },
            ],
        }
    },
    mounted() {
        if (this.$route.query.to === 'whatsAppTemplates') {
            this.templateType = 'whatsapp'
            this.listIcon = ['fab', 'whatsapp']
            this.listName = 'available_wa_templates'
        }
        if (this.$route.query.to === 'smsTemplates') {
            this.templateType = 'sms'
            this.listIcon = 'comment-sms'
            this.listName = 'available_sms_templates'
        }

        this.payload = {
            query: [],
            filter: [
                { dbField: 'Type', value: this.templateType },
                { dbField: 'Active', value: this.selectedActive },
            ],
            sortDirection: this.sortDirection,
            sortField: this.sortField,
            perPage: this.perPage,
            page: this.page,
        }
        this.displaySearchResults(this.payload)
        this.eventBus.on('trigger-change-society', (index) => {
            this.displaySearchResults(this.payload)
        })
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapState(useNotificationStore, {
            templates: 'templates',
            count: 'templatesCount',
            fetchError: 'fetchError',
            totalPages: 'totalPagesTemplates',
            loading: 'loading',
        }),
        templatesActionsAccess() {
            return actionsAccess.notifications.templates
        },
        canCreateTemplate() {
            return checkAccess(
                this.userStore,
                this.templatesActionsAccess.create,
            )
        },
        canUpdateTemplate() {
            return checkAccess(
                this.userStore,
                this.templatesActionsAccess.update,
            )
        },
        canDeleteTemplate() {
            return checkAccess(
                this.userStore,
                this.templatesActionsAccess.delete,
            )
        },
    },
    watch: {
        templates() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    methods: {
        ...mapActions(useNotificationStore, ['getTemplates']),
        back() {
            this.page -= 1
            this.payload.page = this.page
            this.displaySearchResults(this.payload)
        },
        next() {
            this.page += 1
            this.payload.page = this.page
            this.displaySearchResults(this.payload)
        },
        openAddOrUpdate(template = null) {
            if (template) {
                this.currentTemplate = template
            } else {
                this.currentTemplate = null
            }
            this.showCreateOrUpdate = !this.showCreateOrUpdate
            if (this.showCreateOrUpdate === false) {
                this.displaySearchResults(this.payload)
            }
        },
        changePerPage(perPage) {
            this.perPage = perPage
            this.payload.perPage = perPage
            this.displaySearchResults(this.payload)
        },
        changeActiveStatus(option = 2) {
            if (option === 2) {
                this.payload.filter = [
                    { dbField: 'Type', value: this.templateType },
                ]
                this.displaySearchResults(this.payload)
                return
            }
            this.payload.filter = [
                { dbField: 'Type', value: this.templateType },
                { dbField: 'Active', value: option },
            ]
            this.displaySearchResults(this.payload)
        },
        searchByName(evt) {
            this.searchQuery = evt.term
            if (evt.term === '') {
                this.payload.query = []
                this.displaySearchResults(this.payload)
                return
            }
            this.payload.query = [{ dbField: 'Name', term: evt.term }]

            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.displaySearchResults(this.payload)
            }, 1000)
        },
        displaySearchResults(payload) {
            this.getTemplates(payload)
        },
    },
}
</script>
