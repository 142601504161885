<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'tourSimulations'" />
            <filtrable-table
                v-else
                id="tourSimulationsList"
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                :items="simulations"
                :columns="columns"
                :storeToSort="'tourSimulations'"
                :page="page"
                :count="count"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :itemActions="itemActions"
                :canAddNew="canAddNew()"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @oldView="goToSimulation($event)"
                @update="simulationView($event)"
                @updateStep="goToUpdateStep($event)"
                @delete="deleteOne($event)"
                @relaunch="relaunchSimulation($event)"
                @deleteAll="deleteAll($event)"
                @relaunchAll="relaunchAll($event)"
                @mergeSim="mergeSim($event)"
                @goToCorrection="goToCorrection($event)"
            >
                <!-- @viewContacts="$emit('contacts', $event)"
                @viewDeliveryAddresses="$emit('clientDeliveryAdresses', $event)" -->
            </filtrable-table>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import { mapActions, mapState, mapStores } from 'pinia'

import FetchDataError from '@/components/atoms/FetchDataError.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useSimulationStore } from '@/stores/simulationStore'
import { useUserStore } from '@/stores/userStore'
import societyEventBus from '@/mixins/societyEventBusMixin'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Tours',
    mixins: [filterList, societyEventBus],
    components: {
        FiltrableTable,
        FetchDataError,
    },
    data() {
        return {
            columns: [],
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],
            toursInterval: null,

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    watch: {
        simulations() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    // TODO pour le lien si erreur dans le calcul => envoyer vers ordersList => avec pour la recherche nom client et step incorrect
    // TODO toursimulation_orderstep => recupérer les steps erronés mettre lien dans le rapport (changer le texte de retour) renvoyer vers
    // TODO la page correction des données avec un filtre sur les ids des steps récupérés vie la table ci-dessus
    created() {
        window.scrollTo(0, 0)
        this.columns = [
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                isLink: checkAccess(this.userStore, this.simActionsAccess.map),
                routeName: 'simulation_view',
            },
            {
                name: 'steps_count',
                dbField: 'NbSteps',
            },
            {
                name: 'drivers',
                dbField: 'ConstraintDriver',
            },
            {
                name: 'vehicles',
                dbField: 'ConstraintVehicle',
            },
            {
                name: 'areas',
                dbField: 'ConstraintSector',
            },

            {
                name: 'status',
                filtrable: true,
                dbField: 'Status',
                type: 'select',
                sortable: false,
                options: [
                    {
                        name: 'simulation_status.in_progress',
                        value: 'inProgress',
                        dbField: 'Status',
                    },
                    {
                        name: 'simulation_status.finished',
                        value: 'finished',
                        dbField: 'Status',
                    },
                    {
                        name: 'simulation_status.requested',
                        value: 'request',
                        dbField: 'Status',
                    },
                    {
                        name: 'simulation_status.archived',
                        value: 'archived',
                        dbField: 'Status',
                    },
                    {
                        name: 'simulation_status.errors',
                        value: 'errors',
                        dbField: 'Status',
                    },
                ],
            },
            {
                name: 'tours_count',
                dbField: 'NbTours',
            },
            {
                name: 'distance',
                dbField: 'Distance',
            },
            {
                name: 'duration',
                dbField: 'Duration',
            },
            {
                name: 'not_affected',
                dbField: 'NbStepsUnaffected',
            },

            {
                name: 'report',
                filtrable: false,
                sortable: false,
            },

            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        const filteredList = this.$cookies.get('filtered')
        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    unmounted() {
        clearInterval(this.toursInterval)
    },

    computed: {
        simActionsAccess() {
            return actionsAccess.simulations
        },
        ...mapStores(useUserStore),
        ...mapState(useSimulationStore, {
            simulations: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),

        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.simActionsAccess.map)) {
                actions.push({
                    name: 'map',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (checkAccess(this.userStore, this.simActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }
            if (checkAccess(this.userStore, this.simActionsAccess.update)) {
                actions.push({
                    name: 'relaunch',
                    action: 'relaunch',
                    icon: 'sync',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []

            if (checkAccess(this.userStore, this.simActionsAccess.update)) {
                actions.push({
                    name: 'relaunch_selection',
                    action: 'relaunchAll',
                    icon: 'sync',
                })
                actions.push({
                    name: 'merge',
                    action: 'mergeSim',
                    icon: 'compress-arrows-alt',
                })
            }
            if (checkAccess(this.userStore, this.simActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }

            return actions
        },
    },
    methods: {
        upFilter(event) {
            clearInterval(this.toursInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(this.userStore, this.simActionsAccess.create)
        },
        ...mapActions(useSimulationStore, {
            relaunch: 'relaunch',
            delete: 'delete',
            searchSimulation: 'search',
            merge: 'merge',
        }),
        goToSimulation(id) {
            clearInterval(this.toursInterval)

            this.$emit('goToSimulation', id)
        },
        simulationView(id) {
            this.$emit('simulationView', id)
        },
        goToUpdateStep(event) {
            this.$emit('goToUpdateStep', event)
        },
        goToCorrection(date) {
            this.$emit('goToCorrection', date)
        },
        async deleteOne(id) {
            this.searchingCursor = true

            const response = await this.delete(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$emit('deleted')
                this.$toast.success(this.$t('deleted_ok'))

                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('simulation_delete_ko'))
            }
        },
        async relaunchSimulation(id) {
            this.searchingCursor = true

            const response = await this.relaunch(id)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('simulation_generation_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('simulation_generation_ko'))
            }
        },
        relaunchAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.relaunch(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        deleteAll(elToDelete) {
            this.searchingCursor = true

            elToDelete.forEach((el, index) => {
                this.delete(el)
                if (index === elToDelete.length - 1) {
                    this.searchingCursor = false
                }
            })
        },
        async mergeSim(ids) {
            this.searchingCursor = true

            const response = await this.merge(ids)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('simulation_merge_ok'))
                this.setResults()
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('simulation_merge_ko'))
            }
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.toursInterval)
            this.$cookies.remove('filtered')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.toursInterval)
            this.$cookies.remove('filtered')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)

            this.searchSimulation(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filtered', search)
            this.toursInterval = setInterval(() => {
                this.searchSimulation(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.toursInterval)
            this.$cookies.remove('filtered')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },
        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
