<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <fetch-data-error v-if="fetchError" :dataType="'contracts'" />
        <div
            v-if="fetchError"
            class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
        >
            <button
                type="button"
                @click="setContractForm(null)"
                class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
            >
                <fa-icon icon="plus" class="fa-sm mr-2"></fa-icon>

                {{ $t('add_contract') }}
            </button>
        </div>

        <span v-else>
            <!-- <div>
                <div class="flex items-center justify-center mt-14 mb-4">
                    <input
                        id="container-for-all-checkbox"
                        type="checkbox"
                        v-model="onlyLatePlus30Todo"
                        @change="onlyLatePlus30TodoChanged()"
                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                    />
                    <label
                        for="container-for-all-checkbox"
                        class="ml-2 font-medium theme-color"
                        >{{ $t('only_late_plus_todo_30') }}</label
                    >
                </div>
            </div> -->
            <filtrable-table
                id="contractsList"
                :items="contracts"
                :smallMT="false"
                :columns="columns"
                :storeToSort="'contracts'"
                :page="page"
                :count="count"
                :loading="loading"
                :multiActions="multiActions"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew && showContractModal === false"
                :itemActions="itemActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="setContractForm(null)"
                @clear="clearFilter($event)"
                @update="setContractForm($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
            </filtrable-table>
            <contract-modal
                v-if="showContractModal === true"
                :clientOptions="clientOptions"
                :formData="contractFormData"
                :update="isUpdate"
                @resetContractForm="resetContractForm()"
                @searchChanged="searchClientsQuery($event)"
            ></contract-modal>
        </span>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import ContractModal from '@/components/elements/ContractModal.vue'
import clientMultiselectDebouncer from '@/mixins/clientMultiselectDebouncer'
import contractOptions from '@/mixins/contractOptions'
import filterList from '@/mixins/filterList'
import societyEventBus from '@/mixins/societyEventBusMixin'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useContractStore } from '@/stores/contractStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    name: 'ContractsList',
    components: { FiltrableTable, FetchDataError, ContractModal },

    mixins: [
        filterList,
        contractOptions,
        clientMultiselectDebouncer,
        societyEventBus,
    ],

    created() {
        clearInterval(this.contractsInterval)
        const filteredList = this.$cookies.get('filteredContractsList')

        window.scrollTo(0, 0)
        this.columns = [
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                isLink: true,
            },
            {
                name: 'client',
                filtrable: true,
                type: 'search',

                dbField: 'Client.Name',
            },
            {
                name: 'contract_type',
                filtrable: true,
                dbField: 'Type',
                type: 'select',
                canSearch: true,
                translate: true,

                canBeEmpty: true,
                canBeCleared: true,
                sortable: false,
                withIconNoText: false,
                optionAlreadyTranslated: true,
                options: this.contractTypeOptions,
            },
            {
                name: 'signature_date',
                dbField: 'SignatureDate',
                sortable: true,
            },
            {
                name: 'end_date',
                dbField: 'DateEnd',
                sortable: true,
            },

            {
                name: 'auto_renew',
                filtrable: true,
                dbField: 'AutomaticRenewal',
                type: 'select',
                sortable: false,
                withIconNoText: false,
                canSearch: true,
                canBeEmpty: true,
                translate: true,
                canBeCleared: true,
                optionAlreadyTranslated: true,

                options: this.renewalOptions,
            },
            {
                name: 'contract_duration',
                filtrable: true,
                dbField: 'Duration',
                type: 'select',
                sortable: false,
                withIconNoText: false,
                canSearch: true,
                canBeEmpty: true,
                optionAlreadyTranslated: true,
                translate: true,
                canBeCleared: true,
                options: this.monthOptions,
            },

            {
                name: 'notice',
                filtrable: true,
                dbField: 'NoticePeriod',
                type: 'select',
                sortable: false,
                withIconNoText: false,
                canSearch: true,
                canBeEmpty: true,
                canBeCleared: true,
                optionAlreadyTranslated: true,

                translate: true,
                options: this.weekOptionsNotice,
            },
            {
                name: 'alert_before_end',

                dbField: 'AlertBeforeEnd',
                filtrable: true,
                type: 'select',
                sortable: false,
                withIconNoText: false,
                canSearch: true,
                canBeEmpty: true,
                canBeCleared: true,
                translate: true,
                optionAlreadyTranslated: true,

                options: this.weekOptionsAlert,
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
            this.onlyLatePlus30Todo = filteredList.onlyLatePlus30Todo
        }
        let universeFilter = null
        const clientUniverseFilter = []
        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                if (!this.filter.find((f) => f.dbField === 'IdUniverse')) {
                    universeFilter = {
                        dbField: 'IdUniverse',
                        value:
                            this.selectedUniverses.length > 0
                                ? this.selectedUniverses
                                : this.universesOptions,
                    }
                    this.filter.push(universeFilter)
                    clientUniverseFilter.push(universeFilter)
                }
                this.universeFilterForClientSearch = universeFilter

                this.setResults()
                this.eventBus.on('trigger-universe-search', (param) => {
                    this.searchCustomUniverses(param)
                })
                this.eventBus.on('remove-from-selected-universe', (index) => {
                    this.removeFromSelectedUniverses(index)
                })
            }
        }, 10)
    },
    data() {
        return {
            columns: [],
            contractFormData: {},
            onlyLatePlus30Todo: true,
            searchingCursor: false,
            contractsInterval: null,
            clientOptionsForList: [],
            refresh: 0,
            refreshMS: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'ModificationDate',
            perPage: 100,
            page: 1,
            filter: [],
            isUpdate: false,
            showContractModal: false,

            sortableFields: [
                { name: this.$t('name'), value: 'name' },
                { name: this.$t('signature_date'), value: 'SignatureDate' },
                { name: this.$t('end_date'), value: 'DateEnd' },
            ],
            contact: null,
            // creating: false,
            // isUpdate: false,

            // creatingContract: false,
        }
    },

    computed: {
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.contractsActionAccess.create,
            )
        },

        ...mapState(useUniverseStore, {
            universes: 'all',
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        ...mapState(useContractStore, {
            contracts: 'all',
            count: 'count',
            totalPages: 'totalPages',
            loading: 'loading',
            fetchError: 'fetchError',
        }),
        contractsActionAccess() {
            return actionsAccess.clients.contracts
        },
        ...mapWritableState(useContractStore, {
            currentContract: 'current',
        }),
        ...mapState(useUserStore, {
            user: 'current',
        }),
        ...mapStores(useUserStore),
        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.contractsActionAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(this.userStore, this.contractsActionAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.contractsActionAccess.delete)
            ) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteAll',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    watch: {
        contracts() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    methods: {
        ...mapActions(useContractStore, {
            deleteContract: 'delete',

            searchContracts: 'search',
        }),
        ...mapActions(useClientStore, {
            searchClients: 'search',
        }),
        searchCustomUniverses(clear = false) {
            clearInterval(this.contractsInterval)
            let filter = null
            const clientUniverseFilter = []
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
                clientUniverseFilter.push(filter)
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
                clientUniverseFilter.push(filter)
            }
            this.universeFilterForClientSearch = filter

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.contractsInterval)
            this.searchCustomUniverses()
        },
        onlyLatePlus30TodoChanged() {
            this.searchingCursor = true
            clearInterval(this.contractsInterval)
            this.$cookies.remove('filteredContractsList')
            if (this.onlyLatePlus30Todo === true) {
                clearInterval(this.contractsInterval)

                this.clearFilter({ dbField: 'Status', multi: true })
            } else if (
                this.onlyLatePlus30Todo === false &&
                !this.filter.find((fl) => fl.dbField === 'Status')
            ) {
                clearInterval(this.contractsInterval)

                this.setResults()
            }
        },

        setContractForm(contractId = null) {
            clearInterval(this.contractsInterval)
            if (contractId === null) {
                this.contractFormData = {
                    Name: '',
                    SignatureDate: new Date().toISOString().substr(0, 10),
                    EndDate: new Date().toISOString().substr(0, 10),
                    CreationUserId: this.user.id,
                    ModificationUserId: this.user.id,
                    AutomaticRenewal: 'no',
                    Type: 'client',
                    Duration: 'none',
                    AlertBeforeEnd: 'none',
                    NoticePeriod: 'none',
                    IdUniverse: '',
                    IdClient: '',
                    Id: '',
                }
                this.showContractModal = true
                this.isUpdate = false
            } else {
                const contractToUpdate = this.contracts.find(
                    (contract) => contract.id === contractId,
                )
                this.isUpdate = true
                this.clientOptions.push({
                    name: contractToUpdate.client,
                    value: +contractToUpdate.id_client,
                })
                this.contractFormData = {
                    Name: contractToUpdate.name,
                    SignatureDate: contractToUpdate.signature_date.substr(
                        0,
                        10,
                    ),
                    EndDate: contractToUpdate.end_date
                        ? contractToUpdate.end_date.substr(0, 10)
                        : '',
                    CreationUserId: contractToUpdate.creation_user_id,
                    ModificationUserId: this.user.id,
                    AutomaticRenewal: contractToUpdate.auto_renew,
                    Type: contractToUpdate.contract_type,
                    Duration: contractToUpdate.contract_duration,
                    AlertBeforeEnd: contractToUpdate.alert_before_end,
                    NoticePeriod: contractToUpdate.notice,
                    IdUniverse: contractToUpdate.idUniverse,
                    IdClient: +contractToUpdate.id_client,
                    Id: contractToUpdate.id,
                }
                this.showContractModal = true
            }
        },
        async deleteOne(id) {
            const response = await this.deleteContract(id)
            if (response.status === 200) {
                this.setResults()
                this.$toast.success(this.$t('delete_ok'))
            } else {
                this.$toast.error(this.$t('delete_ko'))
            }
        },
        deleteAll(elToDelete) {
            elToDelete.forEach((el) => {
                this.deleteContract(el)
            })
            this.setResults()
        },
        upFilter(event) {
            clearInterval(this.contractsInterval)

            this.updateFilter(event)
        },
        resetContractForm() {
            // this.v$.contractFormData.$reset()
            this.showContractModal = false
            this.isUpdate = false
            // this.creatingContract = false
            // this.badTimeRange = false

            this.contractFormData = {}
            this.setResults()
        },

        setPage(isNext) {
            this.searchingCursor = true
            clearInterval(this.contractsInterval)
            this.$cookies.remove('filteredContractsList')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            clearInterval(this.contractsInterval)
            this.$cookies.remove('filteredContractsList')
            this.search = search.query

            this.sortDirection = search.sortDirection
            this.filter = search.filter

            this.sortField =
                search.sortField === 'client' ? '' : search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.onlyLatePlus30Todo = search.onlyLatePlus30Todo
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })
            this.setRealSearch(this.search)
            this.searchContracts(search).then(() => {
                this.searchingCursor = false
                this.$cookies.set('filteredContractsList', search)
                this.contractsInterval = setInterval(() => {
                    this.searchContracts(search)
                }, 20000)
            })
            this.refresh += 1
        },
        changePerPage(perPage) {
            clearInterval(this.contractsInterval)
            this.$cookies.remove('filteredContractsList')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            clearInterval(this.contractsInterval)
            if (
                this.filter.find((fl) => fl.dbField === 'Status') &&
                this.onlyLatePlus30Todo === true
            ) {
                this.onlyLatePlus30Todo = false
            }
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
                onlyLatePlus30Todo: this.onlyLatePlus30Todo,
            }
            this.displaySearchResults(search)
        },
    },
    unmounted() {
        clearInterval(this.contractsInterval)
        this.eventBus.all.clear()
    },
}
</script>
