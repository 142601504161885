import Defenses from '../views/harbors/DefensesList.vue'
import Docks from '../views/harbors/DocksList.vue'
import Inspections from '../views/harbors/InspectionsList.vue'
import Wrapper from '../views/harbors/Wrapper.vue'

const harbors = [
    {
        path: '/harbors',
        name: 'harbors',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'HARBORS_LIST', 'HARBORS_FULL'],
            showUniverseSearch: false,
            showSocietySearch: true,
        },
        children: [
            {
                path: 'docks',
                name: 'docks',
                component: Docks,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'HARBORS_FULL',
                        'DOCKS_LIST',
                        'DOCKS_FULL',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: true,
                },
            },
            {
                path: 'defenses',
                name: 'defenses',
                component: Defenses,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'HARBORS_FULL',
                        'DOCKS_FULL',
                        'DEFENSES_FULL',
                        'DEFENSES_LIST',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: true,
                },
            },
            {
                path: 'inspections',
                name: 'inspections',
                component: Inspections,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'HARBORS_FULL',
                        'DOCKS_FULL',
                        'INSPECTIONS_FULL',
                        'INSPECTIONS_LIST',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: true,
                },
            },
        ],
    },
]

export default harbors
