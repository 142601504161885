import NotificationsSettings from '../views/settings/NotificationsWrapper.vue'
import OrdersSettings from '../views/settings/OrdersSettings.vue'
import PricingsParams from '../views/settings/pricings/PricingParamsList.vue'
import PricingsList from '../views/settings/pricings/PricingsList.vue'
import PricingsPostalGroups from '../views/settings/pricings/PricingsPostalGroupsList.vue'
import PricingsSettings from '../views/settings/PricingsSettingsWrapper.vue'
import SettingsWrapper from '../views/settings/Wrapper.vue'

const settings = [
    {
        path: '/settings',
        name: 'settings',
        // redirect: '/login',
        component: SettingsWrapper,
        redirect: { name: 'orders_settings' },
        meta: {
            layout: 'full-layout',
            authorisations: [
                'FULL_ACCESS',
                'SETTINGS_FULL',
                'SETTINGS_ORDERSCODES',
                'SETTINGS_NOTIFICATIONS',
                'SETTINGS_PRICINGS',
            ],
            showUniverseSearch: false,
            showSocietySearch: true,
        },
        children: [
            {
                path: 'orders',
                name: 'orders_settings',
                component: OrdersSettings,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SETTINGS_FULL',
                        'SETTINGS_ORDERSCODES',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: true,
                },
            },
            {
                path: 'notifications',
                name: 'notifications_settings',
                component: NotificationsSettings,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SETTINGS_FULL',
                        'SETTINGS_NOTIFICATIONS',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: true,
                },
            },
            {
                path: 'pricings',
                name: 'pricings_settings',
                component: PricingsSettings,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'SETTINGS_FULL',
                        'SETTINGS_PRICINGS',
                        'PRICINGS_FULL',
                        'PRICINGS_LIST',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: true,
                },
                children: [
                    {
                        path: 'pricings',
                        name: 'pricings_list',
                        component: PricingsList,
                        redirect: { name: 'pricings_settings' },
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'SETTINGS_FULL',
                                'SETTINGS_PRICINGS',
                                'PRICINGS_FULL',
                                'PRICINGS_LIST',
                            ],
                            showUniverseSearch: false,
                            showSocietySearch: true,
                        },
                    },
                    {
                        path: 'params',
                        name: 'pricings_params',
                        component: PricingsParams,
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'PRICINGS_FULL',
                                'PRICINGS_PARAMS_LIST',
                            ],
                            showUniverseSearch: false,
                            showSocietySearch: true,
                        },
                    },
                    {
                        path: 'postal-groups',
                        name: 'pricing_postal_groups',
                        component: PricingsPostalGroups,
                        meta: {
                            authorisations: [
                                'FULL_ACCESS',
                                'PRICINGS_FULL',
                                'PRICINGS_POSTALGROUPS_LIST',
                            ],
                            showUniverseSearch: false,
                            showSocietySearch: true,
                        },
                    },
                ],
            },
            // {
            //     path: 'company/cgv',
            //     name: 'cgv',
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'SETTINGS_FULL', 'CGV_SETTINGS_FULL'],
            //     },
            // },
            // {
            //     path: 'company/vehicles-dispatch',
            //     name: 'vehicle_dispatch',
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'SETTINGS_FULL', 'V_SETTINGS_FULL'],
            //     },
            // },
            // {
            //     path: 'user/:email',
            //     name: 'user_params',
            //     meta: {
            //         authorisations: ['FULL_ACCESS', 'SETTINGS_FULL', 'ORDERS_SETTINGS_FULL'],
            //     },
            // },
        ],
    },
]

export default settings
