<template>
    <div class="mx-auto bg-white">
        <stepper
            :key="refresh"
            :givenStep="currentStep"
            :noPrevious="noPrevious"
            :totalStepsCount="totalStepsCount"
            @next="currentStep += 1"
            @prev="currentStep -= 1"
            @submit="saveNotification()"
            @cancel="resetForm()"
        >
            <template v-if="currentStep === 1" #step1>
                <div class="space-y-4">
                    <h2 class="text-2xl font-bold mb-1">
                        {{
                            `${$t('type_event')} ${
                                isDuplicate === true
                                    ? ' - (' + $t('copy_noun') + ')'
                                    : ''
                            }`
                        }}
                    </h2>
                    <hr class="mb-2" />
                    <div class="grid grid-cols-6 gap-4">
                        <!-- <div v-if="fromTrigger" class="col-span-6">
                            <Multiselect
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="notificationForm.Id"
                                :options="notificationOptions"
                                label="name"
                                track-by="name"
                                :searchable="true"
                                :placeholder="$t('select_one')"
                                :allow-empty="true"
                                :can-clear="true"
                                @select="setNotification($event)"
                                @clear="setNotification()"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-if="noEventTypeError"
                            >
                                <div class="error-msg">
                                    {{ $t('no_results') }}
                                </div>
                            </div>
                        </div> -->
                        <!-- <div
                            v-if="!isUpdate"
                            class="col-span-6 text-base font-bold"
                        >
                            {{ $t('or') + ' ' + $t('create') }}
                        </div> -->
                        <div class="col-span-2">
                            <label class="block mb-2">{{ $t('name') }}</label>
                            <input
                                v-model="notificationForm.Name"
                                @blur="v$.notificationForm.Name.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.name')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.notificationForm.Name
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>

                        <div class="col-span-2">
                            <label class="block mb-2">{{
                                $t('EntityName')
                            }}</label>
                            <Multiselect
                                :id="'delaiUnit' + index"
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="notificationForm.EntityName"
                                :options="entitiesOptions"
                                :hide-selected="true"
                                label="name"
                                track-by="name"
                                :searchable="false"
                                :placeholder="$t('select_one')"
                                :allow-empty="false"
                                :can-clear="false"
                                :object="false"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.notificationForm.EntityName
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2">{{ $t('code') }}</label>
                            <input
                                :disabled="isUpdate"
                                v-model="notificationForm.Code"
                                @blur="v$.notificationForm.Code.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('harbor_form.name')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.notificationForm.Code
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="block mb-2">{{
                            $t('description')
                        }}</label>
                        <textarea
                            v-model="notificationForm.Description"
                            class="border rounded px-3 py-2 mt-1 h-32 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.notificationForm.Description
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center pt-4 justify-center">
                        <input
                            id="set-active-checkbox"
                            type="checkbox"
                            v-model="notificationForm.Active"
                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                        />
                        <label
                            for="set-active-checkbox"
                            class="ml-2 font-medium"
                            >{{ $t('activate_event') }}</label
                        >
                    </div>
                </div>
            </template>
            <template v-if="currentStep === 2" #step2>
                <div class="justify-center my-4">
                    <h2 class="text-2xl font-bold mb-1">
                        {{
                            notificationForm.Name +
                            ' / ' +
                            notificationForm.EntityName +
                            ' / ' +
                            notificationForm.Code
                        }}
                    </h2>
                    <div class="text-sm italic">
                        {{ notificationForm.Description }}
                    </div>
                </div>
                <hr class="mb-3" />
                <div class="space-y-4">
                    <h2 class="text-xl flex justify-center font-bold mb-1">
                        {{ `${$t('trigger_tc', 2)}` }}
                    </h2>
                    <hr class="mb-3" />
                    <div>
                        <div>
                            <div
                                v-for="(
                                    trigger, index
                                ) in notificationForm.EventTriggers"
                                :key="index"
                            >
                                <div
                                    class="grid grid-cols-12 gap-2 mb-2 items-center"
                                >
                                    <div class="col-span-6">
                                        <label
                                            :for="'triggerMS' + index"
                                            class="font-medium"
                                            >{{ $t('trigger_tc', 1) }}</label
                                        >
                                        <Multiselect
                                            :id="'triggerMS' + index"
                                            v-model="trigger.Action"
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            :options="triggersOptions"
                                            :hide-selected="true"
                                            label="name"
                                            track-by="name"
                                            :searchable="true"
                                            :placeholder="$t('select_trigger')"
                                            :allow-empty="false"
                                            :can-clear="false"
                                            :object="false"
                                            @select="
                                                selectTrigger($event, index)
                                            "
                                        ></Multiselect>
                                        <!-- <Multiselect
                                            v-else
                                            :id="'triggerMS' + index"
                                            v-model="trigger.Id"
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            :options="triggersOptions"
                                            :hide-selected="true"
                                            label="name"
                                            track-by="name"
                                            :searchable="true"
                                            :placeholder="$t('select_trigger')"
                                            :allow-empty="false"
                                            :can-clear="false"
                                            :object="false"
                                            @select="
                                                selectTrigger($event, index)
                                            "
                                        ></Multiselect> -->
                                        <div
                                            class="text-xs italic mb-2"
                                            v-for="error of v$.notificationForm
                                                .EventTriggers.$each.$response
                                                .$errors[index].Action"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-3">
                                        <label
                                            :for="'delai' + index"
                                            class="font-medium"
                                            >{{ $t('delay') }}</label
                                        >
                                        <input
                                            :id="'delai' + index"
                                            type="number"
                                            :min="0"
                                            :step="1"
                                            v-model="trigger.Delai"
                                            class="w-full px-3 py-2 border rounded-md"
                                        />
                                        <div
                                            class="text-xs italic mb-2"
                                            v-for="error of v$.notificationForm
                                                .EventTriggers.$each.$response
                                                .$errors[index].Delai"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-2">
                                        <label
                                            :for="'delaiUnit' + index"
                                            class="font-medium"
                                            >{{ $t('unit_delay') }}</label
                                        >
                                        <Multiselect
                                            :id="'delaiUnit' + index"
                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                            v-model="trigger.UnitDelai"
                                            :options="delaiUnitOptions"
                                            :hide-selected="true"
                                            label="name"
                                            track-by="name"
                                            :searchable="false"
                                            :placeholder="$t('select_one')"
                                            :allow-empty="false"
                                            :can-clear="false"
                                            :object="false"
                                        ></Multiselect>
                                        <div
                                            class="text-xs italic mb-2"
                                            v-for="error of v$.notificationForm
                                                .EventTriggers.$each.$response
                                                .$errors[index].UnitDelai"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-1">
                                        <div class="flex justify-end">
                                            <label
                                                :for="'trash-canIcon' + index"
                                                class="font-medium"
                                            ></label>
                                            <fa-icon
                                                :id="'trash-canIcon' + index"
                                                icon="trash-can"
                                                :title="$t('remove')"
                                                class="cursor-pointer error-msg mt-3"
                                                @click.prevent="
                                                    selectTrigger(
                                                        trigger,
                                                        index,
                                                        true,
                                                    )
                                                "
                                            ></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        trigger.IdTemplateEmail ||
                                        trigger.IdTemplateSMS ||
                                        trigger.IdTemplateWhatsApp
                                    "
                                    class="border-2 border-green-300"
                                >
                                    <div class="text-xl text-green-300 my-2">
                                        {{ $t('selected_templates', 1) }}
                                    </div>
                                    <div
                                        v-if="
                                            trigger.CommunicationTemplateEmail
                                        "
                                        class="w-full h-full"
                                        :key="refreshIFrame"
                                    >
                                        <h3 class="text-lg font-bold mt-4">
                                            {{
                                                $t(
                                                    trigger
                                                        .CommunicationTemplateEmail
                                                        .Name,
                                                )
                                            }}
                                        </h3>
                                        <iframe
                                            :srcdoc="
                                                trigger
                                                    .CommunicationTemplateEmail
                                                    .CommunicationTemplateContent
                                                    .FieldHtmlPart
                                            "
                                            class="h-96"
                                            style="
                                                width: -webkit-fill-available;
                                            "
                                        >
                                        </iframe>
                                    </div>
                                    <div
                                        v-if="trigger.CommunicationTemplateSMS"
                                        class="w-full h-full"
                                        :key="refreshIFrame"
                                    >
                                        <h3 class="text-lg font-bold mt-4">
                                            {{
                                                $t(
                                                    trigger
                                                        .CommunicationTemplateSMS
                                                        .Name,
                                                )
                                            }}
                                        </h3>
                                        <sms-template
                                            :headerText="
                                                trigger.CommunicationTemplateSMS
                                                    .CommunicationTemplateContent
                                                    .FieldSenderName
                                            "
                                            :messages="[
                                                {
                                                    sender: trigger
                                                        .CommunicationTemplateSMS
                                                        .CommunicationTemplateContent
                                                        .FieldSenderName,
                                                    text: trigger
                                                        .CommunicationTemplateSMS
                                                        .CommunicationTemplateContent
                                                        .FieldTextPart,
                                                },
                                            ]"
                                        ></sms-template>
                                    </div>
                                    <div
                                        v-if="
                                            trigger.CommunicationTemplateWhatsApp
                                        "
                                        class="w-full h-full"
                                        :key="refreshIFrame"
                                    >
                                        <h3 class="text-lg font-bold mt-4">
                                            {{
                                                $t(
                                                    trigger
                                                        .CommunicationTemplateWhatsApp
                                                        .Name,
                                                )
                                            }}
                                        </h3>
                                        <whats-app-template
                                            :avatarUrl="`/logo/${logo}header-nobg.png`"
                                            :contactName="
                                                trigger
                                                    .CommunicationTemplateWhatsApp
                                                    .CommunicationTemplateContent
                                                    .FieldSenderName
                                            "
                                            :messages="[
                                                {
                                                    sender: trigger
                                                        .CommunicationTemplateWhatsApp
                                                        .CommunicationTemplateContent
                                                        .FieldSenderName,
                                                    text: trigger
                                                        .CommunicationTemplateWhatsApp
                                                        .CommunicationTemplateContent
                                                        .FieldTextPart,
                                                },
                                            ]"
                                        ></whats-app-template>
                                    </div>
                                </div>
                                <div class="flex justify-center items-center my-2">
                                    <button
                                        class="themed-button text-base ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                        @click.prevent="showTemplates(index)"
                                    >
                                        <fa-icon
                                            :icon="
                                                displayTemplates[index] === true
                                                    ? 'eye-slash'
                                                    : 'eye'
                                            "
                                            class="fa-sm mr-2"
                                        ></fa-icon>
                                        {{
                                            $t(
                                                displayTemplates[index] === true
                                                    ? 'hide_templates'
                                                    : 'display_templates',
                                            )
                                        }}
                                    </button>
                                </div>
                                <hr class="my-4" />
                                <div>
                                    <span
                                        v-if="displayTemplates[index] === true"
                                    >
                                        <span
                                            v-if="
                                                emailTemplates.length > 0 &&
                                                trigger.Action.includes('Email')
                                            "
                                        >
                                            <div
                                                class="font-bold text-md my-4 items-center"
                                            >
                                                <fa-icon
                                                    icon="envelopes-bulk"
                                                    class="mr-2"
                                                ></fa-icon>
                                                {{
                                                    $t(
                                                        'available_email_templates',
                                                    )
                                                }}
                                            </div>
                                            <hr class="my-4" />
                                            <div
                                                v-for="(
                                                    emailTemplate, indexTemplate
                                                ) in emailTemplates"
                                                :key="indexTemplate"
                                            >
                                                <div class="w-full h-full">
                                                    <h3
                                                        class="text-md font-medium my-1 theme-color"
                                                    >
                                                        {{
                                                            $t(
                                                                emailTemplate.Name,
                                                            )
                                                        }}
                                                    </h3>
                                                    <div
                                                        class="flex items-center py-2 justify-center"
                                                    >
                                                        <input
                                                            :id="
                                                                'emailTemplate' +
                                                                indexTemplate
                                                            "
                                                            :checked="
                                                                notificationForm
                                                                    .EventTriggers
                                                                    .length > 0
                                                                    ? emailTemplate.Id ===
                                                                      notificationForm
                                                                          .EventTriggers[
                                                                          index
                                                                      ]
                                                                          .IdTemplateEmail
                                                                    : false
                                                            "
                                                            type="radio"
                                                            name="inline-radio-group"
                                                            class="cursor-pointer darky"
                                                            :value="template"
                                                            @change="
                                                                setTemplate(
                                                                    index,
                                                                    'email',
                                                                    emailTemplate,
                                                                )
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                'emailTemplate' +
                                                                indexTemplate
                                                            "
                                                            class="ml-2 font-medium"
                                                            >{{
                                                                $t(
                                                                    'select_this_template',
                                                                )
                                                            }}</label
                                                        >
                                                    </div>

                                                    <iframe
                                                        :srcdoc="
                                                            emailTemplate
                                                                .CommunicationTemplateContent
                                                                .FieldHtmlPart
                                                        "
                                                        class="h-96"
                                                        style="
                                                            width: -webkit-fill-available;
                                                        "
                                                    >
                                                    </iframe>
                                                    <hr class="my-4" />
                                                </div>
                                            </div>
                                        </span>
                                        <span
                                            v-if="
                                                smsTemplates.length > 0 &&
                                                trigger.Action.includes('Sms')
                                            "
                                        >
                                            <div
                                                class="font-bold text-md mb-2 items-center"
                                            >
                                                <fa-icon
                                                    icon="comment-sms"
                                                    class="mr-2"
                                                ></fa-icon>
                                                {{
                                                    $t(
                                                        'available_sms_templates',
                                                    )
                                                }}
                                            </div>
                                            <hr class="my-4" />

                                            <div
                                                v-for="(
                                                    smsTemplate,
                                                    indexsmsTemplate
                                                ) in smsTemplates"
                                                :key="indexsmsTemplate"
                                            >
                                                <div class="w-full h-full">
                                                    <h3
                                                        class="text-md font-medium my-1 theme-color"
                                                    >
                                                        {{
                                                            $t(smsTemplate.Name)
                                                        }}
                                                    </h3>
                                                    <div
                                                        class="flex items-center py-2 justify-center"
                                                    >
                                                        <input
                                                            :id="
                                                                'smsTemplate' +
                                                                indexsmsTemplate
                                                            "
                                                            type="radio"
                                                            name="inline-radio-group-sms"
                                                            class="cursor-pointer darky"
                                                            :value="template"
                                                            :checked="
                                                                notificationForm
                                                                    .EventTriggers
                                                                    .length > 0
                                                                    ? smsTemplate.Id ===
                                                                      notificationForm
                                                                          .EventTriggers[
                                                                          index
                                                                      ]
                                                                          .IdTemplateSMS
                                                                    : false
                                                            "
                                                            @change="
                                                                setTemplate(
                                                                    index,
                                                                    'sms',
                                                                    smsTemplate,
                                                                )
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                'smsTemplate' +
                                                                indexsmsTemplate
                                                            "
                                                            class="ml-2 font-medium"
                                                            >{{
                                                                $t(
                                                                    'select_this_template',
                                                                )
                                                            }}</label
                                                        >
                                                    </div>
                                                    <sms-template
                                                        :headerText="
                                                            smsTemplate
                                                                .CommunicationTemplateContent
                                                                .FieldSenderName
                                                        "
                                                        :messages="[
                                                            {
                                                                sender: smsTemplate
                                                                    .CommunicationTemplateContent
                                                                    .FieldSenderName,
                                                                text: smsTemplate
                                                                    .CommunicationTemplateContent
                                                                    .FieldTextPart,
                                                            },
                                                        ]"
                                                    >
                                                    </sms-template>
                                                    <!-- <iframe
                                                    :srcdoc="
                                                        smsTemplate
                                                            .CommunicationTemplateContent
                                                            .FieldTextPart
                                                    "
                                                    style="
                                                        width: -webkit-fill-available;
                                                    "
                                                >
                                                </iframe> -->
                                                    <hr class="my-4" />
                                                </div>
                                            </div>
                                        </span>
                                        <span
                                            v-if="
                                                waTemplates.length > 0 &&
                                                trigger.Action.includes(
                                                    'WhatsApp',
                                                )
                                            "
                                        >
                                            <div
                                                class="font-bold text-md mb-2 items-center"
                                            >
                                                <fa-icon
                                                    icon="comment-sms"
                                                    class="mr-2"
                                                ></fa-icon>
                                                {{
                                                    $t('available_wa_templates')
                                                }}
                                            </div>
                                            <hr class="my-4" />

                                            <div
                                                v-for="(
                                                    waTemplate, indexWaTemplate
                                                ) in waTemplates"
                                                :key="indexWaTemplate"
                                            >
                                                <div class="w-full h-full">
                                                    <h3
                                                        class="text-md font-medium my-1 theme-color"
                                                    >
                                                        {{
                                                            $t(waTemplate.Name)
                                                        }}
                                                    </h3>
                                                    <div
                                                        class="flex items-center py-2 justify-center"
                                                    >
                                                        <input
                                                            :id="
                                                                'WaTemplate' +
                                                                indexWaTemplate
                                                            "
                                                            name="inline-radio-group-wa"
                                                            class="cursor-pointer darky"
                                                            :value="template"
                                                            type="radio"
                                                            :checked="
                                                                notificationForm
                                                                    .EventTriggers
                                                                    .length > 0
                                                                    ? waTemplate.Id ===
                                                                      notificationForm
                                                                          .EventTriggers[
                                                                          index
                                                                      ]
                                                                          .IdTemplateWhatsApp
                                                                    : false
                                                            "
                                                            @change="
                                                                setTemplate(
                                                                    index,
                                                                    'wa',
                                                                    waTemplate,
                                                                )
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                'WaTemplate' +
                                                                indexWaTemplate
                                                            "
                                                            class="ml-2 font-medium"
                                                            >{{
                                                                $t(
                                                                    'select_this_template',
                                                                )
                                                            }}</label
                                                        >
                                                    </div>
                                                    <whats-app-template
                                                        :avatarUrl="`/logo/${logo}header-nobg.png`"
                                                        :contactName="
                                                            waTemplate
                                                                .CommunicationTemplateContent
                                                                .FieldSenderName
                                                        "
                                                        :messages="[
                                                            {
                                                                sender: waTemplate
                                                                    .CommunicationTemplateContent
                                                                    .FieldSenderName,
                                                                text: waTemplate
                                                                    .CommunicationTemplateContent
                                                                    .FieldTextPart,
                                                            },
                                                        ]"
                                                    ></whats-app-template>
                                                    <!-- <iframe
                                                    :srcdoc="
                                                        waTemplate
                                                            .CommunicationTemplateContent
                                                            .FieldTextPart
                                                    "
                                                    style="
                                                        width: -webkit-fill-available;
                                                    "
                                                >
                                                </iframe> -->
                                                    <hr class="my-4" />
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div
                                v-if="
                                    notificationForm.EventTriggers.length ===
                                        0 ||
                                    notificationForm.EventTriggers[
                                        notificationForm.EventTriggers.length -
                                            1
                                    ].UnitDelai !== ''
                                "
                                class="flex justify-center items-center my-2"
                            >
                                <button
                                    type="button"
                                    @click.prevent="addTrigger()"
                                    class="themed-button text-base ml-2 focus:outline-none flex items-center justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                >
                                    <fa-icon
                                        icon="plus"
                                        class="fa-sm mr-2"
                                    ></fa-icon>

                                    {{ $t('add_trigger') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </stepper>
    </div>
</template>

<script>
import Stepper from '@/components/atoms/Stepper.vue'
import SmsTemplate from '@/components/elements/SmsTemplate.vue'
import WhatsAppTemplate from '@/components/elements/WhatsAppTemplate.vue'
import { useNotificationStore } from '@/stores/notificationStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    name: 'CreateOrUpdateNotification',
    components: {
        Stepper,
        Multiselect,
        SmsTemplate,
        WhatsAppTemplate,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            emailTemplates: [],
            smsTemplates: [],
            waTemplates: [],
            notificationOptions: [],
            refresh: 0,
            refreshIFrame: 0,
            displayTemplates: [],
            // selectedTriggers: [],
            triggersOptions: [],
            noTriggerError: false,
            notificationForm: {
                Id: '',
                Name: '',
                Description: '',
                Code: '',
                EntityName: '',
                Active: true,
                EventTriggers: [],
                EventTriggersToRemove: [],
            },
            currentStep: 1,
            delaiUnitOptions: [
                { name: this.$t('minutes'), value: 'minutes' },
                { name: this.$t('hours'), value: 'hours' },
                { name: this.$t('days'), value: 'days' },
            ],
        }
    },
    props: {
        currStep: {
            type: Number,
            default: 1,
        },
        totalStepsCount: {
            type: Array,
            default: () => [1, 2],
        },

        notificationId: {
            type: Number,
            default: null,
        },
        triggerId: {
            type: Number,
            default: null,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        isDuplicate: {
            type: Boolean,
            default: false,
        },
        noPrevious: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(useUserStore, { user: 'current', logo: 'logo' }),
        ...mapWritableState(useNotificationStore, {
            notification: 'current',
            entitiesOptions: 'entitiesOptions',
        }),
    },

    mounted() {
        this.triggersOptions = []

        // if (this.fromTrigger) {
        //     this.addTrigger()

        this.getTemplates({
            query: [],
            filter: [], // [{dbField: 'Active', value: 1}],
            sortDirection: 'ASC',
            sortField: 'Name',
            perPage: 10000,
            page: 1,
        }).then((res) => {
            this.emailTemplates = []
            if (res && res.status === 200) {
                res.data.Records.forEach((template) => {
                    if (
                        template.Type === 'email' &&
                        template.Name !== 'base_template'
                    ) {
                        this.emailTemplates.push(template)
                    }
                    if (template.Type === 'sms') {
                        this.smsTemplates.push(template)
                    }
                    if (template.Type === 'whatsapp') {
                        this.waTemplates.push(template)
                    }
                })
            }
        })
        // this.searchEventTypes({
        //     query: [],
        //     filter: [],
        //     sortDirection: 'ASC',
        //     sortField: 'Name',
        //     perPage: 10000,
        //     page: 1,
        // }).then((res) => {
        //     res.data.Records.forEach((eventType) => {
        //         const option = {
        //             value: eventType.Id,
        //             name: eventType.Name,
        //             Name: eventType.Name,
        //             Id: eventType.Id,
        //             Code: eventType.Code,
        //             EntityName: eventType.EntityName,
        //             Description: eventType.Description,
        //             Active: eventType.Active === 1,
        //             EventTriggers: eventType.EventTriggers,
        //         }
        //         this.notificationOptions.push(option)
        //     })
        // })
        this.triggersOptions = [
            {
                name: this.$t('sendEmail'),
                value: 'sendEmail',
                disabled: false,
            },
            { name: this.$t('sendSms'), value: 'sendSms', disabled: false },
            {
                name: this.$t('sendEmailWhatsApp'),
                value: 'sendEmailWhatsApp',
                disabled: false,
            },
            {
                name: this.$t('sendEmailSms'),
                value: 'sendEmailSms',
                disabled: false,
            },
            {
                name: this.$t('sendWhatsApp'),
                value: 'sendWhatsApp',
                disabled: false,
            },
            {
                name: this.$t('sendSmsWhatsApp'),
                value: 'sendSmsWhatsApp',
                disabled: false,
            },
            {
                name: this.$t('sendEmailSmsWhatsApp'),
                value: 'sendEmailSmsWhatsApp',
                disabled: false,
            },
        ]
        // } else {
        this.getEntities()

        // this.searchTriggers({
        //     query: [],
        //     filter: [],
        //     sortDirection: 'ASC',
        //     sortField: 'Action',
        //     perPage: 10000,
        //     page: 1,
        //     withEventType: this.fromTrigger,
        // })
        //     .then((res) => {
        //         res.data.Records.forEach((trigger) => {
        //             // todo set triggeroptions
        //             const option = {
        //                 value: trigger.Id,
        //                 name: `${trigger.Id} - ${this.$t(trigger.Action)}`,
        //                 Action: trigger.Action,
        //                 Delai: trigger.Delai,
        //                 UnitDelai: trigger.UnitDelai,
        //                 IdTemplateEmail: trigger.IdTemplateEmail,
        //                 IdTemplateSMS: trigger.IdTemplateSMS,
        //                 IdTemplateWhatsApp: trigger.IdTemplateWhatsApp,
        //                 TemplateEmail: trigger.CommunicationTemplateEmail,
        //                 TemplateSMS: trigger.CommunicationTemplateSMS,
        //                 TemplateWhatsApp:
        //                     trigger.CommunicationTemplateWhatsApp,
        //                 Id: trigger.Id,
        //             }
        //             this.triggersOptions.push(option)
        //         })
        //     })
        //     .catch((err) => {
        //         this.noTriggerError = true
        //     })
        // }

        if (
            this.isUpdate === true ||
            (this.isDuplicate === true && this.notificationId)
        ) {
            this.notificationForm.EventTriggers.forEach(() => {
                this.displayTemplates.push(false)
            })
            // if (this.notificationId) {
            this.fetchOne(this.notificationId).then((res) => {
                // this.currentStep = this.fromTrigger === false ? 1 : 2

                this.notificationForm = structuredClone(res.data)

                this.notificationForm.EventTriggersToRemove = []
                this.notificationForm.Active =
                    this.notificationForm.Active === 1

                if (this.isDuplicate === true) {
                    this.notificationForm.Id = ''
                    this.notificationForm.Name = `${this.$t(
                        'copy_noun',
                    )} - ${this.notificationForm.Name}`
                    this.notificationForm.Code = ''
                }
                this.refresh += 1
            })
        }
        // } else {
        //     this.fetchOneTrigger(this.triggerId).then((res) => {
        //         this.currentStep = this.fromTrigger === false ? 1 : 2

        //         this.notificationForm.EventTriggers[0] = structuredClone(
        //             res.data,
        //         )

        //         this.notificationForm.EventTriggersToRemove = []

        //         if (this.isDuplicate === true) {
        //             this.notificationForm.Id = ''
        //             this.notificationForm.Name = `${this.$t(
        //                 'copy_noun',
        //             )} - ${this.notificationForm.Name}`
        //             this.notificationForm.Code = ''
        //         }
        //         this.refresh += 1
        //     })
        // }
    },
    validations() {
        return {
            notificationForm: {
                Name: {
                    required,
                },
                Description: {
                    required,
                },
                Code: {
                    required,
                },
                EntityName: {
                    required,
                },
                EventTriggers: {
                    $each: helpers.forEach({
                        Delai: {
                            required,
                        },
                        UnitDelai: {
                            required,
                        },
                        Action: {
                            required,
                        },
                    }),
                },
            },
            // selectedTriggers: {
            //     $each: helpers.forEach({
            //         Delai: {
            //             required,
            //         },
            //         UnitDelai: {
            //             required,
            //         },
            //         Action: {
            //             required,
            //         },
            //     }),
            // },
        }
    },
    methods: {
        ...mapActions(useNotificationStore, [
            'create',
            'update',
            'fetchOne',
            'fetchOneTrigger',
            'createTrigger',
            'updateTrigger',
            'searchEventTypes',
            'searchTriggers',
            'getTemplates',
            'getEntities',
        ]),

        resetForm() {
            this.notificationForm = {
                Id: '',
                Name: '',
                Description: '',
                Code: '',
                EntityName: '',
                Active: true,
                EventTriggers: [],
                EventTriggersToRemove: [],
            }

            this.currentStep = 1
            this.$emit('cancel')
        },
        showTemplates(index) {
            this.displayTemplates[index] = !this.displayTemplates[index]
        },
        setNotification(notificationId = null) {
            if (notificationId === null) {
                this.notificationForm = {
                    Id: '',
                    Name: '',
                    Code: '',
                    EntityName: '',
                    Active: true,
                    Description: '',
                    EventTriggers: [],
                    EventTriggersToRemove: [],
                }

                this.currentStep = 1
                return
            }
            const notification = this.notificationOptions.find(
                (pr) => pr.Id === notificationId,
            )
            this.notificationForm = notification
        },
        addTrigger() {
            this.notificationForm.EventTriggers.push({
                Id: '',
                Delai: 0,
                Action: 'SendEmail',
                name: '',
                value: 0,
                UnitDelai: 'minutes',
                IdEventType: this.notificationForm.Id ?? null,
                IdTemplateEmail: 0,
                IdTemplateSMS: 0,
                IdTemplateWhatsApp: 0,
                TemplateEmail: null,
            })
            this.displayTemplates.push(false)
        },

        selectTrigger(trigger, index = null, remove = false) {
            // TODO ne fonctionne pas comme pour les clients tableau normal
            const trig = this.triggersOptions.find((tr) => tr.value === trigger)
            if (remove === false) {
                const selectedTrigger = {
                    Id: trig.Id ? trig.Id : 0,
                    name: trig.name,
                    value: trig.value,
                    Name: trig.name,
                    DefaultPricing: false,
                    Delai: trig.Delai ?? 1,
                    Action: trig.value,
                    UnitDelai: trig.UnitDelai ?? 'minutes',
                    IdEventType: this.notificationForm.Id ?? null,
                    IdTemplateEmail: trig.IdTemplateEmail ?? 0,
                    IdTemplateSMS: trig.IdTemplateSMS ?? 0,
                    IdTemplateWhatsApp: trig.IdTemplateWhatsApp ?? 0,

                    CommunicationTemplateEmail: trig.CommunicationTemplateEmail,
                    CommunicationTemplateSMS: trig.CommunicationTemplateSMS,
                    CommunicationTemplateWhatsApp:
                        trig.CommunicationTemplateWhatsApp,
                }
                this.refreshIFrame += 1
                this.notificationForm.EventTriggers[index] = selectedTrigger
            } else {
                this.notificationForm.EventTriggers.splice(index, 1)
                if (trigger.Id > 0 && trigger.Id !== '') {
                    this.notificationForm.EventTriggersToRemove.push({
                        Id: trigger.Id,
                    })
                }
            }
            // this.pricingForm.PricingParamClients = this.selectedParams
        },
        setTemplate(triggerIndex, type = 'email', template = null) {
            if (type === 'email') {
                this.notificationForm.EventTriggers[
                    triggerIndex
                ].CommunicationTemplateEmail = template
                this.notificationForm.EventTriggers[
                    triggerIndex
                ].IdTemplateEmail = template.Id
            }
            if (type === 'sms') {
                this.notificationForm.EventTriggers[
                    triggerIndex
                ].CommunicationTemplateSMS = template
                this.notificationForm.EventTriggers[
                    triggerIndex
                ].IdTemplateSMS = template.Id
            }
            if (type === 'wa') {
                this.notificationForm.EventTriggers[
                    triggerIndex
                ].CommunicationTemplateWhatsApp = template
                this.notificationForm.EventTriggers[
                    triggerIndex
                ].IdTemplateWhatsApp = template.Id
            }
        },

        async saveNotification() {
            this.v$.notificationForm.$reset()
            const isValid = await this.v$.notificationForm.$validate()
            if (!isValid) {
                this.$toast.error(this.$t('form_errors'))
                return
            }
            // if (this.fromTrigger === false) {
            if (this.isUpdate === false) {
                this.create(this.notificationForm)
                    .then(() => {
                        this.$emit('save')
                        this.$toast.success(this.$t('pricing_created'))
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            } else {
                this.update(this.notificationForm)
                    .then(() => {
                        this.$emit('save')
                        this.$toast.success(this.$t('pricing_updated'))
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            }
            // }
            // else if (this.isUpdate === false) {
            //     this.createTrigger(this.notificationForm.EventTriggers[0])
            //         .then(() => {
            //             this.$emit('save')
            //             this.$toast.success(this.$t('trigger_created'))
            //         })
            //         .catch((error) => {
            //             this.$toast.error(this.$t('error_occured'))
            //         })
            // } else {
            //     this.notificationForm.EventTriggers[0].Id = this.triggerId
            //     this.updateTrigger(this.notificationForm.EventTriggers[0])
            //         .then(() => {
            //             this.$emit('save')
            //             this.$toast.success(this.$t('trigger_updated'))
            //         })
            //         .catch((error) => {
            //             this.$toast.error(this.$t('error_occured'))
            //         })
            // }
        },
    },
    unmounted() {
        this.notification = null
    },
}
</script>

<style scoped>
/* Styles Tailwind déjà intégrés dans les classes */
</style>
