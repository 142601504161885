/* eslint-disable no-console */
<template>
    <div class="min-h-full flex flex-col justify-center sm:py-12">
        <div
            id="signup"
            class="shadow w-full darky rounded-md p-8 xs:p-0 mx-auto md:w-full md:max-w-6xl"
        >
            <div class="flex justify-end mb-4">
                <night-mode-switch id="nightswitch" />
                <language-switcher id="langswitch" />
                <button
                    id="restart"
                    type="button"
                    @click="startTour()"
                    class="transition duration-200 px-5 cursor-pointer font-normal text-sm rounded text-gray-500 focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-inset"
                >
                    <span class="inline-block font-bold">{{
                        $t('shepherd.restart_tour')
                    }}</span>
                </button>
            </div>
            <div id="logo" class="flex justify-center">
                <img
                    :class="userStore.logo === 'bft' ? 'w-[30%]' : ''"
                    alt="Company logo"
                    :src="`/logo/${userStore.logo}-nobg.png`"
                />
            </div>
            <alert-info
                v-if="userAlreadyExists"
                class="bg-yellow-100 text-yellow-700"
                :color="'yellow'"
                :text="'user_already_exists'"
            />

            <div class="px-5 py-7">
                <form @keydown.enter.prevent @submit.prevent="signUp()">
                    <div class="px-3">
                        <div class="grid grid-cols-6 gap-4">
                            <div class="col-span-3 rounded-md border-2 p-4">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('account_informations') }}
                                        <hr />
                                    </span>
                                </div>
                                <div id="email" class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                        >{{ $t('email') + ' *' }}
                                    </label>
                                    <div class="text-xs italic mt-1 mb-2">
                                        {{ $t('email_used_for_login') }}
                                    </div>
                                    <input
                                        type="email"
                                        autocomplete="email"
                                        @blur="v$.userForm.Email.$touch"
                                        v-model="userForm.Email"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.userForm.Email.$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                                <div class="grid grid-cols-6 gap-2">
                                    <div class="col-span-3">
                                        <div class="mb-3 items-center">
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                >{{
                                                    $t('firstname') + ' *'
                                                }}</label
                                            >
                                            <input
                                                v-model="userForm.FirstName"
                                                @blur="
                                                    v$.userForm.FirstName.$touch()
                                                "
                                                autocomplete="name"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.userForm
                                                .FirstName.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-3">
                                        <div class="mb-3 items-center">
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                >{{ $t('lastname') + ' *' }}
                                            </label>
                                            <input
                                                autocomplete="name"
                                                v-model="userForm.LastName"
                                                @blur="
                                                    v$.userForm.LastName.$touch
                                                "
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.userForm.LastName
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid grid-cols-6 gap-2">
                                    <div class="col-span-3">
                                        <div class="mb-3 items-center">
                                            <label
                                                class="font-semibold text-gray-600 text-sm basis-1/4 pb-1 mr-2 block"
                                                >{{ $t('phone') }}</label
                                            >
                                            <input
                                                type="tel"
                                                v-model="userForm.Phone"
                                                @blur="v$.userForm.Phone.$touch"
                                                autocomplete="tel"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.userForm.Phone
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-3">
                                        <div class="mb-3 items-center">
                                            <label
                                                class="font-semibold text-gray-600 text-sm basis-1/4 pb-1 mr-2 block"
                                                >{{ $t('mobile') }}</label
                                            >
                                            <input
                                                type="tel"
                                                v-model="userForm.MobilePhone"
                                                @blur="
                                                    v$.userForm.MobilePhone.$touch()
                                                "
                                                autocomplete="tel"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                            />
                                        </div>
                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.userForm
                                                .MobilePhone.$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ error.$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{ $t('password') + ' *' }}</label
                                    >
                                    <div class="relative w-full">
                                        <div
                                            class="absolute inset-y-0 right-0 flex items-center px-2"
                                        >
                                            <icon-button
                                                id="eye"
                                                class="ml-2 cursor-pointer"
                                                @click="
                                                    togglePasswordVisibility()
                                                "
                                                :icon="passwordVisibility"
                                                :noStyle="true"
                                                :iconStyle="'text-zinc-500 pt-2'"
                                            ></icon-button>
                                        </div>
                                        <input
                                            id="passwordInput"
                                            v-model="password"
                                            tabindex="1"
                                            :type="passwordType"
                                            autocomplete="new-password"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                </div>
                                <password-score
                                    id="pwdStrength"
                                    :value="password"
                                    class="mt-2 mb-6"
                                    @passed="isPasswordStrong = true"
                                    @failed="isPasswordStrong = false"
                                />
                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-sm text-gray-600 pb-1 mr-2 block"
                                        >{{
                                            $t('confirm_password') + ' *'
                                        }}</label
                                    >
                                    <div class="relative w-full">
                                        <div
                                            class="absolute inset-y-0 right-0 flex items-center px-2"
                                        >
                                            <icon-button
                                                @click="
                                                    togglePasswordVisibility()
                                                "
                                                :icon="passwordVisibility"
                                                class="ml-2 cursor-pointer"
                                                :noStyle="true"
                                                :iconStyle="'text-zinc-500 pt-2'"
                                            ></icon-button>
                                        </div>
                                        <input
                                            id="confirmPasswordInput"
                                            autocomplete="new-password"
                                            tabindex="2"
                                            v-model="confirmPassword"
                                            :type="passwordType"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />

                                        <div
                                            class="text-xs italic mt-1 mb-2"
                                            v-for="error of v$.confirmPassword
                                                .$errors"
                                            :key="error.$uid"
                                        >
                                            <div class="error-msg">
                                                {{ $t('passwords_dont_match') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-xs italic mt-1 mb-2">
                                    {{ $t('pw_notice') }}
                                </div>
                            </div>
                            <div class="col-span-3 rounded-md border-2 p-4">
                                <div class="px-3">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('society_informations') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div id="siret" class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                            >{{
                                                $t('registration_number') + ' *'
                                            }}
                                        </label>
                                        <input
                                            autocomplete="siret"
                                            v-model="userForm.Society.Siret"
                                            @blur="setBySiret()"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.userForm.Society
                                            .Siret.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div id="name" class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                            >{{ $t('company_name') + ' *' }}
                                        </label>
                                        <input
                                            autocomplete="name"
                                            v-model="userForm.Society.Name"
                                            @blur="
                                                v$.userForm.Society.Name.$touch
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.userForm.Society.Name
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div id="slogan" class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                            >{{ $t('slogan') }}
                                        </label>
                                        <input
                                            autocomplete="slogan"
                                            v-model="userForm.Society.Slogan"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div id="address" class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                            >{{ $t('address') + ' *' }}
                                        </label>
                                        <input
                                            id="autocomplete"
                                            v-model="userForm.Society.Address1"
                                            @blur="
                                                v$.userForm.Society.Address1.$touch()
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                            >{{ $t('postal_code') + ' *' }}
                                        </label>
                                        <input
                                            autocomplete="postal_code"
                                            v-model="
                                                userForm.Society.PostalCode
                                            "
                                            @blur="
                                                v$.userForm.Society.PostalCode.$touch()
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.userForm.Society
                                            .PostalCode.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                            >{{ $t('city') + ' *' }}
                                        </label>
                                        <input
                                            autocomplete="city"
                                            v-model="userForm.Society.City"
                                            @blur="
                                                v$.userForm.Society.City.$touch
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.userForm.Society.City
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="map"
                                    style="width: 100%; height: 500px"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                    <button
                        id="validate"
                        :disabled="!isPasswordStrong"
                        type="submit"
                        class="themed-button mt-4 transition duration-200 focus:shadow-sm focus:ring-opacity-50 text-white w-full py-2.5 rounded text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                    >
                        <span class="inline-block mr-2">{{
                            $t('sign_up')
                        }}</span>
                    </button>
                    <button
                        id="cancel"
                        @click="$router.push({ name: 'login' })"
                        class="transition duration-200 mx-5 mt-5 px-5 cursor-pointer font-normal text-sm rounded text-gray-500 focus:outline-none focus:ring-2 focus:ring-opacity-50 ring-inset"
                    >
                        <span class="inline-block ml-1 font-bold">{{
                            $t('cancel')
                        }}</span>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import AlertInfo from '@/components/atoms/AlertInfo.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import PasswordScore from '@/components/atoms/PasswordScore.vue'
import LanguageSwitcher from '@/components/elements/LanguageSwitcher.vue'
import NightModeSwitch from '@/components/elements/NightModeSwitch.vue'
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'
import shepherd from '@/mixins/shepherdCreateAccount.js'
import siretChecker from '@/mixins/siretChecker'
import keys from '@/resources/keys'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { helpers, required, sameAs } from '@vuelidate/validators'
import { mapActions, mapStores } from 'pinia'
import { ref } from 'vue'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)
const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
)

let marker = null
export default {
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]

        const isPasswordStrong = ref(false)
        return { key, isPasswordStrong, v$: useVuelidate() }
    },
    name: 'CreateSocietyAccount',
    components: {
        NightModeSwitch,
        LanguageSwitcher,
        IconButton,
        PasswordScore,
        AlertInfo,
    },
    mixins: [shepherd, googleScriptLoader, gmapStyles, siretChecker],
    created() {
        this.loadGoogleScript('autocomplete')
    },
    mounted() {
        window.scrollTo(0, 0)
        this.userStore.$patch({
            current: null,
            isLoggedIn: false,
            isB2C: null,
        })

        this.$cookies.keys().forEach((cookie) => {
            if (
                cookie !== 'logo' &&
                cookie !== 'nightMode' &&
                cookie !== 'locale'
            ) {
                this.$cookies.remove(cookie)
            }
        })
    },
    data() {
        return {
            userAlreadyExists: false,
            password: '',
            confirmPassword: '',
            passwordType: 'password',
            passwordVisibility: 'eye',
            userForm: {
                LastName: '',
                FirstName: '',
                Phone: '',
                Login: '',
                MobilePhone: '',
                Email: '',
                Password: this.password,
                Type: 'admin',
        
                IdUniverse: 0, // id de none
                Society: {
                    Name: '',
                    Siret: '',
                    Address1: '',
                    Address2: '',
                    Address3: '',
                    Address4: '',
                    PostalCode: '',
                    City: '',
                    Country: '',
                    Latitude: '',
                    Longitude: '',
                },
            },
        }
    },
    validations() {
        return {
            password: {
                required,
            },
            confirmPassword: { sameAs: sameAs(this.password) },
            userForm: {
                Email: { required },
                FirstName: { required },
                LastName: { required },
                Phone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                MobilePhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                Society: {
                    Name: { required },
                    Address1: { required },
                    Siret: { required },
                    PostalCode: {
                        required,
                        isZipCode: helpers.withMessage(
                            this.$t('bad_zip_format'),
                            isZipCode,
                        ),
                    },

                    City: { required },
                    Longitude: { required },
                    Latitude: { required },
                },
            },
        }
    },
    computed: {
        ...mapStores(useUserStore),
    },
    methods: {
        ...mapActions(useUniverseStore, ['search']),
        setBySiret() {
            if (this.isSIRET(this.userForm.Society.Siret)) {
                this.societyNotFound = false
                this.userStore
                    .fetchBySiret({
                        siret: this.userForm.Society.Siret,
                        fromNew: true,
                    })
                    .then((res) => {
                        this.userForm.Society = {
                            Name: res.data.Name,
                            Slogan: res.data.Slogan,
                            Address1: res.data.Address1,
                            Address2: res.data.Address2,
                            Address3: res.data.Address3,
                            Address4: res.data.Address4,
                            PostalCode: res.data.PostalCode,
                            City: res.data.City,
                            Country: res.data.Country ?? 'FRA',
                            Latitude: +res.data.Latitude,
                            Longitude: +res.data.Longitude,
                            PaymentProvider: res.data.PaymentProvider,
                            Siren: res.data.Siren,
                            Nic: res.data.Nic,
                            Siret: res.data.Siret,
                            CodeAPE: res.data.CodeAPE,
                        }
                        this.setAddressOnMap()
                    })
                    .catch((err) => {
                        this.societyNotFound = true
                        console.error(err)
                    })
            } else {
                this.$toast.error(this.$t('siret_bad_format'))
            }
        },
        setAddressOnMap() {
            const googleInterval = setInterval(() => {
                if (google) {
                    clearInterval(googleInterval)
                    let center = null
                    if (
                        this.userForm.Society.Latitude &&
                        this.userForm.Society.Longitude
                    ) {
                        center = {
                            lat: +this.userForm.Society.Latitude,
                            lng: +this.userForm.Society.Longitude,
                        }
                        this.map = new google.maps.Map(
                            document.getElementById('map'),
                            {
                                center,
                                zoom: 15,
                            },
                        )
                        marker = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,

                            position: center,
                            title: this.$t('company_position'),
                            draggable: true,
                        })
                        marker.setMap(this.map)
                        const eventScreen =
                            'ontouchstart' in document.documentElement
                                ? 'touchend'
                                : 'dragend'

                        marker.addListener(eventScreen, (evt) => {
                            this.markerDragged(evt)
                        })
                        this.setGoogleMapStyle(false)
                    }
                }
            }, 20)
        },
        markerDragged(position) {
            this.userForm.Society.Latitude = position.latLng.lat()
            this.userForm.Society.Longitude = position.latLng.lng()
        },
        togglePasswordVisibility() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text'
                this.passwordVisibility = 'eye-slash'
            } else {
                this.passwordType = 'password'
                this.passwordVisibility = 'eye'
            }
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.userForm.Society.Address2 = ''
            this.userForm.Society.Address1 = ''

            this.userForm.Society.PostalCode = ''
            this.userForm.Society.City = ''

            const userInput = this.userForm.Society.Address1
            this.userForm.Society.Latitude = place.geometry.location.lat()
            this.userForm.Society.Longitude = place.geometry.location.lng()
            if (marker !== null) {
                marker.setMap(null)
            }
            this.map.setCenter({
                lat: this.userForm.Society.Latitude,
                lng: this.userForm.Society.Longitude,
            })
            this.map.setZoom(15)

            marker = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                position: {
                    lat: this.userForm.Society.Latitude,

                    lng: this.userForm.Society.Longitude,
                },
                draggable: true,
            })
            marker.setMap(this.map)
            const eventScreen =
                'ontouchstart' in document.documentElement
                    ? 'touchend'
                    : 'dragend'

            marker.addListener(eventScreen, (evt) => {
                this.markerDragged(evt)
            })

            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    this.userForm.Society.PostalCode = val
                } else if (addressType === 'locality') {
                    this.userForm.Society.City = val
                } else if (addressType === 'street_number') {
                    this.userForm.Society.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.userForm.Society.Address1 =
                        this.userForm.Society.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.userForm.Society.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.userForm.Society.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.userForm.Society.Address1 === userInput
                ) {
                    this.userForm.Society.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    this.userForm.Society.Address2 === ''
                ) {
                    this.userForm.Society.Address2 = place.name
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    this.userForm.Society.Name === ''
                ) {
                    this.userForm.Society.Name = place.name
                }
            })
        },
        async signUp() {
            this.updating = true
            this.userAlreadyExists = false

            const pwOk =
                (await this.v$.password.$validate()) &&
                (await this.v$.confirmPassword.$validate())
            if (pwOk === true) {
                this.userForm.Login = this.userForm.Email
                const key =
                    window.location.hostname === 'localhost'
                        ? 'localhost'
                        : window.location.host.substring(
                              0,
                              window.location.host.indexOf('.'),
                          )
                this.userForm.key = `${keys[key]}`
                this.userForm.Society.Email = this.userForm.Email

                this.userForm.Password = this.password
                // if (
                //     this.userForm.Society.Name === '' &&
                //     this.userForm.LastName !== '' &&
                //     this.userForm.FirstName !== ''
                // ) {
                //     this.userForm.Society.Name = `${this.userForm.FirstName} ${this.userForm.LastName}`
                // }
                // this.userForm.Society.BillingAddress = {
                //     Address1: this.userForm.Society.Address1,
                //     PostalCode: this.userForm.Society.PostalCode,
                //     City: this.userForm.Society.City,
                //     Country: this.userForm.Society.Country,
                //     ContactPhone: this.userForm.Phone,
                //     ContactEmail: this.userForm.Society.Email,
                //     ContactFirstName: this.userForm.FirstName,
                //     ContactLastName: this.userForm.LastName,
                //     ContactMobilePhone: this.userForm.MobilePhone,
                // }

                const isUserFormCorrect = await this.v$.userForm.$validate()
                this.userForm.fromNew = true
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (isUserFormCorrect === true) {
                    this.userStore
                        .createSociety(this.userForm)
                        .then((res) => {
                            if (res && res.status === 201) {
                                this.$toast.success(
                                    `${this.$t('welcome')} ${
                                        this.userForm.FirstName
                                    } ${this.userForm.LastName} !`,
                                )
                                this.userStore.login({
                                    Login: this.userForm.Login,
                                    Password: this.userForm.Password,
                                })
                            }
                            //  else {
                            //     this.$toast.error(`${this.$t('user_create_ko')}`)
                            // }
                            this.updating = false
                        })
                        .catch((err) => {
                            this.updating = false
                            if (err.response && err.response.status === 422) {
                                this.$toast.error(
                                    `${this.$t('user_already_exists')}`,
                                )
                            } else {
                                this.$toast.error(
                                    `${this.$t('user_create_ko')}`,
                                )
                            }
                        })
                } else {
                    this.updating = false

                    this.$toast.error(this.$t('form_errors'))
                }
            } else {
                this.updating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {},
}
</script>
<style>
@import '@/assets/_shepherd.scss';
</style>
