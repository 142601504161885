<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error
                v-if="fetchError"
                :dataType="'hazardous_products'"
            />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="hazardousProductsList"
                :items="hazardousProducts"
                :columns="columns"
                :storeToSort="'hazardousProduct'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="toCreateHazardous()"
                @clear="clearFilter($event)"
                @update="toUpdateHazardous($event)"
                @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)"
            >
                <!-- @delete="deleteOne($event)"
                @deleteAll="deleteAll($event)" -->
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            :headerText="modalHeaderText"
            :buttonText="'save'"
            :creating="creating"
            id="addModal"
            :mxWidth="'w-1/2'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="submitModal()"
        >
            <template #body>
                <div class="mb-3 items-center flex flex-row mt-2 px-3">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('code') + ' *' }}</label
                    >
                    <input
                        v-model="formData.Code"
                        :disabled="editHaz === true"
                        :class="editHaz === true ? 'cursor-not-allowed' : ''"
                        autocomplete="code"
                        @blur="v$.formData.Code.$touch"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-for="error of v$.formData.Code.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div class="mb-3 items-center flex flex-row px-3">
                    <label
                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                        >{{ $t('description') + ' *' }}</label
                    >
                    <textarea
                        v-model="formData.Description"
                        class="border rounded px-3 py-2 mt-1 w-full"
                    />
                </div>
                <div
                    class="text-xs italic mt-1 mb-2"
                    v-for="error of v$.formData.Description.$errors"
                    :key="error.$uid"
                >
                    <div class="error-msg">
                        {{ error.$message }}
                    </div>
                </div>
                <div
                    class="grid grid-cols-4 gap-2 p-4 justify-center items-center"
                >
                    <div class="col-span-2">
                        <span class="text-sm mx-2 whitespace-nowrap">
                            {{ `${$t('group_onu')}` }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.GroupONU"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>
                    <div class="col-span-2">
                        <span class="text-sm mx-2 whitespace-nowrap">
                            {{ `${$t('onu_class')}` }}
                        </span>
                        <input
                            type="number"
                            v-model="formData.Class"
                            step="0.01"
                            class="border rounded px-3 py-2 h-10 w-full"
                        />
                    </div>
                </div>

                <span
                    class="flex mt-3 italic text-xs items-center justify-center"
                >
                    {{ $t('required_fields') }}
                </span>
            </template>
        </modal>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useHazardousProductStore } from '@/stores/hazardousProductStore'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapState, mapStores } from 'pinia'
import societyEventBus from '@/mixins/societyEventBusMixin'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'HazardousList',
    mixins: [filterList,societyEventBus],

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            columns: [],
            refresh: 0,
            isDefault: false,
            editHaz: false,
            modalHeaderText: 'new_hazardous_product',
            creating: false,
            searchQuery: '',
            search: [],
            sortDirection: 'ASC',
            sortField: 'Code',
            perPage: 10,
            page: 1,
            show: false,

            hazardousInterval: null,
            filter: [],
            sortableFields: [
                { name: this.$t('code'), value: 'Code' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],

            formData: {
                Code: '',
                Id: '',
                Class: '',
                Description: '',
                GroupONU: 0,
            },
        }
    },
    validations() {
        return {
            formData: {
                Code: { required },
                Description: { required },
            },
        }
    },
    watch: {
        hazardousProducts() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    mounted() {
        this.isDefault = false
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('hazardousFiltered')
        this.columns = [
            // gérer le islink pour b2C
            {
                name: 'code',
                filtrable: true,

                isLink: checkAccess(
                    this.userStore,
                    this.hazardousActionsAccess.update,
                ),
                // routeName: 'update_article',

                dbField: 'Code',
                type: 'search',
                sortable: true,
            },
            {
                name: 'description',
                filtrable: true,
                dbField: 'Description',
                type: 'search',
                sortable: true,
            },

            {
                name: 'group_onu',
                filtrable: true,
                sortable: false,
                dbField: 'GroupONU',
                type: 'search',
            },
            {
                name: 'onu_class',
                filtrable: true,
                dbField: 'Class',
                sortable: true,
                type: 'search',
            },

            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }

        this.setResults()
    },

    unmounted() {
        clearInterval(this.hazardousInterval)
    },
    computed: {
        hazardousActionsAccess() {
            return actionsAccess.orders.hazardous
        },
        ...mapStores(useUserStore),
        ...mapState(useHazardousProductStore, {
            hazardousProducts: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
            current: 'current',
        }),
        ...mapStores(useHazardousProductStore),

        itemActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.hazardousActionsAccess.update)
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }

            if (
                checkAccess(this.userStore, this.hazardousActionsAccess.delete)
            ) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            if (
                checkAccess(this.userStore, this.hazardousActionsAccess.delete)
            ) {
                return [
                    {
                        name: 'delete_selection',
                        action: 'deleteAll',
                        icon: 'trash-can',
                    },
                ]
            }
            return []
        },
    },
    methods: {
        upFilter(event) {
            clearInterval(this.hazardousInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.hazardousActionsAccess.create,
            )
        },

        async deleteOne(article) {
            this.searchingCursor = true

            const response = await this.hazardousProductStore.delete(article)
            if (response.status === 200) {
                this.searchingCursor = false

                this.$toast.success(this.$t('deleted_ok'))
            } else {
                this.searchingCursor = false

                this.$toast.error(this.$t('deleted_ko'))
            }
        },

        toCreateHazardous() {
            clearInterval(this.hazardousInterval)
            // this.$router.push({ name: 'new_container' })
            this.hazardousProductStore.$patch({ current: null })
            this.modalHeaderText = 'new_hazardous_product'
            this.show = true
        },
        toUpdateHazardous(article) {
            this.isDefault = false
            clearInterval(this.hazardousInterval)

            this.modalHeaderText = 'update_hazardous_product'
            let id = article
            if (article.id) {
                id = article.id
            }

            this.hazardousProductStore.$patch({ current: null })
            this.hazardousProductStore.fetchOne(id).then(() => {
                const hazInterval = setInterval(() => {
                    if (this.current !== null) {
                        clearInterval(hazInterval)

                        this.editHaz = true

                        this.formData = {
                            Code: this.current.Code,
                            Id: this.current.Id,
                            CreationUserId: this.current.CreationUserId,
                            Description: this.current.Description,
                            Class: this.current.Class,
                            GroupONU: this.current.GroupONU,
                        }

                        this.show = true
                    }
                    // }
                })
            })
        },
        closeModal() {
            this.isDefault = false

            this.creating = false
            this.v$.$reset()
            this.show = false
            this.editHaz = false
            this.formData = {
                Code: '',
                Id: '',
                CreationUserId: '',
                Description: '',
                Class: '',
                GroupONU: '',
            }
            this.setResults()
        },
        async submitModal() {
            this.searchingCursor = true

            this.creating = true
            const isValid = await this.v$.$validate()
            if (isValid === true) {
                if (this.editHaz === true) {
                    this.formData.ModificationUserId = this.userStore.current.id

                    const response = await this.hazardousProductStore.update({
                        id: this.formData.Id,
                        form: this.formData,
                    })
                    if (response.status === 200) {
                        this.searchingCursor = false

                        this.$toast.success(this.$t('update_ok'))
                        // this.$router.push({ name: 'articles' })
                        this.setResults()
                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.formData.CreationUserId = this.userStore.current.id

                    const response = await this.hazardousProductStore.create(
                        this.formData,
                    )
                    if (response.status === 201) {
                        this.searchingCursor = false

                        this.setResults()

                        this.closeModal()
                    } else {
                        this.searchingCursor = false

                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.searchingCursor = false

                this.$toast.error(this.$t('form_errors'))
                return false
            }
        },
        deleteAll(elToDelete) {
            elToDelete.forEach((el) => {
                this.hazardousProductStore.delete(el)
            })
        },

        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.hazardousInterval)
            this.$cookies.remove('hazardousFiltered')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.hazardousInterval)
            this.$cookies.remove('hazardousFiltered')
            this.search = search.query

            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    this.setRealSelected(col.dbField, index)
                }
            })
            this.setRealSearch(this.search)

            this.hazardousProductStore.search(search).then(() => {
                this.searchingCursor = false
                this.refresh += 1
            })
            this.hazardousInterval = setInterval(() => {
                this.hazardousProductStore.search(search)
            }, 20000)

            this.$cookies.set('hazardousFiltered', search)

            this.refresh += 1
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            this.perPage = perPage
            this.page = 1
            clearInterval(this.hazardousInterval)
            this.$cookies.remove('hazardousFiltered')
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
<style lang="scss" scoped>
.selectMS ::v-deep(.multiselect) {
    .multiselect-placeholder {
        font-size: small !important;
    }
}
</style>
