<template>
    <modal
        @closeModal="$emit('close')"
        @sendFromModal="saveTemplate()"
        :mxWidth="'w-2/3'"
        :canCancel="true"
        :mxHeight="'max-h-screen'"
        :buttonAlign="'justify-end'"
    >
        <template #body>
            <div class="grid grid-cols-9 gap-2 m-4">
                <div class="col-span-6">
                    <div class="my-4">
                        <label
                            for="templateName"
                            class="block text-sm font-medium text-gray-700"
                        >
                            {{ $t('name') + '* ' }}
                        </label>
                        <input
                            v-model="templateForm.Name"
                            type="text"
                            name="templateName"
                            id="templateName"
                            autocomplete="templateName"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.templateForm.Name.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label
                            for="templateSender"
                            class="block text-sm font-medium text-gray-700"
                        >
                            {{ $t('template_sender') + '* ' }}
                        </label>
                        <input
                            v-model="
                                templateForm.CommunicationTemplateContent
                                    .FieldSenderName
                            "
                            type="text"
                            name="templateSender"
                            id="templateSender"
                            autocomplete="templateSender"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.templateForm
                                .CommunicationTemplateContent.FieldSenderName
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label
                            for="templateSenderEmail"
                            class="block text-sm font-medium text-gray-700"
                        >
                            {{ $t('template_sender_email') }}
                        </label>
                        <input
                            v-model="
                                templateForm.CommunicationTemplateContent
                                    .FieldSenderEmail
                            "
                            type="text"
                            name="templateSenderEmail"
                            id="templateSenderEmail"
                            autocomplete="templateSenderEmail"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                    </div>
                    <div class="mb-4">
                        <label
                            for="templateTag"
                            class="block text-sm font-medium text-gray-700"
                        >
                            {{ $t('template_tag') }}
                        </label>
                        <input
                            v-model="
                                templateForm.CommunicationTemplateContent.Tag
                            "
                            type="text"
                            name="templateTag"
                            id="templateTag"
                            autocomplete="templateTag"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                    </div>
                    <div>
                        <label
                            for="templateContent"
                            class="block text-sm font-medium text-gray-700"
                        >
                            {{ $t('template_content') }}
                        </label>
                        <textarea
                            v-model="
                                templateForm.CommunicationTemplateContent
                                    .FieldTextPart
                            "
                            type="text"
                            name="templateContent"
                            id="templateContent"
                            class="border h-32 rounded px-3 py-2 mt-1 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.templateForm
                                .CommunicationTemplateContent.FieldTextPart
                                .$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center pt-4 justify-center">
                        <input
                            id="set-active-checkbox"
                            type="checkbox"
                            v-model="templateForm.Active"
                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                        />
                        <label
                            for="set-active-checkbox"
                            class="ml-2 font-medium"
                            >{{ $t('activate_template') }}</label
                        >
                    </div>
                </div>
                <div class="col-span-3">
                    <whats-app-template
                        v-if="templateType === 'whatsapp'"
                        :avatarUrl="`/logo/${logo}header-nobg.png`"
                        :contactName="
                            templateForm.CommunicationTemplateContent
                                .FieldSenderName
                        "
                        :messages="[
                            {
                                sender: templateForm
                                    .CommunicationTemplateContent
                                    .FieldSenderName,
                                text: templateForm.CommunicationTemplateContent
                                    .FieldTextPart,
                            },
                        ]"
                    ></whats-app-template>

                    <sms-template
                        v-else
                        :headerText="
                            templateForm.CommunicationTemplateContent
                                .FieldSenderName
                        "
                        :messages="[
                            {
                                sender: templateForm
                                    .CommunicationTemplateContent
                                    .FieldSenderName,
                                text: templateForm.CommunicationTemplateContent
                                    .FieldTextPart,
                            },
                        ]"
                    ></sms-template>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import Modal from '@/components/atoms/Modal.vue'
import SmsTemplate from '@/components/elements/SmsTemplate.vue'
import WhatsAppTemplate from '@/components/elements/WhatsAppTemplate.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState } from 'pinia'

import { useNotificationStore } from '@/stores/notificationStore.js'
import { useUserStore } from '@/stores/userStore.js'

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    name: 'CreateOrUpdateTemplate',
    components: {
        Modal,
        SmsTemplate,
        WhatsAppTemplate,
    },
    data() {
        return {
            templateForm: {
                Id: 0,
                Name: '',
                TemplateID: '',
                Provider: '',
                Type: 'sms',
                Active: true,

                CommunicationTemplateContent: {
                    Id: 0,
                    IdCommunicationTemplate: 0,
                    Tag: '',
                    FieldSenderName: '',
                    FieldSenderEmail: '',
                    FieldTo: null,
                    FieldSubject: '',
                    FieldTextPart: '',
                    FieldHtmlPart: '',
                },
            },
        }
    },
    computed: {
        ...mapState(useUserStore, { user: 'current', logo: 'logo' }),
    },
    props: {
        currentTemplate: {
            type: Object,
            default: () => ({}),
        },
        templateType: {
            type: String,
            default: 'sms',
        },
    },
    mounted() {
        if (this.currentTemplate) {
            this.templateForm = this.currentTemplate
            this.templateForm.Active = this.currentTemplate.Active === 1
        } else {
            this.templateForm.Type = this.templateType
            this.templateForm.CommunicationTemplateContent.FieldSenderEmail =
                this.user.email
            this.templateForm.CommunicationTemplateContent.FieldSenderName = `${this.user.firstName} ${this.user.lastName}`
        }
    },
    validations: {
        templateForm: {
            Name: {
                required,
            },
            CommunicationTemplateContent: {
                FieldSenderName: {
                    required,
                },
                FieldTextPart: {
                    required,
                },
            },
        },
    },
    methods: {
        ...mapActions(useNotificationStore, [
            'createTemplate',
            'updateTemplate',
        ]),
        async saveTemplate() {
            this.v$.templateForm.$reset()
            const isValid = await this.v$.templateForm.$validate()
            if (!isValid) {
                this.$toast.error(this.$t('form_errors'))
                return
            }
            if (this.templateForm.Id !== 0) {
                this.updateTemplate(this.templateForm)
                    .then(() => {
                        this.$toast.success(this.$t('template_updated'))
                        this.$emit('close')
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            } else {
                this.createTemplate(this.templateForm)
                    .then(() => {
                        this.$toast.success(this.$t('template_created'))
                        this.$emit('close')
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('error_occured'))
                    })
            }
        },
    },
}
</script>
