<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />

        <div
            id="create-order"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div class="p-5">
                <form
                    class="mb-2"
                    @keydown.enter.prevent
                    @submit.prevent="generateSimulation()"
                >
                    <div>
                        <div class="grid grid-cols-6 gap-4 mt-2">
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('params') }}
                                        <hr />
                                    </span>
                                </div>
                                <div class="flex items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mt-2 mr-2 block"
                                        >{{ $t('date') }}</label
                                    >
                                    <input
                                        type="date"
                                        v-model="formData.DateBegin"
                                        required
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                        @change="setResults()"
                                    />
                                </div>
                                <div class="mt-2 flex items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mt-2 mr-2 block"
                                        >{{ $t('simulation_name') }}</label
                                    >
                                    <input
                                        type="text"
                                        v-model="formData.Name"
                                        required
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>
                            <div class="rounded-md border-2 p-4 col-span-3">
                                <div class="px-3">
                                    <div class="mb-5 flex">
                                        <span class="text-lg">
                                            {{ $t('requirements') }}
                                            <hr />
                                        </span>
                                    </div>
                                    <div class="flex items-center mt-4">
                                        <input
                                            id="ref-add-update-checkbox"
                                            type="checkbox"
                                            :disabled="env !== 'beaujolyon'"
                                            v-model="formData.ConstraintCO2"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="ref-add-update-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            >{{ $t('co2_requirement') }}</label
                                        >
                                    </div>
                                    <div class="flex items-center mt-4">
                                        <input
                                            id="ref-add-update-checkbox"
                                            type="checkbox"
                                            :disabled="env !== 'beaujolyon'"
                                            v-model="formData.ConstraintPrice"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="ref-add-update-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            >{{
                                                $t('price_requirement')
                                            }}</label
                                        >
                                    </div>

                                    <div class="flex items-center mt-4">
                                        <input
                                            id="ref-add-update-checkbox"
                                            type="checkbox"
                                            v-model="formData.ConstraintDriver"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="ref-add-update-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            >{{
                                                $t('drivers_requirement')
                                            }}</label
                                        >
                                    </div>
                                    <div class="flex items-center mt-4">
                                        <input
                                            id="ref-add-update-checkbox"
                                            type="checkbox"
                                            v-model="formData.ConstraintVehicle"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="ref-add-update-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            >{{
                                                $t('vehicles_requirement')
                                            }}</label
                                        >
                                    </div>
                                    <div class="flex items-center mt-4">
                                        <input
                                            id="ref-add-update-checkbox"
                                            type="checkbox"
                                            v-model="formData.ConstraintSector"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="ref-add-update-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            >{{
                                                $t('areas_requirement')
                                            }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <fetch-data-error v-if="fetchError" :dataType="'orders'" />
                    <filtrable-table
                        v-else
                        id="stepsList"
                        :items="steps"
                        :columns="columns"
                        :storeToSort="'orders'"
                        :page="page"
                        :count="count"
                        :loading="loading"
                        @perPageChanged="changePerPage($event)"
                        @updateSort="displaySearchResults($event)"
                        :perPage="perPage"
                        :sortDirection="sortDirection"
                        :sortableFields="sortableFields"
                        :totalPages="totalPages"
                        :searchQuery="searchQuery"
                        :multiActions="[]"
                        @selectedChanged="setSelected($event)"
                        :refresh="refresh"
                        :canAddNew="false"
                        :canSelectItems="true"
                        @pageChanged="setPage($event)"
                        @searched="debounceSearch"
                        @sortDirectionChanged="updateSortDirection($event)"
                        @selected="updateFilter($event)"
                        @create="$emit('create')"
                        @clear="clearFilter($event)"
                        @update="goToUpdateOne($event)"
                        @updateStep="goToUpdateStep($event)"
                        @delete="deleteOne($event)"
                        @deleteAll="deleteAll($event)"
                    >
                    </filtrable-table>
                    <div class="p-2 mt-4">
                        <div class="flex justify-center">
                            <button
                                type="button"
                                @click="back()"
                                class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                            >
                                {{ $t('cancel') }}
                            </button>
                            <button
                                type="submit"
                                :disabled="
                                    generating ||
                                    formData.Selection.length === 0
                                "
                                class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                            >
                                <span v-if="generating">
                                    <fa-icon icon="spinner" spin-pulse></fa-icon
                                ></span>
                                <span v-else>
                                    {{ $t('generate') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import BackTo from '@/components/elements/BackTo.vue'
import societyEventBus from '@/mixins/societyEventBusMixin'
import { useOrderStore } from '@/stores/orderStore'
import { useSimulationStore } from '@/stores/simulationStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import { mapActions, mapState } from 'pinia'

export default {
    name: 'SimulationGeneration',
    mixins: [societyEventBus],
    components: { FiltrableTable, FetchDataError, BackTo, Multiselect },
    setup() {
        const env =
            window.location.hostname === 'localhost' ||
            window.location.host.includes('dev')
                ? 'dev'
                : window.location.host.includes('lium')
                  ? 'lium'
                  : window.location.host.substring(
                        0,
                        window.location.host.indexOf('.'),
                    )
        return { env }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.columns = [
            {
                name: 'order_status',
                dbField: 'Order.Status',
                filtrable: true,
                selected: {
                    name: this.$t('all_orders'),
                    dbField: 'Order.Status',
                    value: 'all',
                },
                canBeEmpty: false,
                canBeCleared: false,
                type: 'select',
                sortable: false,
                translate: true,
                options: this.orderStatusOptions,
            },
            {
                name: 'name',
                filtrable: true,
                dbField: 'AddressName',
                type: 'search',
                sortable: true,
            },
            {
                name: 'address',
                dbField: 'Address1',
            },
            {
                name: 'postal_code',
                filtrable: true,
                dbField: 'PostalCode',
                type: 'search',
                sortable: true,
            },
            {
                name: 'city',
                filtrable: true,
                dbField: 'City',
                type: 'search',
                sortable: true,
            },

            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                const universeFilter = {
                    dbField: 'IdUniverse',
                    value:
                        this.selectedUniverses.length > 0
                            ? this.selectedUniverses
                            : this.universesOptions,
                }
                if (!this.filter.find((f) => f.dbField === 'IdUniverse')) {
                    this.filter.push(universeFilter)
                }
                this.filter.push({
                    name: this.$t('all_orders'),
                    dbField: 'Order.Status',
                    value: 'all',
                })
                this.setResults()
                this.eventBus.on('trigger-universe-search', (param) => {
                    this.searchCustomUniverses(param)
                })
                this.eventBus.on('remove-from-selected-universe', (index) => {
                    this.removeFromSelectedUniverses(index)
                })
                this.refreshMs += 1
            }
        }, 10)
    },
    data() {
        return {
            columns: [],
            refreshMs: 0,
            orderStatusOptions: [
                {
                    name: this.$t('all_orders'),
                    dbField: 'Order.Status',

                    value: 'all',
                },
                {
                    name: this.$t('imported_only'),
                    dbField: 'Order.Status',

                    value: 'imported',
                },
                {
                    name: this.$t('not_imported_only'),
                    dbField: 'Order.Status',

                    value: 'not_imported',
                },
            ],
            orderStatusFilter: 'all',
            generating: false,
            searchQuery: '',
            search: [],
            refresh: 0,
            sortDirection: 'ASC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            filter: [],

            formData: {
                ConstraintVehicle: false,
                ConstraintDriver: false,
                ConstraintSector: false,
                Selection: [],
                Status: 'request',
                DateEnd: '',
                NbSteps: 0,
                CreationUserId: '',
                ModificationUserId: '',
                DateBegin: new Date().toISOString().substr(0, 10),
                Name: `${this.$t('tour_simulation')} ${new Date()
                    .toISOString()
                    .substr(0, 10)}`,
            },

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
                { name: this.$t('city'), value: 'City' },
                { name: this.$t('postal_code'), value: 'PostalCode' },
            ],
        }
    },
    watch: {
        'formData.DateBegin': function (newVal) {
            this.formData.Name = `${this.$t('tour_simulation')} ${
                this.formData.DateBegin
            }`
            this.formData.Selection = []
        },
    },
    computed: {
        ...mapState(useOrderStore, {
            steps: 'steps',
            count: 'countSteps',
            fetchError: 'fetchError',
            totalPages: 'stepsTotalPages',
            loading: 'loading',
        }),
        ...mapState(useUserStore, {
            user: 'current',
            userUniverses: 'userUniverses',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
    },
    methods: {
        ...mapActions(useSimulationStore, ['generate']),
        ...mapActions(useOrderStore, ['searchSteps']),

        back() {
            this.$router.push(this.$router.options.history.state.back)
            this.$emit('back', 'simulations')
        },
        setSelected(event) {
            this.formData.Selection = event
        },
        searchCustomUniverses(clear = false) {
            let filter = null
            clearInterval(this.ordersInterval)
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)

            this.searchCustomUniverses()
        },
        async generateSimulation() {
            this.generating = true
            this.formData.CreationUserId = this.user.id
            this.formData.ModificationUserId = this.user.id
            this.formData.NbSteps = this.formData.Selection.length
            this.formData.DateEnd = this.formData.DateBegin
            // const isFormCorrect = await this.v$.$validate()

            // // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            // if (isFormCorrect === true) {
            try {
                const response = await this.generate(this.formData)
                if (response) {
                    this.generating = false

                    const backPath = this.$router.options.history.state.back
                    this.$router.push(backPath)

                    this.$emit('created', 'simulations')
                    this.$toast.success(this.$t('simulation_generation_ok'))
                }
            } catch (err) {
                this.generating = false

                console.log(err)
                this.$toast.error(`${this.$t('simulation_generation_ko')}`)
                // this.$toast.error(err.data.message)
            }
            // }
            //  else {
            //     this.generating = false

            //     this.$toast.error(this.$t('form_errors'))
            // }
        },

        setPage(isNext) {
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }
            this.setResults()
        },
        displaySearchResults(search) {
            let formattedDate = new Date(this.formData.DateBegin).toISOString()
            formattedDate = formattedDate.slice(0, formattedDate.indexOf('T'))

            search.date = formattedDate

            this.searchSteps(search)
            this.refreshMs += 1
        },
        changePerPage(perPage) {
            this.perPage = perPage
            this.page = 1
            this.setResults()
            this.refresh += 1
        },
        updateSortDirection(sort) {
            this.sortDirection = sort.direction
            this.sortField = sort.field
            this.setResults()
        },
        upFilter(event) {
            clearInterval(this.ordersInterval)
            this.updateFilter(event)
        },
        updateFilter(filter) {
            if (!this.filter.some((f) => f.dbField === filter.dbField)) {
                this.filter.push(filter)
            } else if (this.filter.some((f) => f.dbField === filter.dbField)) {
                const index = this.filter.findIndex(
                    (fil) => fil.dbField === filter.dbField,
                )
                this.filter[index] = filter
            }
            this.setResults()
        },
        clearFilter(filterToRemove) {
            const index = this.filter.findIndex(
                (f) => f.dbField === filterToRemove,
            )
            this.filter.splice(index, 1)
            this.setResults()
        },
        updateSortField(sortField) {
            this.sortField = sortField
            this.setResults()
        },
        debounceSearch(query) {
            this.search.forEach((s, index) => {
                if (s.dbField === query.dbField) {
                    this.search.splice(index, 1)
                }
            })
            this.search.push(query)

            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.setResults()
            }, 400)
        },
        setResults() {
            let formattedDate = new Date(this.formData.DateBegin).toISOString()
            formattedDate = formattedDate.slice(0, formattedDate.indexOf('T'))
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
                date: formattedDate,
                deliveryOnly: true,
                forSim: true,
            }
            this.displaySearchResults(search)
        },
    },
    unmounted() {
        this.eventBus.all.clear()
    },
}
</script>
