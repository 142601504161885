import { defineStore } from 'pinia'

const useNotificationStore = defineStore('notification', {
    state: () => ({
        all: null,
        count: null,
        fetchError: false,
        create: false,
        totalPages: 0,
        loading: false,
        current: null,
        allTriggers: null,
        countTriggers: null,
        totalPagesTriggers: 0,
        entitiesOptions: [],
        templates: [],
        templatesCount: null,
        totalPagesTemplates: 0,
    }),

    actions: {
        getEntities() {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/system/entities`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.entitiesOptions = res.data
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        searchEventTypes(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'Active') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/eventType/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            } else {
                apiUrl = `/api/v1/eventType/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const notifs = []
                            if (count > 0) {
                                res.data.Records.forEach((notif) => {
                                    const formattedNotif = {
                                        id: +notif.Id,
                                        name: notif.Name,
                                        active: notif.Active,
                                        code: notif.Code,
                                        entity: notif.EntityName,
                                        description: notif.Description,
                                        triggers_list: notif.EventTriggers,
                                        creation_date: notif.CreationDate,
                                        Id: +notif.Id,
                                        Name: notif.Name,
                                        Description: notif.Description,
                                        Code: notif.Code,
                                        Active: notif.Active === 1,
                                        EntityName: notif.EntityName,
                                    }
                                    notifs.push(formattedNotif)
                                })
                            }

                            this.all = notifs
                            this.count = count
                            this.totalPages = totalPages
                            this.loading = false
                        } else {
                            this.all = null
                            this.count = 0
                            this.totalPages = 0
                            this.loading = false
                        }
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        create(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/eventType', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        update(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/eventType/${payload.Id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOne(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/eventType/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = res.data

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deactivateNotif(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/eventType/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deactivateManyNotifs(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post(`/api/v1/eventType/deactivateMany`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        getTemplates(payload) {
            this.loading = true
            this.fetchError = false

            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' ) {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/communicationTemplate/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            } else {
                apiUrl = `/api/v1/communicationTemplate/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            this.templatesCount = +res.data.TotalResults

                            this.totalPagesTemplates = +res.data.TotalPages

                            this.templates = res.data.Records
                        } else {
                            this.templatesCount = 0
                            this.totalPagesTemplates = 0
                            this.templates = null
                        }
                        this.loading = false
                        resolve(res)
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        searchTriggers(payload) {
            this.loading = true
            this.fetchError = false
            const withEventType = payload.withEventType ? 'true' : 'false'
            let query = []
            if (payload.query.length > 0) {
                payload.query.forEach((q) => {
                    if (q.term.length > 0) {
                        query.push(
                            `{"${q.dbField}":[{"operator":"LIKE","value":"${q.term}"}]}`,
                        )
                    }
                })
            }
            if (payload.filter.length > 0) {
                payload.filter.forEach((type) => {
                    // types.push(`"${type.options[0].value}"`)
                    if (type.dbField === 'Id' || type.dbField === 'Active') {
                        query.push(
                            `{"${type.dbField}":[{"operator":"=","value":"${type.value}"}]}`,
                        )
                    } else {
                        query.push(
                            `{"${type.dbField}":[{"operator":"LIKE","value":"${type.value}"}]}`,
                        )
                    }
                })
            }

            let apiUrl = ''
            if (Object.keys(query).length === 0) {
                query = ''
                apiUrl = `/api/v1/eventTrigger/search?nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true&withEventType=${withEventType}`
            } else {
                apiUrl = `/api/v1/eventTrigger/search?query={"Query":[${query}]}&nbPP=${payload.perPage}&page=${payload.page}&order=${payload.sortDirection}&sort=${payload.sortField}&populate=true&withEventType=${withEventType}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        if (res && res.status === 200) {
                            const count = Number.isNaN(+res.data.TotalResults)
                                ? 0
                                : +res.data.TotalResults
                            const totalPages = Number.isNaN(
                                +res.data.TotalPages,
                            )
                                ? 0
                                : +res.data.TotalPages
                            const triggers = []
                            if (count > 0) {
                                res.data.Records.forEach((trigger) => {
                                    const formattedTrigger = {
                                        id: +trigger.Id,
                                        event_type_id: +trigger.IdEventType,
                                        delai: +trigger.Delai,
                                        event_type_name:
                                            trigger.EventType?.Name,
                                        unit_delai: trigger.UnitDelai,
                                        action: trigger.Action,
                                        id_template_email:
                                            +trigger.IdTemplateEmail,
                                        id_template_sms: +trigger.IdTemplateSMS,
                                        id_template_whatsapp:
                                            +trigger.IdTemplateWhatsApp,
                                        event_type: trigger.EventType,
                                        template_email:
                                            trigger.CommunicationTemplateEmail,
                                        template_sms:
                                            trigger.CommunicationTemplateSMS,
                                        template_whatsApp:
                                            trigger.CommunicationTemplateWhatsApp,
                                    }
                                    triggers.push(formattedTrigger)
                                })
                            }

                            this.allTriggers = triggers
                            this.countTriggers = count
                            this.totalPagesTriggers = totalPages
                            this.loading = false
                            resolve(res)
                        } else {
                            this.allTriggers = null
                            this.countTriggers = 0
                            this.totalPagesTriggers = 0
                            this.loading = false
                            resolve(res)
                        }
                    })
                    .catch((err) => {
                        this.fetchError = true
                        this.loading = false

                        reject(err)
                    })
            })
        },
        createTrigger(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/eventTrigger', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log(err)

                        reject(err)
                    })
            })
        },
        updateTrigger(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(`/api/v1/eventTrigger/${payload.Id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        this.current = null

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchOneTrigger(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/eventTrigger/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const trigger = res.data
                        const current = {
                            id: +trigger.Id,
                            event_type_id: +trigger.IdEventType,
                            delai: +trigger.Delai,
                            unit_delai: trigger.UnitDelai,
                            action: trigger.Action,
                            id_template_email: +trigger.IdTemplateEmail,
                            id_template_sms: +trigger.IdTemplateSMS,
                            id_template_whatsapp: +trigger.IdTemplateWhatsApp,
                            name: this.i18n.global.t(trigger.Action),
                            value: trigger.Action,
                            template_email: trigger.CommunicationTemplateEmail,
                            template_sms: trigger.CommunicationTemplateSMS,
                            template_whatsApp:
                                trigger.CommunicationTemplateWhatsApp,
                        }
                        this.current = current

                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        deleteTrigger(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .delete(`/api/v1/eventTrigger/${payload}`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },

        fetchTemplates() {
            // ajouter dans api le endpoint pour les templates EMAIl et pour les templates sms
            return new Promise((resolve, reject) => {
                this.axios
                    .get(`/api/v1/templateEmail`, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        const templates = []
                        if (res && res.status === 200) {
                            res.data.forEach((template) => {
                                const formattedTemplate = {
                                    id: +template.Id,
                                    name: template.Name,
                                    subject: template.Subject,
                                    body: template.Body,
                                    creation_date: template.CreationDate,
                                }
                                templates.push(formattedTemplate)
                            })
                        }
                        resolve(templates)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createTemplate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .post('/api/v1/communicationTemplate', payload, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateTemplate(payload) {
            return new Promise((resolve, reject) => {
                this.axios
                    .put(
                        `/api/v1/communicationTemplate/${payload.Id}`,
                        payload,
                        {
                            headers: {
                                Authorization: `Bearer ${this.cookies.get(
                                    'user_session',
                                )}`,
                            },
                            signal: this.controller.signal,
                        },
                    )
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})

export { useNotificationStore }
