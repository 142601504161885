import Dashboard from '../views/Dashboard.vue'
import EmailsDashboard from '../views/EmailsDashboard.vue'

const dashboard = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            authorisations: [
                'DASHBOARD_OPERATOR',
                'DASHBOARD_HARBOR',
                'FULL_ACCESS',
                'DASHBOARD_CLIENT',
            ],
            showUniverseSearch: true,
            showSocietySearch: true,
        },
    },
    {
        path: '/emails-dashboard',
        name: 'emails_dashboard',
        component: EmailsDashboard,

        meta: {
            authorisations: ['DASHBOARD_EMAIL_STATS', 'FULL_ACCESS'],
            showUniverseSearch: false,
            showSocietySearch: true,
        },
    },
]

export default dashboard
