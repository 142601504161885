<template>
    <div class="mobile-screen-template m-4">
        <div class="status-bar">
            <div class="status-icons">
                <span>📶</span>
                <span>🔋</span>
                <span>{{ time }}</span>
            </div>
        </div>

        <div class="header">
            {{ props.headerText }}
        </div>

        <div class="sms-conversation">
            <div
                v-for="(message, index) in props.messages"
                :key="index"
                :class="['message', message.sender]"
            >
                <div class="message-content">
                    {{ message.text }}
                    <div class="message-time">{{ time }}</div>
                </div>
            </div>
        </div>

        <div class="home-indicator"></div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
    headerText: {
        type: String,
        default: 'Messages',
    },
    messages: {
        type: Array,
        default: () => [
            { text: 'Salut, ça va ?', sender: 'received', time: '10:30' },
            { text: 'Oui et toi ?', sender: 'sent', time: '10:31' },
        ],
    },
})

const time = ref(getCurrentTime())

function getCurrentTime() {
    const now = new Date()
    return (
        now.getHours().toString().padStart(2, '0') +
        ':' +
        now.getMinutes().toString().padStart(2, '0')
    )
}

onMounted(() => {
    const timeInterval = setInterval(() => {
        time.value = getCurrentTime()
    }, 60000)

    return () => clearInterval(timeInterval)
})
</script>

<style scoped>
.mobile-screen-template {
    width: 300px;
    height: 600px;
    border: 2px solid #333;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.status-bar {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #f0f0f0;
}

.header {
    background-color: #007bff;
    color: white;
    text-align: center;
    padding: 10px;
}

.sms-conversation {
    flex-grow: 1;
    padding: 10px;
    background-color: #f0f0f0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.message {
    max-width: 80%;
    margin: 5px 0;
}

.message.sent {
    align-self: flex-end;
}

.message.received {
    align-self: flex-start;
}

.message-content {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.message.sent .message-content {
    background-color: #dcf8c6;
}

.message-time {
    font-size: 0.7em;
    color: gray;
    text-align: right;
    margin-top: 5px;
}

.home-indicator {
    height: 5px;
    width: 50px;
    background-color: #333;
    border-radius: 5px;
    margin: 10px auto;
}
</style>
