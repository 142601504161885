<template>
    <div class="px-4 mt-16">
        <div class="my-4 flex items-center font-semibold justify-start">
            <label>{{ $t('see_last_stats') }}</label>
            <input
                type="number"
                v-model="days"
                min="1"
                class="w-1/12 border border-gray-300 rounded-md p-2 mx-2"
            />
            <span>{{ $t('last_days') }}</span>
            <button
                type="button"
                id="submit-button"
                @click="displaySearchResults(this.searchQuery)"
                class="text-white themed-button focus:outline-none rounded text-sm font-bold px-5 py-2.5 focus:z-10 ml-6"
            >
                <fa-icon icon="refresh" class="mr-2"></fa-icon>

                {{ $t('refresh') }}
            </button>
        </div>

        <AggregatedStats :data="aggregatedStats" :key="refresh" />
        <EventsTable
            :key="refreshEvts"
            :events="events"
            :searchQuery="searchQuery"
            @searched="displaySearchResults($event)"
            @changePerPage="changePerPage($event)"
        />
    </div>
</template>

<script>
import { mapActions } from 'pinia'
import AggregatedStats from '@/components/elements/AggregatedStats.vue'
import EventsTable from '@/components/elements/EventsTable.vue'
import { useEmailStore } from '@/stores/emailStore'

export default {
    name: 'EmailsDashboard',
    inject: ['eventBus'],
    components: {
        AggregatedStats,
        EventsTable,
    },
    created() {
        this.displaySearchResults()
        this.eventBus.on('trigger-change-society', (index) => {
            this.displaySearchResults()

        })
    },

    data() {
        return {
            events: [],
            aggregatedStats: {},
            refresh: 0,
            refreshEvts: 0,
            searchQuery: '',
            statsInterval: null,
            days: 15,
        }
    },
    watch: {
        days() {
            this.displaySearchResults()
        },
    },

    methods: {
        ...mapActions(useEmailStore, ['fetchStats']),
        displaySearchResults(query = null) {
            let payload = { days: this.days }
            if (query !== null && query.term && query.term !== '') {
                this.searchQuery = query.term
                payload = { ...payload, email: query.term }
            } else if (query !== null) {
                payload = { ...payload, email: query }
            } else {
                this.searchQuery = ''
            }
            this.fetchStats(payload).then((res) => {
                if (query === null) {
                    this.aggregatedStats = res?.data?.aggregatedStats

                    this.refresh += 1
                }
                this.events = res?.data?.events?.events ?? []
                // this.searchingCursor = false

                this.refreshEvts += 1
            })
        },
    },
    unmounted() {
        clearInterval(this.statsInterval)
    },
}
</script>
