import { defineStore } from 'pinia'

const useEmailStore = defineStore('email', {
    state: () => ({
        all: null,
        aggregatedStats: null,
        events: null,
        count: 0,
        totalPages: 0,
        loading: false,
    }),

    actions: {
        fetchStats(payload) {
            let apiUrl = `/api/v1/communicationSent/getPeriodStatsFromProvider?days=${payload.days}`

            if (payload && payload.email && payload.email !== '') {
                apiUrl = `${apiUrl}&email=${payload.email}`
            }
            return new Promise((resolve, reject) => {
                this.axios
                    .get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${this.cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.controller.signal,
                    })
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },
})

export { useEmailStore }
