export default {
    methods: {
        isSIRET(siret) {
            return this.verify(siret, 14)
        },

        isSIREN(siren) {
            return this.verify(siren, 9)
        },

        verify(number, size) {
            if (Number.isNaN(number) || number.length !== size) return false
            let bal = 0
            let total = 0
            for (let i = size - 1; i >= 0; i -= 1) {
                const step = (number.charCodeAt(i) - 48) * (bal + 1)
                /* if (step>9) { step -= 9; }
                 total += step; */
                total += step > 9 ? step - 9 : step
                bal = 1 - bal
            }
            return total % 10 === 0
        },
    },
}
