<template>
    <main
        class="pl-4 pt-2 rounded-md h-full dark-mode text-xs"
        :class="processing === true ? 'cursor-wait' : ''"
        style="width: 95vw"
    >
        <span
            class="sticky-block bg-white darky"
            :class="processing === true ? 'cursor-wait' : ''"
        >
            <span id="activities-block">
                <div
                    class="items-center pb-1 flex justify-center text-zinc-400 bg-white darky pt-2"
                >
                    <!-- aTODO ajouter ele numéro de semaine -->
                    <fa-icon
                        @click="previousWeek()"
                        icon="circle-chevron-left"
                        class="fa-2xl mr-2 cursor-pointer text-zinc-300"
                    />
                    <span class="text-2xl font-semibold">
                        <div class="rounded themed-bg light">
                            {{ ` ${$t('week', 1)} ${weekNumber}` }}
                        </div>
                        {{
                            week[0].toLocaleDateString($i18n.locale, options) +
                            ' - ' +
                            week[6].toLocaleDateString($i18n.locale, options)
                        }}
                    </span>
                    <fa-icon
                        @click="nextWeek()"
                        icon="circle-chevron-right"
                        class="fa-2xl ml-2 cursor-pointer text-zinc-300"
                    />
                </div>
                <span class="bg-white">
                    <div
                        class="rounded slider bg-zinc-100 px-4 border-2 h-32 overflow-x-auto overflow-y-hidden darky border-zinc-200"
                    >
                        <div class="flex items-center mt-3 justify-center">
                            <input
                                id="set-active-checkbox"
                                type="checkbox"
                                v-model="displayAll"
                                class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                            />
                            <label
                                for="set-active-checkbox"
                                class="ml-2 font-medium"
                                >{{ $t('show_disabled_activities') }}</label
                            >
                        </div>
                        <div
                            class="px-2 py-2 whitespace-nowrap inline-flex items-center"
                        >
                            <div
                                v-if="canCreateGlobalActivity === true"
                                class="border-2 success-msg rounded mx-2 border-gray-300 px-2 py-3"
                            >
                                <fa-icon
                                    icon="plus"
                                    class="fa-xl cursor-pointer"
                                    :title="$t('create')"
                                    @click="showModal(index, null, true, true)"
                                ></fa-icon>
                            </div>
                            <div
                                v-for="activity in activities"
                                :key="activity.title"
                                class="px-2 py-2 mx-2 activity-text shadow rounded"
                                :class="
                                    processing
                                        ? 'cursor-wait'
                                        : grabbed
                                          ? 'cursor-grabbing'
                                          : 'cursor-grab'
                                "
                                :draggable="canUpdate === true"
                                @dragstart="startDrag($event, activity)"
                                :style="`background-color: ${activity.color};`"
                            >
                                <span
                                    v-if="canUpdateGlobalActivity === true"
                                    class="flex justify-center mb-1"
                                >
                                    <button
                                        v-if="
                                            activity.user_id &&
                                            activity.user_id ===
                                                userStore.current.id
                                        "
                                        class="themed-button text-xs text-white transition duration-150 font-semibold px-2 rounded-full"
                                        @click="activityActivation(activity)"
                                    >
                                        {{
                                            activity.active === 1
                                                ? $t('deactivate')
                                                : $t('activate')
                                        }}
                                    </button>
                                    <!-- <fa-icon
                                    icon="trash-can"
                                    :title="$t('delete')"
                                    class="fa-md cursor-pointer mr-2"
                                    @click="deleteActivity(null, activity.id)"
                                ></fa-icon
                               >--><fa-icon
                                        icon="pen"
                                        class="fa-md cursor-pointer ml-1"
                                        :title="$t('edit')"
                                        @click="showModal(null, activity.id)"
                                    ></fa-icon>
                                </span>

                                <span
                                    class="whitespace-nowrap justify-center flex items-center"
                                    :style="`color: ${
                                        activity.type === 'offNotPaid'
                                            ? 'red'
                                            : ''
                                    }`"
                                >
                                    <fa-icon
                                        v-if="activity.type === 'offPaid'"
                                        :title="$t('off_paid')"
                                        class="fa-sm mr-1"
                                        icon="user-slash"
                                    ></fa-icon>
                                    <fa-icon
                                        v-else-if="
                                            activity.type === 'offNotPaid'
                                        "
                                        :title="$t('off_not_paid')"
                                        class="fa-sm mr-1"
                                        style="color: red"
                                        icon="user-slash"
                                    ></fa-icon>

                                    <fa-icon
                                        v-else
                                        :title="$t('atwork')"
                                        icon="user"
                                        class="fa-sm mr-1"
                                    ></fa-icon>
                                    {{ activity.title }}
                                </span>
                                <div class="font-semibold activity-text">
                                    {{ `${activity.start} - ${activity.end}` }}
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </span>
        </span>
        <table
            class="w-full px-4 my-12 table-auto leading-normal"
            :class="processing === true ? 'cursor-wait' : ''"
        >
            <thead>
                <tr id="table-header" class="darky z-100">
                    <th
                        class="rounded-tl w-[10%] px-6 py-2 stop-stretching border-b-2 border-r-2 bg-zinc-100 border-zinc-200"
                    >
                        {{ $t('drivers') }}
                    </th>
                    <th
                        v-for="(day, i) in weekDays"
                        :key="i"
                        class="px-12 w-[10%] border-zinc-200 py-2 border-b-2 font-semibold tracking-wider whitespace-nowrap"
                        :class="
                            6 === i
                                ? 'rounded-tr bg-zinc-100 '
                                : today.toLocaleDateString() ===
                                    week[i].toLocaleDateString()
                                  ? 'themed-bg light border-r-2 activity-text'
                                  : 'border-r-2 bg-zinc-100 '
                        "
                    >
                        {{ `${$t(day)} ${week[i].getDate()}` }}
                    </th>
                </tr>
            </thead>
            <tbody :key="refresh">
                <tr
                    class="darky z-100"
                    v-for="(driver, index) in drivers"
                    :key="index"
                    :class="!isOdd(index) ? 'light-stripe' : 'dark-stripe'"
                >
                    <td
                        class="px-3 w-[10%] py-1 border-zinc-200 text-xs darky border-separate border-r-2 border-b-2"
                    >
                        <button
                            class="mb-2 text-sm cursor-pointer font-semibold"
                            @click="$emit('goToDriverPlanning', driver.Id)"
                        >
                            <span class="items-center custom-link">
                                {{ `${driver.FirstName} ${driver.LastName}` }}
                            </span>
                        </button>
                        <div class="whitespace-nowrap">
                            <span :title="$t('contract_hours')">
                                {{
                                    `${
                                        driver.ContractHour
                                            ? driver.ContractHour
                                            : 0
                                    } h | `
                                }}
                            </span>
                            <span
                                :title="$t('hours_done')"
                                class="bg-green-400 translate-x-6 -translate-y-1/2 top-0 right-0 bottom-auto left-auto rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-1 px-2 text-xs leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded-full z-10"
                            >
                                {{
                                    `${
                                        driver.hours.real
                                            ? driver.hours.real
                                            : 0
                                    } h`
                                }}
                            </span>
                            |
                            <span
                                :title="$t('hours_supp')"
                                class="bg-red-400 translate-x-6 -translate-y-1/2 top-0 right-0 bottom-auto left-auto rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-1 px-2 text-xs leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded-full z-10"
                            >
                                {{
                                    `${
                                        driver.hours.overtime
                                            ? driver.hours.overtime
                                            : 0
                                    } h`
                                }}
                            </span>
                            |
                            <span
                                :title="$t('hours_miss')"
                                class="bg-yellow-400 translate-x-6 -translate-y-1/2 top-0 right-0 bottom-auto left-auto rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 py-1 px-2 text-xs leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded-full z-10"
                            >
                                {{
                                    `${
                                        driver.hours.miss
                                            ? driver.hours.miss
                                            : 0
                                    } h`
                                }}
                            </span>
                        </div>
                    </td>
                    <td
                        v-for="(day, dayIndex) in weekDays"
                        :key="dayIndex"
                        class="py-3 px-1 w-[10%] border-zinc-200 text-xs darky border-separate border-r-2 border-b-2"
                        @drop="
                            onDrop(
                                $event,
                                `driver-${driver.IdUser}-activities`,
                                dayIndex,
                                driver.IdUser,
                                index,
                            )
                        "
                        @dragover.prevent
                        @dragenter.prevent
                    >
                        <span
                            :key="refresh"
                            :class="
                                getDriverActivities(
                                    driver.IdUser,
                                    dayIndex,
                                    index,
                                ).length > 1
                                    ? 'grid grid-cols-2 px-1 gap-1'
                                    : ''
                            "
                        >
                            <div
                                v-for="activity in getDriverActivities(
                                    driver.IdUser,
                                    dayIndex,
                                    index,
                                )"
                                :id="`${driver.Id}-${dayIndex}`"
                                :key="activity.Name"
                                class="p-2 activity-text shadow rounded cursor-grab border-2"
                                :class="
                                    getDriverActivities(
                                        driver.IdUser,
                                        dayIndex,
                                        index,
                                    ).length > 1
                                        ? 'col-span-1'
                                        : ''
                                "
                                :style="`background-color: ${activity.Color};`"
                                :draggable="canUpdate === true"
                                @dragstart="startDrag($event, activity)"
                            >
                                <div
                                    v-if="activity.basePlanning === true"
                                    class="flex items-center justify-between"
                                >
                                    <span class="italic mr-2 whitespace-nowrap">
                                        {{ $t('regular_activity') }}
                                    </span>
                                    <span
                                        v-if="canUpdate === true"
                                        class="flex justify-end"
                                    >
                                        <fa-icon
                                            icon="trash-can"
                                            :title="$t('delete')"
                                            class="fa-md cursor-pointer mr-2"
                                            @click="
                                                deleteActivity(
                                                    activity,
                                                    driver.IdUser,
                                                )
                                            "
                                        ></fa-icon>
                                    </span>
                                </div>
                                <span v-else class="flex justify-end">
                                    <fa-icon
                                        v-if="canUpdate === true"
                                        icon="trash-can"
                                        :title="$t('delete')"
                                        class="fa-md cursor-pointer mr-2"
                                        @click="
                                            deleteActivity(
                                                activity,
                                                driver.IdUser,
                                            )
                                        "
                                    ></fa-icon>
                                </span>
                                <span
                                    class="justify-center flex items-center px-2 flex-wrap"
                                    :style="`color: ${
                                        activity.Type === 'offNotPaid'
                                            ? 'red'
                                            : ''
                                    }`"
                                >
                                    <fa-icon
                                        v-if="activity.Type === 'offPaid'"
                                        :title="$t('off_paid')"
                                        class="fa-sm mr-1"
                                        icon="user-slash"
                                    ></fa-icon>
                                    <fa-icon
                                        v-else-if="
                                            activity.Type === 'offNotPaid'
                                        "
                                        :title="$t('off_not_paid')"
                                        class="fa-sm mr-1"
                                        style="color: red"
                                        icon="user-slash"
                                    ></fa-icon>
                                    <fa-icon
                                        v-else
                                        :title="$t('atwork')"
                                        icon="user"
                                        class="fa-sm mr-1"
                                    ></fa-icon>
                                    {{ activity.Name }}
                                </span>
                                <div class="font-semibold activity-text">
                                    {{ `${activity.Start} - ${activity.End}` }}
                                </div>
                            </div>
                        </span>

                        <!-- <fa-icon
                            icon="plus"
                            class="fa-xl cursor-pointer"
                            :title="$t('create')"
                            @click="
                                showModal(
                                    index,
                                    null,
                                    true,
                                    false,
                                    driver.id,
                                    dayIndex
                                )
                            "
                        ></fa-icon> -->
                    </td>
                </tr>
            </tbody>
        </table>
        <modal
            v-if="showEditModal"
            :headerText="modalHeaderText"
            :buttonText="modalButtonText"
            id="addModal"
            :mxWidth="'w-1/4'"
            :buttonAlign="'justify-center'"
            @closeModal="closeModal()"
            @sendFromModal="submitModal()"
        >
            <template #body>
                <div class="mx-3">
                    <div class="mb-3 items-center flex flex-row mt-2">
                        <label
                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                            >{{ $t('title') + ' *' }}</label
                        >
                        <input
                            v-model="activityForm.title"
                            autocomplete="name"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.activityForm.title.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div class="mb-3 items-center flex flex-row mt-2">
                        <label
                            class="font-semibold text-gray-600 whitespace-nowrap pb-1 mr-6 block"
                            >{{ $t('activity_type') + ' *' }}</label
                        >
                        <Multiselect
                            class="border darky rounded px-3 py-2 mt-1 w-full"
                            v-model="activityForm.type"
                            :options="[
                                { name: $t('atwork'), value: 'atwork' },
                                {
                                    name: $t('off_paid'),
                                    value: 'offPaid',
                                },
                                {
                                    name: $t('off_not_paid'),
                                    value: 'offNotPaid',
                                },
                            ]"
                            :searchable="false"
                            :allow-empty="false"
                            :can-deselect="false"
                            :can-clear="false"
                            label="name"
                            track-by="name"
                        ></Multiselect>
                    </div>
                    <div
                        class="text-xs italic mt-1 mb-2"
                        v-for="error of v$.activityForm.type.$errors"
                        :key="error.$uid"
                    >
                        <div class="error-msg">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div class="mb-3 items-center flex flex-row mt-4 px-12">
                        <label
                            class="font-semibold text-gray-600 pb-1 mr-2 block"
                            >{{ $t('pick_color') }}</label
                        >

                        <input
                            type="color"
                            v-model="activityForm.color"
                            id="favcolor"
                            name="favcolor"
                            class="cursor-pointer"
                        />
                        <!-- class="border rounded px-3 py-2 mt-1 w-full" -->
                    </div>
                    <div class="grid grid-cols-2 gap-2 mt-4 px-3">
                        <div class="col-span-1">
                            <label
                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                >{{ $t('start_time') + ' *' }}</label
                            >
                            <input
                                type="time"
                                v-model="activityForm.start"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.activityForm.start.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>

                        <div class="col-span-1">
                            <label
                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                >{{ $t('end_time') + ' *' }}</label
                            >
                            <input
                                type="time"
                                v-model="activityForm.end"
                                class="border rounded px-3 py-2 mt-1 w-full"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.activityForm.end.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-xs italic mt-1 mb-2" v-if="badTimeRange">
                        <div class="error-msg">
                            {{ $t('bad_time_range') }}
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                </div>
            </template>
        </modal>
        <modal
            v-if="openOverlapModal"
            :headerText="'overlap_modal_header'"
            :buttonText="'overlap_modal_button'"
            id="overlapModal"
            :mxWidth="'max-w-2xl'"
            :buttonAlign="'justify-center'"
            @closeModal="forcedByUser(false)"
            @sendFromModal="forcedByUser(true)"
        >
            <template #body>
                <div class="mx-3">
                    <div class="flex justify-center mt-2">
                        {{ $t('overlap_activity') }}
                    </div>
                    <div class="flex justify-center">
                        {{ $t('overlap_activity2') }}
                    </div>
                </div></template
            ></modal
        >
        <modal
            v-if="openIsUsedModal"
            :headerText="'is_used_modal_header'"
            :buttonText="'is_used_modal_button_update_all'"
            id="isUsedModal"
            :mxWidth="'max-w-3xl'"
            :buttonAlign="'justify-center'"
            :secondSubmit="true"
            :secondSubmitText="$t('create_new_activity')"
            @closeModal="forcedByUser(false, true)"
            @sendFromModal="forcedByUser(true, true, $event)"
        >
            <template #body>
                <div class="mx-4 text-base">
                    <div class="flex justify-center mt-3">
                        {{ $t('is_used_activity') }}
                    </div>
                    <div class="flex mt-2 justify-center">
                        {{ $t('is_used_activity2') }}
                    </div>
                    <div class="flex mt-2 justify-center">
                        {{ $t('is_used_activity3') }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import { mapActions, mapState, mapStores } from 'pinia'

import slider from '@/mixins/slider'
import { useActivityStore } from '@/stores/activityStore'
import { useDriverStore } from '@/stores/driverStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

import Modal from '@/components/atoms/Modal.vue'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useUniverseStore } from '@/stores/universeStore'

const timeIsValid = helpers.regex(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

export default {
    name: 'WeeklyPlanning',
    components: {
        Modal,
        Multiselect,
    },
    mixins: [slider],
    inject: ['eventBus'],
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            forceActivity: null,
            grabbed: false,
            universesFilter: [],
            displayAll: false,
            openOverlapModal: false,
            forceActivityUpdate: null,
            duplicate: false,
            openIsUsedModal: false,
            processing: false,
            badTimeRange: false,
            updateGlobal: false,
            newGlobalActivity: false,
            createActivity: false,
            modalHeaderText: 'edit_activity',
            modalButtonText: 'update',
            showEditModal: false,
            activityForm: null,
            driverIndexInUpdate: null,
            activityIndexInUpdate: null,
            today: new Date(),
            monday: null,
            picked: new Date().toISOString().slice(0, 10),
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            refresh: 0,
            filter: [{ dbField: 'Active', value: 1 }],
            options: {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            },
            drag: false,
            weekDays: [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday',
            ],
        }
    },
    validations() {
        return {
            activityForm: {
                title: { required },
                type: { required },
                start: {
                    required,
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
                end: {
                    required,
                    timeIsValid: helpers.withMessage(
                        this.$t('bad_time_format'),
                        timeIsValid,
                    ),
                },
            },
        }
    },
    created() {
        window.scrollTo(0, 0)
        this.processing = true
        this.eventBus.on('trigger-universe-search', (param) => {
            this.searchCustomUniverses(param)
        })
        this.eventBus.on('remove-from-selected-universe', (index) => {
            this.removeFromSelectedUniverses(index)
        })
        this.getMonday(this.today)

        const begin = this.week[0].toISOString().slice(0, 10)
        const end = this.week[6].toISOString().slice(0, 10)
        let universesToUse = []
        const universesInterval = setInterval(() => {
            if (this.universesOptions.length > 0) {
                clearInterval(universesInterval)
                universesToUse =
                    this.selectedUniverses.length > 0
                        ? this.selectedUniverses
                        : this.universesOptions
                universesToUse.forEach((universe) => {
                    this.universesFilter.push(universe.value)
                })
                const payload = {
                    begin: begin.replaceAll('-', ''),
                    end: end.replaceAll('-', ''),
                    universes: this.universesFilter.join(),
                }
                this.activityStore
                    .search({
                        query: [],
                        filter: [{ dbField: 'Active', value: 1 }],
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                    })
                    .then((res) => {
                        this.fetchDriversRealPlannings(payload).then(() => {
                            this.processing = false
                        })
                    })
            }
        }, 10)
        this.eventBus.on('trigger-change-society', (index) => {
            const payload = {
                begin: begin.replaceAll('-', ''),
                end: end.replaceAll('-', ''),
                universes: this.universesFilter.join(),
            }
            this.activityStore
                .search({
                    query: [],
                    filter: [{ dbField: 'Active', value: 1 }],
                    sortDirection: this.sortDirection,
                    sortField: this.sortField,
                    perPage: this.perPage,
                    page: this.page,
                })
                .then((res) => {
                    this.fetchDriversRealPlannings(payload).then(() => {
                        this.processing = false
                    })
                })
        })
        //     })
        // })
    },
    mounted() {
        window.scrollTo(0, 0)
        this.setSlider()
    },
    unmounted() {},
    watch: {
        displayAll(newVal) {
            let filter = []
            if (newVal === false) {
                filter = [{ dbField: 'Active', value: 1 }]
            }
            this.activityStore.search({
                query: [],
                filter,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: this.perPage,
                page: this.page,
            })
        },
        driversActivities() {
            this.refresh += 1
        },
    },
    computed: {
        ...mapState(useDriverStore, {
            // drivers: 'all',
            count: 'count',
            driversActivities: 'driversActivities',
            driversCalendar: 'driversCalendar',
        }),
        ...mapState(useActivityStore, {
            activities: 'all',
            currentGlobal: 'current',
        }),
        ...mapStores(useActivityStore, useUserStore),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),
        canUpdate() {
            return checkAccess(
                this.userStore,
                actionsAccess.weeklyPlanning.update,
            )
        },
        canCreateGlobalActivity() {
            return checkAccess(
                this.userStore,
                actionsAccess.weeklyPlanning.create,
            )
        },
        canUpdateGlobalActivity() {
            return checkAccess(
                this.userStore,
                actionsAccess.weeklyPlanning.updateActivity,
            )
        },

        selectedDate() {
            const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            }

            const date = new Date(this.picked).toLocaleDateString(
                this.$i18n.locale,
                options,
            )

            return date
        },

        drivers() {
            const results = []
            this.driversCalendar.forEach((drCa) => {
                results.push(drCa.driver)
            })
            return results
        },

        week() {
            const wk = []
            wk.push(this.monday)
            const daysToAdd = [1, 2, 3, 4, 5, 6]
            daysToAdd.forEach((day) => {
                const nextDay = new Date(this.monday.getTime())

                nextDay.setDate(this.monday.getDate() + day)

                wk.push(nextDay)
            })

            return wk
        },
        weekNumber() {
            const oneJan = new Date(this.week[0].getFullYear(), 0, 1)
            const numberOfDays = Math.floor(
                (this.week[0] - oneJan) / (24 * 60 * 60 * 1000),
            )
            return Math.ceil((this.week[0].getDay() + 1 + numberOfDays) / 7)
        },
    },
    methods: {
        ...mapActions(useDriverStore, {
            searchDrivers: 'search',
            fetchDriverPlanning: 'fetchDriverPlanning',
            addPlanningBaseActivity: 'addPlanningBaseActivity',
            fetchDriverRealPlanning: 'fetchDriverRealPlanning',
            fetchDriversRealPlannings: 'fetchDriversRealPlannings',
            addPlanningCalendarActivity: 'addPlanningCalendarActivity',
            deletePlanningCalendar: 'deletePlanningCalendar',
            addPlanningException: 'addPlanningException',
        }),
        searchCustomUniverses(clear = false) {
            clearInterval(this.dashboardInterval)

            let filter = null
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                // this.selectedUniverses = []
                filter = this.universesOptions // TDO universOptions?
            } else {
                filter = this.selectedUniverses
            }
            this.universesFilter = []
            filter.forEach((universe) => {
                this.universesFilter.push(universe.value)
            })
            const begin = this.week[0].toISOString().slice(0, 10)
            const end = this.week[6].toISOString().slice(0, 10)

            const payload = {
                // id: driver.user_id,
                begin: begin.replaceAll('-', ''),
                end: end.replaceAll('-', ''),
                universes: this.universesFilter.join(),
            }
            this.fetchDriversRealPlannings(payload).then(() => {
                this.processing = false
            })
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.dashboardInterval)

            this.searchCustomUniverses()
        },
        nextWeek() {
            this.processing = true
            this.monday = new Date(this.monday.getTime())
            this.monday.setDate(
                this.monday.getDate() +
                    ((1 + 7 - this.monday.getDay()) % 7 || 7),
            )
            // this.drivers.forEach((driver) => {
            const begin = this.week[0].toISOString().slice(0, 10)
            const end = this.week[6].toISOString().slice(0, 10)

            const payload = {
                // id: driver.user_id,
                begin: begin.replaceAll('-', ''),
                end: end.replaceAll('-', ''),
                universes: this.universesFilter.join(),
            }
            this.fetchDriversRealPlannings(payload).then(() => {
                this.processing = false
            })
            // })
        },
        previousWeek() {
            this.processing = true
            this.monday = new Date(this.monday.getTime())
            this.monday.setDate(this.monday.getDate() - 7)
            // this.drivers.forEach((driver) => {
            const begin = this.week[0].toISOString().slice(0, 10)
            const end = this.week[6].toISOString().slice(0, 10)

            const payload = {
                // id: driver.user_id,
                begin: begin.replaceAll('-', ''),
                end: end.replaceAll('-', ''),
                universes: this.universesFilter.join(),
            }
            this.fetchDriversRealPlannings(payload).then(() => {
                this.processing = false
            })
            // })
        },
        getMonday(d) {
            const dt = new Date(d)
            const day = dt.getDay()
            const diff = dt.getDate() - day + (day === 0 ? -6 : 1)
            this.monday = new Date(dt.setDate(diff))
        },
        getDriverActivities(driverUserId, dayIndex, drIndex) {
            if (
                this.driversActivities &&
                +driverUserId !== 0 &&
                driverUserId !== null
            ) {
                let date = this.week[dayIndex]
                date = `${date.getFullYear()}-${`0${`0${
                    date.getMonth() + 1
                }`.slice(-2)}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
                const activities = this.driversActivities.filter(
                    (activity) =>
                        activity.Date === date &&
                        +activity.IdUser === +driverUserId,
                )
                return activities
            }
            return []
        },
        activityActivation(activity) {
            if (activity.active === 1) {
                this.activityStore.deactivate(activity.id).then(() => {
                    let filter = []
                    if (this.displayAll === false) {
                        filter = [{ dbField: 'Active', value: 1 }]
                    }
                    this.activityStore.search({
                        query: [],
                        filter,
                        sortDirection: this.sortDirection,
                        sortField: this.sortField,
                        perPage: this.perPage,
                        page: this.page,
                    })
                })
            } else {
                const form = {
                    CreationUserId: activity.creation_user_id,
                    ModificationUserId: this.userStore.current.id,
                    Color: activity.color,
                    End: activity.end,
                    Start: activity.start,
                    Name: activity.title,
                    Active: 1,
                    Type: activity.type,
                    IdUser: activity.user_id,
                }
                this.activityStore
                    .update({
                        id: activity.id,
                        form,
                    })
                    .then((res) => {
                        let filter = []
                        if (this.displayAll === false) {
                            filter = [{ dbField: 'Active', value: 1 }]
                        }
                        this.activityStore.search({
                            query: [],
                            filter,
                            sortDirection: this.sortDirection,
                            sortField: this.sortField,
                            perPage: this.perPage,
                            page: this.page,
                        })
                    })
                    .catch((err) => {
                        this.$toast.error('activity_create_ko')
                    })
            }
        },

        deleteActivity(activity, driverUserId) {
            this.processing = true
            const begin = this.week[0].toISOString().slice(0, 10)
            const end = this.week[6].toISOString().slice(0, 10)
            if (activity.basePlanning === true) {
                this.addPlanningException({
                    IdPlanningBase: activity.IdPlanningBase,
                    DateException: activity.Date,
                    CreationUserId: this.userStore.current.id,
                    ModificationUserId: this.userStore.current.id,
                })
                    .then(() => {
                        const payload = {
                            id: driverUserId,
                            begin: begin.replaceAll('-', ''),
                            end: end.replaceAll('-', ''),
                        }

                        this.fetchDriverRealPlanning(payload).then(() => {
                            this.processing = false
                        })
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('error_occured'))
                        this.processing = false
                    })
            } else {
                this.deletePlanningCalendar(activity.Id)
                    .then(() => {
                        const payload = {
                            id: driverUserId,
                            begin: begin.replaceAll('-', ''),
                            end: end.replaceAll('-', ''),
                        }
                        this.fetchDriverRealPlanning(payload).then(() => {
                            this.processing = false
                        })
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('error_occured'))

                        this.processing = false
                    })
            }
        },
        showModal(
            driverIndex,
            activityId,
            create = false,
            newGlobalActivity = false,
            driverId = null,
            dayIndex = null,
        ) {
            this.driverIndexInUpdate = driverIndex

            if (create === false) {
                // create  activity in driver line  is not allowed for now

                // if (driverIndex !== null) {
                //     this.activityForm = this.driversActivities[
                //         driverIndex
                //     ].find((act) => act.id === +activityId)
                //     this.activityIndexInUpdate = this.driversActivities[
                //         driverIndex
                //     ].indexOf(this.activityForm)
                // } else {
                this.updateGlobal = true
                this.activityForm = this.activities.find(
                    (act) => act.id === +activityId,
                )
                this.activityForm.userId = this.userStore.current.id
                this.activityIndexInUpdate = this.activities.indexOf(
                    this.activityForm,
                )
                // }
            } else {
                this.modalHeaderText = 'new_activity'
                this.modalButtonText = 'save'

                if (newGlobalActivity === true) {
                    this.createActivity = true

                    this.newGlobalActivity = true
                    this.activityForm = {
                        id: this.activities.length + 1,
                        title: '',
                        color: '#fff8dc',
                        start: '08:00',
                        end: '16:00',
                        list: 'activities',
                        type: 'atwork',
                        active: 1,
                        userId: this.userStore.current.id,
                    }
                }
                // update assigned activity is not allowed for now
                // else {
                //     this.createActivity = true
                //     this.activityForm = {
                //         id: this.driversActivities[driverIndex].length + 1,
                //         title: '',
                //         day: dayIndex,
                //         driver: driverId,
                //         color: '#fff8dc',
                //         start: '08:00',
                //         type: 'atwork',
                //         end: '16:00',
                //         active: 1,

                //         list: `driver-${driverId}-activities`,
                //     }
                // }
            }
            this.showEditModal = true
        },
        async submitModal() {
            this.badTimeRange = false
            this.v$.activityForm.$reset()

            const isFormCorrect = await this.v$.$validate()
            const hasTimeErrors = this.checkTimes()
            if (isFormCorrect === true && hasTimeErrors === false) {
                if (this.createActivity === false) {
                    if (this.updateGlobal === false) {
                        // update  activity in driver line  is not allowed for now
                        this.driversActivities[this.driverIndexInUpdate][
                            this.activityIndexInUpdate
                        ] = this.activityForm
                    } else {
                        this.activityStore
                            .fetchOne(this.activityForm.id)
                            .then(() => {
                                if (this.currentGlobal.IsUsed === 1) {
                                    this.openIsUsedModal = true
                                    this.processing = false
                                    this.forceActivityUpdate = null
                                    const isUsedInterval = setInterval(() => {
                                        if (this.forceActivityUpdate !== null) {
                                            this.processing = false

                                            clearInterval(isUsedInterval)

                                            if (this.duplicate === true) {
                                                const form = {
                                                    CreationUserId:
                                                        this.userStore.current
                                                            .id,
                                                    ModificationUserId:
                                                        this.userStore.current
                                                            .id,
                                                    Color: this.activityForm
                                                        .color,
                                                    End: this.activityForm.end,
                                                    Start: this.activityForm
                                                        .start,
                                                    Name: this.activityForm
                                                        .title,
                                                    Active: 1,
                                                    Type: this.activityForm
                                                        .type,
                                                    IdUser: this.userStore
                                                        .current.id,
                                                }
                                                this.closeModal()
                                                let filter = []
                                                if (this.displayAll === false) {
                                                    filter = [
                                                        {
                                                            dbField: 'Active',
                                                            value: 1,
                                                        },
                                                    ]
                                                }
                                                this.activityStore
                                                    .create(form)
                                                    .then((res) => {
                                                        this.activityStore
                                                            .search({
                                                                query: [],
                                                                filter,
                                                                sortDirection:
                                                                    this
                                                                        .sortDirection,
                                                                sortField:
                                                                    this
                                                                        .sortField,
                                                                perPage:
                                                                    this
                                                                        .perPage,
                                                                page: this.page,
                                                            })
                                                            .then(() => {
                                                                this.duplicate = false

                                                                this.refresh += 1
                                                                this.processing = false
                                                            })
                                                    })
                                                    .catch((err) => {
                                                        this.processing = false
                                                        this.duplicate = false

                                                        this.$toast.error(
                                                            'activity_create_ko',
                                                        )
                                                    })
                                            } else if (
                                                this.forceActivityUpdate ===
                                                true
                                            ) {
                                                this.processing = true

                                                const form = {
                                                    ModificationUserId:
                                                        this.userStore.current
                                                            .id,
                                                    Color: this.activityForm
                                                        .color,
                                                    End: this.activityForm.end,
                                                    Start: this.activityForm
                                                        .start,
                                                    Name: this.activityForm
                                                        .title,
                                                    Active: this.activityForm
                                                        .active,
                                                    Type: this.activityForm
                                                        .type,
                                                    IdUser: this.userStore
                                                        .current.id,
                                                    Id: this.activityForm.id,
                                                }
                                                this.closeModal()
                                                let filter = []
                                                if (this.displayAll === false) {
                                                    filter = [
                                                        {
                                                            dbField: 'Active',
                                                            value: 1,
                                                        },
                                                    ]
                                                }
                                                this.activityStore
                                                    .update({
                                                        id: form.Id,
                                                        form,
                                                    })
                                                    .then((res) => {
                                                        this.activityStore
                                                            .search({
                                                                query: [],
                                                                filter,
                                                                sortDirection:
                                                                    this
                                                                        .sortDirection,
                                                                sortField:
                                                                    this
                                                                        .sortField,
                                                                perPage:
                                                                    this
                                                                        .perPage,
                                                                page: this.page,
                                                            })
                                                            .then(() => {
                                                                this.getMonday(
                                                                    this.today,
                                                                )

                                                                const begin =
                                                                    this.week[0]
                                                                        .toISOString()
                                                                        .slice(
                                                                            0,
                                                                            10,
                                                                        )
                                                                const end =
                                                                    this.week[6]
                                                                        .toISOString()
                                                                        .slice(
                                                                            0,
                                                                            10,
                                                                        )

                                                                const payload =
                                                                    {
                                                                        begin: begin.replaceAll(
                                                                            '-',
                                                                            '',
                                                                        ),
                                                                        end: end.replaceAll(
                                                                            '-',
                                                                            '',
                                                                        ),
                                                                        universes:
                                                                            this.universesFilter.join(),
                                                                    }
                                                                this.fetchDriversRealPlannings(
                                                                    payload,
                                                                ).then(() => {
                                                                    this.processing = false
                                                                })
                                                                this.refresh += 1
                                                            })
                                                    })
                                                    .catch((err) => {
                                                        this.$toast.error(
                                                            'update_ko',
                                                        )
                                                    })
                                            } else {
                                                this.processing = false
                                                this.duplicate = false
                                                this.forceActivity = null
                                                this.$toast.info(
                                                    this.$t('action_canceled'),
                                                )
                                            }
                                        }
                                    }, 150)
                                } else {
                                    this.processing = true

                                    const form = {
                                        ModificationUserId:
                                            this.userStore.current.id,
                                        Color: this.activityForm.color,
                                        End: this.activityForm.end,
                                        Start: this.activityForm.start,
                                        Name: this.activityForm.title,
                                        Active: this.activityForm.active,
                                        Type: this.activityForm.type,
                                        IdUser: this.userStore.current.id,
                                        Id: this.activityForm.id,
                                    }
                                    this.closeModal()
                                    let filter = []
                                    if (this.displayAll === false) {
                                        filter = [
                                            {
                                                dbField: 'Active',
                                                value: 1,
                                            },
                                        ]
                                    }
                                    this.activityStore
                                        .update({
                                            id: form.Id,
                                            form,
                                        })
                                        .then((res) => {
                                            this.activityStore
                                                .search({
                                                    query: [],
                                                    filter,
                                                    sortDirection:
                                                        this.sortDirection,
                                                    sortField: this.sortField,
                                                    perPage: this.perPage,
                                                    page: this.page,
                                                })
                                                .then(() => {
                                                    this.getMonday(this.today)

                                                    const begin = this.week[0]
                                                        .toISOString()
                                                        .slice(0, 10)
                                                    const end = this.week[6]
                                                        .toISOString()
                                                        .slice(0, 10)

                                                    const payload = {
                                                        begin: begin.replaceAll(
                                                            '-',
                                                            '',
                                                        ),
                                                        end: end.replaceAll(
                                                            '-',
                                                            '',
                                                        ),
                                                        universes:
                                                            this.universesFilter.join(),
                                                    }
                                                    this.fetchDriversRealPlannings(
                                                        payload,
                                                    ).then(() => {
                                                        this.processing = false
                                                    })
                                                    this.refresh += 1
                                                })
                                        })
                                        .catch((err) => {
                                            this.$toast.error('update_ko')
                                        })
                                }
                            })
                        // this.activities[this.activityIndexInUpdate] =
                        //     this.activityForm
                    }
                } else if (this.newGlobalActivity === true) {
                    const form = {
                        CreationUserId: this.userStore.current.id,
                        ModificationUserId: this.userStore.current.id,
                        Color: this.activityForm.color,
                        End: this.activityForm.end,
                        Start: this.activityForm.start,
                        Name: this.activityForm.title,
                        Active: 1,
                        Type: this.activityForm.type,
                        IdUser: this.userStore.current.id,
                    }
                    let filter = []
                    if (this.displayAll === false) {
                        filter = [{ dbField: 'Active', value: 1 }]
                    }
                    this.activityStore
                        .create(form)
                        .then((res) => {
                            this.activityStore.search({
                                query: [],
                                filter,
                                sortDirection: this.sortDirection,
                                sortField: this.sortField,
                                perPage: this.perPage,
                                page: this.page,
                            })
                        })
                        .catch((err) => {
                            this.$toast.error('activity_create_ko')
                        })
                        .finally(() => {
                            this.refresh += 1

                            this.closeModal()
                        })
                } else {
                    this.driversActivities[this.driverIndexInUpdate].push(
                        this.activityForm,
                    )
                    this.refresh += 1

                    this.closeModal()
                }
            }
        },
        forcedByUser(isForced, isUsedModal = false, secondSubmit = false) {
            this.processing = isForced
            if (isUsedModal === true) {
                this.forceActivityUpdate =
                    isForced === true && secondSubmit === false

                this.duplicate = secondSubmit
                this.openIsUsedModal = false
                if (isForced === false && secondSubmit === false) {
                    this.closeModal()
                }
            } else {
                this.forceActivity = isForced

                this.openOverlapModal = false
            }
        },
        checkTimeOverlap(driverUserId, activityToAdd, dayIndex, evt) {
            let overlap = false
            // we create a new date with the time we already have (date itself doesnt matter it jsut needds to be the same for both compared times)
            // after that we can compare these two dates with getTime( date js function)
            this.getDriverActivities(driverUserId, dayIndex).forEach(
                (driverAct) => {
                    const [driverActStartHour, driverActStartMinutes] =
                        driverAct.Start.split(':')
                    const [driverActEndHour, driverActEndMinutes] =
                        driverAct.End.split(':')
                    const [activityToAddStartHour, activityToAddStartMinutes] =
                        activityToAdd.start.split(':')
                    const [activityToAddEndHour, activityToAddEndMinutes] =
                        activityToAdd.end.split(':')

                    const drActivityStart = new Date(
                        2022,
                        0,
                        1,
                        +driverActStartHour,
                        +driverActStartMinutes,
                    )
                    const drActivityEnd = new Date(
                        2022,
                        0,
                        1,
                        +driverActEndHour,
                        +driverActEndMinutes,
                    )
                    const actToAddStart = new Date(
                        2022,
                        0,
                        1,
                        +activityToAddStartHour,
                        +activityToAddStartMinutes,
                    )
                    const actToAddEnd = new Date(
                        2022,
                        0,
                        1,
                        +activityToAddEndHour,
                        +activityToAddEndMinutes,
                    )

                    if (
                        drActivityStart.getTime() === actToAddStart.getTime() ||
                        drActivityStart.getTime() <
                            actToAddStart.getTime() <
                            drActivityEnd.getTime() ||
                        drActivityStart.getTime() <
                            actToAddEnd.getTime() <
                            drActivityEnd.getTime()
                    ) {
                        overlap = true
                        this.processing = false
                        this.openOverlapModal = true
                    }
                },
            ) // activités existantes du user

            return overlap
        },
        checkTimes() {
            if (
                this.activityForm.end < this.activityForm.start ||
                this.activityForm.end === this.activityForm.start
            ) {
                this.badTimeRange = true
                return true
            }
            return false
        },
        closeModal() {
            this.badTimeRange = false
            this.v$.activityForm.$reset()
            this.showEditModal = false
            this.activityForm = null
            this.driverIndexInUpdate = null
            this.activityIndexInUpdate = null
            this.createActivity = false
            this.newGlobalActivity = false
            this.updateGlobal = false
            this.processing = false
        },
        isOdd(num) {
            return num % 2
        },
        startDrag(evt, activity) {
            this.grabbed = true
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'

            evt.dataTransfer.setData('draggedItemId', activity.id)
            evt.dataTransfer.setData('draggedItemList', activity.list)
        },
        onDrop(evt, list, dayIndex, driverUserId, drIndex) {
            this.grabbed = false

            const draggedItemList = evt.dataTransfer.getData('draggedItemList')

            this.processing = true
            const draggedItemId = evt.dataTransfer.getData('draggedItemId')
            let currentList = this.activities
            // TODO gérer le drag and drop entre jours / entre drivers
            if (draggedItemList !== 'activities') {
                // const activity = this.driversActivities.find(
                //     (driverAct) => driverAct.list === draggedItemList
                // )
                // const activityIndex = this.driversActivities.indexOf(activity)
                currentList = this.driversActivities
            }

            const activity = currentList.find(
                (act) => act.id === +draggedItemId,
            )
            let date = this.week[dayIndex]
            date = `${date.getFullYear()}${`0${`0${date.getMonth() + 1}`.slice(
                -2,
            )}`.slice(-2)}${`0${date.getDate()}`.slice(-2)}`
            const actDate = activity.Date
                ? activity.Date.replaceAll('-', '')
                : null

            if (
                actDate === null ||
                date !== actDate ||
                list !== draggedItemList
            ) {
                let timeOverlap = false
                timeOverlap = this.checkTimeOverlap(
                    driverUserId,
                    activity,
                    dayIndex,
                    evt,
                )
                if (timeOverlap === false) {
                    this.addPlanningCalendarActivity({
                        Name: activity.title,
                        Color: activity.color,
                        IdUser: driverUserId,
                        Date: date,
                        End: activity.end,
                        IdPlanningActivity: activity.base_activity_id,
                        Start: activity.start,
                        Type: activity.type,
                        CreationUserId: this.userStore.current.id,
                        ModificationUserId: this.userStore.current.id,
                    })
                        .then(() => {
                            const begin = this.week[0]
                                .toISOString()
                                .slice(0, 10)
                            const end = this.week[6].toISOString().slice(0, 10)

                            const payload = {
                                id: driverUserId,
                                begin: begin.replaceAll('-', ''),
                                end: end.replaceAll('-', ''),
                            }
                            this.fetchDriverRealPlanning(payload).then(() => {
                                this.processing = false
                                this.refresh += 1
                            })
                        })
                        .catch(() => {
                            this.processing = false
                            this.$toast.error(this.$t('error_occured'))
                        })
                } else {
                    const overlapInterval = setInterval(() => {
                        if (this.forceActivity !== null) {
                            clearInterval(overlapInterval)

                            if (this.forceActivity === true) {
                                this.addPlanningCalendarActivity({
                                    Name: activity.title,
                                    Color: activity.color,
                                    IdUser: driverUserId,
                                    Date: date,
                                    End: activity.end,
                                    IdPlanningActivity:
                                        activity.base_activity_id,
                                    Start: activity.start,
                                    Type: activity.type,
                                    CreationUserId: this.userStore.current.id,
                                    ModificationUserId:
                                        this.userStore.current.id,
                                })
                                    .then(() => {
                                        const begin = this.week[0]
                                            .toISOString()
                                            .slice(0, 10)
                                        const end = this.week[6]
                                            .toISOString()
                                            .slice(0, 10)

                                        const payload = {
                                            id: driverUserId,
                                            begin: begin.replaceAll('-', ''),
                                            end: end.replaceAll('-', ''),
                                        }
                                        this.fetchDriverRealPlanning(
                                            payload,
                                        ).then(() => {
                                            this.processing = false
                                            this.forceActivity = null

                                            this.refresh += 1
                                        })
                                    })
                                    .catch(() => {
                                        this.forceActivity = null

                                        this.processing = false
                                        this.$toast.error(
                                            this.$t('error_occured'),
                                        )
                                    })
                            } else {
                                this.processing = false
                                this.forceActivity = null
                                this.$toast.info(this.$t('action_canceled'))
                            }
                        }
                    }, 150)
                }
            } else {
                this.processing = false
                this.forceActivity = null
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.moving-card {
    @apply opacity-50 bg-gray-100 border border-blue-500;
}
.sticky-block {
    position: sticky;
    z-index: 12;
    top: 2.9rem; /* Don't forget this, required for the stickiness */
}
thead {
    position: sticky;
    z-index: 10;
    top: 15.6rem; /* Don't forget this, required for the stickiness */
}
</style>
