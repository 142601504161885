<template>
    <main
        style="width: 96vw"
        class="px-4 pt-10 rounded-md h-full"
        :class="processing === true ? 'cursor-progress' : ''"
    >
        <div id="authnroles" class="mx-auto md:w-full">
            <div class="grid grid-cols-6 gap-4 mt-16">
                <div class="rounded-md border-2 p-2 col-span-2">
                    <span
                        class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                    >
                        <fa-icon icon="users" class="mr-2"></fa-icon
                        >{{ $t('users') }}
                    </span>
                    <div class="grid-cols-3 grid gap-1 mt-2">
                        <div class="col-span-1 pl-2 pb-2">
                            <search-input
                                :key="refresh"
                                :customHeight="true"
                                :searchBy="'search_by_name'"
                                :dbField="'FirstName'"
                                :searchQuery="userSearchQuery"
                                :small="false"
                                :searchIcon="'magnifying-glass'"
                                @searched="debounceUserSearch($event)"
                            >
                            </search-input>
                        </div>
                        <div class="col-span-1 pb-2">
                            <select-one
                                id="sort-select"
                                :key="refresh"
                                :small="true"
                                :sortableFields="clientTypeOptions"
                                :storeToSort="'allUsersStore'"
                                :selected="selectedUserType"
                                :withLabel="false"
                                :searchedItems="users"
                                @clear="clearUserFilter()"
                                @selected="filterUsers($event)"
                            ></select-one>
                        </div>
                        <div class="col-span-1 pr-2 pb-2 mt-1">
                            <div
                                :title="$t('create')"
                                @click="toCreateUser()"
                                class="h-10 w-full items-center themed-bg cursor-pointer justify-center flex border-2 themed-border rounded-md"
                            >
                                <fa-icon icon="plus" class="fa-xl"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div
                        class="grid-cols-2 grid gap-1 px-2 mt-2 h-screen overflow-y-auto"
                    >
                        <div
                            class="px-2 pb-2 border-2 rounded-md col-span-1 max-h-fit"
                            v-for="(user, index) in users"
                            :key="index"
                            @drop="onDropRole($event, user.id)"
                            @dragover.prevent
                            @dragenter.prevent
                        >
                            <div class="flex justify-between my-2 pr-2">
                                <fa-icon
                                    icon="trash-can"
                                    :title="$t('delete')"
                                    class="fa-sm mr-3 cursor-pointer"
                                    @click="
                                        deleteUser(
                                            user.id,
                                            `${user.lastname} ${user.firstname}`
                                        )
                                    "
                                ></fa-icon>
                                <fa-icon
                                    icon="pen"
                                    class="fa-sm cursor-pointer"
                                    :title="$t('edit')"
                                    @click="toUpdateUser(user.id)"
                                ></fa-icon>
                            </div>
                            <span
                                class="items-center text-sm justify-center mt-2 font-semibold flex custom-link mb-2"
                            >
                                {{ `${user.firstname} ${user.lastname}` }}
                            </span>
                            <span
                                class="items-center text-sm justify-center italic overflow-auto flex mb-2"
                            >
                                {{ user.email }}
                            </span>
                            <span
                                class="items-center text-sm justify-center flex mb-2"
                            >
                                {{ `${$t('user_type')} : ${$t(user.type)}` }}
                            </span>
                            <span v-if="user.roles && user.roles.length">
                                <span
                                    class="items-center text-sm justify-center mt-2 font-semibold flex pb-2"
                                >
                                    {{ `${$t('roles')}` }}
                                </span>
                                <div class="mb-2">
                                    <div
                                        v-for="(userRole, index) in user.roles"
                                        :key="index"
                                        class="text-xs mb-2"
                                        :title="userRole.Description"
                                    >
                                        <div
                                            class="border-2 rounded-md h-auto p-1"
                                        >
                                            <div class="flex justify-end">
                                                <fa-icon
                                                    :title="$t('remove')"
                                                    icon="xmark"
                                                    class="ml-2 cursor-pointer"
                                                    @click="
                                                        removeUserRole(
                                                            userRole.IdUserRole
                                                        )
                                                    "
                                                ></fa-icon>
                                            </div>
                                            {{ `${userRole.Name}` }}
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div
                                class="items-center text-xs justify-center flex"
                            >
                                {{
                                    `${$t('modification_date')} ${new Date(
                                        user.modification_date
                                    ).toLocaleString($i18n.locale)}`
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rounded-md border-2 p-2 col-span-2">
                    <span
                        class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                    >
                        <fa-icon icon="masks-theater" class="mr-2"></fa-icon
                        >{{ $t('roles') }}
                    </span>
                    <div class="grid-cols-3 grid gap-1 mt-2">
                        <div class="col-span-1 pl-2 pb-2">
                            <search-input
                                :key="refresh"
                                :searchBy="'search_by_name'"
                                :customHeight="true"
                                :dbField="'Name'"
                                :searchQuery="roleSearchQuery"
                                :small="false"
                                :searchIcon="'magnifying-glass'"
                                @searched="debounceRoleSearch($event)"
                            >
                            </search-input>
                        </div>
                        <div class="col-span-1 pb-2">
                            <search-input
                                :key="refresh"
                                :customHeight="true"
                                :searchBy="'search_by_code'"
                                :dbField="'Code'"
                                :searchQuery="roleCodeSearchQuery"
                                :small="false"
                                :searchIcon="'magnifying-glass'"
                                @searched="debounceRoleSearch($event)"
                            >
                            </search-input>
                        </div>
                        <div class="col-span-1 pr-2 pb-2 mt-1">
                            <div
                                :title="$t('create')"
                                @click="toCreateRole()"
                                class="h-10 w-full items-center themed-bg cursor-pointer justify-center flex border-2 themed-border rounded-md"
                            >
                                <fa-icon icon="plus" class="fa-xl"></fa-icon>
                            </div>
                        </div>
                    </div>

                    <div
                        class="grid-cols-2 grid gap-1 px-2 mt-2 h-screen overflow-y-auto"
                    >
                        <div
                            class="px-2 pb-2 border-2 rounded-md col-span-1 max-h-fit"
                            v-for="(role, index) in roles"
                            :key="index"
                            @drop="onDrop($event, role.Id)"
                            @dragover.prevent
                            @dragenter.prevent
                            :draggable="true"
                            :class="
                                processing
                                    ? 'cursor-wait'
                                    : grabbed
                                    ? 'cursor-grabbing'
                                    : 'cursor-grab'
                            "
                            @dragstart="startDragRole($event, role.Id)"
                        >
                            <div class="flex justify-between my-2 pr-2">
                                <fa-icon
                                    icon="trash-can"
                                    :title="$t('delete')"
                                    class="fa-sm cursor-pointer mr-3"
                                    @click="deleteRole(role.Id, role.Name)"
                                ></fa-icon>
                                <fa-icon
                                    icon="pen"
                                    class="fa-sm cursor-pointer"
                                    :title="$t('edit')"
                                    @click="toUpdateRole(role.Id)"
                                ></fa-icon>
                            </div>
                            <span
                                class="items-center text-sm justify-center font-semibold flex custom-link mb-2"
                            >
                                {{ role.Name }}
                            </span>
                            <span
                                class="items-center text-sm justify-center font-semibold flex mb-2 overflow-auto"
                            >
                                {{ `${$t('code')} : ${role.Code}` }}
                            </span>
                            <span
                                class="items-center text-sm justify-center mt-2 flex mb-2"
                            >
                                {{ `${role.Description}` }}
                            </span>
                            <span v-if="role.Authorizations.length">
                                <span
                                    class="items-center text-sm justify-center mt-2 font-semibold flex pb-2"
                                >
                                    {{ `${$t('authorisations')}` }}
                                </span>
                                <div class="mb-2">
                                    <div
                                        v-for="(
                                            roleAuth, index
                                        ) in role.Authorizations"
                                        :key="index"
                                        class="mb-2 text-xs"
                                        :title="roleAuth.Description"
                                    >
                                        <div
                                            class="border-2 rounded-md h-auto p-1"
                                        >
                                            <div class="flex justify-end">
                                                <fa-icon
                                                    icon="xmark"
                                                    :title="$t('remove')"
                                                    class="ml-2 cursor-pointer"
                                                    @click="
                                                        removeRoleAuthorisation(
                                                            roleAuth.IdRoleAuthorization
                                                        )
                                                    "
                                                ></fa-icon>
                                            </div>
                                            {{ `${roleAuth.Name}` }}
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div
                                class="items-center text-xs justify-center flex"
                            >
                                {{
                                    `${$t('modification_date')} ${new Date(
                                        role.ModificationDate
                                    ).toLocaleString($i18n.locale)}`
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rounded-md border-2 p-2 col-span-2">
                    <div class="mt-2">
                        <span
                            class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                        >
                            <fa-icon icon="user-lock" class="mr-2"></fa-icon
                            >{{ $t('authorisations') }}
                        </span>
                        <div class="grid-cols-3 grid gap-1 mt-2">
                            <div class="col-span-1 pl-2 pb-2">
                                <search-input
                                    :key="refresh"
                                    :searchBy="'search_by_name'"
                                    :dbField="'Name'"
                                    :customHeight="true"
                                    :searchQuery="authSearchQuery"
                                    :small="false"
                                    :searchIcon="'magnifying-glass'"
                                    @searched="debounceAuthSearch($event)"
                                >
                                </search-input>
                            </div>
                            <div class="col-span-1 pb-2">
                                <search-input
                                    :key="refresh"
                                    :customHeight="true"
                                    :searchBy="'search_by_code'"
                                    :dbField="'Code'"
                                    :searchQuery="authCodeSearchQuery"
                                    :small="false"
                                    :searchIcon="'magnifying-glass'"
                                    @searched="debounceAuthSearch($event)"
                                >
                                </search-input>
                            </div>
                            <div class="col-span-1 pr-2 pb-2 mt-1">
                                <div
                                    :title="$t('create')"
                                    @click="toCreateAuthorisation()"
                                    class="h-10 w-full items-center cursor-pointer themed-bg justify-center flex border-2 rounded-md themed-border"
                                >
                                    <fa-icon
                                        icon="plus"
                                        class="fa-xl"
                                    ></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div
                            class="grid-cols-2 grid gap-1 px-2 mt-2 h-screen overflow-y-auto"
                        >
                            <div
                                class="px-2 pb-2 border-2 rounded-md col-span-1 max-h-fit"
                                v-for="(authorisation, index) in authorisations"
                                :key="index"
                                :draggable="true"
                                :class="
                                    processing
                                        ? 'cursor-wait'
                                        : grabbed
                                        ? 'cursor-grabbing'
                                        : 'cursor-grab'
                                "
                                @dragstart="startDrag($event, authorisation.Id)"
                            >
                                <div class="flex justify-between pr-2 my-2">
                                    <fa-icon
                                        icon="trash-can"
                                        :title="$t('delete')"
                                        class="fa-sm cursor-pointer mr-3"
                                        @click="
                                            deleteAuthorisation(
                                                authorisation.Id,
                                                authorisation.Name
                                            )
                                        "
                                    ></fa-icon>

                                    <fa-icon
                                        icon="pen"
                                        class="fa-sm cursor-pointer"
                                        :title="$t('edit')"
                                        @click="
                                            toUpdateAuthorisation(
                                                authorisation.Id
                                            )
                                        "
                                    ></fa-icon>
                                </div>
                                <span
                                    class="items-center text-sm justify-center font-semibold flex custom-link mb-2"
                                >
                                    {{ authorisation.Name }}
                                </span>
                                <span
                                    class="items-center text-sm justify-center mt-2 font-semibold flex mb-2 overflow-auto"
                                >
                                    {{
                                        `${$t('code')} : ${authorisation.Code}`
                                    }}
                                </span>
                                <span
                                    class="items-center text-sm justify-center mt-2 flex mb-2"
                                >
                                    {{ `${authorisation.Description}` }}
                                </span>
                                <div
                                    class="items-center text-xs justify-center flex"
                                >
                                    {{
                                        `${$t('modification_date')} ${new Date(
                                            authorisation.ModificationDate
                                        ).toLocaleString($i18n.locale)}`
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <modal
                v-if="showAuthModal"
                :headerText="authModalHeaderText"
                :buttonText="'save'"
                :creating="creating"
                id="addModal"
                :mxWidth="'w-1/2'"
                :buttonAlign="'justify-center'"
                @closeModal="closeAuthModal()"
                @sendFromModal="submitAuthModal()"
            >
                <template #body>
                    <div class="mb-3 mt-2 px-8">
                        <span class="grid grid-cols-2 gap-1">
                            <div class="col-span-1">
                                <label
                                    class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('name') + ' *' }} <br />
                                </label>
                                <input
                                    v-model="authFormData.Name"
                                    autocomplete="name"
                                    maxlength="100"
                                    @blur="v$.authFormData.Name.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.authFormData.Name
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <label
                                    class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('auth_code') + ' *' }} <br />
                                </label>
                                <input
                                    v-model="authFormData.Code"
                                    :title="
                                        editAuthorisation === true
                                            ? $t('auth_code_not_editable')
                                            : ''
                                    "
                                    :disabled="editAuthorisation === true"
                                    maxLength="30"
                                    :placeholder="'ex.: NOM_ENTITE-ACTION_CORRESPONDANTE'"
                                    @blur="v$.authFormData.Code.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.authFormData.Code
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </span>
                        <label
                            class="basis-1/4 text-gray-600 pb-1 pt-2 mr-2 block"
                            >{{ $t('description') + ' *' }} <br />
                        </label>
                        <textarea
                            v-model="authFormData.Description"
                            @blur="v$.authFormData.Description.$touch"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.authFormData.Description.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                        <span
                            class="items-center justify-center mt-2 flex pb-2"
                        >
                            {{ `${$t('add_to_roles')}` }}
                        </span>
                        <div class="border-t-2 border-b-2 themed-border">
                            <div
                                v-for="(role, index) in roles"
                                :key="index"
                                :class="
                                    index === roles.length - 1 ? 'mb-1' : ''
                                "
                            >
                                <div
                                    class="flex items-center mt-1 justify-start"
                                >
                                    <input
                                        :id="`checkbox-role-${index}`"
                                        type="checkbox"
                                        v-model="isCheckedRole(role.Id).value"
                                        @change="checkRole(role.Id)"
                                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                    />
                                    <label
                                        :for="`checkbox-role-${index}`"
                                        class="ml-2 font-medium"
                                    >
                                        {{
                                            `${role.Name} : ${role.Description}`
                                        }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                </template>
            </modal>
            <modal
                v-if="showRoleModal"
                :headerText="roleModalHeaderText"
                :buttonText="'save'"
                :creating="creating"
                id="addModal"
                :mxWidth="'w-1/2'"
                :buttonAlign="'justify-center'"
                @closeModal="closeRoleModal()"
                @sendFromModal="submitRoleModal()"
            >
                <template #body>
                    <div class="mb-3 mt-2 px-8">
                        <span class="grid grid-cols-2 gap-1">
                            <div class="col-span-1">
                                <label
                                    class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('name') + ' *' }} <br />
                                </label>
                                <input
                                    v-model="roleFormData.Name"
                                    autocomplete="name"
                                    maxlength="100"
                                    @blur="v$.roleFormData.Name.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.roleFormData.Name
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-1">
                                <label
                                    class="basis-1/4 text-gray-600 pb-1 mr-2 block"
                                    >{{ $t('role_code') + ' *' }} <br />
                                </label>
                                <input
                                    v-model="roleFormData.Code"
                                    :title="
                                        editRole === true
                                            ? $t('role_code_not_editable')
                                            : ''
                                    "
                                    :disabled="editRole === true"
                                    maxLength="20"
                                    :placeholder="'ex.: CODE'"
                                    @blur="v$.roleFormData.Code.$touch"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                                <div
                                    class="text-xs italic mt-1 mb-2"
                                    v-for="error of v$.roleFormData.Code
                                        .$errors"
                                    :key="error.$uid"
                                >
                                    <div class="error-msg">
                                        {{ error.$message }}
                                    </div>
                                </div>
                            </div>
                        </span>
                        <label
                            class="basis-1/4 text-gray-600 pb-1 pt-2 mr-2 block"
                            >{{ $t('description') + ' *' }} <br />
                        </label>
                        <textarea
                            v-model="roleFormData.Description"
                            @blur="v$.roleFormData.Description.$touch"
                            class="border rounded px-3 py-2 mt-1 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 mb-2"
                            v-for="error of v$.roleFormData.Description.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                        <span
                            class="items-center justify-center mt-2 flex pb-2"
                        >
                            {{ `${$t('authorisations')}` }}
                        </span>
                        <div
                            class="border-t-2 border-b-2 themed-border h-44 overflow-y-auto"
                        >
                            <div
                                v-for="(auth, index) in authorisations"
                                :key="index"
                                :class="
                                    index === authorisations.length - 1
                                        ? 'mb-1'
                                        : ''
                                "
                            >
                                <div
                                    class="flex items-center mt-1 justify-start"
                                >
                                    <input
                                        :id="`checkbox-auth-${index}`"
                                        type="checkbox"
                                        v-model="isCheckedAuth(auth.Id).value"
                                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        @change="
                                            checkAuth(
                                                auth,
                                                !isCheckedAuth(auth.Id).value
                                            )
                                        "
                                    />
                                    <label
                                        :for="`checkbox-auth-${index}`"
                                        class="ml-2 font-medium"
                                    >
                                        {{
                                            `${auth.Name} : ${auth.Description}`
                                        }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <span
                            class="items-center justify-center mt-2 flex pb-2"
                        >
                            {{ `${$t('add_role_to_users')}` }}
                        </span>
                        <div class="border-t-2 border-b-2 themed-border">
                            <div class="border-b-2 mt-2 pb-2">
                                <div
                                    class="flex items-center mt-1 justify-center flex-wrap"
                                >
                                    <span
                                        v-for="(userType, index) in userTypes"
                                        :key="index"
                                        class="flex items-center"
                                    >
                                        <input
                                            :id="`checkbox-${userType.name}`"
                                            type="checkbox"
                                            v-model="userType.value"
                                            @change="checkByType(userType)"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            :for="`checkbox-${userType.name}`"
                                            class="ml-1 mr-4 font-medium"
                                        >
                                            {{ $t(userType.name) }}
                                        </label>
                                    </span>
                                </div>
                                <div
                                    class="text-xs italic mt-1 flex justify-center"
                                >
                                    {{ $t('already_selected_info') }}
                                </div>
                            </div>
                            <div class="h-56 overflow-y-auto">
                                <div
                                    v-for="(user, index) in users"
                                    :key="index"
                                    :class="
                                        index === users.length - 1 ? 'mb-1' : ''
                                    "
                                >
                                    <div
                                        class="flex items-center mt-1 justify-start"
                                    >
                                        <input
                                            :id="`checkbox-user-${index}`"
                                            type="checkbox"
                                            v-model="
                                                isCheckedUser(user.id).value
                                            "
                                            @change="checkUser(user.id)"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            :for="`checkbox-user-${index}`"
                                            class="ml-2 font-medium"
                                        >
                                            {{
                                                `${$t(user.type)} - ${
                                                    user.firstname
                                                }  ${user.lastname}`
                                            }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        class="flex mt-1 italic text-xs items-center justify-center"
                    >
                        {{ $t('required_fields') }}
                    </span>
                </template>
            </modal>
            <modal
                v-if="showDeleteConfirmModal"
                :headerText="deleteModalHeader"
                :buttonText="'confirm'"
                id="deletemodal"
                :mxWidth="'max-w-2xl'"
                :buttonAlign="'justify-center'"
                @closeModal="deleteConfirmation(false)"
                @sendFromModal="deleteConfirmation(true)"
            >
                <template #body>
                    <div class="mx-8">
                        <div class="flex justify-center mt-8">
                            {{ $t(deleteModalText) }}
                        </div>

                        <div class="flex justify-center mt-2">
                            {{ elementToDelete }}
                        </div>
                        <div class="flex text-xs italic justify-center mt-4">
                            {{ $t('delete_modal_generic_text_2') }}
                        </div>
                    </div></template
                ></modal
            >
        </div>
    </main>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { mapState, mapStores } from 'pinia'
import Modal from '@/components/atoms/Modal.vue'
import { useUserStore } from '@/stores/userStore'
import { useAuthorisationStore } from '@/stores/authorisationStore'
import { useRoleStore } from '@/stores/roleStore'
import filterList from '@/mixins/filterList'
import SearchInput from '@/components/elements/SearchInput.vue'
import { useAllUsersStore } from '@/stores/allUsersStore'
import SelectOne from '@/components/elements/SelectOne.vue'
import userRoleAuth from '@/mixins/userRoleAuth'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'AuthorizationManager',

    components: {
        Modal,
        SelectOne,
        SearchInput,
    },
    mixins: [filterList, userRoleAuth],
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            processing: false,
            editAuthorisation: false,
            authModalHeaderText: 'new_authorisation',
            roleModalHeaderText: 'new_role',
            creating: false,
            roleAuthIds: [],
            authRoleIds: [],
            userRoleIds: [],
            selectedUserType: null,
            roleSearchQuery: '',
            roleCodeSearchQuery: '',
            userSearchQuery: '',
            showDeleteConfirmModal: false,
            confirmDelete: null,
            elementToDelete: '',
            deleteModalHeader: '',
            deleteModalText: '',
            authSearchQuery: '',
            authCodeSearchQuery: '',
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10000,
            page: 1,
            showAuthModal: false,
            showRoleModal: false,
            authorisationsInterval: null,
            rolesInterval: null,
            filter: [],
            userFilter: [],
            query: [],
            roleQuery: [],
            userQuery: [],
            authFormData: {
                Name: '',
                Id: '',
                CreationUserId: '',
                Code: null,
                Description: '',
            },
            roleFormData: {
                Name: '',
                Id: '',
                Code: null,
                CreationUserId: '',
                Description: '',
            },
            grabbed: false,
        }
    },
    validations() {
        return {
            authFormData: {
                Name: { required: requiredIf(this.showAuthModal === true) },
                Description: {
                    required: requiredIf(this.showAuthModal === true),
                },
                Code: { required: requiredIf(this.showAuthModal === true) },
            },
            roleFormData: {
                Name: { required: requiredIf(this.showRoleModal === true) },
                Description: {
                    required: requiredIf(this.showRoleModal === true),
                },
                Code: {
                    required: requiredIf(this.showRoleModal === true),
                },
            },
        }
    },
    watch: {
        authorisations() {
            this.refresh += 1
        },
        roles() {
            this.refresh += 1
        },
        users() {
            this.refresh += 1
        },
    },

    created() {
        window.scrollTo(0, 0)
        this.searchAllStores()
    },

    unmounted() {
        // clearInterval(this.authorisationsInterval)
        // clearInterval(this.rolesInterval)
        // clearInterval(this.UsersInterval)
    },
    computed: {
        ...mapStores(useAuthorisationStore, useRoleStore, useAllUsersStore),

        ...mapState(useUserStore, {
            user: 'current',
        }),
        authorisations() {
            return this.authorisationStore.all
        },
        roles() {
            return this.roleStore.all
        },
        users() {
            return this.allUsersStore.all
        },
        clientTypeOptions() {
            const options = {
                options: [
                    {
                        name: 'client',
                        value: 'client',
                        dbField: 'Type',
                    },
                    {
                        name: 'driver',
                        value: 'driver',
                        dbField: 'Type',
                    },
                    {
                        name: 'operator',
                        value: 'operator',
                        dbField: 'Type',
                    },
                    {
                        name: 'admin',
                        value: 'admin',
                        dbField: 'Type',
                    },
                    {
                        name: 'super_admin',
                        value: 'superadmin',
                        dbField: 'Type',
                    },
                ],
            }

            return options
        },
    },
    methods: {
        searchAllStores() {
            const realSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: this.perPage,
                page: this.page,
                query: this.query,
                filter: [],
                fromBO: true,
            }
            this.authorisationStore.search(realSearch).then(() => {
                this.refresh += 1
            })
            // this.authorisationsInterval = setInterval(() => {
            //     this.authorisationStore.search(realSearch)
            // }, 20000)
            const roleSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: this.perPage,
                page: this.page,
                query: this.roleQuery,
                filter: [],
                fromBO: true,
            }
            this.roleStore.search(roleSearch).then(() => {
                this.refresh += 1
            })
            // this.rolesInterval = setInterval(() => {
            //     this.roleStore.search(realSearch)
            // }, 20000)
            const userSearch = {
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: this.perPage,
                page: this.page,
                query: this.userQuery,
                fromBo: true,

                filter: this.userFilter,
            }
            this.allUsersStore.search(userSearch)
            this.processing = false
        },
        checkRoleAuths(roleId, authId) {
            const currentRole = this.roles.find((role) => role.Id === roleId)
            const hasAuth = currentRole.Authorizations.find(
                (auth) => auth.Id === authId
            )
            if (hasAuth) {
                return true
            }
            return false
        },
        startDrag(evt, authId) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('draggedAuthId', authId)
            evt.dataTransfer.setData('draggedList', 'auth')

            this.grabbed = true
        },
        onDrop(evt, roleId) {
            if (evt.dataTransfer.getData('draggedList') === 'auth') {
                this.processing = true
                this.grabbed = false

                const draggedAuthId = evt.dataTransfer.getData('draggedAuthId')

                let roleAlreadyHasAuth = false
                roleAlreadyHasAuth = this.checkRoleAuths(roleId, draggedAuthId)

                if (roleAlreadyHasAuth === false) {
                    const payload = {
                        IdRole: roleId,
                        IdAuthorization: draggedAuthId,
                        CreationUserId: this.user.id,
                        ModificationUserId: this.user.id,
                    }
                    this.roleStore
                        .addRoleAuthorisation(payload)
                        .then(() => {
                            this.processing = false

                            this.searchAllStores()
                        })
                        .catch(() => {
                            this.processing = false
                            this.$toast.error(this.$t('error_occured'))
                        })
                } else {
                    this.processing = false
                    this.$toast.info(this.$t('role_already_has_auth'))
                }
            } else {
                this.$toast.info(this.$t('action_not_allowed'))
            }
        },

        startDragRole(evt, roleId) {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('draggedRoleId', roleId)
            evt.dataTransfer.setData('draggedList', 'role')

            this.grabbed = true
        },
        onDropRole(evt, userId) {
            if (evt.dataTransfer.getData('draggedList') === 'role') {
                this.processing = true
                this.grabbed = false

                const draggedRoleId = evt.dataTransfer.getData('draggedRoleId')

                let userAlreadyHasRole = false
                userAlreadyHasRole = this.checkUserRoles(userId, draggedRoleId)

                if (userAlreadyHasRole === false) {
                    const payload = {
                        IdUser: userId,
                        IdRole: draggedRoleId,
                        CreationUserId: this.user.id,
                        ModificationUserId: this.user.id,
                    }
                    this.allUsersStore
                        .addUserRole(payload)
                        .then(() => {
                            this.processing = false

                            this.searchAllStores()
                        })
                        .catch(() => {
                            this.processing = false
                            this.$toast.error(this.$t('error_occured'))
                        })
                } else {
                    this.processing = false
                    this.$toast.info(this.$t('role_already_has_auth'))
                }
            } else {
                this.$toast.info(this.$t('action_not_allowed'))
            }
        },
        checkUserRoles(userId, roleId) {
            const currentUser = this.users.find((user) => user.id === userId)
            const hasAuth = currentUser.roles.find((role) => role.Id === roleId)
            if (hasAuth) {
                return true
            }
            return false
        },
        removeRoleAuthorisation(roleAuthorizationId) {
            this.roleStore
                .deleteRoleAuthorisation(roleAuthorizationId)
                .then(() => {
                    this.searchAllStores()
                    this.$toast.success(this.$t('deleted_ok'))
                })
                .catch(() => {
                    this.$toast.error(this.$t('deleted_ko'))
                })
        },
        removeUserRole(userRoleId) {
            this.allUsersStore
                .deleteUserRole(userRoleId)
                .then(() => {
                    this.searchAllStores()
                    this.$toast.success(this.$t('deleted_ok'))
                })
                .catch(() => {
                    this.$toast.error(this.$t('deleted_ko'))
                })
        },
        deleteRole(id, roleName) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `${this.$t('role')} : ${roleName}`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.processing = true

                        this.roleStore
                            .delete(id)
                            .then(() => {
                                this.searchAllStores()
                                this.processing = false

                                this.$toast.success(this.$t('deleted_ok'))
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                                this.processing = false
                            })
                    }
                }
            }, 150)
        },
        deleteConfirmation(confirmDelete) {
            this.confirmDelete = confirmDelete
            this.processing = confirmDelete
            this.showDeleteConfirmModal = false
        },
        deleteAuthorisation(id, authName) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `${this.$t('authorisation')} : ${authName}`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.processing = true

                        this.authorisationStore
                            .delete(id)
                            .then(() => {
                                this.searchAllStores()
                                this.processing = false

                                this.$toast.success(this.$t('deleted_ok'))
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                                this.processing = false
                            })
                    }
                }
            }, 150)
        },
        deleteUser(id, userName) {
            this.confirmDelete = null
            this.showDeleteConfirmModal = true
            this.deleteModalHeader = 'delete_modal_generic_header'
            this.deleteModalText = 'delete_modal_generic_text'
            this.elementToDelete = `${this.$t('user')} : ${userName}`

            const deleteInterval = setInterval(() => {
                if (this.confirmDelete !== null) {
                    clearInterval(deleteInterval)

                    if (this.confirmDelete === true) {
                        this.processing = true

                        this.allUsersStore
                            .delete(id)
                            .then(() => {
                                this.processing = false
                                this.searchAllStores()

                                this.$toast.success(this.$t('user_delete_ok'))
                            })
                            .catch(() => {
                                this.processing = false

                                this.$toast.error(this.$t('user_delete_ko'))
                            })
                    }
                }
            }, 150)
        },
        toCreateUser() {
            this.$router.push({ name: 'new_user' })
        },
        toUpdateUser(id) {
            this.allUsersStore.fetchOne(id).then(() => {
                this.$router.push({ name: 'update_user', params: { id } })
            })
        },
        toCreateAuthorisation() {
            this.processing = true

            const roleSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: this.perPage,
                page: this.page,
                query: [],
                filter: [],
                fromBO: true,
            }
            this.roleStore.search(roleSearch).then(() => {
                this.refresh += 1
                // this.rolesInterval = setInterval(() => {
                //     this.roleStore.search(realSearch)
                // }, 20000)

                this.authorisationStore.current = null
                this.authModalHeaderText = 'new_authorisation'
                this.showAuthModal = true
                this.processing = false
            })
        },
        toUpdateAuthorisation(id) {
            this.processing = true
            const roleSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: this.perPage,
                page: this.page,
                query: [],
                filter: [],
                fromBO: true,
            }
            this.roleStore.search(roleSearch).then(() => {
                this.refresh += 1

                this.authorisationStore.fetchOne(id).then((res) => {
                    this.authFormData = {
                        Id: this.authorisationStore.current.Id,
                        Name: this.authorisationStore.current.Name,
                        Code: this.authorisationStore.current.Code,
                        CreationUserId:
                            this.authorisationStore.current.CreationUserId,
                 
                        Description:
                            this.authorisationStore.current.Description,
                    }
                    this.authModalHeaderText = 'update_authorisation'
                    this.showAuthModal = true
                    this.editAuthorisation = true
                    this.roles.forEach((role) => {
                        if (
                            role.Authorizations.length &&
                            role.Authorizations.find(
                                (roleAuth) =>
                                    roleAuth.Id ===
                                    this.authorisationStore.current.Id
                            )
                        ) {
                            this.authRoleIds.push(role.Id)
                        }
                    })
                    this.processing = false
                })
            })
        },

        toCreateRole() {
            this.processing = true

            const realSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: this.perPage,
                page: this.page,
                query: [],
                filter: [],
                fromBO: true,
            }
            const usersRealSearch = {
                sortDirection: 'ASC',
                sortField: 'LastName',
                perPage: this.perPage,
                page: this.page,
                query: [],
                filter: [],
                fromBO: true,
            }
            this.allUsersStore.search(usersRealSearch)
            this.authorisationStore.search(realSearch).then(() => {
                this.refresh += 1
                this.roleStore.current = null
                this.roleModalHeaderText = 'new_role'
                this.showRoleModal = true
                this.processing = false
            })
        },
        toUpdateRole(id) {
            this.processing = true

            const realSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: this.perPage,
                page: this.page,
                query: [],
                filter: [],
                fromBO: true,
            }
            const usersRealSearch = {
                sortDirection: 'ASC',
                sortField: 'LastName',
                perPage: this.perPage,
                page: this.page,
                query: [],
                filter: [],
                fromBO: true,
            }
            this.allUsersStore.search(usersRealSearch)
            this.authorisationStore.search(realSearch).then(() => {
                this.refresh += 1
                this.roleStore.fetchOne(id).then((res) => {
                    this.roleFormData = {
                        Id: this.roleStore.current.Id,
                        Name: this.roleStore.current.Name,
                        Code: this.roleStore.current.Code,
                        CreationUserId: this.roleStore.current.CreationUserId,
                        Description: this.roleStore.current.Description,
                    }
                    this.roleStore.current.Authorizations.forEach((auth) => {
                        this.roleAuthIds.push(auth.Id)
                    })
                    this.roleStore.current.Users.forEach((user) => {
                        this.userRoleIds.push(user.Id)
                    })
                    this.roleModalHeaderText = 'update_role'
                    this.showRoleModal = true
                    this.editRole = true
                    this.processing = false
                })
            })
        },

        closeAuthModal() {
            this.authorisationStore.$patch({ current: null })
            this.creating = false
            this.v$.authFormData.$reset()
            this.showAuthModal = false
            this.editAuthorisation = false
            this.authFormData = {
                Name: '',
                Id: '',
                Code: null,
                CreationUserId: '',
                Description: null,
            }
            this.authRoleIds = []
            this.searchAllStores()
        },
        closeRoleModal() {
            this.roleStore.$patch({ current: null })
            this.roleAuthIds = []
            this.userRoleIds = []
            this.creating = false
            this.v$.roleFormData.$reset()
            this.showRoleModal = false
            this.editRole = false
            this.roleFormData = {
                Name: '',
                Id: '',
                Code: null,
                CreationUserId: '',
                Description: null,
            }
            this.searchAllStores()
        },
        async submitAuthModal() {
            this.creating = true
            const isValid = await this.v$.$validate()
            const codeIsAvailable = !this.notPickableCodes(
                this.authorisationStore.count,
                this.authorisations,
                this.authFormData.Id
            ).includes(this.authFormData.Code)

            if (codeIsAvailable === false) {
                this.creating = false
                this.$toast.error(this.$t('code_already_exists'))
                return
            }
            // this.query.push({ dbField: 'Name', term: this.authSearchQuery })
            // this.query.push({ dbField: 'Code', term: this.authCodeSearchQuery })

            if (isValid === true) {
                this.authFormData.Roles = []
                this.authRoleIds.forEach((id) => {
                    this.authFormData.Roles.push({ Id: id })
                })

                if (this.editAuthorisation === true) {
                    this.authFormData.ModificationUserId = this.user.id

                    const response = await this.authorisationStore.update({
                        id: this.authFormData.Id,
                        form: this.authFormData,
                    })
                    if (response.status === 200) {
                        this.searchAllStores()

                        this.$toast.success(this.$t('update_ok'))
                        this.closeAuthModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.authFormData.CreationUserId = this.user.id

                    const response = await this.authorisationStore.create(
                        this.authFormData
                    )
                    if (response.status === 201) {
                        this.searchAllStores()

                        this.$toast.success(this.$t('create_ok'))
                        this.creating = false

                        this.closeAuthModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.$toast.error(this.$t('form_errors'))
            }
        },
        async submitRoleModal() {
            this.creating = true
            const codeIsAvailable = !this.notPickableCodes(
                this.roleStore.count,
                this.roles,
                this.roleFormData.Id
            ).includes(this.roleFormData.Code)

            if (codeIsAvailable === false) {
                this.creating = false
                this.$toast.error(this.$t('code_already_exists'))
                return
            }
            // this.roleQuery.push({ dbField: 'Name', term: this.roleSearchQuery })
            // this.roleQuery.push({
            //     dbField: 'Code',
            //     term: this.roleCodeSearchQuery,
            // })
            const isValid = await this.v$.$validate()

            if (isValid === true) {
                this.roleFormData.Authorizations = []
                this.roleAuthIds.forEach((id) => {
                    this.roleFormData.Authorizations.push({ Id: id })
                })
                this.roleFormData.Users = []
                this.userRoleIds.forEach((id) => {
                    this.roleFormData.Users.push({ Id: id })
                })
                if (this.editRole === true) {
                    this.roleFormData.ModificationUserId = this.user.id
                    const response = await this.roleStore.update({
                        id: this.roleFormData.Id,
                        form: this.roleFormData,
                    })
                    if (response.status === 200) {
                        this.creating = false

                        this.searchAllStores()

                        this.$toast.success(this.$t('update_ok'))
                        this.closeRoleModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('update_ko'))
                    }
                } else {
                    this.roleFormData.CreationUserId = this.user.id

                    const response = await this.roleStore.create(
                        this.roleFormData
                    )
                    if (response.status === 201) {
                        this.creating = false

                        this.searchAllStores()

                        this.$toast.success(this.$t('create_ok'))

                        this.closeRoleModal()
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('create_ko'))
                    }
                }
            } else {
                this.creating = false
                this.$toast.error(this.$t('form_errors'))
            }
        },
        // setPage(isNext) {
        //     this.processing = true

        //     clearInterval(this.authorisationsInterval)
        //     this.$cookies.remove('filtered')

        //     if (isNext) {
        //         this.page += 1
        //     } else {
        //         this.page -= 1
        //     }

        //     this.setResults()
        // },
        clearUserFilter() {
            this.processing = true
            this.userFilter = []
            this.selectedUserType = null
            const realSearch = {
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: this.perPage,
                page: this.page,
                query: this.userQuery,
                fromBo: true,

                filter: this.userFilter,
            }
            this.allUsersStore.search(realSearch)
            this.processing = false

            this.refresh += 1
        },
        filterUsers(filter) {
            this.processing = true
            this.selectedUserType = filter

            this.userFilter = [filter]
            const realSearch = {
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: this.perPage,
                page: this.page,
                query: this.userQuery,
                fromBo: true,

                filter: this.userFilter,
            }

            this.allUsersStore.search(realSearch)
            this.processing = false

            this.refresh += 1
        },
        debounceUserSearch(newQuery) {
            this.page = 1

            this.processing = true
            this.userSearchQuery = newQuery.term
            const queryExists = this.userQuery.findIndex(
                (q) => q.dbField === newQuery.dbField
            )
            if (queryExists !== -1) {
                this.userQuery.splice(queryExists, 1)
            }
            clearInterval(this.usersInterval)
            this.userQuery.push(newQuery)

            clearTimeout(this.debounce)
            const realSearch = {
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                perPage: this.perPage,
                page: this.page,
                query: this.userQuery,
                fromBo: true,

                filter: this.userFilter,
            }
            this.debounce = setTimeout(() => {
                this.allUsersStore.search(realSearch)
                this.processing = false

                this.refresh += 1

                // this.rolesInterval = setInterval(() => {
                //     this.roleStore.search(realSearch)
                // }, 20000)
            }, 400)
        },
        debounceRoleSearch(newQuery) {
            this.page = 1

            this.processing = true
            if (newQuery.dbField === 'Code') {
                this.roleCodeSearchQuery = newQuery.term
            } else {
                this.roleSearchQuery = newQuery.term
            }
            const queryExists = this.roleQuery.findIndex(
                (q) => q.dbField === newQuery.dbField
            )
            if (queryExists !== -1) {
                this.roleQuery.splice(queryExists, 1)
            }
            clearInterval(this.rolesInterval)
            this.roleQuery.push(newQuery)

            clearTimeout(this.debounce)
            const realSearch = {
                sortDirection: 'ASC',
                sortField: 'NAME',
                perPage: this.perPage,
                page: this.page,
                query: this.roleQuery,
                fromBO: true,

                filter: [],
            }
            this.debounce = setTimeout(() => {
                this.roleStore.search(realSearch).then(() => {
                    this.processing = false

                    this.refresh += 1
                })

                // this.rolesInterval = setInterval(() => {
                //     this.roleStore.search(realSearch)
                // }, 20000)
            }, 400)
        },
        debounceAuthSearch(newQuery) {
            this.processing = true

            this.page = 1
            if (newQuery.dbField === 'Code') {
                this.authCodeSearchQuery = newQuery.term
            } else {
                this.authSearchQuery = newQuery.term
            }
            const queryExists = this.query.findIndex(
                (q) => q.dbField === newQuery.dbField
            )
            if (queryExists !== -1) {
                this.query.splice(queryExists, 1)
            }
            clearInterval(this.authorisationsInterval)
            this.query.push(newQuery)

            clearTimeout(this.debounce)
            const realSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: this.perPage,
                page: this.page,
                query: this.query,
                fromBO: true,

                filter: [],
            }
            this.debounce = setTimeout(() => {
                this.authorisationStore.search(realSearch).then(() => {
                    this.processing = false

                    this.refresh += 1
                })

                // this.authorisationsInterval = setInterval(() => {
                //     this.authorisationStore.search(realSearch)
                // }, 20000)
            }, 400)
        },
    },
}
</script>
<style lang="scss" scoped>
#sort-select ::v-deep(.multiselect) {
    font-size: small !important;
    height: 2em !important;
    width: auto;

    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: small !important;
    }
    .multiselect-tag {
        font-size: small !important;
    }
}
</style>
