import CreateOrUpdate from '../views/vehicles/CreateOrUpdate.vue'
import MaintenancesList from '../views/vehicles/MaintenancesList.vue'
import Wrapper from '../views/vehicles/Wrapper.vue'

const vehicles = [
    {
        path: '/vehicles',
        name: 'vehicles',
        component: Wrapper,
        meta: {
            layout: 'full-layout',
            authorisations: ['FULL_ACCESS', 'VEHICLES_FULL', 'VEHICLES_LIST'],
            showUniverseSearch: false,
            showSocietySearch: true,
        },
        children: [
            {
                path: 'new',
                name: 'new_vehicle',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'VEHICLES_FULL',
                        'VEHICLES_CREATE',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: false,
                },
            },
            {
                path: 'update/:id',
                name: 'update_vehicle',
                component: CreateOrUpdate,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'VEHICLES_FULL',
                        'VEHICLES_UPDATE',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: false,
                },
            },
            {
                path: 'maintenances',
                name: 'maintenances_list',
                component: MaintenancesList,
                meta: {
                    authorisations: [
                        'FULL_ACCESS',
                        'VEHICLES_MAINTENANCES_LIST',
                        'VEHICLES_MAINTENANCES_FULL',
                    ],
                    showUniverseSearch: false,
                    showSocietySearch: true,
                },
            },
        ],
    },
]

export default vehicles
