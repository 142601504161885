<template>
    <main style="width: 96vw" class="px-4 pt-2 rounded-md darky h-full">
        <back-to @back="back()" />

        <div
            id="create-address"
            class="shadow rounded-md xs:p-0 mx-auto md:w-full"
        >
            <div class="p-5">
                <div class="mb-2">
                    <span
                        class="text-3xl flex items-start mb-5 border-b-2 themed-border"
                    >
                        <button
                            @click="$emit('goToUpdateOrder', formData.IdOrder)"
                            class="mr-2"
                            :title="$t('full_order')"
                        >
                            <span class="items-center custom-link">
                                {{ $route.params.order_ref }}
                            </span>
                        </button>
                        {{
                            ' - ' + $t('order_step') + ' ' + formData.StepNumber
                        }}
                    </span>
                </div>
                <div class="grid grid-cols-6 gap-4 mt-2 mb-4">
                    <div class="rounded-md border-2 p-4 col-span-3">
                        <form
                            class="mb-2"
                            @keydown.enter.prevent
                            @submit.prevent="updateStp()"
                        >
                            <div>
                                <div class="mb-5 flex">
                                    <span class="text-lg">
                                        {{ $t('address') }}
                                        <hr />
                                    </span>
                                </div>

                                <div class="mb-3 items-center px-3">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('address_type') + ' *' }}</label
                                    >
                                    <!-- <input
                                        v-model="formData.Type"
                                        autocomplete="name"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    /> -->
                                    <div class="flex justify-center mt-3 mb-3">
                                        <div class="flex items-center mr-4">
                                            <input
                                                id="inline-radio"
                                                type="radio"
                                                v-model="formData.Type"
                                                value="pickup"
                                                checked
                                                name="inline-radio-group"
                                                class="cursor-pointer darky"
                                            />
                                            <label
                                                for="inline-radio"
                                                class="ml-2 text-base font-medium"
                                                >{{
                                                    $t('pick_up_label')
                                                }}</label
                                            >
                                        </div>
                                        <div class="flex items-center mr-4">
                                            <input
                                                id="inline-2-radio"
                                                type="radio"
                                                v-model="formData.Type"
                                                class="cursor-pointer darky"
                                                value="delivery"
                                                name="inline-radio-group"
                                            />
                                            <label
                                                for="inline-2-radio"
                                                class="ml-2 text-base font-medium"
                                                >{{
                                                    $t('delivery_label')
                                                }}</label
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="px-3">
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{
                                                $t('search_name') + ' *'
                                            }}</label
                                        >
                                        <input
                                            v-model="formData.AddressName"
                                            @blur="
                                                v$.formData.AddressName.$touch
                                            "
                                            autocomplete="name"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.AddressName
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>

                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('address') + ' 1 *' }}
                                        </label>
                                        <!-- type="text" -->
                                        <input
                                            v-model="formData.Address1"
                                            id="autocomplete"
                                            @blur="v$.formData.Address1.$touch"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.Address1
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>

                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('address') }} 2</label
                                        >
                                        <input
                                            v-model="formData.Address2"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{
                                                $t('postal_code') + ' *'
                                            }}</label
                                        >
                                        <input
                                            id="postal_code"
                                            autocomplete="postal-code"
                                            v-model="formData.PostalCode"
                                            @blur="
                                                v$.formData.PostalCode.$touch
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.PostalCode
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div class="mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >{{ $t('city') + ' *' }}</label
                                        >
                                        <input
                                            v-model="formData.City"
                                            id="locality"
                                            @blur="v$.formData.City.$touch"
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData.City
                                            .$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-4 gap-2 mt-2">
                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                >Longitude *</label
                                            >
                                            <input
                                                type="text"
                                                v-model="formData.Longitude"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                :class="
                                                    Number.isNaN(
                                                        formData.Longitude,
                                                    )
                                                        ? 'error-msg border border-red-500'
                                                        : ''
                                                "
                                            />
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .Longitude.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="col-span-2 mb-3 items-center"
                                        >
                                            <label
                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                >Latitude *</label
                                            >
                                            <input
                                                type="text"
                                                v-model="formData.Latitude"
                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                :class="
                                                    Number.isNaN(
                                                        formData.Latitude,
                                                    )
                                                        ? 'error-msg border border-red-500'
                                                        : ''
                                                "
                                            />
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$.formData
                                                    .Latitude.$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-if="
                                            Number.isNaN(formData.Latitude) ||
                                            Number.isNaN(formData.Longitude)
                                        "
                                    >
                                        <div class="error-msg">
                                            {{ $t('fix_lat_lng_info_1') }}
                                            <br />
                                            {{ $t('fix_lat_lng_info_2') }}
                                        </div>
                                    </div>
                                    <span
                                        class="flex mt-1 italic text-xs items-center justify-center"
                                    >
                                        {{ $t('required_fields') }}
                                    </span>
                                    <div class="flex items-center mt-4">
                                        <input
                                            id="ref-add-update-checkbox"
                                            type="checkbox"
                                            v-model="formData.UpdateRefAddress"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="ref-add-update-checkbox"
                                            class="ml-2 text-sm font-medium"
                                            >{{
                                                $t('update_ref_address')
                                            }}</label
                                        >
                                    </div>
                                </div>

                                <div class="p-2 mt-4">
                                    <div class="flex justify-center">
                                        <button
                                            type="button"
                                            @click="back()"
                                            class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                        >
                                            {{ $t('cancel') }}
                                        </button>
                                        <!-- TODO update step -->
                                        <button
                                            type="submit"
                                            :disabled="creating"
                                            class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                        >
                                            <span v-if="creating">
                                                <fa-icon
                                                    icon="spinner"
                                                    spin-pulse
                                                ></fa-icon
                                            ></span>
                                            <span v-else>
                                                {{ $t('save') }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="rounded-md border-2 p-4 col-span-3">
                        <span
                            v-if="
                                currentStep !== null &&
                                currentStep.reference_address !== null
                            "
                            :key="refresh"
                        >
                            <div class="mb-5 flex">
                                <span class="text-lg">
                                    {{ $t('reference_postal_address') }}
                                    <hr />
                                </span>
                            </div>

                            <div class="mb-3 items-center px-3">
                                <label
                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                    >{{ $t('address_type') }}</label
                                >
                                <input
                                    v-model="
                                        currentStep.reference_address
                                            .AddressType
                                    "
                                    disabled
                                    autocomplete="name"
                                    class="border rounded px-3 py-2 mt-1 w-full"
                                />
                            </div>
                            <div class="px-3">
                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('search_name') }}</label
                                    >
                                    <input
                                        v-model="
                                            currentStep.reference_address.Name
                                        "
                                        disabled
                                        autocomplete="name"
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>

                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('address') }} 1
                                    </label>

                                    <input
                                        v-model="
                                            currentStep.reference_address
                                                .Address1
                                        "
                                        disabled
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>

                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('address') }} 2</label
                                    >
                                    <input
                                        v-model="
                                            currentStep.reference_address
                                                .Address2
                                        "
                                        disabled
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('postal_code') }}</label
                                    >
                                    <input
                                        id="postal_code"
                                        autocomplete="postal-code"
                                        disabled
                                        v-model="
                                            currentStep.reference_address
                                                .PostalCode
                                        "
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>

                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('city') }}</label
                                    >
                                    <input
                                        v-model="
                                            currentStep.reference_address.City
                                        "
                                        id="locality"
                                        disabled
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>

                                <div class="grid grid-cols-4 gap-2 mt-2">
                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >Longitude *</label
                                        >
                                        <input
                                            disabled
                                            type="text"
                                            v-model="
                                                currentStep.reference_address
                                                    .Longitude
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>

                                    <div class="col-span-2 mb-3 items-center">
                                        <label
                                            class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                            >Latitude *</label
                                        >
                                        <input
                                            disabled
                                            type="text"
                                            v-model="
                                                currentStep.reference_address
                                                    .Latitude
                                            "
                                            class="border rounded px-3 py-2 mt-1 w-full"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('additional_info') }}
                                    </label>
                                    <input
                                        type="text"
                                        disabled
                                        v-model="
                                            currentStep.reference_address
                                                .AdditionalInformation
                                        "
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                                <div class="mb-3 items-center">
                                    <label
                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                        >{{ $t('driver_info') }}
                                    </label>
                                    <input
                                        type="text"
                                        disabled
                                        v-model="
                                            currentStep.reference_address
                                                .DriverInformation
                                        "
                                        class="border rounded px-3 py-2 mt-1 w-full"
                                    />
                                </div>
                            </div>
                        </span>
                        <span v-else :key="refresh">
                            <div class="mb-5 flex">
                                <span class="text-lg">
                                    {{ $t('reference_postal_address') }}
                                    <hr />
                                </span>
                            </div>
                            <div class="mb-3 items-center">
                                {{ $t('no_ref_address') }}
                            </div>
                        </span>
                    </div>
                </div>
                <div id="map" style="width: 100%; height: 500px"></div>
            </div>
        </div>
    </main>
</template>
<script>
import BackTo from '@/components/elements/BackTo.vue'
import gmapStyles from '@/mixins/gmapStyles'
import googleScriptLoader from '@/mixins/googleScript'
import keys from '@/resources/keys'
import { useClientStore } from '@/stores/clientStore'
import { useOrderStore } from '@/stores/orderStore'
import { useUserStore } from '@/stores/userStore'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { defineComponent } from 'vue'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)
let mark = null
export default defineComponent({
    name: 'UpdateStep',
    components: {
        BackTo,
    },
    mixins: [googleScriptLoader, gmapStyles],
    setup() {
        const key =
            keys[
                window.location.hostname === 'localhost'
                    ? 'localhost'
                    : window.location.host.substring(
                          0,
                          window.location.host.indexOf('.'),
                      )
            ]

        return { key, v$: useVuelidate() }
    },
    created() {
        this.loadGoogleScript('autocomplete')
    },
    mounted() {
        window.scrollTo(0, 0)
        this.map = null

        this.currentStepInterval = setInterval(() => {
            if (this.currentStep) {
                clearInterval(this.currentStepInterval)

                this.formData = {
                    Id: this.currentStep.id,
                    AddressName: this.currentStep.name,
                    Latitude:
                        this.currentStep.lat !== ''
                            ? parseFloat(this.currentStep.lat)
                            : '',
                    Longitude:
                        this.currentStep.lng !== ''
                            ? parseFloat(this.currentStep.lng)
                            : '',
                    UpdateRefAddress: true,

                    Address1: this.currentStep.address,
                    Address2: this.currentStep.address2,
                    Address3: this.currentStep.address3,
                    Address4: this.currentStep.address4,
                    PostalCode: this.currentStep.postal_code,
                    City: this.currentStep.city,
                    IdDriver: this.currentStep.driver,
                    StepNumber: this.currentStep.step_number,
                    ModificationUserId: this.user.id,
                    CreationUserId: this.currentStep.creation_user_id,
                    Type: this.currentStep.address_type,
                    TypeVehicle: this.currentStep.vehicle_type,
                    IdOrder: this.currentStep.order_id,
                    IdClientAddress: this.currentStep.client_address_id,
                    Moment: this.currentStep.moment,
                    BeforeDateTime: this.currentStep.beforeDateTime,
                    FromDateTime: this.currentStep.fromDateTime,
                    IdVehicle: this.currentStep.vehicle_Id,
                }
                // this.orderReference = this.currentStep.order_ref
            }
        }, 1000)
        const googleInterval = setInterval(() => {
            if (google && this.currentStep) {
                clearInterval(googleInterval)
                let center = null
                if (this.currentStep !== null) {
                    if (this.currentStep.lat && this.currentStep.lng) {
                        center = {
                            lat: +this.currentStep.lat,
                            lng: +this.currentStep.lng,
                        }
                        this.map = new google.maps.Map(
                            document.getElementById('map'),
                            {
                                center,
                                zoom: 15,
                            },
                        )
                        mark = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,

                            position: center,
                            title: this.$t('order_step'),
                            label: this.currentStep.step_number.toString(),
                            draggable: true,
                        })
                        mark.setMap(this.map)
                        const eventScreen =
                            'ontouchstart' in document.documentElement
                                ? 'touchend'
                                : 'dragend'

                        mark.addListener(eventScreen, (evt) => {
                            this.markerDragged(evt)
                        })
                        this.setGoogleMapStyle(false)
                    }

                    if (
                        this.currentStep.reference_address !== null &&
                        this.currentStep.reference_address.Latitude !== null &&
                        this.currentStep.reference_address.Longitude !== null
                    ) {
                        if (center === null) {
                            this.map = new google.maps.Map(
                                document.getElementById('map'),
                                {
                                    center: {
                                        lat: +this.currentStep.reference_address
                                            .Latitude,
                                        lng: +this.currentStep.reference_address
                                            .Longitude,
                                    },

                                    zoom: 15,
                                },
                            )
                        }
                        this.setGoogleMapStyle(false)

                        const refMarker = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,

                            position: {
                                lat: +this.currentStep.reference_address
                                    .Latitude,
                                lng: +this.currentStep.reference_address
                                    .Longitude,
                            },
                            label: 'R',
                            title: this.$t('reference_postal_address'),
                            draggable: false,
                        })
                        refMarker.setMap(this.map)
                    }

                    if (center === null) {
                        center = {
                            lat: this.user.env.latitude,
                            lng: this.user.env.longitude,
                        }
                        this.map = new google.maps.Map(
                            document.getElementById('map'),
                            {
                                center,
                                zoom: 11,
                            },
                        )
                        mark = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,
                            position: center,
                            label: '?',
                            draggable: true,
                        })
                        mark.setMap(this.map)
                        const eventScreen =
                            'ontouchstart' in document.documentElement
                                ? 'touchend'
                                : 'dragend'

                        mark.addListener(eventScreen, (evt) => {
                            this.markerDragged(evt)
                        })
                        this.setGoogleMapStyle(false)
                    }
                }
            }
        }, 20)
    },
    data() {
        return {
            refresh: 0,
            creating: false,
            center: null,
            currentStepInterval: null,
            googleRes: null,
            formData: {
                Id: '',
                UpdateRefAddress: true,
                AddressName: '',
                Latitude: '',
                Longitude: '',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                PostalCode: '',
                City: '',
                IdDriver: '',
                StepNumber: '',
                ModificationUserId: '',
                CreationUserId: '',
                Type: '',
                TypeVehicle: '',
                IdVehicle: '',
            },
        }
    },
    validations() {
        return {
            formData: {
                AddressName: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },
                Latitude: { required },
                Longitude: { required },
                City: { required },
            },
        }
    },
    watch: {
        currentStep() {
            this.refresh += 1
        },
    },
    computed: {
        ...mapWritableState(useOrderStore, {
            currentStep: 'currentStep',
        }),
        ...mapState(useUserStore, {
            user: 'current',
            mapMarker: 'mapMarker',
        }),
    },

    methods: {
        ...mapActions(useClientStore, [
            'updateDeliveryAddress',
            'createDeliveryAddress',
        ]),
        ...mapActions(useOrderStore, ['updateStep']),
        back() {
            const backPath = this.$router.options.history.state.back
            // console.log(this.$router.options.history)
            this.$router.push(backPath)
            if (backPath === '/orders') {
                this.$emit('back', 'orders')
            } else {
                this.$emit('back', 'orderStepCorrection')
            }
        },
        markerDragged(position) {
            this.formData.Latitude = position.latLng.lat()
            this.formData.Longitude = position.latLng.lng()
        },
        fillInAddress(autocomplete, unique) {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            this.formData.Address2 = ''
            this.formData.Address1 = ''

            this.formData.PostalCode = ''
            this.formData.City = ''

            const userInput = this.formData.Address1
            this.formData.Latitude = place.geometry.location.lat()
            this.formData.Longitude = place.geometry.location.lng()
            if (mark !== null) {
                mark.setMap(null)
            }

            this.map.setCenter({
                lat: this.formData.Latitude,
                lng: this.formData.Longitude,
            })

            this.map.setZoom(15)

            mark = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                position: {
                    lat: this.formData.Latitude,

                    lng: this.formData.Longitude,
                },
                label: this.currentStep.step_number.toString(),
                draggable: true,
                title: this.$t('order_step'),
            })
            mark.setMap(this.map)
            const eventScreen =
                'ontouchstart' in document.documentElement
                    ? 'touchend'
                    : 'dragend'

            mark.addListener(eventScreen, (evt) => {
                this.markerDragged(evt)
            })

            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name
                if (addressType === 'postal_code') {
                    this.formData.PostalCode = val
                } else if (addressType === 'locality') {
                    this.formData.City = val
                } else if (addressType === 'street_number') {
                    this.formData.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    this.formData.Address1 =
                        this.formData.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${this.formData.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    this.formData.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    this.formData.Address1 === userInput
                ) {
                    this.formData.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    this.formData.Address2 === ''
                ) {
                    this.formData.Address2 = place.name
                }
                // if (
                //     place.types[0] !== 'street_address' &&
                //     place.types[0] !== 'route' &&
                //     place.types[0] !== 'locality' &&
                //     this.formData.Name === ''
                // ) {
                //     this.formData.Name = place.name
                // }
            })
        },

        async updateStp() {
            this.creating = true
            const isFormCorrect = await this.v$.$validate()

            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    const response = await this.updateStep(params)
                    if (response.status === 200) {
                        this.creating = false

                        this.currentStep = null

                        const backPath = this.$router.options.history.state.back
                        this.$router.push(backPath)
                        this.$toast.success(this.$t('address_update_ok'))
                        if (backPath === '/orders') {
                            this.$emit('updated', 'orders')
                        } else {
                            this.$emit('updated', 'orderStepCorrection')
                        }
                    } else {
                        this.creating = false

                        this.$toast.error(this.$t('address_update_ko'))
                        // this.$toast.error(response.data.message)
                    }
                } catch (err) {
                    this.creating = false

                    this.$toast.error(`${this.$t('address_update_ko')}`)
                    // this.$toast.error(err.data.message)
                }
            }
        },
    },
    unmounted() {
        this.currentStep = null
    },
})
</script>
