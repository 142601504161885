<template>
    <div class="whatsapp-chat-container m-4">
        <div class="chat-header rounded-t">
            <div class="back-icon">←</div>
            <img :src="props.avatarUrl" class="avatar" />
            <div class="contact-info">
                <div class="contact-name">{{ props.contactName }}</div>
                <div class="contact-status">{{ $t(props.contactStatus) }}</div>
            </div>
        </div>

        <div class="chat-messages">
            <div
                v-for="(message, index) in props.messages"
                :key="index"
                :class="[
                    'message',
                    message.sender === 'me' ? 'sent' : 'received',
                ]"
            >
                {{ message.text }}
                <span class="message-time">{{ time }}</span>
            </div>
        </div>

        <div class="chat-input rounde-b">
            <input
                type="text"
                :placeholder="inputPlaceholder"
                class="message-input"
            />
            <button class="send-button">➤</button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    contactName: {
        type: String,
        default: 'Contact',
    },
    contactStatus: {
        type: String,
        default: 'on_line',
    },
    avatarUrl: {
        type: String,
        default: 'https://via.placeholder.com/50',
    },
    inputPlaceholder: {
        type: String,
        default: 'Tapez un message',
    },
    messages: {
        type: Array,
        default: () => [
            { text: 'Salut !', sender: 'other', time: '10:30' },
            { text: 'Comment ça va ?', sender: 'me', time: '10:31' },
        ],
    },
})
</script>

<style scoped>
.whatsapp-chat-container {
    width: 350px;
    height: 600px;
    /* border: 1px solid #e0e0e0; */
    display: flex;
    flex-direction: column;
}

.chat-header {
    display: flex;
    align-items: center;
    background-color: #008069;
    color: white;
    padding: 10px;
}

.avatar {
    width: 40px;
    height: 40px;
    /* border-radius: 25%; */
    margin: 0 10px;
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #e5ddd5;
}

.message {
    max-width: 70%;
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
}

.sent {
    background-color: #dcf8c6;
    align-self: flex-end;
    margin-left: auto;
}

.received {
    background-color: white;
    align-self: flex-start;
}

.message-time {
    font-size: 0.7em;
    color: gray;
    margin-left: 5px;
}

.chat-input {
    display: flex;
    padding: 10px;
    background-color: #f0f0f0;
}

.message-input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 20px;
    border: none;
}

.send-button {
    background: none;
    border: none;
    font-size: 1.5em;
    color: #008069;
    margin-left: 10px;
}
</style>
