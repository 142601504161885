<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'docks'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="docksList"
                :items="docks"
                :columns="columns"
                :storeToSort="'docks'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null, true)"
                @update="openModal($event)"
                @delete="doDelete($event)"
                @deleteMany="doDeleteMany($event)"
                @removedefenses="doDeleteDefense($event)"
                @inspect="inspectDockModal($event)"
            >
            </filtrable-table>
        </div>
        <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-2/3'"
            :canCancel="false"
            :showSubmit="false"
            :closeMark="false"
            :mxHeight="'max-h-screen'"
            :buttonAlign="'justify-center'"
        >
            <template #body
                ><create-or-update-slider
                    :isUpdate="isUpdate"
                    :dockId="dockId"
                    :harborId="harborId"
                    :newFromDock="newFromDock"
                    @cancel="openModal()"
                    @save="openModal()"
                ></create-or-update-slider></template
        ></modal>
        <modal
            v-if="openInspectionModal"
            id="addInspectionModal"
            :headerText="'inspection_modal_header'"
            :buttonText="'inspect'"
            :mxWidth="'w-2/3'"
            :mxHeight="'max-h-screen'"
            :buttonAlign="'justify-center'"
            @closeModal="inspectDockModal()"
            @sendFromModal="inspectDock()"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center text-lg font-semibold my-4">
                        {{
                            `${dockForInspection.name} - ${dockForInspection.harbor}`
                        }}
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label class="block mb-2">{{
                                $t('inspection_form.name')
                            }}</label>
                            <input
                                v-model="inspectionForm.Name"
                                @blur="v$.inspectionForm.Name.$touch"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('inspection_form.name')"
                            />
                            <div
                                class="text-xs italic mt-1 mb-2"
                                v-for="error of v$.inspectionForm.Name.$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="block mb-2">{{
                                $t('inspection_form.description')
                            }}</label>
                            <input
                                v-model="inspectionForm.Description"
                                class="w-full px-3 py-2 border rounded-md"
                                :placeholder="$t('inspection_form.description')"
                            />
                        </div>
                    </div></div></template
        ></modal>
        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span v-if="toConfirm.name" class="mr-1">
                                    {{
                                        `${toConfirm.name} - ${toConfirm.description}`
                                    }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="flex items-center justify-center mt-4">
                        {{ $t(confirmModalText3) }}
                    </div>
                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useHarborStore } from '@/stores/harborStore'
import { useUserStore } from '@/stores/userStore'
import CreateOrUpdateSlider from '@/views/harbors/CreateOrUpdateSlider.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapStores, mapWritableState } from 'pinia'
import societyEventBus from '@/mixins/societyEventBusMixin'


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Docks',
    mixins: [filterList, societyEventBus],
    components: {
        FiltrableTable,
        FetchDataError,
        CreateOrUpdateSlider,
        Modal,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            refresh: 0,
            searchQuery: '',
            isUpdate: false,
            show: false,
            entityTypeToDelete: 'dock_tc',
            dockId: null,
            harborId: null,

            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            docksInterval: null,
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
            newFromDock: false,
            dockForInspection: null,
            openInspectionModal: false,
            inspectionForm: {
                IdDock: '',
                Name: '',
                Description: '',
            },
        }
    },
    watch: {
        docks() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },
    validations() {
        return {
            inspectionForm: {
                Name: { required },
                Description: { required },
            },
        }
    },
    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredDocks')
        this.columns = [
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.docksActionsAccess.update,
                ),
            },

            {
                name: 'description',
                filtrable: false,

                dbField: 'Description',
                sortable: false,
            },
            {
                name: 'harbor',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'defenses',
                filtrable: false,
                sortable: false,

                enum: true,
                icon: 'shield',
                canRemoveEnum: checkAccess(
                    this.userStore,
                    this.docksActionsAccess.update,
                ),
                enumColNumber: 'grid-cols-2',
            },

            {
                name: 'last_inspection',
                filtrable: false,
                sortable: false,
            },
            {
                name: 'creation_date',
                sortable: true,
                dbField: 'CreationDate',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        }
        this.setResults()
    },

    unmounted() {
        clearInterval(this.docksInterval)
    },
    computed: {
        docksActionsAccess() {
            return actionsAccess.harbors.docks
        },
        ...mapWritableState(useHarborStore, {
            currentDock: 'currentDock',
            docks: 'docks',
            count: 'countDocks',
            fetchError: 'fetchError',
            totalPages: 'docksTotalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (checkAccess(this.userStore, this.docksActionsAccess.update)) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            // TODO add this action when the dock is created + ADD AUTH
            if (checkAccess(this.userStore, this.docksActionsAccess.inspect)) {
                actions.push({
                    name: 'start_inspection',
                    action: 'inspect',
                    icon: 'magnifying-glass',
                })
            }
            if (checkAccess(this.userStore, this.docksActionsAccess.delete)) {
                actions.push({
                    name: 'delete',
                    action: 'delete',
                    icon: 'trash-can',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (checkAccess(this.userStore, this.docksActionsAccess.delete)) {
                actions.push({
                    name: 'delete_selection',
                    action: 'deleteMany',
                    icon: 'trash-can',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useHarborStore, [
            'searchDocks',
            'fetchOneDock',
            'deleteDock',
            'deleteManyDocks',
            'deleteDefense',
            'inspect',
        ]),
        upFilter(event) {
            clearInterval(this.docksInterval)
            this.updateFilter(event)
        },
        canAddNew() {
            return checkAccess(this.userStore, this.docksActionsAccess.create)
        },
        openModal(id = null, create = false) {
            this.isUpdate = false
            this.newFromDock = false
            this.dockId = null
            this.harborId = null
            clearInterval(this.docksInterval)
            if (id) {
                this.dockId = id
                this.harborId = this.docks.find((h) => h.id === id).harborId
                this.isUpdate = true
            } else {
                this.currentDock = null
                this.newFromDock = true
                if (create === false) {
                    this.setResults()
                }
            }
            this.show = !this.show
        },
        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        doDelete(id) {
            clearInterval(this.docksInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.entityTypeToDelete = 'dock_tc'

            const dock = this.docks.find((h) => h.id === id)
            this.elementsToConfirm.push(dock)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteDock(id)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        doDeleteMany(ids) {
            clearInterval(this.docksInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'dock_tc'

            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text_plural'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            this.showConfirmModal = true

            ids.forEach((id) => {
                const dock = this.docks.find((h) => h.id === id)
                this.elementsToConfirm.push(dock)
            })

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteManyDocks(ids)
                            .then(() => {
                                this.$toast.success(this.$t('deleted_ok'))

                                this.setResults()
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('deleted_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },

        doDeleteDefense(defense) {
            clearInterval(this.docksInterval)
            clearInterval(this.docksInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'defense_tc'
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.confirmModalText3 = 'delete_modal_generic_text_3'
            defense.description = defense.Description
            defense.name = defense.Name
            this.elementsToConfirm.push(defense)
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteDefense(defense.Id)
                            .then(() => {
                                this.setResults()
                                this.$toast.success(this.$t('delete_ok'))
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('delete_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        inspectDockModal(id = '') {
            this.v$.inspectionForm.$reset()

            this.dockForInspection = null
            this.inspectionForm = {
                IdDock: id,
                Name: '',
                Description: '',
            }
            if (id !== '') {
                const dock = this.docks.find((h) => h.id === id)
                this.dockForInspection = dock
            }
            this.openInspectionModal = !this.openInspectionModal
        },
        async inspectDock() {
            this.v$.inspectionForm.$reset()
            const isValid = await this.v$.inspectionForm.$validate()
            if (isValid === true) {
                this.inspect(this.inspectionForm)
                    .then(() => {
                        this.$toast.success(this.$t('inspection_ok'))

                        this.inspectDockModal()
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('inspection_ko'))
                    })
            } else {
                this.$toast.error(this.$t('form_errors'))
            }
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.docksInterval)
            this.$cookies.remove('filteredDocks')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.docksInterval)
            this.$cookies.remove('filteredDocks')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchDocks(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredDocks', search)
            this.docksInterval = setInterval(() => {
                this.searchDocks(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.docksInterval)
            this.$cookies.remove('filteredDocks')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
