<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'invoices'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="billingsList"
                :items="billings"
                :columns="columns"
                :storeToSort="'invoicings'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="false"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @dateFilterUpdate="setDateFilter($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @create="$emit('create')"
                @clear="clearFilter($event)"
                @setInvoiceAsPaid="showPayInvoiceModal($event)"
                @setManyAsPaid="showPayInvoiceModal($event)"
                @sendReminder="sendInvoiceReminder($event)"
                @sendManyReminders="sendInvoiceReminder($event, true)"
                @sendReminderWithPaymentLink="
                    sendInvoiceReminder($event, false, true)
                "
                @sendReminderToManyWithPaymentLink="
                    sendInvoiceReminder($event, true, true)
                "
            >
            </filtrable-table>
            <modal
                v-if="show"
                :headerText="'set_invoice_as_paid'"
                :buttonText="'validate'"
                id="payInvoiceModal"
                :mxWidth="'w-2/5'"
                :buttonAlign="'justify-center'"
                @closeModal="closePayInvoiceModal"
                @sendFromModal="submitPayModal()"
            >
                <template #body>
                    <div class="mx-8">
                        <div class="flex justify-center font-semibold mt-4">
                            {{ $t('select_payment_date') }}
                        </div>
                        <input
                            :id="`invoiceDatePicker`"
                            type="date"
                            v-model="invoiceForm.PaymentDate"
                            class="border rounded px-3 py-2 w-full"
                        />
                        <div
                            class="text-xs italic mt-1 ml-2 mb-2"
                            v-for="error of v$.invoiceForm.PaymentDate.$errors"
                            :key="error.$uid"
                        >
                            <div class="error-msg">
                                {{ error.$message }}
                            </div>
                        </div>
                        <div class="mt-3 items-center flex flex-row">
                            <div
                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                            >
                                {{ $t('payment_type') }}
                            </div>
                            <Multiselect
                                id="payment"
                                class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                v-model="invoiceForm.PaymentType"
                                :options="paymentTypeOptions"
                                :searchable="false"
                                :allow-empty="false"
                                :can-deselect="false"
                                :can-clear="false"
                                label="name"
                                track-by="name"
                            ></Multiselect>
                            <div
                                class="text-xs italic mt-1 ml-2 mb-2"
                                v-for="error of v$.invoiceForm.PaymentType
                                    .$errors"
                                :key="error.$uid"
                            >
                                <div class="error-msg">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>

                        <!-- <div
                            class="flex text-xs italic items-center error-msg justify-center mt-4"
                        >
                            <fa-icon
                                :icon="'triangle-exclamation'"
                                class="mr-2 fa-md"
                            ></fa-icon>
                            {{ $t(confirmModalText2) }}
                        </div> -->
                    </div></template
                ></modal
            >
        </div>
    </main>
</template>
<script>
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import FiltrableTable from '@/components/FiltrableTable.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useBillingStore } from '@/stores/billingStore'
import { useClientStore } from '@/stores/clientStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import societyEventBus from '@/mixins/societyEventBusMixin'

import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'UnpaidInvoices',
    mixins: [filterList, societyEventBus],
 

    components: {
        FiltrableTable,
        FetchDataError,
        Modal,
        Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            columns: [],
            dateFilter: null,
            dueDateFilter: null,
            invoicesToPay: null,
            invoiceForm: {},
            show: false,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 100,
            page: 1,
            billingsInterval: null,
            filter: [],

            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
        }
    },
    validations() {
        return {
            invoiceForm: {
                PaymentDate: {
                    required,
                },
                PaymentType: {
                    required,
                },
            },
        }
    },
    watch: {
        billings() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        clearInterval(this.billingsInterval)

        const filteredList = this.$cookies.get('filteredUnpaid')

        this.filter.push({
            dbField: 'PaymentStatus',
            value: 'paid',
        })
        this.columns = [
            {
                name: 'bill_number',
                filtrable: true,
                // isLink: checkAccess(
                //     this.userStore,
                //     this.billingsActionsAccess.update
                // ),
                // routeName: 'update_billing',

                dbField: 'Number',
                type: 'search',
                sortable: true,
            },
            {
                name: 'client_name',
                filtrable: true,
                dbField: 'ClientName',
                type: 'search',
                sortable: true,
                hide: this.userStore.isB2C
                    ? this.userStore.isB2C
                    : this.$cookies.get('is_B2C') === 'true',
            },
            // {
            //     name: 'ht',
            //     filtrable: false,
            //     // isLink: true,
            //     dbField: 'TotalHT',
            //     sortable: false,
            // },
            {
                name: 'ttc',
                filtrable: false,
                // isLink: true,
                dbField: 'TotalTTC',
                sortable: false,
            },
            {
                name: 'date_invoice',
                sortable: true,
                // isLink: true,
                dbField: 'DateInvoice',
                withButton: false,
                datePicker: true,
                pickedDate: this.dateFilter,
            },
            {
                name: 'due_date',
                sortable: true,
                // isLink: true,
                dbField: 'DueDateInvoice',

                withButton: false,
                datePicker: true,
                pickedDate: this.dueDateFilter,
            },

            {
                name: 'type',
                filtrable: true,
                dbField: 'Type',
                type: 'select',
                sortable: false,
                options: [
                    {
                        name: 'end_month',
                        value: 'month',
                        dbField: 'Type',
                    },
                    {
                        name: 'occasional',
                        value: 'occasional',
                        dbField: 'Type',
                    },

                    {
                        name: 'web',
                        value: 'web',
                        dbField: 'Type',
                    },
                ],
            },
            {
                name: 'actions',

                // isLink: true,
            },
            // {
            //     name: 'PDF',
            //     filtrable: false,
            //     dwnldOrSend: true,
            //     sortable: false,
            // },
        ]

        if (filteredList) {
            this.search = filteredList.query

            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page

            const dateFiltered = this.filter.find(
                (filt) => filt.dbField === 'DateInvoice',
            )
            if (dateFiltered !== undefined) {
                this.dateFilter = dateFiltered.value
            }
            const dueDateFiltered = this.filter.find(
                (filt) => filt.dbField === 'DueDateInvoice',
            )
            if (dueDateFiltered !== undefined) {
                this.dueDateFilter = dueDateFiltered.value
            }
        }

        const isB2CInterval = setInterval(() => {
            if (this.userStore.isB2C !== null) {
                clearInterval(isB2CInterval)
                if (this.userStore.isB2C === true) {
                    this.filter.push({
                        dbField: 'IdClient',
                        value: this.currentClient.id,
                    })
                } else {
                    const universesInterval = setInterval(() => {
                        if (this.universesOptions.length > 0) {
                            clearInterval(universesInterval)
                            const universeFilter = {
                                dbField: 'IdUniverse',
                                value:
                                    this.selectedUniverses.length > 0
                                        ? this.selectedUniverses
                                        : this.universesOptions,
                            }
                            if (
                                !this.filter.find(
                                    (f) => f.dbField === 'IdUniverse',
                                )
                            ) {
                                this.filter.push(universeFilter)
                            }
                        }
                    }, 10)
                }
                // const realSearch = {
                //     query: this.search,
                //     filter: this.filter,
                //     sortDirection: this.sortDirection,
                //     sortField: this.sortField,
                //     perPage: this.perPage,
                //     page: this.page,
                // }
                this.setResults()
                this.eventBus.on('trigger-universe-search', (param) => {
                    this.searchCustomUniverses(param)
                })
                this.eventBus.on('remove-from-selected-universe', (index) => {
                    this.removeFromSelectedUniverses(index)
                })
                // this.searchBillings(realSearch)
                // this.billingsInterval = setInterval(() => {
                //     this.searchBillings(realSearch)
                // }, 20000)
            }
        }, 200)
    },

    unmounted() {
        clearInterval(this.billingsInterval)
        this.eventBus.all.clear()
    },
    computed: {
        billingsActionsAccess() {
            return actionsAccess.billings
        },
        ...mapState(useBillingStore, {
            billings: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),
        ...mapStores(useUserStore),
        ...mapState(useClientStore, {
            currentClient: 'current',
        }),
        ...mapState(useUniverseStore, {
            selectedUniverses: 'selectedUniverses',
            universesOptions: 'universesOptions',
        }),

        paymentTypeOptions() {
            return [
                { name: this.$t('web'), value: 'web' },
                { name: this.$t('check'), value: 'check' },
                { name: this.$t('credit_card'), value: 'creditcard' },
                { name: this.$t('cash'), value: 'cash' },
                { name: this.$t('transfer'), value: 'transfer' },
                { name: this.$t('client_credit'), value: 'clientCredit' },
            ]
        },
        itemActions() {
            const actions = []
            // if (
            //     checkAccess(this.userStore, this.billingsActionsAccess.update)
            // ) {
            //     actions.push(
            //         {
            //             name: 'set_invoice_as_paid',
            //             action: 'setInvoiceAsPaid',
            //             icon: 'cash-register',
            //         },

            //         {
            //             name: 'send_reminder',
            //             action: 'sendReminder',
            //             icon: 'envelope',
            //         }
            //     )
            // }
            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(this.userStore, this.billingsActionsAccess.update)
            ) {
                actions.push(
                    {
                        name: 'set_many_as_paid',
                        action: 'setManyAsPaid',
                        icon: 'cash-register',
                    },

                    {
                        name: 'send_many_reminders',
                        action: 'sendManyReminders',
                        icon: 'envelope',
                    },
                    {
                        name: 'send_reminder_mail_with_link',
                        action: 'sendReminderToManyWithPaymentLink',
                        icon: 'envelope',
                        iconB: 'link',
                    },
                )
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useBillingStore, [
            'searchBillings',
            'download',
            'sendInvoice',
            'sendReminderInvoice',
            'setInvoiceAsPaid',
        ]),
        upFilter(event) {
            clearInterval(this.billingsInterval)
            this.updateFilter(event)
        },

        sendInvoiceReminder(invoices, many = false, withLink = false) {
            const invoicesPayload = []
            if (many === false) {
                invoicesPayload.push({ Id: invoices })
            } else {
                invoices.forEach((invoice) => {
                    let invoiceToPay = true
                    this.billings.forEach((bill) => {
                        if (bill.Id === invoice) {
                            if (bill.payment_status === 'paid') {
                                invoiceToPay = false
                            }
                        }
                    })
                    if (invoiceToPay === true) {
                        invoicesPayload.push({ Id: invoice })
                    }
                })
            }
            this.sendReminderInvoice({
                Invoices: invoicesPayload,
                WithPaymentLink: withLink,
            })
                .then((res) => {
                    this.$toast.success(
                        this.$t(
                            'success_sending_reminder_mail',
                            invoicesPayload.length === 1 ? 1 : 2,
                        ),
                    )
                    this.setResults()
                })
                .catch((err) => {
                    this.$toast.error(
                        this.$t(
                            'error_sending_reminder_mail',
                            invoicesPayload.length === 1 ? 1 : 2,
                        ),
                    )
                })
            // this.$toast.info(this.$t('not_implemented'))
        },
        showPayInvoiceModal(invoices) {
            this.v$.$reset()

            this.invoicesToPay = invoices
            this.invoiceForm = {
                PaymentType: 'transfer',
                PaymentDate: new Date().toISOString().substr(0, 10),
                ModificationUserId: this.userStore.current.id,
            }
            this.show = true
        },
        closePayInvoiceModal() {
            this.setResults()
            this.v$.$reset()
            this.invoicesToPay = null
            this.invoiceForm = {}
            this.show = false
        },
        submitPayModal() {
            const isValid = this.v$.$validate()
            if (isValid === false) {
                return this.$toast.error(this.$t('form_errors'))
            }
            const invoicesPayload = []
            // this.invoiceForm.PaymentType = this.invoiceForm.PaymentType.value
            if (typeof this.invoicesToPay === 'number') {
                invoicesPayload.push({
                    Id: this.invoicesToPay,
                    ...this.invoiceForm,
                })
            } else {
                this.invoicesToPay.forEach((invoice) => {
                    invoicesPayload.push({ Id: invoice, ...this.invoiceForm })
                })
            }
            this.setInvoiceAsPaid({ Invoices: invoicesPayload })
                .then((res) => {
                    this.$toast.success(
                        this.$t(
                            'success_invoice_set_as_paid',
                            invoicesPayload.length === 1 ? 1 : 2,
                        ),
                    )
                    this.closePayInvoiceModal()
                })
                .catch((err) => {
                    this.$toast.error(
                        this.$t(
                            'error_invoice_set_as_paid',
                            invoicesPayload.length === 1 ? 1 : 2,
                        ),
                    )
                })
            // this.$toast.info(this.$t('not_implemented'))
        },
        searchCustomUniverses(clear = false) {
            let filter = null
            clearInterval(this.ordersInterval)
            this.searchingCursor = true
            if (clear === true || this.selectedUniverses.length === 0) {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.universesOptions,
                }
            } else {
                filter = {
                    dbField: 'IdUniverse',
                    value: this.selectedUniverses,
                }
            }

            this.upFilter(filter)
        },
        removeFromSelectedUniverses(index) {
            clearInterval(this.ordersInterval)

            this.searchCustomUniverses()
        },
        downloadInvoice(invoice) {
            this.axios
                .get(`/api/v1/invoice/${invoice.id}/pdf`, {
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$cookies.get(
                            'user_session',
                        )}`,
                    },
                    signal: this.$controller.signal,
                })
                .then((res) => {
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', invoice.file)

                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    URL.revokeObjectURL(url)
                })
        },

        setDateFilter(dFilter) {
            this.searchingCursor = true
            const filterExistsIndex = this.filter.findIndex(
                (df) => df.dbField === dFilter.dbField,
            )
            if (filterExistsIndex !== -1) {
                this.filter[filterExistsIndex] = dFilter
            } else {
                this.filter.push({
                    dbField: dFilter.dbField,
                    value: dFilter.value,
                })
                if (dFilter.dbField === 'DueDateInvoice') {
                    this.dueDateFilter = dFilter.value
                } else if (dFilter.dbField === 'DateInvoice') {
                    this.dateFilter = dFilter.value
                }
            }
            this.setResults()
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filteredUnpaid')
            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filteredUnpaid')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page

            if (this.userStore.isB2C === true) {
                this.filter.push({
                    dbField: 'IdClient',
                    value: this.currentClient.id,
                })
            }
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchBillings(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredUnpaid', search)
            this.billingsInterval = setInterval(() => {
                this.searchBillings(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.billingsInterval)
            this.$cookies.remove('filteredUnpaid')
            this.perPage = perPage
            this.page = 1

            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
