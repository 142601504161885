<template>
    <main
        style="width: 96vw"
        class="px-4 pt-2 rounded-md h-full"
        :class="searchingCursor === true ? 'cursor-progress' : ''"
    >
        <div>
            <fetch-data-error v-if="fetchError" :dataType="'eventType_list'" />
            <filtrable-table
                v-else
                :class="searchingCursor === true ? 'cursor-progress' : ''"
                id="notificationsList"
                :items="eventTypes"
                :columns="columns"
                :tableWidth="'table-fixed w-auto'"
                :storeToSort="'notifications'"
                :page="page"
                :count="count"
                :loading="loading"
                @perPageChanged="changePerPage($event)"
                @updateSort="displaySearchResults($event)"
                :perPage="perPage"
                :sortDirection="sortDirection"
                :sortableFields="sortableFields"
                :totalPages="totalPages"
                :searchQuery="searchQuery"
                :refresh="refresh"
                :canAddNew="canAddNew()"
                :itemActions="itemActions"
                :multiActions="multiActions"
                @pageChanged="setPage($event)"
                @searched="debounceSearch"
                @sortDirectionChanged="updateSortDirection($event)"
                @selected="upFilter($event)"
                @clear="clearFilter($event)"
                @create="openModal(null, true)"
                @update="openModal($event)"
                @duplicate="openModal($event, true, true)"
                @activate="doDeactivate($event, true)"
                @deactivate="doDeactivate($event)"
                @deactivateAll="doDeactivateMany($event)"
                @openEnumModalInfo="openEnumModalInfo($event)"
                @removetriggers_list="doDeleteEventTrigger($event)"
            >
            </filtrable-table>
        </div>

        <modal
            v-if="show"
            id="addModal"
            :mxWidth="'w-2/3'"
            :canCancel="false"
            :closeMark="false"
            :showSubmit="false"
            :mxHeight="'h-screen'"
            :buttonAlign="'justify-center'"
        >
            <template #body>
                <create-or-update-notification
                    :isUpdate="isUpdate"
                    :isDuplicate="isDuplicate"
                    :notificationId="notificationId"
                    @cancel="openModal()"
                    @save="openModal()"
                ></create-or-update-notification
            ></template>
        </modal>
        <modal
            v-if="showEnumModalInfo"
            id="enumModalInfo"
            :mxWidth="'w-2/5'"
            :canCancel="true"
            :showSubmit="false"
            :cancelText="'close'"
            :mxHeight="'max-h-screen'"
            :buttonAlign="'justify-center'"
            @closeModal="openEnumModalInfo(null)"
        >
            <template #body>
                <span
                    v-if="
                        iframeSrc === '' &&
                        smsContent === null &&
                        waContent === null
                    "
                    class="flex justify-center italic text-lg"
                >
                    {{ $t('no_template_assigned') }}</span
                >
                <span v-else>
                    <span v-if="iframeSrc !== ''">
                        <h2
                            class="text-xl flex justify-center font-bold mb-1 pt-4 pb-2"
                        >
                            {{ $t('email_template') }}
                        </h2>
                        <iframe
                            class="w-full h-96"
                            :srcdoc="iframeSrc"
                            frameborder="0"
                        ></iframe>
                    </span>
                    <span v-if="smsContent !== null" class="mt-2">
                        <hr v-if="iframeSrc !== ''" />
                        <h2
                            class="text-xl flex justify-center font-bold mb-1 pt-4 pb-2"
                        >
                            {{ $t('sms_template') }}
                        </h2>
                        <sms-template
                            :headerText="smsContent.sender"
                            :messages="[
                                {
                                    sender: smsContent.sender,
                                    text: smsContent.text,
                                },
                            ]"
                        ></sms-template>
                    </span>
                    <span v-if="waContent !== null" class="mt-2">
                        <hr v-if="iframeSrc !== '' && smsContent !== null" />
                        <h2
                            class="text-xl flex justify-center font-bold mb-1 pt-4 pb-2"
                        >
                            {{ $t('wa_template') }}
                        </h2>
                        <whats-app-template
                            :avatarUrl="`/logo/${userStore.logo}header-nobg.png`"
                            :contactName="waContent.sender"
                            :messages="[
                                {
                                    sender: waContent.sender,
                                    text: waContent.text,
                                },
                            ]"
                        ></whats-app-template>
                    </span>
                </span>
            </template>
        </modal>
        <modal
            v-if="showConfirmModal"
            :headerText="confirmModalHeader"
            :buttonText="'confirm'"
            id="deletemodal"
            :buttonAlign="'justify-center'"
            @closeModal="actionConfirmation(false)"
            @sendFromModal="actionConfirmation(true)"
        >
            <template #body>
                <div class="mx-8">
                    <div class="flex justify-center font-semibold mt-4">
                        {{ $t(confirmModalText) }}
                    </div>
                    <div class="flex justify-center font-semibold mt-4">
                        {{
                            $t(
                                entityTypeToDelete,
                                elementsToConfirm.length === 1 ? 1 : 2,
                            )
                        }}
                    </div>
                    <div
                        v-for="(toConfirm, iToConfirm) in elementsToConfirm"
                        :key="iToConfirm"
                        class="flex justify-center mt-2"
                    >
                        <ul>
                            <li
                                class="flex text-sm items-center whitespace-nowrap"
                            >
                                <fa-icon icon="circle" class="fa-2xs mr-2" />
                                <span
                                    v-if="
                                        toConfirm.name && toConfirm.description
                                    "
                                    class="mr-1"
                                >
                                    {{
                                        `${toConfirm.name} - ${toConfirm.description}`
                                    }}
                                </span>
                                <span v-else-if="toConfirm.name" class="mr-1">
                                    {{ toConfirm.name }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div
                        class="flex text-xs italic items-center error-msg justify-center mt-4"
                    >
                        <fa-icon
                            :icon="'triangle-exclamation'"
                            class="mr-2 fa-md"
                        ></fa-icon>
                        {{ $t(confirmModalText2) }}
                    </div>
                </div></template
            ></modal
        >
    </main>
</template>
<script>
import FiltrableTable from '@/components/FiltrableTable.vue'
import FetchDataError from '@/components/atoms/FetchDataError.vue'
import Modal from '@/components/atoms/Modal.vue'
import SmsTemplate from '@/components/elements/SmsTemplate.vue'
import WhatsAppTemplate from '@/components/elements/WhatsAppTemplate.vue'
import filterList from '@/mixins/filterList'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useNotificationStore } from '@/stores/notificationStore'
import { useUserStore } from '@/stores/userStore'
import CreateOrUpdateNotification from '@/views/settings/notifications/CreateOrUpdateNotification.vue'
import { mapActions, mapStores, mapWritableState } from 'pinia'
import societyEventBus from '@/mixins/societyEventBusMixin'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'EventTypeList',
    mixins: [filterList, societyEventBus],
    components: {
        FiltrableTable,
        FetchDataError,
        CreateOrUpdateNotification,
        Modal,
        SmsTemplate,
        WhatsAppTemplate,
    },
    data() {
        return {
            smsContent: null,
            waContent: null,
            iframeSrc: '',
            enumInfo: {},
            showEnumModalInfo: false,
            entityTypeToDelete: 'notification_tc',
            showSummaryMap: false,
            notificationId: null,
            isUpdate: false,
            isDuplicate: false,
            show: false,
            refresh: 0,
            searchQuery: '',
            search: [],
            sortDirection: 'DESC',
            sortField: 'CreationDate',
            perPage: 10,
            page: 1,
            eventTypeInterval: null,
            formData: {},
            filter: [],
            columns: [],
            sortableFields: [
                { name: this.$t('name'), value: 'Name' },
                { name: this.$t('creation_date'), value: 'CreationDate' },
            ],
            showConfirmModal: false,
            elementsToConfirm: [],
            confirmModalHeader: 'confirm_modal_header',
            confirmModalText: 'confirm_modal_text',
            confirmModalText2: 'confirm_modal_text',
        }
    },
    watch: {
        eventTypes() {
            if (this.page === 1) {
                this.refresh += 1
            }
        },
    },

    created() {
        window.scrollTo(0, 0)
        const filteredList = this.$cookies.get('filteredEventTypes')
        this.columns = [
            {
                name: 'active',
                filtrable: true,
                dbField: 'Active',
                type: 'select',
                sortable: false,
                withIconNoText: true,
                selected: {
                    name: this.$t('active'),
                    value: 1,
                    dbField: 'Active',
                },
                options: [
                    {
                        name: 'active',
                        value: 1,
                        dbField: 'Active',
                    },
                    {
                        name: 'deactivated',
                        value: 0,
                        dbField: 'Active',
                    },
                ],
            },
            {
                name: 'name',
                filtrable: true,
                dbField: 'Name',
                type: 'search',
                sortable: true,
                isLink: checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.eventTypes.update,
                ),
            },
            {
                name: 'code',
                filtrable: true,
                dbField: 'Code',
                type: 'search',
                sortable: true,
            },
            {
                name: 'entity',
                filtrable: true,
                dbField: 'EntityName',
                type: 'search',
                sortable: true,
            },
            {
                name: 'description',
                filtrable: false,

                dbField: 'Description',
                sortable: false,
            },
            {
                name: 'triggers_list',
                filtrable: false,
                dbField: 'Triggers',
                sortable: false,
                enum: true,
                canRemoveEnum: checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.eventTypes.delete,
                ),
                emitParent: true,
                clickableEnum: true,
                enumClickIcon: 'circle-info',
                enumColNumber: 'grid-cols-3',
                enumClickAction: 'openEnumModalInfo',
            },
        ]

        if (filteredList) {
            this.search = filteredList.query

            // this.searchQuery = filteredList.query[0]
            //     ? filteredList.query[0].term
            //     : ''
            this.sortDirection = filteredList.sortDirection
            this.filter = filteredList.filter
            this.sortField = filteredList.sortField
            this.perPage = filteredList.perPage
            this.page = filteredList.page
        } else {
            this.filter.push({ dbField: 'Active', value: 1, name: 'active' })
        }
        this.setResults()
    },

    unmounted() {
        clearInterval(this.eventTypeInterval)
    },
    computed: {
        notificationsActionAccess() {
            return actionsAccess.notifications
        },
        ...mapWritableState(useNotificationStore, {
            currentEventType: 'current',
            eventTypes: 'all',
            count: 'count',
            fetchError: 'fetchError',
            totalPages: 'totalPages',
            loading: 'loading',
        }),

        ...mapStores(useUserStore),

        itemActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.eventTypes.update,
                )
            ) {
                actions.push({
                    name: 'edit',
                    action: 'update',
                    icon: 'pen',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.eventTypes.create,
                )
            ) {
                actions.push({
                    name: 'duplicate',
                    action: 'duplicate',
                    icon: 'clone',
                })
            }
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.eventTypes.delete,
                )
            ) {
                actions.push({
                    name: 'deactivate',
                    action: 'deactivate',
                    icon: 'times',
                })
            }

            return actions
        },
        multiActions() {
            const actions = []
            if (
                checkAccess(
                    this.userStore,
                    this.notificationsActionAccess.eventTypes.delete,
                )
            ) {
                actions.push({
                    name: 'deactivate_selection',
                    action: 'deactivateAll',
                    icon: 'times',
                })
            }
            return actions
        },
    },
    methods: {
        ...mapActions(useNotificationStore, [
            'searchEventTypes',
            'deactivateNotif',
            'deactivateManyNotifs',
            'deleteTrigger',
            'update',
        ]),
        canAddNew() {
            return checkAccess(
                this.userStore,
                this.notificationsActionAccess.eventTypes.create,
            )
        },
        upFilter(event) {
            clearInterval(this.eventTypeInterval)
            this.updateFilter(event)
        },

        openEnumModalInfo(enumItem = null) {
            this.iframeSrc = ''
            this.smsContent = null
            this.waContent = null
            if (enumItem !== null) {
                if (enumItem.CommunicationTemplateEmail) {
                    this.iframeSrc =
                        enumItem.CommunicationTemplateEmail.CommunicationTemplateContent.FieldHtmlPart
                }

                if (enumItem.CommunicationTemplateSMS) {
                    this.smsContent = {
                        sender: enumItem.CommunicationTemplateSMS
                            .CommunicationTemplateContent.FieldSenderName,
                        text: enumItem.CommunicationTemplateSMS
                            .CommunicationTemplateContent.FieldTextPart,
                    }
                }
                if (enumItem.CommunicationTemplateWhatsApp) {
                    this.waContent = {
                        sender: enumItem.CommunicationTemplateWhatsApp
                            .CommunicationTemplateContent.FieldSenderName,
                        text: enumItem.CommunicationTemplateWhatsApp
                            .CommunicationTemplateContent.FieldTextPart,
                    }
                }
            }
            this.showEnumModalInfo = !this.showEnumModalInfo
        },
        openModal(id = null, create = false, duplicate = false) {
            this.isUpdate = false
            this.isDuplicate = false
            this.notificationId = null
            clearInterval(this.eventTypeInterval)
            if (id) {
                this.notificationId = id
                if (duplicate) {
                    this.isUpdate = false
                    this.isDuplicate = true
                } else {
                    this.isUpdate = true
                }
            } else {
                this.currentEventType = null
                if (create === false) {
                    this.setResults()
                }
            }
            this.show = !this.show
        },

        actionConfirmation(confirmDelete) {
            this.confirmAction = confirmDelete
            this.showConfirmModal = false
        },
        doDeactivate(id, activate = false) {
            clearInterval(this.eventTypeInterval)
            if (activate === true) {
                const eventType = this.eventTypes.find(
                    (evtType) => evtType.Id === id,
                )
                eventType.Active = 1

                this.update(eventType)
                    .then(() => {
                        this.$toast.success(this.$t('notification_activate_ok'))

                        this.setResults()
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('notification_activate_ko'))
                    })
            } else {
                this.deactivateNotif(id)
                    .then(() => {
                        this.$toast.success(
                            this.$t('notification_deactivate_ok', 1),
                        )

                        this.setResults()
                    })
                    .catch(() => {
                        this.$toast.error(
                            this.$t('notification_deactivate_ko', 1),
                        )
                    })
            }
        },
        doDeactivateMany(ids) {
            this.deactivateManyNotifs(ids)
                .then(() => {
                    this.$toast.success(
                        this.$t('notification_deactivate_ok', 2),
                    )

                    this.setResults()
                })
                .catch(() => {
                    this.$toast.error(this.$t('notification_deactivate_ko', 2))
                })
        },

        doDeleteEventTrigger(data) {
            clearInterval(this.eventTypeInterval)
            this.confirmAction = null
            this.elementsToConfirm = []
            this.entityTypeToDelete = 'trigger_tc'
            this.confirmModalHeader = 'delete_modal_generic_header'
            this.confirmModalText = 'delete_modal_generic_text'
            this.confirmModalText2 = 'irreversible_action'
            this.elementsToConfirm.push({ name: data.enumItem.Action })
            this.showConfirmModal = true

            const deleteInterval = setInterval(async () => {
                if (this.confirmAction !== null) {
                    clearInterval(deleteInterval)
                    this.elementsToConfirm = []
                    if (this.confirmAction === true) {
                        this.confirmAction = null
                        this.deleteTrigger(data.enumItem.Id)
                            .then(() => {
                                this.setResults()
                                this.$toast.success(this.$t('delete_ok'))
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('delete_ko'))
                            })
                    } else {
                        this.confirmAction = null

                        this.$toast.info(this.$t('action_canceled'))
                    }
                }
            }, 150)
        },
        setPage(isNext) {
            this.searchingCursor = true

            clearInterval(this.eventTypeInterval)
            this.$cookies.remove('filteredEventTypes')

            if (isNext) {
                this.page += 1
            } else {
                this.page -= 1
            }

            this.setResults()
        },
        displaySearchResults(search) {
            this.searchingCursor = true

            clearInterval(this.eventTypeInterval)
            this.$cookies.remove('filteredEventTypes')
            this.search = search.query
            this.date = search.date
            this.sortDirection = search.sortDirection
            this.filter = search.filter
            this.sortField = search.sortField
            this.perPage = search.perPage
            this.page = search.page
            this.setRealSearch(this.search)
            this.columns.forEach((col, index) => {
                if (col.type === 'select' || col.type === 'icon-select') {
                    if (!col.multi) {
                        this.setRealSelected(col.dbField, index)
                    } else {
                        this.setRealSelected(col.dbField, index, true)
                    }
                }
            })

            this.searchEventTypes(search).then(() => {
                this.searchingCursor = false

                this.refresh += 1
            })

            this.$cookies.set('filteredEventTypes', search)
            this.eventTypeInterval = setInterval(() => {
                this.searchEventTypes(search)
            }, 20000)
        },
        changePerPage(perPage) {
            this.searchingCursor = true

            clearInterval(this.eventTypeInterval)
            this.$cookies.remove('filteredEventTypes')
            this.perPage = perPage
            this.page = 1
            this.setResults()
        },

        setResults() {
            const search = {
                query: this.search,
                sortDirection: this.sortDirection,
                sortField: this.sortField,
                filter: this.filter,
                page: this.page,
                perPage: this.perPage,
            }
            this.displaySearchResults(search)
        },
    },
}
</script>
