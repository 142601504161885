const actionsAccess = {
    areas: {
        update: ['AREAS_UPDATE', 'AREAS_FULL', 'FULL_ACCESS'],
        activate: [
            'AREAS_ACTIVATE',
            'AREAS_FULL',
            'FULL_ACCESS',
            'AREAS_UPDATE',
        ],
        create: ['AREAS_CREATE', 'AREAS_FULL', 'FULL_ACCESS'],
    },
    billings: {
        update: ['BILLINGS_UPDATE', 'BILLINGS_FULL', 'FULL_ACCESS'],
        delete: ['BILLINGS_DELETE', 'BILLINGS_FULL', 'FULL_ACCESS'],
        create: ['BILLINGS_CREATE', 'BILLINGS_FULL', 'FULL_ACCESS'],
    },
    harbors: {
        list: ['HARBORS_LIST', 'HARBORS_FULL', 'FULL_ACCESS'],
        create: ['HARBORS_CREATE', 'HARBORS_FULL', 'FULL_ACCESS'],
        update: ['HARBORS_UPDATE', 'HARBORS_FULL', 'FULL_ACCESS'],
        delete: ['HARBORS_DELETE', 'HARBORS_FULL', 'FULL_ACCESS'],
        docks: {
            list: ['DOCKS_LIST', 'DOCKS_FULL', 'HARBORS_FULL', 'FULL_ACCESS'],
            create: [
                'DOCKS_CREATE',
                'DOCKS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'DOCKS_UPDATE',
                'DOCKS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            inspect: [
                'DOCKS_INSPECT',
                'DOCKS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'DOCKS_DELETE',
                'DOCKS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
        },
        inspections: {
            list: [
                'INSPECTIONS_LIST',
                'INSPECTIONS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            create: [
                'INSPECTIONS_CREATE',
                'INSPECTIONS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'INSPECTIONS_UPDATE',
                'INSPECTIONS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'INSPECTIONS_DELETE',
                'INSPECTIONS_FULL',
                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
        },
        defenses: {
            list: [
                'DEFENSES_LIST',
                'DEFENSES_FULL',
                'DOCKS_FULL',

                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            create: [
                'DEFENSES_CREATE',
                'DEFENSES_FULL',
                'DOCKS_FULL',

                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'DEFENSES_UPDATE',
                'DEFENSES_FULL',
                'DOCKS_FULL',

                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'DEFENSES_DELETE',
                'DEFENSES_FULL',
                'DOCKS_FULL',

                'HARBORS_FULL',
                'FULL_ACCESS',
            ],
        },
    },

    clients: {
        update: ['CLIENTS_UPDATE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        delete: ['CLIENTS_DELETE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        create: ['CLIENTS_CREATE', 'CLIENTS_FULL', 'FULL_ACCESS'],
        contacts: {
            list: ['CONTACTS_LIST', 'CONTACTS_FULL', 'FULL_ACCESS'],
            create: ['CONTACTS_CREATE', 'CONTACTS_FULL', 'FULL_ACCESS'],
            update: ['CONTACTS_UPDATE', 'CONTACTS_FULL', 'FULL_ACCESS'],
            delete: ['CONTACTS_DELETE', 'CONTACTS_FULL', 'FULL_ACCESS'],
        },
        contracts: {
            list: [
                'CONTRACT_LIST',
                'CLIENTS_FULL',
                'CONTRACT_FULL',
                'FULL_ACCESS',
            ],
            create: ['CONTRACT_CREATE', 'CONTRACT_FULL', 'FULL_ACCESS'],
            update: ['CONTRACT_UPDATE', 'CONTRACT_FULL', 'FULL_ACCESS'],
            delete: ['CONTRACT_DELETE', 'CONTRACT_FULL', 'FULL_ACCESS'],
        },
        addresses: {
            list: [
                'DELIVERY-ADDRESSES_LIST',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            create: [
                'DELIVERY-ADDRESSES_CREATE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'DELIVERY-ADDRESSES_UPDATE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'DELIVERY-ADDRESSES_DELETE',
                'DELIVERY-ADDRESSES_FULL',
                'FULL_ACCESS',
            ],
        },
        contactClientHistory: {
            list: [
                'CONTACT-CLIENT-HISTORY_FULL',
                'CONTACT-CLIENT-HISTORY_LIST',
                'FULL_ACCESS',
            ],
            create: [
                'CONTACT-CLIENT-HISTORY_CREATE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'CONTACT-CLIENT-HISTORY_UPDATE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
            archive: [
                'CONTACT-CLIENT-HISTORY_ARCHIVE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'CONTACT-CLIENT-HISTORY_DELETE',
                'CONTACT-CLIENT-HISTORY_FULL',
                'FULL_ACCESS',
            ],
        },
        credits: {
            create: ['CLIENT-CREDITS_CREATE', 'CREDITS_FULL', 'FULL_ACCESS'],
            update: ['CLIENT-CREDITS_UPDATE', 'CREDITS_FULL', 'FULL_ACCESS'],
        },
    },
    dashboard: {
        email: ['DASHBOARD_EMAIL_STATS', 'FULL_ACCESS'],
        operator: ['DASHBOARD_OPERATOR', 'FULL_ACCESS'],
        client: ['DASHBOARD_CLIENT', 'FULL_ACCESS'],
        harbor: ['DASHBOARD_HARBOR', 'FULL_ACCESS'],
    },
    roles: {
        update: ['ROLES_UPDATE', 'ROLES_FULL', 'FULL_ACCESS'],
        delete: ['ROLES_DELETE', 'ROLES_FULL', 'FULL_ACCESS'],
        create: ['ROLES_CREATE', 'ROLES_FULL', 'FULL_ACCESS'],
        assignAuthToRole: ['ROLES_ADDAUTH', 'ROLES_FULL', 'FULL_ACCESS'],
    },
    universes: {
        list: ['UNIVERSES_LIST', 'UNIVERSES_FULL', 'USERS_FULL', 'FULL_ACCESS'],
        create: [
            'UNIVERSES_CREATE',
            'UNIVERSES_FULL',
            'USERS_FULL',
            'FULL_ACCESS',
        ],
        update: [
            'UNIVERSES_UPDATE',
            'UNIVERSES_FULL',
            'USERS_FULL',
            'FULL_ACCESS',
        ],
        delete: [
            'UNIVERSES_DELETE',
            'UNIVERSES_FULL',
            'USERS_FULL',
            'FULL_ACCESS',
        ],
    },
    users: {
        update: ['USERS_UPDATE', 'USERS_FULL', 'FULL_ACCESS'],
        delete: ['USERS_DELETE', 'USERS_FULL', 'FULL_ACCESS'],
        create: ['USERS_CREATE', 'USERS_FULL', 'FULL_ACCESS'],
        assignRole: ['ROLES_ASSIGN', 'USERS_FULL', 'FULL_ACCESS'],
        assignUniverse: ['UNIVERSES_ASSIGN', 'USERS_FULL', 'FULL_ACCESS'],

        resetPass: ['USERS_SENDRPLINK', 'USERS_FULL', 'FULL_ACCESS'],
    },
    drivers: {
        list: ['DRIVERS_LIST', 'DRIVERS_FULL', 'FULL_ACCESS'],
        create: ['DRIVERS_CREATE', 'DRIVERS_FULL', 'FULL_ACCESS'],
        update: ['DRIVERS_UPDATE', 'DRIVERS_FULL', 'FULL_ACCESS'],
        // delete: ['DRIVERS_DELETE', 'DRIVERS_FULL', 'FULL_ACCESS'],

        activate: [
            'DRIVERS_ACTIVATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'DRIVERS_UPDATE',
        ],
    },
    driverPlanning: {
        read: [
            'PLANNING-BASE_READ',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'WEEKLY_FULL',
        ],
        update: [
            'PLANNING-BASE_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
            'WEEKLY_FULL',
        ],
    },
    weeklyPlanning: {
        read: ['WEEKLY_READ', 'WEEKLY_FULL', 'DRIVERS_FULL', 'FULL_ACCESS'],
        create: [
            'WEEKLY_CREATEACTIVITY',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
        updateActivity: [
            'WEEKLY_UPDATEACTIVITY',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
        update: [
            'WEEKLY_UPDATE',
            'WEEKLY_FULL',
            'DRIVERS_UPDATE',
            'DRIVERS_FULL',
            'FULL_ACCESS',
        ],
    },
    suppliers: {
        list: ['SUPPLIERS_LIST', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        create: ['SUPPLIERS_CREATE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        update: ['SUPPLIERS_UPDATE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
        delete: ['SUPPLIERS_DELETE', 'SUPPLIERS_FULL', 'FULL_ACCESS'],
    },
    vehicles: {
        list: ['VEHICLES_LIST', 'VEHICLES_FULL', 'FULL_ACCESS'],
        create: ['VEHICLES_CREATE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        update: ['VEHICLES_UPDATE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        // delete: ['VEHICLES_DELETE', 'VEHICLES_FULL', 'FULL_ACCESS'],
        activate: [
            'VEHICLES_ACTIVATE',
            'VEHICLES_FULL',
            'FULL_ACCESS',
            'VEHICLES_UPDATE',
        ],
        maintenances: {
            list: [
                'VEHICLES_FULL',
                'FULL_ACCESS',
                'VEHICLES_MAINTENANCES_LIST',
                'VEHICLES_MAINTENANCES_FULL',
            ],
            create: [
                'VEHICLES_MAINTENANCES_CREATE',
                'VEHICLES_MAINTENANCES_FULL',
                'FULL_ACCESS',
            ],
            update: [
                'VEHICLES_MAINTENANCES_UPDATE',
                'VEHICLES_MAINTENANCES_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'VEHICLES_MAINTENANCES_DELETE',
                'VEHICLES_MAINTENANCES_FULL',
                'FULL_ACCESS',
            ],
        },
    },
    roundTripOrder: ['O_ROUNDTRIP_ALLOWED', 'FULL_ACCESS'],
    roundTripRec: ['REC_ROUNDTRIP_ALLOWED', 'FULL_ACCESS'],
    orders: {
        idExternal: {
            update: ['ORDERS_UPDATE_IDEXTERNAL', 'ORDERS_FULL', 'FULL_ACCESS'],
            create: ['ORDERS_CREATE_IDEXTERNAL', 'ORDERS_FULL', 'FULL_ACCESS'],
        },
        displayRefConteneur: ['ORDERS_DISPLAY_CONTENEURREF', 'FULL_ACCESS'],
        finishManySteps: [
            'ORDERS_FINISH_MANY_STEPS',
            'ORDERS_FULL',
            'FULL_ACCESS',
        ],
        containersOnHold: {
            list: ['WAITING_CONTAINERS_LIST', 'FULL_ACCESS'],
            update: ['WAITING_CONTAINERS_UPDATE', 'FULL_ACCESS'],
        },
        displayGroups: ['ORDERS_DISPLAY_GROUPS', 'FULL_ACCESS'],
        livePrice: ['ORDERS_LIVEPRICE', 'FULL_ACCESS'],
        updatePrice: ['ORDERS_UPDATEPRICE', 'FULL_ACCESS'],
        forcePrice: ['ORDERS_FORCEPRICE', 'FULL_ACCESS'],
        orderPricing: ['ORDERS_PRICINGSCENARIO', 'FULL_ACCESS'],
        tourPricing: ['ORDERS_TOURPRICINGSCENARIO', 'FULL_ACCESS'],
        createMultipacks: ['PACKAGES_CREATE_MULTIPACKS', 'FULL_ACCESS'],
        deleteMultipacks: ['PACKAGES_DELETE_MULTIPACKS', 'FULL_ACCESS'],
        list: ['ORDERS_LIST', 'ORDERS_FULL', 'FULL_ACCESS'],
        create: ['ORDERS_CREATE', 'ORDERS_FULL', 'FULL_ACCESS'],
        update: ['ORDERS_UPDATE', 'ORDERS_FULL', 'FULL_ACCESS'],
        cancel: ['ORDERS_CANCEL', 'ORDERS_FULL', 'FULL_ACCESS'],
        updateStatus: ['ORDERS_STEPSSTATUS', 'ORDERS_FULL', 'FULL_ACCESS'],
        assign: {
            driver: ['ORDERS_ASSIGNDRIVER', 'ORDERS_FULL', 'FULL_ACCESS'],
            vehicle: ['ORDERS_ASSIGNVEHICLE', 'ORDERS_FULL', 'FULL_ACCESS'],
        },
        refunds: {
            list: ['REFUNDS_LIST', 'REFUNDS_FULL', 'FULL_ACCESS'],
            create: ['REFUNDS_CREATE', 'REFUNDS_FULL', 'FULL_ACCESS'],
            validate: ['REFUNDS_VALIDATE', 'REFUNDS_FULL', 'FULL_ACCESS'],
            cancel: ['REFUNDS_CANCEL', 'REFUNDS_FULL', 'FULL_ACCESS'],
        },
        webPayment: ['ORDERS_WEB_PAYMENT', 'FULL_ACCESS'],
        delete: ['ORDERS_DELETE', 'ORDERS_FULL', 'FULL_ACCESS'],
        signatures: ['ORDERS_SIGNATURES', 'ORDERS_FULL', 'FULL_ACCESS'],
        trackings: ['ORDERS_TRACKINGS', 'ORDERS_FULL', 'FULL_ACCESS'],
        imports: {
            list: ['ORDERS-IMPORT_LIST', 'ORDERS-IMPORT_FULL', 'FULL_ACCESS'],
            create: [
                'ORDERS-IMPORT_CREATE',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
            cancel: [
                'ORDERS-IMPORT_CANCEL',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
            delete: [
                'ORDERS-IMPORT_DELETE',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
            download: [
                'ORDERS-IMPORT_DOWNLOAD',
                'ORDERS-IMPORT_FULL',
                'FULL_ACCESS',
            ],
        },
        addUserFilesToOrder: [
            'ADD_FILE_USERUPLOAD',
            'ORDERS_FULL',
            'FULL_ACCESS',
        ],
        deleteUserFilesFromOrder: [
            'DELETE_FILE_USERUPLOAD',
            'ORDERS_FULL',
            'FULL_ACCESS',
        ],
        containers: {
            list: ['PACKAGES_LIST', 'PACKAGES_FULL', 'FULL_ACCESS'],
            create: ['PACKAGES_CREATE', 'PACKAGES_FULL', 'FULL_ACCESS'],
            update: ['PACKAGES_UPDATE', 'PACKAGES_FULL', 'FULL_ACCESS'],
            activate: [
                'PACKAGES_ACTIVATE',
                'PACKAGES_FULL',
                'FULL_ACCESS',
                'PACKAGES_UPDATE',
            ],
            updateDefault: [
                'PACKAGES_UPDATE_DEFAULT',
                'PACKAGES_FULL',
                'FULL_ACCESS',
            ],
        },
        recurrents: {
            list: ['RECURRENTS_LIST', 'RECURRENTS_FULL', 'FULL_ACCESS'],
            create: ['RECURRENTS_CREATE', 'RECURRENTS_FULL', 'FULL_ACCESS'],
            update: ['RECURRENTS_UPDATE', 'RECURRENTS_FULL', 'FULL_ACCESS'],
            delete: ['RECURRENTS_DELETE', 'RECURRENTS_FULL', 'FULL_ACCESS'],
            displayGroups: ['RECURRENTS_DISPLAY_GROUPS', 'FULL_ACCESS'],

            activate: [
                'RECURRENTS_ACTIVATE',
                'RECURRENTS_FULL',
                'FULL_ACCESS',
                'RECURRENTS_UPDATE',
            ],
        },
        unpaid: {
            list: ['UNPAID_LIST', 'UNPAID_FULL', 'FULL_ACCESS'],
            update: ['UNPAID_UPDATE', 'UNPAID_FULL', 'FULL_ACCESS'],
            delete: ['UNPAID_DELETE', 'UNPAID_FULL', 'FULL_ACCESS'],
            sendMail: ['UNPAID_SENDMAIL', 'UNPAID_FULL', 'FULL_ACCESS'],
        },
        sales: {
            view: [
                'FULL_ACCESS',
                'SALES_FULL',
                'SALES_CREATE',
                'SALES_UPDATE',
                'SALES_DELETE',
            ],
            create: ['FULL_ACCESS', 'SALES_FULL', 'SALES_CREATE'],
            delete: ['SALES_DELETE', 'SALES_FULL', 'FULL_ACCESS'],
            update: ['SALES_UPDATE', 'SALES_FULL', 'FULL_ACCESS'],
            addUpdateInOrder: [
                'SALES_ADD_UP_IN_ORDER',
                'SALES_FULL',
                'FULL_ACCESS',
            ],
            deleteInOrder: [
                'SALES_DELETE_IN_ORDER',
                'SALES_FULL',
                'FULL_ACCESS',
            ],
        },
        articles: {
            list: ['FULL_ACCESS', 'ARTICLES_FULL', 'ARTICLES_LIST'],
            create: ['FULL_ACCESS', 'ARTICLES_FULL', 'ARTICLES_CREATE'],
            delete: ['ARTICLES_DELETE', 'ARTICLES_FULL', 'FULL_ACCESS'],
            update: ['ARTICLES_UPDATE', 'ARTICLES_FULL', 'FULL_ACCESS'],
        },
        hazardous: {
            list: ['FULL_ACCESS', 'HAZARDOUS_FULL', 'HAZARDOUS_LIST'],
            create: ['FULL_ACCESS', 'HAZARDOUS_FULL', 'HAZARDOUS_CREATE'],
            delete: ['HAZARDOUS_DELETE', 'HAZARDOUS_FULL', 'FULL_ACCESS'],
            update: ['HAZARDOUS_UPDATE', 'HAZARDOUS_FULL', 'FULL_ACCESS'],
            addUpdateInOrder: [
                'HAZARDOUS_ADD_UP_IN_ORDER',
                'HAZARDOUS_FULL',
                'FULL_ACCESS',
            ],
            deleteInOrder: [
                'HAZARDOUS_DELETE_IN_ORDER',
                'HAZARDOUS_FULL',
                'FULL_ACCESS',
            ],
        },
        groups: {
            list: ['ORDERGROUPS_LIST', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
            create: ['ORDERGROUPS_CREATE', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
            update: ['ORDERGROUPS_UPDATE', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
            delete: ['ORDERGROUPS_DELETE', 'ORDERGROUPS_FULL', 'FULL_ACCESS'],
        },
    },
    settings: {
        orders: ['SETTINGS_ORDERSCODES', 'FULL_ACCESS', 'SETTINGS_FULL'],

        notifications: [
            'SETTINGS_NOTIFICATIONS',
            'FULL_ACCESS',
            'SETTINGS_FULL',
        ],
        pricings: ['SETTINGS_PRICINGS', 'FULL_ACCESS', 'SETTINGS_FULL'],
    },
    notifications: {
        list: ['NOTIFS_LIST', 'FULL_ACCESS', 'NOTIFS_FULL'],

        create: ['NOTIFS_CREATE', 'FULL_ACCESS', 'NOTIFS_FULL'],
        update: ['NOTIFS_UPDATE', 'FULL_ACCESS', 'NOTIFS_FULL'],
        delete: ['NOTIFS_DELETE', 'FULL_ACCESS', 'NOTIFS_FULL'],
        eventTypes: {
            list: [
                'NOTIFS_EVENTTYPES_LIST',
                'FULL_ACCESS',
                'NOTIFS_EVENTTYPES_FULL',
                'NOTIFS_FULL',
            ],
            create: [
                'NOTIFS_EVENTTYPES_CREATE',
                'FULL_ACCESS',
                'NOTIFS_EVENTTYPES_FULL',
                'NOTIFS_FULL',
            ],
            update: [
                'NOTIFS_EVENTTYPES_UPDATE',
                'FULL_ACCESS',
                'NOTIFS_EVENTTYPES_FULL',
                'NOTIFS_FULL',
            ],
            delete: [
                'NOTIFS_EVENTTYPES_DELETE',
                'FULL_ACCESS',
                'NOTIFS_EVENTTYPES_FULL',
                'NOTIFS_FULL',
            ],
        },
        templates: {
            list: [
                'NOTIFS_TEMPLATES_LIST',
                'FULL_ACCESS',
                'NOTIFS_TEMPLATES_FULL',
                'NOTIFS_FULL',
            ],
            create: [
                'NOTIFS_TEMPLATES_CREATE',
                'FULL_ACCESS',
                'NOTIFS_TEMPLATES_FULL',
                'NOTIFS_FULL',
            ],
            update: [
                'NOTIFS_TEMPLATES_UPDATE',
                'FULL_ACCESS',
                'NOTIFS_TEMPLATES_FULL',
                'NOTIFS_FULL',
            ],
            delete: [
                'NOTIFS_TEMPLATES_DELETE',
                'FULL_ACCESS',
                'NOTIFS_TEMPLATES_FULL',
                'NOTIFS_FULL',
            ],
        },
        // triggers: {
        //     list: ['NOTIFS_TRIGGERS_LIST', 'FULL_ACCESS', 'NOTIFS_FULL'],
        //     create: ['NOTIFS_TRIGGERS_CREATE', 'FULL_ACCESS', 'NOTIFS_FULL'],
        //     update: ['NOTIFS_TRIGGERS_UPDATE', 'FULL_ACCESS', 'NOTIFS_FULL'],
        //     delete: ['NOTIFS_TRIGGERS_DELETE', 'FULL_ACCESS', 'NOTIFS_FULL'],
        // },
    },
    pricings: {
        list: ['PRICINGS_LIST', 'FULL_ACCESS', 'PRICINGS_FULL'],

        create: ['PRICINGS_CREATE', 'FULL_ACCESS', 'PRICINGS_FULL'],
        update: ['PRICINGS_UPDATE', 'FULL_ACCESS', 'PRICINGS_FULL'],
        delete: ['PRICINGS_DELETE', 'FULL_ACCESS', 'PRICINGS_FULL'],
        params: {
            list: ['PRICINGS_PARAMS_LIST', 'FULL_ACCESS', 'PRICINGS_FULL'],
            create: ['PRICINGS_PARAMS_CREATE', 'FULL_ACCESS', 'PRICINGS_FULL'],
            update: ['PRICINGS_PARAMS_UPDATE', 'FULL_ACCESS', 'PRICINGS_FULL'],
            delete: ['PRICINGS_PARAMS_DELETE', 'FULL_ACCESS', 'PRICINGS_FULL'],
        },

        groups: {
            list: [
                'PRICINGS_POSTALGROUPS_LIST',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
            create: [
                'PRICINGS_POSTALGROUPS_CREATE',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
            update: [
                'PRICINGS_POSTALGROUPS_UPDATE',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
            delete: [
                'PRICINGS_POSTALGROUPS_DELETE',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
        },
        clientGroupsZip: {
            list: [
                'PRICINGS_CLIENTGROUPS_ZIP_LIST',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
            create: [
                'PRICINGS_CLIENTGROUPS_ZIP_CREATE',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
            update: [
                'PRICINGS_CLIENTGROUPS_ZIP_UPDATE',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
            delete: [
                'PRICINGS_CLIENTGROUPS_ZIP_DELETE',
                'FULL_ACCESS',
                'PRICINGS_FULL',
            ],
        },
    },
    tours: {
        map: ['TOURS_MAPVIEW', 'FULL_ACCESS', 'TOURS_FULL'],
        create: ['TOURS_CREATE', 'FULL_ACCESS', 'TOURS_FULL'],
        delete: ['TOURS_DELETE', 'FULL_ACCESS', 'TOURS_FULL'],
        update: ['TOURS_UPDATE', 'FULL_ACCESS', 'TOURS_FULL'],
    },
    simulations: {
        map: ['SIMULATIONS_MAPVIEW', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
        update: ['SIMULATIONS_UPDATE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
        create: ['SIMULATIONS_GENERATE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
        delete: ['SIMULATIONS_DELETE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
        integrate: ['SIMULATIONS_INTEGRATE', 'FULL_ACCESS', 'SIMULATIONS_FULL'],
    },
    daily: {
        map: ['DAILY_MAPVIEW', 'ORDERS_FULL', 'FULL_ACCESS', 'DAILY_FULL'],
        update: ['DAILY_FULL', 'ORDERS_FULL', 'ORDERS_UPDATE', 'FULL_ACCESS'],
    },
}

export default actionsAccess
